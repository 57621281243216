import { useContainer } from "@/components/containers/ContainerProvider";
import SfyForm from "@/components/form/SfyForm";
import FormSelect from "@/components/form/inputs/FormSelect";
import FormTextInput from "@/components/form/inputs/FormTextInput";
import { ActionIcon, Group, Table } from "@mantine/core";
import { WorkflowNodeContext, WorkflowNodeValues } from "../../descriptors/descriptor";
import { ActionResultType } from "@/components/actions/actions";
import { FaMinus, FaPlus } from "react-icons/fa";
import { RxDragHandleHorizontal } from "react-icons/rx";

function getPropertyFields(ctx: WorkflowNodeContext) {
    Object.entries(ctx.inputs)
    return []
}

export default function GenerateReportConfigure({ ctx } : {values: WorkflowNodeValues, ctx: WorkflowNodeContext}) {
    const { closeAllDrawers } = useContainer();

    const saveFilter = async () => {
        closeAllDrawers()
        return { type: ActionResultType.NONE }
    }
    return <SfyForm onSubmit={saveFilter} onClose={closeAllDrawers}>        
        <label>Report Type</label>
        <FormSelect data={[{ value: 'CSV', label: 'CSV' }]} defaultValue="CSV" allowDeselect={false}></FormSelect>
        <label>Mappings</label>
        {JSON.stringify(ctx.inputs)}
        <Table style={{textAlign: 'left', maxWidth: 500}}>
            <thead>
                <th></th>
                <th style={{fontWeight: 500}}>Column</th>
                <th style={{fontWeight: 500}}>Field</th>
                <th></th>
            </thead>
            <tbody>
                <tr>
                    <td style={{paddingRight: 10}}>
                        <ActionIcon color="gray" variant="light">
                            <RxDragHandleHorizontal></RxDragHandleHorizontal>
                        </ActionIcon>
                    </td>
                    <td>
                        <FormTextInput asField placeholder="Column Name" size="sm" styles={{input: {paddingLeft: 0, borderWidth: 0}}}></FormTextInput>
                    </td>
                    <td>
                        <FormSelect
                            data={getPropertyFields(ctx)}
                            styles={{input: {border: 0, paddingLeft: 0}}}
                            placeholder="Field"
                        ></FormSelect>
                    </td>
                    <td>
                        <Group gap={5}>
                            <ActionIcon size="sm" color="green" variant="light">
                                <FaPlus></FaPlus>
                            </ActionIcon>
                            <ActionIcon size="sm" color="red" variant="light">
                                <FaMinus></FaMinus>
                            </ActionIcon>
                        </Group>
                    </td>
                </tr>
            </tbody>
        </Table>
    </SfyForm>
}