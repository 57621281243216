import GraphNavigator, { GraphNavigatorProps } from "../navigator/GraphNavigator";
import { ReactNode } from "react";
import classes from './GraphInteractor.module.css';

type GraphInteractorProps = {
    navigatorProps: GraphNavigatorProps,
    children: ReactNode
}

export default function GraphInteractor({ navigatorProps, children } : GraphInteractorProps) {
    return <>
        <GraphNavigator {...navigatorProps}></GraphNavigator>
        <div className={classes.body}>
            {children}
        </div>
    </>
}