import { TiFlowMerge } from "react-icons/ti";
import { WorkflowNodeProps, WorkflowNodeTemplate } from "./WorkflowNodeTemplate";

export default function ControlFlowBlock(props : WorkflowNodeProps) {
    return <WorkflowNodeTemplate
            {...props}
            icon={<TiFlowMerge color="white" size="0.85rem"></TiFlowMerge>}
            headerColor="#8e3d64"
            sourceEnabled
            targetEnabled
        >
        </WorkflowNodeTemplate>
}