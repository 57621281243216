import { StepContentProps } from "@/components/form/MultiStepForm";
import { Grid, Group, Stack } from "@mantine/core";
import { useMemo, useState } from "react";
import classes from './ReportFieldsForm.module.css';
import cx from 'clsx';
import { BiDotsVertical, BiPlus } from "react-icons/bi";
import FormCheckbox from "@/components/form/FormCheckbox";

function splitArrayInTwo<T>(arr: T[]) {
    const mid = Math.ceil(arr.length / 2);
    const firstHalf = arr.slice(0, mid);
    const secondHalf = arr.slice(mid);
    return [firstHalf, secondHalf];
}

type ReportFieldTabProps = {
    tab: string
    label: string
    activeTab: string
    fields: ReportField[]
    custom?: boolean
    onSelect: (tab: string) => void
}

function ReportFieldTab({ tab, label, activeTab, custom, fields, onSelect } : ReportFieldTabProps) {
    const active = useMemo(() => activeTab === tab, [activeTab])
    const selectedFieldCount = useMemo(() => fields.filter(() => false).length, [fields])
    return <div className={cx(classes.tab, active ? classes.active : undefined)} onClick={() => onSelect(tab)}>
        <div style={{flex: 1}}>
            <div>{label}</div>
            <div style={{fontSize: '0.7rem'}}>{selectedFieldCount}/{fields.length}</div>
        </div>
        {custom ? <div style={{position: 'absolute', top: 8, right: 8}}><BiDotsVertical></BiDotsVertical></div> : <></>}
    </div>
}

type ReportFieldAddTabProps = {
    activeTab: string
    onSelect: (tab: string) => void
}

function ReportFieldsAddTab({ activeTab, onSelect } : ReportFieldAddTabProps) {
    const active = useMemo(() => activeTab === "_add", [activeTab])
    return <div className={cx(classes.tab, active ? classes.active : undefined)} onClick={() => onSelect("_add")}>
        <Group gap={5} style={{flex: 1}}>
            <BiPlus></BiPlus>
            <div>Customize</div>
        </Group>
    </div>
}

type ReportField = {
    name: string
    label: string
}

type ReportFieldContentProps = {
    tab: string
    fields?: ReportField[]
}

function ReportFieldContent({ tab, fields } : ReportFieldContentProps) {
    if (tab === '_add') {
        return <ReportFieldCustomizeForm></ReportFieldCustomizeForm>
    }
    const splitFields = splitArrayInTwo(fields || [])
    return <div>
        <Grid>
            {splitFields[0].map((v, idx) => <><Grid.Col span={6}>
                <FormCheckbox label={v.label}></FormCheckbox>
            </Grid.Col>
            {splitFields[1][idx] ? <Grid.Col span={6}>
                <FormCheckbox label={splitFields[1][idx].label}></FormCheckbox>
            </Grid.Col>: <></>}</>
        )}
        </Grid>
    </div>
}

function ReportFieldCustomizeForm() {
    return <></>
}

export default function ReportFieldsForm({}: StepContentProps) {
    const pv = [{
        tab: 'issue',
        label: 'Issue',
        fields: [
            {
                name: 'message',
                label: 'Message',
            },
            {
                name: 'description',
                label: 'Description'
            },
            {
                name: 'issueCode',
                label: 'Issue Code',
            },
            {
                name: 'createdDate',
                label: 'Created Date'
            },
            {
                name: 'priority',
                label: 'Priority'
            }
        ],
    }, {
        tab: 'vulnerability',
        label: 'Vulnerability',
        fields: [],
    }]
    const [activeTab, setActiveTab] = useState("issue")
    return <form>
        <Grid style={{marginTop: 12}}>
            <Grid.Col span={4}>
                <Stack gap={4}>
                    {pv.map(t => <ReportFieldTab {...t} activeTab={activeTab}
                        onSelect={setActiveTab}
                    ></ReportFieldTab>)}
                    <ReportFieldsAddTab activeTab={activeTab} onSelect={setActiveTab}></ReportFieldsAddTab>
                </Stack>
            </Grid.Col>
            <Grid.Col span={8}>
                <ReportFieldContent tab={activeTab} fields={pv.find(i => i.tab === activeTab)?.fields || []}></ReportFieldContent>
            </Grid.Col>
        </Grid>
    </form>
}
