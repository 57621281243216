import { useTranslation } from "react-i18next";
import { DisplayElemSize } from "@/components/table/EntityTable";
import { UsersApi, User } from "@/libs/client";
import { useTitle } from "@/libs/hooks/useTitle";
import EntityPage from "@/components/page/EntityPage";
import ListSection, { ListSectionCallbacks } from "@/components/page/sections/ListSection";
import { useContainer } from "@/components/containers/ContainerProvider";
import { AddUser } from "./actions/AddUser";
import { useRef } from "react";
import { FaThumbsUp, FaTrash } from "react-icons/fa";
import { ActionResultType } from "@/components/actions/actions";
import { Avatar, Badge } from "@mantine/core";
import { PiUser } from "react-icons/pi";


export default function UsersLanding() {
    const { t } = useTranslation();
    const { openModal, openConfirm } = useContainer();
    const listRef = useRef<ListSectionCallbacks>()
    useTitle(t("Users"))
    const headers = [
        t('common.username'),
        'Provider',
        t('Role')
    ];
    return <EntityPage
        category={t('common.manage')}
        title={t("Users")}>
        <ListSection<User>
            callbacks={listRef}
            load={async (page) => {
                const resp = await new UsersApi().users(page);
                if (resp.status === 200) {
                    return resp.data;
                }
                return { error: 'TODO' };
            }}
            headers={headers}
            renderRow={(e) => ({
                cols: [
                    {
                        display: [{
                            label: e.username || '',
                            size: DisplayElemSize.lg,
                        }, {
                            label: e.email || '',
                            size: DisplayElemSize.sm,
                            secondary: true,
                        }, {
                            elem: e.approved ? <></> : <Badge color="yellow">Waiting for approval</Badge>
                        }], 
                    },
                    {
                        display: {
                            label: e.provider,
                        }
                    },
                    {
                        display: {
                            label: t(e.role || '') || '',
                        }
                    },
                ],
            })}
            addLabel={t('Add User')}
            onAdd={() => openModal('add_user', <AddUser onRefresh={() => listRef.current?.refresh()}></AddUser>, {
                title: t('Add User') || ''
            })}
            rowActions={(u) => {
                const approve = {
                    label: 'Approve',
                    icon: <FaThumbsUp></FaThumbsUp>,
                    execute: async () => {
                        openConfirm('approve_user', {
                            title: 'Approve User',
                            confirmationText: <>Are you sure you want to approve the user <b>{u.username}</b>?</>,
                            cancelText: "No don't approve",
                            submitText: "Yes, Approve",
                            color: 'green',
                            onSubmit: async () => {
                                const resp = await new UsersApi().approveUser(u.id!)
                                if (resp.status === 204) {
                                    listRef.current?.refresh();
                                    return { type: ActionResultType.SUCCESS }
                                }
                                return { type: ActionResultType.FAILURE }
                            }
                        })
                    }
                }
                const del = {
                    label: 'Delete',
                    icon: <FaTrash></FaTrash>,
                    execute: async () => {
                        openConfirm('delete_user', {
                            title: 'Delete User',
                            confirmationText: <>Are you sure you want to delete the user <b>{u.username}</b>?</>,
                            cancelText: "No don't delete",
                            submitText: "Yes, Delete",
                            confirmInput: {
                                data: u.username || '',
                                placeholder: 'Please enter the username of the user to delete'
                            },
                            color: 'red',
                            onSubmit: async () => {
                                const resp = await new UsersApi().deleteUser(u.id!)
                                if (resp.status === 204) {
                                    listRef.current?.refresh();
                                    return { type: ActionResultType.SUCCESS }
                                }
                                return { type: ActionResultType.FAILURE }
                            }
                        })
                    }
                }
                if (!u.approved) {
                    return [approve, del]
                }
                return [del]
            }}
            emptyLabel={t("No users were found")}
            logo={{
                elem: e => <Avatar size="md" radius="xl" src={e?.imageUrl}/>,
                width: 75,
                headerIcon: <PiUser></PiUser>
            }}
        ></ListSection>
    </EntityPage>
}