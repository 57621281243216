import { ReactNode, useEffect, useRef } from 'react';
import { NodeApi, NodeRendererProps, Tree, TreeApi } from 'react-arborist';
import { FaCaretDown, FaCaretRight } from 'react-icons/fa';
import { useGraphDisplay } from '../../providers/GraphDisplayProvider';

export type TreeElement = {
    id: string
    label: string
    renderIcon?: (props: any) => ReactNode
    category?: boolean
    emptyMessage?: string
    children?: TreeElement[],
    openByDefault?: boolean,
    color?: string

    new?: boolean
    removed?: boolean
    updated?: boolean
}

type TreeViewProps = {
    elements: TreeElement[]
    height?: number
    search?: string
    onExpand?: (id: string) => void
}

const styles = {
    treeNode: (node: NodeApi<TreeElement>, color: string) => ({
        cursor: 'pointer', 
        color, 
        marginLeft: 15, 
        fontSize: '0.82rem', 
        display: 'flex', 
        justifyContent: 'flex-start', 
        alignItems: 'center',
        ...(node.isSelected && {background: '#505050'})
    })
}


function TreeNode({ node, style, dragHandle } : NodeRendererProps<TreeElement>) {
    const color =  node.data.color || 'white';
    let labelPrefix = '';
    if (node.data.new) {
        labelPrefix = '+'
    } else if (node.data.removed) {
        labelPrefix = '-'
    } else if (node.data.updated) {
        labelPrefix = '*'
    }
    useEffect(() => {
        if (node.data.openByDefault) {
            node.open()
        }
    }, [])
    const hasCaret = !node.isLeaf && !!node.children?.length
    return (
      <div
        key={node.id}
        title={node.data.label}
        style={{ ...style, ...styles.treeNode(node, color), userSelect: 'none' }}
        ref={dragHandle} 
        onClick={() => node.isOpen ? node.close() : node.open()}>
            {hasCaret && <div style={{marginRight: 5 , marginLeft: 3, display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                {node.isOpen ? <FaCaretDown style={{fontSize: '0.75rem'}}></FaCaretDown> : <FaCaretRight style={{fontSize: '0.75rem'}}></FaCaretRight> }
            </div>}
            {!hasCaret && <div style={{marginRight: 5 , marginLeft: 3, display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                {node.data.renderIcon ? node.data.renderIcon({ color }) : undefined}
            </div>}
            <div title={labelPrefix + node.data.label} style={{display: 'block', fontSize: '0.8rem', textDecoration: node.data.removed ? 'line-through' : '',  whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '190px'}}>
                {labelPrefix + node.data.label}
            </div>
      </div>
    );
}

const getRealId = (s: string) => s.split("||")[0]


export function TreeView({ elements, height, search, onExpand } : TreeViewProps) {
    const treeRef = useRef<TreeApi<TreeElement>>();
    const { selected, setSelected } = useGraphDisplay()

    useEffect(() => {
        const tree = treeRef.current
        let cur = tree?.firstNode || null
        const ids = new Set([...selected])
        while (cur != null) {
            if (cur.children?.length) {
                cur.children?.forEach(c => {
                    if (ids.has(getRealId(c.data.id))) {
                        c.selectMulti()
                    } else {
                        c.deselect()
                    }
                })                
            }  
            if (ids.has(getRealId(cur.data.id))) {
                cur.selectMulti()
            } else {
                cur.deselect()
            }
            cur = cur.next
        }
    }, [selected])
    return <Tree
        ref={treeRef}
        data={elements.map((e, idx) => ({...e, id: `${e.id}||${idx}`}))}
        searchTerm={search}
        width={250}
        searchMatch={
            (node, term) => {
                return `${node.data.id} ${node.data.label}`.toLowerCase().includes(term.toLowerCase())
            }
          }
        openByDefault={false}
        disableDrag={true}
        indent={12}
        rowHeight={26}
        height={height}
        overscanCount={1}
        padding={10}
        onToggle={(v) => {
            if (onExpand)
                onExpand(v);
        }}
        onClick={() => setSelected(treeRef.current?.selectedNodes.map(n => getRealId(n.data.id)) || [])}
        >
            {TreeNode}
        </Tree>
}
