import GraphInteractor from "@/graph/visualize/common/interactor/GraphInteractor";
import SchemaRenderer from "../renderer/SchemaRenderer";
import { useGraphLoader } from "@/graph/visualize/providers/GraphLoaderProvider";
import { useGraphDisplay } from "@/graph/visualize/providers/GraphDisplayProvider";
import { useEffect, useState } from "react";
import SchemaViewerToolbar from "./SchemaViewerToolbar";
import { ModifiedGraph } from "../../../../../libs/graph/modified";
import { InstalledIntegration, IntegrationsApi, SchemaEntity, SchemaGraph, SchemaRelationship } from "@/libs/client";
import { buildSchemaNavigator } from "../navigator/schema.navigator";
import { useTranslation } from "react-i18next";
import { useTitle } from "@/libs/hooks/useTitle";
import GraphBox from "@/graph/visualize/common/box/GraphBox";
import GraphMetrics from "@/graph/visualize/common/metrics/GraphMetrics";
import { millisecondsToTimeInfo } from "@/libs/utils/metrics";
import { labelFrom } from "@/libs/utils/label";
import { useProject } from "@/libs/project/ProjectProvider";

function SchemaGraphBox({ schemaGraph } : { schemaGraph?: SchemaGraph }) {
    if (!schemaGraph) {
        return <></>
    }
    const { reloadSchemaGraph } = useGraphLoader()
    const { curProject } = useProject()
    const [installedIntegrations, setInstalledIntegrations] = useState<InstalledIntegration[]>([])
    const fetchInstalledIntegrations = async () => {
        const resp = await new IntegrationsApi().installedIntegrations(curProject.id!, {
            page: 1,
        });
        setInstalledIntegrations(resp.data.list!);
    }
    useEffect(() => {
        fetchInstalledIntegrations()
    }, []);

    const { t } = useTranslation()
    const [values, setValues] = useState<{[key: string]: string}>({
        version: 'v0',
        integration: '',
    })
    return <GraphBox
        title={t("schema_graph.label")}
        onValues={vs => setValues(vs)}
        values={values}
        rows={[
            { 
                label: 'Status', 
                key: 'status', 
                valueLabel: t(labelFrom(schemaGraph.state!, 'graph.state.')) || '', 
                type: 'BADGE',
                color: { UP_TO_DATE: 'green', SYNCHING: 'blue', OUTDATED: 'red' }[schemaGraph.state!]
            },
            { label: 'Integration', key: 'integration', options: [{ value: '', label: 'All'}, {value: 'core', label: 'Custom'}, ...installedIntegrations.filter(ii => !ii.integration?.catalog?.custom).map(ii => ({
                value: ii.name || '',
                label: ii.integration?.label || '',
            }))], type: 'SELECT', onChange: (v) => {
                reloadSchemaGraph(v);
            } },
            { label: t('common.version'), key: 'version', valueLabel: 'v' + schemaGraph.version, type: 'LINK' },
        ]}
    ></GraphBox>
}

export default function SchemaViewer() {
    const { t } = useTranslation()
    useTitle(t('schema_graph.label'))
    const { reloadSchemaGraph } = useGraphLoader()
    const [startedLoad, setStartedLoad] = useState(false)
    const { schemaGraph, isLoading, loadingSchema } = useGraphLoader()
    const [modifiedGraph, setModifiedGraph] = useState<ModifiedGraph<SchemaGraph, SchemaEntity, SchemaRelationship> | undefined>()
    const { setActiveType } = useGraphDisplay()
    useEffect(() => {
        reloadSchemaGraph()
        setActiveType("SCHEMA")
        setStartedLoad(true)
    }, [])
    useEffect(() => {
        if (!schemaGraph || !startedLoad) {
            return
        }
        setModifiedGraph({
            graph: schemaGraph!,
            nodes: Object.values(schemaGraph!.entities),
            edges: Object.values(schemaGraph!.relationships),
        })
    }, [schemaGraph])
    const loading = startedLoad && (isLoading() || !modifiedGraph)
    return <GraphInteractor navigatorProps={{ 
        title: t('schema_graph.label'), 
        loading,
        elements: modifiedGraph ? buildSchemaNavigator(modifiedGraph!) : [],
    }}>
            <SchemaViewerToolbar schemaGraph={schemaGraph}></SchemaViewerToolbar>
            <SchemaGraphBox schemaGraph={schemaGraph!}
            ></SchemaGraphBox>
            { !loading && <SchemaRenderer
                modifiedGraph={modifiedGraph!}
            ></SchemaRenderer>}
            <GraphMetrics
                metrics={[
                    { value: Object.values(schemaGraph?.entities || {}).length, unit: 'Entities'},
                    { value: Object.values(schemaGraph?.relationships || {}).length, unit: 'Relationships'},
                    millisecondsToTimeInfo(loadingSchema.timeInMs),
                ]}
            ></GraphMetrics>
        </GraphInteractor>
}


