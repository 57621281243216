interface TimeInfo {
    value: number;
    unit: string;
}

export const millisecondsToTimeInfo = (milliseconds: number): TimeInfo => {
    if (milliseconds >= 1000) {
        const seconds = milliseconds / 1000;
        const formattedSeconds = seconds.toFixed(2).replace(/\.?0+$/, ''); // Format and remove trailing zeros
        return { value: parseFloat(formattedSeconds), unit: 's' };
    } else {
        const formattedMilliseconds = milliseconds.toFixed(2).replace(/\.?0+$/, ''); // Format and remove trailing zeros
        return { value: parseFloat(formattedMilliseconds), unit: 'ms' };
    }
}