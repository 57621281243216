import { ReactNode } from "react"

export type ControlParameter = {
    id: string
    label: string
    description?: string
    options?: ControlParameterOption[]
}

export type ControlParameterOption = {
    value: any
    label: string
}

export type Criteria = {
    id: string
    label: string
    description?: string
    checked?: boolean
    enabled: boolean
    mode: 'AUTOMATIC' | 'MANUAL'
    configElement?: ReactNode
}

export type ControlCriteriaSection = {
    label: string
    id: string
    criteria: Criteria[]
    parameterDescriptors?: ControlParameter[]
}

export type Control = {
    label: string
    id: string
    sections: ControlCriteriaSection[]
    type: 'hipaa' | 'nist'
    tags?: string[]
    enabled: boolean

    parameterDescriptors?: ControlParameter[]
    parameters: {[key: string]: any}
}

export function getPercentage(c: Control): number {
    return Math.round(100 * (getChecked(c) / getTotal(c)));
}

export function getChecked(c: Control): number {
    return c.sections.flatMap(s => s.criteria).filter(cr => cr.enabled).filter(cr => cr.checked).length;
}

export function getTotal(c: Control): number {
    return c.sections.flatMap(s => s.criteria).filter(cr => cr.enabled).length;
}

export function getCheckedAutomatic(c: Control): number {
    return c.sections.flatMap(s => s.criteria).filter(cr => cr.enabled).filter(cr => cr.mode === 'AUTOMATIC').filter(cr => cr.checked).length;
}

export function getTotalAutomatic(c: Control): number {
    return c.sections.flatMap(s => s.criteria).filter(cr => cr.enabled).filter(cr => cr.mode === 'AUTOMATIC').length;
}

export function getCheckedManual(c: Control): number {
    return c.sections.flatMap(s => s.criteria).filter(cr => cr.enabled).filter(cr => cr.mode === 'MANUAL').filter(cr => cr.checked).length;
}

export function getTotalManual(c: Control): number {
    return c.sections.flatMap(s => s.criteria).filter(cr => cr.enabled).filter(cr => cr.mode === 'MANUAL').length;
}

export function hasManualCriterias(c: Control) {
    return getTotalManual(c) > 0;
}

export function hasAutomaticCriterias(c: Control) {
    return getTotalAutomatic(c) > 0;
}

export function hasAllManualChecked(c: Control) {
    return getCheckedManual(c) === getTotalManual(c)
}

export function hasAllAutomaticChecked(c: Control) {
    return getCheckedAutomatic(c) === getTotalAutomatic(c)
}