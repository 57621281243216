import { CSSProperties, TextInput, TextInputProps } from "@mantine/core"

const FIELD_STYLES = ({ size, grow, value, placeholder }: FormTextInputProps) => ({
    error: {
        position: 'absolute',
        bottom: -8,
        width: 170,
    },
    root: {
        flex: 1,
    },
    input: { 
        fontSize: size !== 'sm' ? '1rem' : '0.9rem',
        padding: 0,
        backgroundColor: 'transparent !important',
        borderWidth: 0,
        width: grow ? 40 + Math.max((value + '').length, (placeholder || '').length) * 9 : undefined,
    },
} as Partial<{ [key: string]: CSSProperties}>)

const REG_STYLES = ({ sensitive } : FormTextInputProps) => ({
    input: {
        ...( sensitive ? { 
            '-webkit-text-security': 'disc !important',
            '-moz-appearance': 'passwordfield',
            'text-security': 'disc',
        } : {})
    }
} as Partial<{ [key: string]: CSSProperties}>)

type FormTextInputProps = TextInputProps & {
    grow?: boolean
    onValue?: (str: string) => void
    asField?: boolean
    sensitive?: boolean
}

export default function FormTextInput(props: FormTextInputProps) {
    const { asField } = props
    const inputStyle = asField ? FIELD_STYLES : REG_STYLES
    const styles =  inputStyle(props);
    return <TextInput
        size={asField ? "md" : "sm"}
        type={props.sensitive ? 'password' : undefined}
        styles={{ 
            root: {
                position: 'relative',
            },
            wrapper: {
                position: 'relative',
            },
            input: styles["input"],
            error: styles["error"],
        }}
        {...props}
    ></TextInput>
}
