

export function removeMarkdown(markdown?: string): string {
    if (!markdown) {
      return '';
    }
  
    // Replace bold text with plain text
    markdown = markdown.replace(/\*\*(.+?)\*\*/g, '\$1');
  
    // Replace italicized text with plain text
    markdown = markdown.replace(/_(.+?)_/g, '\$1');
  
    // Replace strikethrough text with plain text
    markdown = markdown.replace(/~~(.+?)~~/g, '\$1');
  
    // Replace inline code blocks with plain text
    markdown = markdown.replace(/`(.+?)`/g, '\$1');
  
    // Replace code blocks with plain text
    markdown = markdown.replace(/```[\s\S]*?```/g, '\$1');
  
    // Remove links
    markdown = markdown.replace(/\[(.+?)\]\((.+?)\)/g, '\$2');
  
    // Remove images
    markdown = markdown.replace(/!\[(.+?)\]\((.+?)\)/g, '');
  
    // Remove headings
    markdown = markdown.replace(/^#+\s+(.+?)\s*$/gm, '\$1');
    markdown = markdown.replace(/^\s*=+\s*$/gm, '');
    markdown = markdown.replace(/^\s*-+\s*$/gm, '');
  
    // Remove blockquotes
    markdown = markdown.replace(/^\s*>\s+(.+?)\s*$/gm, '\$1');
  
    // Remove lists
    markdown = markdown.replace(/^\s*[\*\+-]\s+(.+?)\s*$/gm, '');
    markdown = markdown.replace(/^\s*\d+\.\s+(.+?)\s*$/gm, '');
  
    // Remove horizontal lines
    markdown = markdown.replace(/^\s*[-*_]{3,}\s*$/gm, '');
  
    return markdown;
  }
  