import { Avatar, Menu } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { AuthApi } from "@/libs/client";
import { useTranslation } from "react-i18next";
import { useAuth } from "@/libs/auth/AuthProvider";
import { FaInfoCircle, FaKey, FaSignOutAlt, FaUser } from "react-icons/fa";
import { useInfo } from "@/libs/info/InfoProvider";


export function AvatarDropdown() {
    const { isExperimental } = useInfo()
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { userCtx, reloadCtx } = useAuth();
    async function logout() {
        await new AuthApi().logout()
        await reloadCtx();
        navigate('');
    }
    return <Menu offset={{mainAxis: 12, crossAxis: -80}} styles={{dropdown: {minWidth: 180}}}>
        <Menu.Target>
            <Avatar style={{marginLeft: 20, cursor: 'pointer'}} size="sm" radius="xl" src={userCtx?.user?.imageUrl}
        />
        </Menu.Target>
        <Menu.Dropdown>
            <Menu.Label>
                <div>{((userCtx.user?.firstName || '') + ' ' + (userCtx.user?.lastName || '')).trim() || userCtx.user?.username}</div>
                <div>{userCtx.user?.tenant?.displayName} (Tenant)</div>
            </Menu.Label>
            { isExperimental() && <Menu.Item leftSection={<FaUser></FaUser>} onClick={() => navigate('/me/profile')}>{ t('profile.label') }</Menu.Item>}
            { isExperimental() &&  <Menu.Item leftSection={<FaKey></FaKey>} onClick={() => navigate('/me/credentials')}>{ t('credentials.label') }</Menu.Item>}
            <Menu.Divider></Menu.Divider>
            <Menu.Item leftSection={<FaInfoCircle></FaInfoCircle>} onClick={() => {
                window.open("https://sentryfy.atlassian.net/servicedesk/customer/portals", "_blank")
            }}>Help Center</Menu.Item>
            <Menu.Divider></Menu.Divider>
            <Menu.Item leftSection={<FaSignOutAlt></FaSignOutAlt>} onClick={logout}>{ t('common.logout' )}</Menu.Item>
        </Menu.Dropdown>
    </Menu>
}