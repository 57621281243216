import { ReactNode, useCallback, useEffect, useState } from 'react';
import { createContext, useContext } from 'react';
import SfyModal, { SfyModalProps } from './SfyModal';
import SfyDrawer, { SfyDrawerProps } from './SfyDrawer';
import SfyConfirmDialog, { SfyConfirmProps } from './SfyConfirmDialog';
import { useLocation } from 'react-router-dom';


export type ContainerProviderContextProps = {
    openModal: (key: string, r: ReactNode, props: SfyModalProps) => void
    closeModal: (key: string) => void
    closeAllModals: () => void

    openDrawer: (key: string, r: ReactNode, props: SfyDrawerProps) => void
    closeDrawer: (key: string) => void
    closeAllDrawers: () => void

    openConfirm: (key: string, props: SfyConfirmProps) => void
    closeConfirm: (key: string) => void

}


export const ContainerProviderContext = createContext({
} as ContainerProviderContextProps);

export const useContainer = () => useContext(ContainerProviderContext);

type ContainerType = 'MODAL' | 'DRAWER' | 'CONFIRM'

type ContainerProps = {
    key: string;
    children?: ReactNode
    props: any,
    container: ContainerType, 
    opened: boolean;
}

export const ContainerProvider = ({ children } : { children: ReactNode}) => {
    const location = useLocation();
    const [containersOpen, setContainersOpen] = useState<ContainerProps[]>([])
    const [lastLocation, setLastLocation] = useState<string>(location.pathname)
    const open = useCallback((key: string, children: ReactNode, props: any, container: ContainerType) => {
        setContainersOpen(old => [...old.filter(o => o.key !== key), {
            key,
            children,
            props,
            container,
            opened: false,
        }])
        setTimeout(() => {
            setContainersOpen(old => {
                const f = old.find(o => o.key === key);
                if (f) {
                    f.opened = true;
                }
                return [...old]
            })
        }, 100)
    }, [location])
    const close = useCallback((key: string) => {
        setContainersOpen(old => {
            const f = old.find(o => o.key === key);
            if (f && f.props.onClose) {
                f.props.onClose();
            }
            return [...old.filter(o => o.key !== key)]
        }) 
    }, [])
    
    useEffect(() => {
        if (location.pathname !== lastLocation) {
            setContainersOpen(old => old.filter(o => o.container !== 'DRAWER'))
        }
        setLastLocation(location.pathname)
    }, [location])
    const openModal = useCallback((key: string, r: ReactNode, props: SfyModalProps) => open(key, r, props, 'MODAL'), [])
    const openDrawer = useCallback((key: string, r: ReactNode, props: SfyDrawerProps) => open(key, r, props, 'DRAWER'), [])
    const openConfirm = useCallback((key: string, props: SfyConfirmProps) => open(key, undefined, props, 'CONFIRM'), [])

    return (
        <ContainerProviderContext.Provider value={{ 
            openModal,
            closeModal: close,

            openDrawer,
            closeDrawer: close,

            openConfirm,
            closeConfirm: close,

            closeAllModals: () => {
                setContainersOpen(old => old.filter(o => o.container !== 'MODAL'))
            },
            closeAllDrawers: () => {
                setContainersOpen(old => old.filter(o => o.container !== 'DRAWER'))
            }
        }}>
            {
                containersOpen.map(c => {
                    if (c.container === 'MODAL') {
                        return <SfyModal key={c.key} {...c.props} opened={c.opened} onClose={() => close(c.key)}>
                            {c.children}
                        </SfyModal>
                    } else if (c.container === 'DRAWER') {
                        return <SfyDrawer key={c.key} {...c.props} opened={c.opened} onClose={() => close(c.key)}>
                            {c.children}
                        </SfyDrawer>
                    } else if (c.container === 'CONFIRM') {
                        return <SfyConfirmDialog key={c.key} {...c.props} opened={c.opened} onClose={() => close(c.key)}>
                            {c.children}
                        </SfyConfirmDialog>
                    }
                    return <></>
                })
            }
            { children }
        </ContainerProviderContext.Provider>
    );
};