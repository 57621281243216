import { WorkflowNodeDescriptor } from "./descriptor";
import inputs_desc_list from "./inputs";
import actions_desc_list from "./actions";
import output_desc_list from "./outputs";
import control_flows_desc_list from "./control_flows";

const BLOCK_DESCRIPTORS: WorkflowNodeDescriptor[] =  [
    ...inputs_desc_list,
    ...actions_desc_list,
    ...control_flows_desc_list,
    ...output_desc_list,
] 

export const getWorkflowNode = (descriptorId: string) => BLOCK_DESCRIPTORS.find(d => d.descriptorId === descriptorId)!;

export default BLOCK_DESCRIPTORS;