import { DataNode } from "@/libs/client";
import { calculateAverage } from "@/libs/utils/math";
import { levenshteinDistance } from "@/libs/utils/search";
import { sortBy } from "@/libs/utils/sort";
import { Button, Checkbox, Group, Portal, Stack, TextInput } from "@mantine/core";
import { useEffect, useMemo, useState } from "react";
import { FaSearch } from "react-icons/fa";
import LazyDataGraph from "../lazy/LazyDataGraph";


type ExportNodesProps = {
    type: string;
    dataGraph: LazyDataGraph;
    onClose: () => void
    onSelect: (selected: string[]) => void
}

function ExpandNodeRow({ node, selected, onClick }: { node: DataNode, selected: boolean, onClick: () => void }) {
    return <Group style={{userSelect: 'none', cursor: 'pointer', marginTop: 10, padding: '1rem 2rem', border: '1px solid #e0e0e0', borderRadius: 4, backgroundColor: selected ? '#12b886': undefined}} onClick={onClick}>
        <Checkbox checked={selected}></Checkbox>
        <Stack gap={3} style={{flex: 1}}>
            <div style={{fontSize: '0.95rem', color: selected ? 'white' : '#5f5f5f', paddingRight: '0.5rem'}}>
                {node.display || node.oid}
            </div>
            <div style={{fontSize: '0.75rem', wordBreak: 'break-all', color: selected ? 'white' : '#a0a0a0'}}>
                {node.oid}
            </div>
        </Stack>
    </Group>
}

export function ExpandNodes({ type, dataGraph, onSelect } : ExportNodesProps) {
    const [search, setSearch] = useState('')
    const [selected, setSelected] = useState<{[key: string]: boolean}>({})
    const [nodesForType, setNodesForType] = useState<DataNode[]>([])
    const fetchNodes = async () => {
        setNodesForType((await dataGraph.nodesOfType(type)).sort(sortBy(n => n.display)))
    }
    useEffect(() => {
        fetchNodes()
    }, [])
    const searchedNodes = useMemo(() => {
        const splitSearch = search.split(/\s+/).map(s => s.toLowerCase()).filter(s => s)
        if (!splitSearch.length) {
            return nodesForType
        }
        const searchRank = (vs: string[]) => {
            const avgRanks = vs.map(v => calculateAverage(splitSearch.map(ss => v?.includes(ss) ? 0 : levenshteinDistance(ss, v || ''))))
            const minRanks = vs.map(v => Math.min(...splitSearch.map(ss => v?.includes(ss) ? 0 : levenshteinDistance(ss, v || ''))))
            return [Math.min(...minRanks), calculateAverage(avgRanks)]
        }
        //Improve search
        return nodesForType.map(n => [n, ...searchRank([n.display, n.id, n.oid, ...Object.values(n.properties).map(p => p + '')].map(p => p?.toLowerCase() || ''))] as [DataNode, number, number])
            .filter(e => e[1] < 6)
            .sort(sortBy(e => e[2]))
            .sort(sortBy(e => e[1]))
            .map(e => e[0])
    }, [search, nodesForType])
    const selectedAsList = useMemo(() => {
        return Object.entries(selected).filter(s => s[1]).map(s => s[0])
    }, [selected])
    const allSelected = useMemo(() => {
        return nodesForType.length === selectedAsList.length
    }, [nodesForType, selectedAsList])
    const selectAll = () => {
        if (allSelected) {
            setSelected({})
        } else {
            setSelected(nodesForType.reduce((acc, cur) => {
                acc[cur.id!] = true
                return acc
            }, {} as { [key: string]: boolean}))
        }
    }
    return <div style={{paddingBottom: 30}}>
        <Portal target="#sfy-modal-header">
            <div style={{position: 'absolute', marginTop: 20, paddingBottom: 5, right: 0, paddingLeft: 60, paddingRight: 60, backgroundColor: 'white', width: '100%'}}>
                <Group>
                <div style={{flex: 1, fontSize: '1rem'}}>
                <TextInput
                    leftSection={<FaSearch color="#12b886"></FaSearch>}
                    size="md"
                    styles={{ input: { border: 0, borderBottom: '1px solid #12b886', borderRadius: '0px !important' }}}
                    placeholder={`Search for ${type}`}
                    value={search}
                    onInput={e => setSearch(e.currentTarget.value)}
                ></TextInput>
                    <div style={{marginTop: 15, marginBottom: 10}}>
                        <Checkbox checked={allSelected} onClick={selectAll} label={"Select All"}></Checkbox>
                    </div>
                </div>
                <Group>
                    <Button onClick={() => {
                        onSelect(selectedAsList)
                    }}>{'Done'}</Button>
                </Group>
            </Group>
            </div>

        </Portal>
        <div style={{paddingTop: 100}}>
            {
                searchedNodes.map(n => <ExpandNodeRow key={n.id!} node={n} selected={selected[n.id!]} onClick={() => setSelected({ ...selected, [n.id!]: !selected[n.id!] })}></ExpandNodeRow>)
            }
        </div>
    </div>
}