import { StepContentProps } from "@/components/form/MultiStepForm";
import UploadBox, { MIME_TYPES_MAP } from "@/components/upload/UploadBox";
import { SchemaGraph } from "@/libs/client"
import { DropzoneProps, FileWithPath } from "@mantine/dropzone";
import { useEffect, useState } from "react";

type ImportSampleFormProps = {
  schema: SchemaGraph
} & StepContentProps

export default function ImportSampleForm({ form }: ImportSampleFormProps) {
    const [files, setFiles] = useState<FileWithPath[]>([])
    useEffect(() => {
      if (files.length) {
        form.setFieldValue('sampleFile', files[0]);
        files[0].arrayBuffer().then(buf => {
          const decoder = new TextDecoder('utf-8');
          const text = decoder.decode(buf);
          form.setFieldValue('fileFields', parsePossibleFields(form.values.fileType, text))

          form.setFieldValue('rawMappings', [])
          form.setFieldValue('fieldMappings', {})
          form.setFieldValue('edgeMappings', {})
        })
      }
    }, [files])
    return <>
        <SampleUploadBox fileType={form.values.fileType} files={files} onDrop={setFiles}></SampleUploadBox>
    </>
}

function parseCsvFields(text: string) {
  const lines = text.split('\n');
  return lines.length > 0 ? lines[0].split(',').map(l => l.trim()) : [];
}

function parsePossibleFields(fileType: string, text: string) {
  switch(fileType) {
    case 'CSV': return parseCsvFields(text)
    default: return [];
  }
}

function SampleUploadBox(props: Partial<DropzoneProps> & { fileType: string, files: FileWithPath[] }) {
    return <UploadBox
      {...props}
      label={props.files?.length ? props.files[0].path! : 'Insert a sample file here'}
      description="Sample file will be used to prepopulate the next step"
      onDrop={(files) => { props.onDrop && props.onDrop(files) }}
      maxSize={50 * 1024 ** 2}
      maxFiles={1}
      accept={[MIME_TYPES_MAP[props.fileType!]]}
    ></UploadBox>
}
  