import { ActionResultType } from "@/components/actions/actions";
import { useContainer } from "@/components/containers/ContainerProvider";
import EntityPage from "@/components/page/EntityPage";
import ListSection from "@/components/page/sections/ListSection";
import { DisplayElemSize } from "@/components/table/EntityTable";
import { Workflow, WorkflowsApi } from "@/libs/client";
import { useTitle } from "@/libs/hooks/useTitle";
import { useProject } from "@/libs/project/ProjectProvider";
import { useTranslation } from "react-i18next";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export default function WorkflowsList() {
    const navigate = useNavigate()
    const { openConfirm } = useContainer()
    const { projectUrl, curProject } = useProject()
    const { t } = useTranslation()
    useTitle(t("Workflows"))
    const headers = [
        t('common.name')
    ];
    return <EntityPage
        category={t('common.code')}
        title={t('Workflows')}
    ><ListSection<Workflow>
        headers={headers}
        renderRow={(e) => ({
            cols: [{
                display: [{
                    label: e.name || '',
                    size: DisplayElemSize.lg,
                }],
            }],
        })}
        load={async (page) => {
            const resp = await new WorkflowsApi().listWorkflows(curProject.id!, page)
            if (resp.status === 200) {
                return resp.data;
            }
            return { error: 'TODO' };
        }}
        addLabel="Add Workflow"
        onAdd={() => {
            navigate(projectUrl('/analysis/workflows/add'))
        }}
        rowActions={() => [
            {
                label: 'Edit',
                icon: <FaEdit></FaEdit>,
                execute: (w) => { 
                    navigate(projectUrl(`/analysis/workflows/${w.id}/edit`))
                }
            },
            {
                label: 'Delete',
                icon: <FaTrash></FaTrash>,
                execute: async (w) => {
                    openConfirm('delete_workflow', {
                        title: 'Delete Workflow',
                        confirmationText: <>Are you sure you want to delete the workflow <b>{w.name}</b>?</>,
                        cancelText: "No don't delete",
                        submitText: "Yes, Delete",
                        confirmInput: {
                            data: w.name || '',
                            placeholder: 'Please enter the name of the workflow to delete'
                        },
                        color: 'red',
                        onSubmit: async () => {
                            return { type: ActionResultType.FAILURE }
                        }
                    })
                }
            }
        ]}
        emptyLabel={t('No workflows yet')}
    ></ListSection>
    </EntityPage>
}