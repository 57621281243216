import { OpsSettingsApi } from "@/libs/client";
import notify from "@/libs/notify/notify";
import { Button, PasswordInput, Stack, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { openConfirmModal } from "@mantine/modals";
import { useEffect } from "react";

export default function SysSlackNotifierSettings() {
    const form = useForm({
        initialValues: {
            botToken: '',
            onPremiseMonitoringChannel: '',
        }
    })
    const fetchSlackNotifier = async () => {
        const fetched = (await new OpsSettingsApi().slackNotifierSettings()).data
        form.setValues(fetched)
    }

    const updateSlackNotifier = async () => {
        openConfirmModal({
            title: 'Please confirm your action',
            children: (
            <Text size="sm">
                This will override the current settings for Slack Notifier. Please make sure they are correct, since they can break existing features.
            </Text>
            ),
            labels: { confirm: 'Confirm', cancel: 'Cancel' },
            onCancel: () => {
            },
            onConfirm: async () => { 
                await new OpsSettingsApi().updateSlackNotifierSettings(form.values)
                notify.success("Successfully updated your Slack Notifier settings")
            },
        })
    }

    useEffect(() => {
        fetchSlackNotifier()
    }, [])

    return <div style={{padding: '0 3rem'}}>
        <div>
            <h2 style={{marginTop: 0, fontWeight: 500}}>Slack</h2>
            <Stack>
                <PasswordInput
                    label="Bot Token"
                    description="Bot token of your installed Sentryfy Slack App"
                    {...form.getInputProps('botToken')}
                ></PasswordInput>
                <TextInput
                    label="Channel (On Premise Notifications)"
                    description="Name of channel to publish on-premise deployments notifications"
                    {...form.getInputProps('onPremiseMonitoringChannel')}
                ></TextInput>
            </Stack>
            <div style={{display: 'flex', gap: 4, marginTop: 20}}>
                <Button variant="default" onClick={() => fetchSlackNotifier()}>Reset</Button>
                <Button onClick={() => updateSlackNotifier()}>Update</Button>
            </div>
        </div>
    </div>
}