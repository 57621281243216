import { StepContentProps } from "@/components/form/MultiStepForm"
import FormTextInput from "@/components/form/inputs/FormTextInput"
import { SchemaEntity, SchemaField, SchemaFieldTypeEnum } from "@/libs/client"
import { ActionIcon, Button, Card, Checkbox, Grid, Group, List, Select, Stack, TextInput, ThemeIcon, rem } from "@mantine/core"
import { useCallback, useEffect, useState } from "react"
import { FaMinus, FaPlus, FaTimes } from "react-icons/fa"
import { PiTagDuotone } from "react-icons/pi"
import classes from './EntityFieldsForm.module.css';
import { sortBy } from "@/libs/utils/sort"
import { TbCircleLetterFFilled } from "react-icons/tb";

type TypeSelectProps = {
    onChange: (type: SchemaFieldTypeEnum) => void
    value?: SchemaFieldTypeEnum
}

function TypeSelect({ value, onChange }: TypeSelectProps) {
    useEffect(() => {
        if (!value) {
            onChange('STRING')
        }
    }, [])
    return <Select
        styles={{ input: {
            marginLeft: 5,
            border: 0,
            maxWidth: 120,
            color: '#12b886',
            padding: 0, 
            fontSize: '0.95rem', 
            backgroundColor: 'transparent !important'}}}
        placeholder="Field Type"
        value={value}
        onChange={(t) => onChange(t! as SchemaFieldTypeEnum)}
        defaultValue={'STRING'}
        data={[
            { value: 'STRING', label: 'String' },
            { value: 'INT', label: 'Int' },
            { value: 'FLOAT', label: 'Float' },
            { value: 'BOOL', label: 'Boolean' },
        ]}
    />
}

type FieldMetaSectionProps = {
    description?: string
    source?: string
    indexed?: boolean
    onChange: (fields: Partial<SchemaField>) => void
}

function FieldMetaSection({ onChange, description, source, indexed }: FieldMetaSectionProps) {
    return <div style={{marginLeft: '2rem'}}>
        <Group gap={3}>
            <div className={classes.metaLabel}>Description</div>
            <TextInput
                placeholder="Description of the field"
                value={description}
                onChange={event => onChange({ description: event.currentTarget.value })}
                styles={{
                    root: {
                        marginTop: -3,
                    },
                    input: {
                        fontSize: '0.9rem',
                        padding: 0, 
                        backgroundColor: 'transparent',
                        borderWidth: 0,        
                    },
                }}
            ></TextInput>
        </Group>
        <Group gap={3}>
            <div className={classes.metaLabel}>Source</div>
            <TextInput
                placeholder="Source of the field"
                value={source}
                onChange={event => onChange({ source: event.currentTarget.value })}
                styles={{
                    root: {
                        marginTop: -3,
                    },
                    input: {
                        fontSize: '0.9rem',
                        padding: 0, 
                        backgroundColor: 'transparent',
                        borderWidth: 0,        
                    },
                }}
            ></TextInput>
        </Group>
        <Group gap={3} style={{height: 36}}>
            <div className={classes.metaLabel}>Indexed?</div>
            <Checkbox 
                size="sm" 
                defaultChecked={true} 
                checked={indexed}
                onChange={(e) => onChange({ indexed: e.currentTarget.checked })}></Checkbox>
        </Group>
    </div>
}

type FieldBoxProps = {
    field: SchemaField
    previousFields: SchemaField[]
    onChange: (f: Partial<SchemaField>) => void
    onRemove: () => void
}

function FieldBox({ onRemove, onChange, field, previousFields } : FieldBoxProps) {
    const [expand, setExpand] = useState(false);
    return  <Stack gap={0}>
    <Group gap={3}>
        <div className={classes.metaIcon} onClick={() => setExpand(current => !current)}>
            <PiTagDuotone size="1.2rem"></PiTagDuotone>
            { !expand ? <FaPlus size="0.5rem" style={{position: 'absolute', bottom: 0, color: '#e1b07b', right: -6 }}></FaPlus> :
            <FaMinus size="0.5rem" style={{position: 'absolute', bottom: 0, color: '#e1b07b', right: -6 }}></FaMinus>}
        </div>
        <FormTextInput 
            value={field.name}
            onChange={event => onChange({ name: event.currentTarget.value })}
            error={previousFields.find(f => f.name === field.name) ? 'Name is already in use' : undefined}
            grow 
            placeholder="Field Name" 
            asField
            size="sm"
        ></FormTextInput>
        <div style={{fontWeight: 'bold', color: '#c0c0c0'}}>:</div>
        <div>
            <TypeSelect value={field.type} onChange={type => onChange({ type })}></TypeSelect>
        </div>
        <Checkbox
            label="Required"
            size="xs"
            labelPosition="left"
            checked={field.required}
            styles={{
                label: {
                    textTransform: 'uppercase', 
                    fontSize: '0.65rem', 
                    lineHeight: '1rem', 
                    height: '0.7rem', 
                    fontWeight: 500, 
                    color: '#12b886',        
                    userSelect: 'none',    
                }
            }}
            onChange={(event) => onChange({ required: event.currentTarget.checked })}
        ></Checkbox>
        <ActionIcon onClick={onRemove} style={{marginLeft: 30}} color="red" size="xs"><FaTimes></FaTimes></ActionIcon>
    </Group>
    {expand ? <Group gap={3}>
        <FieldMetaSection {...field} onChange={vs => onChange(vs)}></FieldMetaSection>
    </Group> : undefined}
    </Stack>
}

type EntitySetupFormProps = {
    materializedEntity?: SchemaEntity;
} & StepContentProps

export default function EntityFieldsForm({ form, materializedEntity } : EntitySetupFormProps) {
    const [fields, setFields] = useState<SchemaField[]>(Object.values((form.values as SchemaEntity).fields || [{}])
        .sort(sortBy(f => f.order))
        .map((f, idx) => ({
            ...f,
            order: idx,
        }))
    )
    useEffect(() => {
        const fieldsMap = fields.filter(f => f.name)
            .reduce((acc, cur) => {
                acc[cur.name!] = cur
                return acc
            }, {} as { [key: string]: SchemaField })
        form.setFieldValue('fields', fieldsMap)
    }, [fields])
    const addField = useCallback(() => setFields(current => { 
        const copy = [...current]
        copy.push({ order: current.length + 1, indexed: true });
        return copy
    }), []);
    return <div className={classes.entityBox}>
        <Group gap={8}>
            <div className={classes.entityLabel}>entity</div>
            <FormTextInput
                placeholder="Entity Name"
                value={form.values['type']}
                readOnly
                asField
            ></FormTextInput>
        </Group>
        <Stack gap={5}>
            <Grid>
                <Grid.Col span={8}>
                    <Stack gap={6}>
                        {fields.map((field, idx) => <FieldBox key={field.order || idx} field={field}
                        previousFields={[...fields].splice(0, idx)}
                        onChange={(vs) => {
                            setFields(cur => {
                                const copy = [...cur]
                                copy[idx] = { ...copy[idx], ...vs, order: idx }
                                return copy  
                            })
                        }} onRemove={() => {
                            setFields(cur => {
                                const copy = [...cur]
                                const lower = copy.slice(0, idx)
                                const upper = copy.slice(idx + 1);
                                return lower.concat(upper);
                            })
                        }}></FieldBox>)}
                    </Stack>
                </Grid.Col>
                <Grid.Col span={4}>
                    { materializedEntity ? <Card withBorder>
                        <Stack>
                        <div>From Entire Graph</div>
                            <List
                                spacing="xs"
                                size="sm"
                                center
                                icon={
                                    <ThemeIcon color="orange" size={24} radius="xl">
                                    <TbCircleLetterFFilled style={{ width: rem(16), height: rem(16) }} />
                                    </ThemeIcon>
                                }
                            >
                                {Object.values(materializedEntity?.fields || {}).map(f => <List.Item key={f.name}>{f.name}: {f.type}</List.Item>)}
                            </List>
                        </Stack>
                    </Card> : undefined }
                </Grid.Col> 
            </Grid>
            <Group gap={3} style={{marginTop: 10}}>
                <Button size="xs" variant="subtle" leftSection={<FaPlus></FaPlus>}
                    onClick={addField}
                >Add Field</Button>
            </Group>
        </Stack>
    </div>
}