import WorkflowFilterConfigure from "../../configure/actions/WorkflowFilterConfigure";
import { WorkflowNodeDescriptor, WorkflowNodeContext, WorkflowNodeValues } from "../descriptor";

const filter_desc: WorkflowNodeDescriptor = {
    descriptorId: 'actions/filter',
    configureForm(ctx: WorkflowNodeContext, values: WorkflowNodeValues) {
        return <WorkflowFilterConfigure ctx={ctx} values={values}></WorkflowFilterConfigure>
    },
    getOutput(ctx: WorkflowNodeContext, _: WorkflowNodeValues) {
        if (Object.entries(ctx.inputs).length === 1) {
            return Object.values(ctx.inputs)[0]
        }
        return { invalid: true }
    }
};

export default filter_desc;