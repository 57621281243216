
import { useTranslation } from "react-i18next";
import { useTitle } from "@/libs/hooks/useTitle";
import EntityPage from "@/components/page/EntityPage";
import { useProject } from "@/libs/project/ProjectProvider";

export default function HistoryLanding() {
    const { t } = useTranslation()
    const { projectUrl } = useProject()

    useTitle(t("History"))
    return <EntityPage
        category={t('Graph')}
        title={t('History')}
        tabs={[{
            key: "data",
            route: projectUrl('/data/commits/data'),
            label: t('data.label')
        }, {
            key: "schema",
            route: projectUrl('/data/commits/schema'),
            label: t('schema.label')
        }]}
    >
    </EntityPage>
}