
import { Commit } from '@/libs/client';
import moment from 'moment';
import { useMemo } from 'react';
import Chart from 'react-apexcharts'

type HistoryCommitChartProps = {
  commits: Commit[];
}

export default function HistoryCommitChart({ commits } : HistoryCommitChartProps) {
  const reversedCommits = useMemo(() => [...commits].reverse(), [commits])
  const versions = useMemo(() => reversedCommits.map(c => `v${c.graphVersion}`), [reversedCommits])
    //TODO: HANDLE CLEAR_ALL
    return <div style={{maxWidth: 650, width: '100%'}}>
        <Chart
            series={[
              {
                name: 'Deletions',
                data: reversedCommits.map(co => co.stats?.deletions || 0),
                color: '#e65a0a',
              },{
                name: 'Additions',
                data: reversedCommits.map(co => co.stats?.additions || 0),
                color: '#2bcd69',
              }, {
                name: 'Updates',
                data: reversedCommits.map(co => co.stats?.updates || 0),
                color: '#ffd500',  
              },]}
            type="bar"
            height={200}
            width={'100%'}
            options={{
                chart: {
                  type: 'bar',
                  height: 250,
                  stacked: true,
                  toolbar: {
                    show: false,
                  },

                },
                dataLabels: {
                  enabled: false,
                },
                grid: {
                  borderColor: '#f4f4f4',
                },
                plotOptions: {
                    bar: {
                      columnWidth: '5px',
                    },
                  },
                  xaxis: {
                    type: 'category',
                    categories: versions,
                    tickPlacement: 'center',
                    axisTicks: {
                      show: true,
                      color: '#f0f0f0'
                    },
                    axisBorder: {
                      color: '#1f1f1f',
                      show: false
                    },
                    labels: {
                      style: {fontSize: '10.5px'}
                    }
                  },
                  yaxis: {
                    labels: {
                      formatter(val, _) {
                        return Math.floor(val) + ''
                      }
                    },                    
                  },
                  
                  legend: {
                    position: 'right',
                    offsetY: 40,
                    showForNullSeries: false,
                    showForZeroSeries: true,
                    fontSize: '11px',
                  },
                  fill: {
                    opacity: 1,
                  },
                  tooltip: {
                    x: {
                      formatter(val, _) {
                        const c = reversedCommits[val - 1]
                        return `v${c.graphVersion} (${moment(c.createdDate).fromNow()})`
                      },
                    }
                  }
            }}
        ></Chart>
    </div>
}