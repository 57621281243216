import { ActionIcon } from '@mantine/core';
import { ReactNode, createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Arrow, Point } from './Arrow';
import { FaTimes } from 'react-icons/fa';

type StoredArrow = {
    key: string;
    start: string;
    end: string;
    startPoint: Point;
    endPoint: Point;
    startEl: any;
    endEl: any;
}

type MapperContextProps = {
    arrows: StoredArrow[]
    onArrowStart: (e: any, key: string) => void
    onArrowEnd: (e: any, key: string) => void
    dropArrow: () => void
}

type MapperType = 'oneToMany' | 'manyToOne' | 'manyToMany' | 'oneToOne';

export const MapperContext = createContext({
} as MapperContextProps);

export const useMapper = () => useContext(MapperContext);

type MapperProviderProps = {
    mapperType: MapperType
    children: ReactNode
    values: string[][]
    onValues: (values: string[][]) => void
}

export const MapperProvider = ({ values, children, mapperType, onValues } : MapperProviderProps) => {
    const [arrows, setArrows] = useState<StoredArrow[]>([])
    const [draggingArrow, setDraggingArrow] = useState(false);
    const [start, setStart] = useState({ x: 0, y: 0, key: '', el: undefined });
    const [end, setEnd] = useState<Point>({ x: 0, y: 0 });
    const [hover, setHover] = useState<string>();
    const [selected, setSelected] = useState<string>();
    const [removeButton, setRemoveButton] = useState<Point>();
    const container = useRef<HTMLDivElement | null>(null)
    useEffect(() => {
        if (!container.current){
            return;
        }
        const updateArrows = () => setArrows(values.map(v => {
                const startEl = document.getElementById(`mapper-dot-${v[0]}`)
                const endEl = document.getElementById(`mapper-dot-${v[1]}`)
                return {
                    value: v,
                    startEl,
                    endEl
                }
            })
            .filter(e => e.startEl && e.endEl)
            .map(e => {
                const v = e.value;
                return {
                    key: `${v[0]}:${v[1]}`,
                    start: v[0],
                    end: v[1],
                    startEl: e.startEl,
                    endEl: e.endEl,
                    startPoint: getStartPoint(e.startEl, container.current!),
                    endPoint: getEndPoint(e.endEl, container.current!),
                }
        }));
        updateArrows();
        setTimeout(() => {
            updateArrows();
        }, 100)
    }, [container, values])
    useEffect(() => {
        const handleResize = () => {
            setArrows(arrows.map(a => ({
                ...a,
                startPoint: getStartPoint(a.startEl, container.current || undefined),
                endPoint: getEndPoint(a.endEl, container.current || undefined),
            })));
        };
        window.addEventListener('resize', handleResize);  
        container.current?.addEventListener('scroll', handleResize);  
        return () => {
          window.removeEventListener('resize', handleResize);
          container.current?.removeEventListener('scroll', handleResize);  
        };
    }, [arrows, container]);
    
    const onArrowStart = useCallback((e: any, key: any) => {
        const startAlready = arrows.find(a => a.start === key);
        if (mapperType.startsWith('oneTo') && startAlready) {
            return;
        }
        setRemoveButton(undefined)
        setSelected(undefined)
        e.preventDefault();
        setDraggingArrow(true);
        const point = getStartPoint(e.target, container.current || undefined)
        setStart({ ...point, key, el: e.target });
        setEnd(point);
    }, [mapperType, arrows, container]);

    const handleArrowMove = useCallback((e: any) => {
        e.preventDefault();
        if (draggingArrow) {
            setEnd(calculatePointFromEvent(e, container.current || undefined))
        }
    }, [draggingArrow]);

    useEffect(() => {
        if (draggingArrow) {
            window.addEventListener('mousemove', handleArrowMove);
        } else {
            window.removeEventListener('mousemove', handleArrowMove);
        }
    }, [draggingArrow])

    const onArrowEnd = useCallback((e: any, key: string) => {
        const endAlready = arrows.find(a => a.end === key);
        if (mapperType.endsWith('ToOne') && endAlready) {
            return;
        }
        setRemoveButton(undefined)
        setSelected(undefined)
        setDraggingArrow(false)
        e.preventDefault();
        if (!draggingArrow) {
            return;
        }
        const hasAlready = !!arrows.find(a => a.key === key)
        if (hasAlready) {
            return
        }
  
        onValues(arrows.map(a => [a.start, a.end]).concat([[start.key, key]]))

        setStart({ x: 0, y: 0, key: '', el: undefined });
        setEnd({ x: 0, y: 0 });
    }, [mapperType, start, draggingArrow, arrows]);

    const onClickArrow = useCallback((e: any, a: StoredArrow) => {
        if (draggingArrow) {
            return
        }
        e.stopPropagation();
        const rect = e.target.getBoundingClientRect();
        const { top, left } = container.current?.getBoundingClientRect() || { top: 0, left: 0 }
        const middleX = rect.left + rect.width / 2;
        const middleY = rect.top + rect.height / 2;
        setRemoveButton({ x: middleX - 10 + - left, y: middleY - 10 - top})
        setSelected(a.key)
    }, [selected, draggingArrow])

    const dropArrow = () => {
        setDraggingArrow(false)
        setStart({ x: 0, y: 0, key: '', el: undefined });
        setEnd({ x: 0, y: 0 });
    }

    useEffect(() => {
        const clickOutside = () => {
            if (selected) {
                setSelected(undefined);
            }
        }
        const dblClick = () => {
            dropArrow();
        }
        window.addEventListener('click', clickOutside);    
        window.addEventListener('dblclick', dblClick);    
        return () => {
            window.removeEventListener('click', clickOutside);
            window.removeEventListener('dblclick', dblClick);
        };
    }, [selected])

    const deleteSelectedArrow = useCallback(() => {
        onValues([
            ...arrows.filter(a => a.key !== selected)
                .map(a => [a.start, a.end])
        ])

    }, [arrows, selected])

    const onArrowHover = useCallback((a : StoredArrow) => {
        if (!draggingArrow) {
            setHover(a.key)
        }
    }, [draggingArrow])
    const onArrowLeave = useCallback(() => {
        setHover(undefined)
    }, [])
    return (
        <MapperContext.Provider value={{ 
            arrows,
            onArrowStart,
            onArrowEnd,
            dropArrow,
        }}>
            <div ref={container} style={{position: 'relative'}}>
                {
                    selected && 
                    <ActionIcon 
                        onClick={deleteSelectedArrow}
                        style={{ zIndex: 3, borderRadius: '50%', padding: '0.25rem', position: 'absolute', top: `${removeButton?.y}px`, left: `${removeButton?.x}px`}}
                        variant='filled' size="xs" color="red"><FaTimes></FaTimes></ActionIcon>
                }
                { draggingArrow && 
                    <Arrow
                        startPoint={start}
                        endPoint={end}
                        config={{ strokeWidth: 1.5, arrowColor: '#a0a0a0', arrowHeadEndingSize: 5 }}
                    ></Arrow>
                }
                { arrows.map(a => <><Arrow {...a} 
                onMouseEnter={() => onArrowHover(a)} 
                onMouseLeave={onArrowLeave}
                onClick={e => onClickArrow(e, a)}
                isHighlighted={a.key === hover || a.key === selected} 
                config={{strokeWidth: 1.5, arrowHeadEndingSize: 5, arrowColor: '#12b886', arrowHighlightedColor: a.key === selected ? '#ff6b6b' : '#25549d'}}></Arrow></>) }
                {children}
            </div>
        </MapperContext.Provider>
    );
};


function getDivWithClassAtLocation(className: string, x: number, y: number) {
    const elements = document.querySelectorAll(`.${className}`);
    
    for (const element of elements) {
      const rect = element.getBoundingClientRect();
      if (rect.left <= x && x <= rect.right && rect.top <= y && y <= rect.bottom) {
        return element;
      }
    }
    return null;
}

function calculatePointFromEvent(e: any, outer?: HTMLElement) : Point {
    const startCicle = getDivWithClassAtLocation("mapper-dot-start", e.clientX, e.clientY)
    const endCircle = getDivWithClassAtLocation("mapper-dot-end", e.clientX, e.clientY)
    if (startCicle) { 
        return getStartPoint(startCicle, outer)
    } else if (endCircle) {
        return getEndPoint(endCircle, outer)
    }
    const scrollTop = outer?.getBoundingClientRect().top || 0
    const scrollLeft = outer?.getBoundingClientRect().left || 0
    return { x: e.clientX - scrollLeft, y: e.clientY - scrollTop };
}

function getStartPoint(element: any, outer?: HTMLElement) {
    const rect = element.getBoundingClientRect();
    const scrollTop = outer?.getBoundingClientRect().top || 0
    const scrollLeft = outer?.getBoundingClientRect().left || 0
    const absoluteRightX = rect.right - scrollLeft;
    const absoluteY = rect.top - scrollTop;
    return { x: absoluteRightX, y: absoluteY + rect.height / 2 };
}

function getEndPoint(element: any, outer?: HTMLElement) {
    const rect = element.getBoundingClientRect()
    const scrollTop = outer?.getBoundingClientRect().top || 0
    const scrollLeft = outer?.getBoundingClientRect().left || 0
    const absoluteLeftX = rect.left  - scrollLeft;
    const absoluteY = rect.top -  scrollTop;
    return { x: absoluteLeftX, y: absoluteY + rect.height / 2 };
}