import { ActionResultType } from "@/components/actions/actions";
import { useContainer } from "@/components/containers/ContainerProvider";
import SfyForm from "@/components/form/SfyForm";
import FormTextInput from "@/components/form/inputs/FormTextInput";
import UploadBox, { MIME_TYPES_MAP } from "@/components/upload/UploadBox";
import { buildDataChangeProps } from "@/graph/visualize/render/data/changes/data.changes";
import { Change, IntegrationsApi } from "@/libs/client";
import { Table } from "@mantine/core";
import { FileWithPath } from "@mantine/dropzone";
import { useCallback, useState } from "react";

type UploadFromFiles = {
    id: string
}

export default function UploadFromFiles({ id }: UploadFromFiles) {
    const { closeAllModals } = useContainer()
    const [files, setFiles] = useState<FileWithPath[]>([])
    const [changes, setChanges] = useState<Change[]>([])
    const [commitStep, setCommitStep] = useState<boolean>(false)
    const [message, setMessage] = useState<string>('')
    const doUpload = useCallback(async () => {
        const resp = await new IntegrationsApi().uploadToIntegration(id, files)
        if (resp.status === 200) {
            setChanges(resp.data)
            setCommitStep(true)
            return { type: ActionResultType.NONE }
        }
        return { type: ActionResultType.FAILURE }
    }, [files])
    const doCommit = useCallback(async () => {
        await new IntegrationsApi().approveUploadToIntegration(id, {
            message,
        })
        return { type: ActionResultType.SUCCESS }
    }, [message])
    return <>
        <SfyForm 
            onClose={closeAllModals}
            disabled={commitStep ? !message || changes.length == 0 : files.length === 0}
            submitLabel={commitStep ? 'Approve' : undefined}
            onSubmit={async () => {
                if (commitStep) {
                    return await doCommit()
                }
                return await doUpload()
            }}
        >
            { !commitStep ? <UploadBox
                label={files.length ? `${files.length} File${files.length > 1 ? 's' : ''} to upload` : 'Drop files here'}
                description="Upload all your custom files here. This will use the mappings you have defined."
                onDrop={(files) => { setFiles(files) }}
                files={files}
                accept={[MIME_TYPES_MAP.CSV]}
            ></UploadBox> : undefined }
            { commitStep ? <div>
                    { changes.length > 0 ? <FormTextInput
                        label="Message"
                        description="Insert a commit message here"
                        value={message}
                        onChange={e => setMessage(e.currentTarget.value)}
                    ></FormTextInput> : undefined }
                    <ChangesTable changes={changes} /> 
                    {changes.length == 0 ? <div style={{marginTop: 10, textAlign: 'center'}}>No changes</div> : undefined}
                </div> : undefined }
        </SfyForm>
    </>
}

function ChangesTable({ changes } : { changes: Change[] }) {
    const getCols = (change: Change) => {
        const vs = buildDataChangeProps(change)
        return [<div style={{display: 'flex',alignItems: 'center', gap: 10}}>{vs.renderIcon({ size: '15px', color: vs.color })} {vs.title}</div>, vs.detail, ]
    }
    return <Table style={{marginTop: 30}}>
        <thead>
            <tr style={{textAlign: 'left'}}>
                <th style={{minWidth: 200}}>Change</th>
                <th>Identifier</th>
            </tr>
        </thead>
        <tbody>
            { changes.map((c, idx) => <tr key={idx}>
                {getCols(c).map(cl => (<td><div style={{display: 'flex',alignItems: 'center'}}>{cl}</div></td>))}
            </tr>) }
        </tbody>
    </Table>
}