import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import cytoscape from 'cytoscape';
import { useGraphDisplay, CursorType } from '../../../providers/GraphDisplayProvider';
import { getSelectedIds } from '../../cy.utils';
import { buildDataLayout } from '../config/data.layout';
import { buildDataCy } from '../config/data.cy';
import { createGrid } from '../../cy.grid';
import { RenderedDataGraph, RenderedNode, RenderedTypeNode } from './rendered';
import { renderOverview } from './rendered.overview';
import { renderQuery } from './rendered.query';
import { useContainer } from '@/components/containers/ContainerProvider';
import { ExpandNodes } from '../actions/ExpandNodes';
import { initDataContextMenu } from '../config/data.ctxmenu';
import classes from './DataRenderer.module.css';
import LazyDataGraph from '../lazy/LazyDataGraph';
import { SchemaGraph } from '@/libs/client';

type DataRendererProps = {
    dataGraph?: LazyDataGraph
    includeFields: string[]
    currentQuery: string
    update?: boolean
    schemaGraph: SchemaGraph
}

function resetCursorHover(cy: cytoscape.Core) {
    cy.on('mouseover', (evt) => {
        if (evt.target.group) {
            cy.nodes().forEach(n => {
                n.addClass('hover-mode')
                n?.connectedEdges().forEach(e => {
                    e.addClass('hover-mode')
                    e.source().addClass('hover-mode')
                    e.target().addClass('hover-mode')
                })
            })
            if (evt.target.group() === 'nodes') {
                const node = cy.nodes(`node[id="${evt.target.id()}"]`)[0]
                node?.addClass("hover")
                node?.connectedEdges().forEach(e => {
                    e.addClass('hover')
                    e.source().addClass('hover')
                    e.target().addClass('hover')
                })
                
                node.parent()?.addClass('hover')
                cy.nodes(`node[parent="${evt.target.id()}"]`).forEach(c => {
                    c.addClass('hover')
                })
            } else {
                const edge = cy.edges(`edge[id="${evt.target.id()}"]`)[0]
                edge?.addClass("hover")
                edge?.source().addClass('hover')
                edge?.target().addClass('hover')
            }
        }
    })
    cy.on('mouseout', (evt) => {
        if (evt.target.group) {
            cy.nodes().forEach(n => {
                n.removeClass('hover-mode')
                n?.connectedEdges().forEach(e => {
                    e.removeClass('hover-mode')
                    e.source().removeClass('hover-mode')
                    e.target().removeClass('hover-mode')
                })
            })
            if (evt.target.group() === 'nodes') {
                const node = cy.nodes(`node[id="${evt.target.id()}"]`)[0]
                node?.removeClass("hover")
                node?.connectedEdges().forEach(e => {
                    e.removeClass('hover')
                    e.source().removeClass('hover')
                    e.target().removeClass('hover')
                })
                cy.nodes(`node[parent="${evt.target.id()}"]`).forEach(c => {
                    c.removeClass('hover')
                })
            } else {
                const edge = cy.edges(`edge[id="${evt.target.id()}"]`)[0]
                edge?.removeClass("hover")
                edge?.source().removeClass('hover')
                edge?.target().removeClass('hover')
            }
        }
    })
}

function resetCursorPointer(cy: cytoscape.Core, onSelected: (selected: string[]) => void, effectInProgress: MutableRefObject<boolean>,
    selectInProgress: MutableRefObject<boolean>) {
    cy.on('select', () => {
        if (!effectInProgress.current) {
            onSelected(getSelectedIds(cy))
            selectInProgress.current = true;
        }
    }) 
    cy.on('unselect', () => {
        if (!effectInProgress.current) {
            onSelected(getSelectedIds(cy))
            selectInProgress.current = true;
        }
    }) 
}

function resetCursorListeners(cursorType: CursorType, 
    cy: cytoscape.Core, onSelected: (selected: string[]) => void, 
    effectInProgress: MutableRefObject<boolean>,
    selectInProgress: MutableRefObject<boolean>,
    expandNode: (type: string) => void) {
    const isCursorType = (ct: CursorType) => ct === cursorType
    cy.off('mouseover')
    cy.off('mouseout')
    if (isCursorType('HOVER')) {
        resetCursorHover(cy)
    }

    cy.off('select')
    cy.off('unselect')
    if (isCursorType('POINTER')) {
        resetCursorPointer(cy, onSelected, effectInProgress, selectInProgress)
    }

    cy.off('dblclick')
    cy.on('dblclick', (evt) => {
        if (evt.target.group) {
            if (evt.target.group() === 'nodes') {
                expandNode(evt.target.id())

                //const children : ElementDefinition[] = renderedNode.childrenDefinitions()
                //if (children.length) {
                   /* if (expanded[evt.target.id()]) {
                        cy.remove(`node[parent = "${evt.target.id()}"]`)
                        expanded[evt.target.id()] = false
                        //renderedNode.expanded = false
                    } else {
                        const buildLabel = (v: DataNode): (String | undefined) => {
                            const split = v.display?.split(/\s+/g) || [];
                            if (split?.length > 1) {
                                return split.slice(0, 2).join(' ') + '\n' + split.slice(2).join(' ');
                            }
                            return split[0];
                        }
                        /*cy.add((children?.map((v, idx) => ({
                            group: 'nodes',
                            data: {
                                id: v.id,
                                label: buildLabel(v),
                                color: "#12b886",
                                rawNode: v,
                                parent: evt.target.id(),
                            },
                            position: { 
                                x: (node?.position().x || 0) + 0.0001 * idx,
                                y: node?.position().y,
                            } ,
                        })) || []) as ElementDefinition[])*/
                        //console.log(children)
                        //cy.add(children)
                        //expanded[evt.target.id()] = true
                        //renderedNode.expanded = true
                    //}
                    //cy.layout({ ...buildDataLayout(cy.nodes(':selected')), randomize: false, fit: false }).run()    
                //}
            }
        }
    })
}

export default function DataRenderer({ dataGraph, schemaGraph, currentQuery, includeFields, update } : DataRendererProps) {
    const [cy, setCy] = useState<cytoscape.Core | undefined>(undefined)
    const { cursorType } = useGraphDisplay()
    const { selected, setSelected, clearSelected } = useGraphDisplay()
    const [init, setInit] = useState(true)
    const effectInProgress = useRef(false)
    const selectInProgress = useRef(false)
    const renderedGraph = useRef<RenderedDataGraph>()
    const containerFuncs = useContainer()
    const { openModal, closeAllModals } = containerFuncs;
    const userDefinedLayout = !currentQuery && Object.entries(schemaGraph?.positions || {}).length > 0
    const expandNode = useCallback((type: string) => {
        const node = cy!.nodes().$id(type);
        if (!node) {
            return
        }
        const rgraph = renderedGraph.current!;
        const renderedNode: RenderedNode = node.data('renderedNode')
        if (!(renderedNode instanceof RenderedTypeNode) 
            || !(renderedNode as RenderedTypeNode).count) {
            return
        }
        const doToggle = async (renderedNode: RenderedNode, children?: string[]) => {
            const inst = await rgraph.toggle(renderedNode, children || [])
            inst.removedNodeIds.forEach(id => cy!.remove(`node[id = "${id}"]`))
            inst.removedEdgeIds.forEach(id => cy!.remove(`edge[id = "${id}"]`))
            const parentNode = cy!.$id(renderedNode.id)
            inst.addedNodes.map(n => cy!.add({
                ...n.definition(),
                position: { ...parentNode.position() },
            }))
            inst.addedEdges.forEach(e => cy!.add(e.definition()))
            //const layout = buildDataLayout([]);
            //cy!.layout(layout).run()
            cy?.layout({ ...buildDataLayout(userDefinedLayout), randomize: false, fit: false }).run()    
        }
        if (renderedNode.count === 1) {
            doToggle(renderedNode, [])
        } else if (!rgraph.isExpanded(renderedNode)) {
            openModal('expand_nodes', <ExpandNodes type={type} onClose={closeAllModals} dataGraph={dataGraph!} onSelect={(selected => {
                closeAllModals()
                doToggle(renderedNode, selected)
            })}></ExpandNodes>, {
                title: `Choose nodes for ${type}`,
                type: 'graph',
            })
        } else {
            doToggle(renderedNode)
        }
    }, [cy, renderedGraph, dataGraph])

    useEffect(() => {
        if (cy) {
            cy.nodes(':selected').forEach(n => { n.unselect() })
            cy.autounselectify(cursorType !== 'POINTER');
            clearSelected()
            resetCursorListeners(cursorType, cy, setSelected, effectInProgress, selectInProgress, expandNode)
        }
    }, [cursorType, expandNode])
    useEffect(() => {
        if (!cy || cursorType !== 'POINTER') {
            return
        }
        if(selectInProgress.current) {
            selectInProgress.current = false
            return
        }
        effectInProgress.current = true;
        cy.nodes(":selected").forEach(s => {
            s.unselect()
        })
        /*
        TODO
        let alreadyAnimated = false
        const nodes = selected.vertices.map(v => `${v.label}/${v.id}`)
            .filter(id => !cy.nodes().$id(id).empty())
        // select node
        nodes.forEach(id => {
                const n = cy.nodes().$id(id)
                if (nodes.length === 1) {
                    cy.animate({ center: { eles: n } }, { duration: 500 })
                    alreadyAnimated = true;
                }
                n.select()
            })
        // select parent
        const parents = selected.vertices.map(v => v.label || '')
            .filter(id => !cy.nodes().$id(id).empty())
        parents.forEach(id => {
                const n = cy.nodes().$id(id)
                if (!alreadyAnimated && parents.length === 1) {
                    cy.animate({ center: { eles: n } }, { duration: 500 })
                }
                n.select()
            })
        */
        setTimeout(() => {
            effectInProgress.current = false;
        }, 100)
    }, [selected])
    useEffect(() => {
        let currentCy = cy
        if (currentCy) {
            //onSelected(undefined)
            currentCy.destroy();
        }
        currentCy =  buildDataCy(cursorType)
            // @ts-ignore
            const cdnd = currentCy.compoundDragAndDrop( {
                boundingBoxOptions: { // same as https://js.cytoscape.org/#eles.boundingBox, used when calculating if one node is dragged over another
                  includeOverlays: false,
                  includeLabels: true
                },
                grabbedNode: () => false,
            });
        cdnd.enable();
        resetCursorListeners(cursorType, currentCy, setSelected, effectInProgress, selectInProgress, expandNode)
        if (update) {
            const { initCanvas, resizeCanvas } = createGrid(currentCy!)
            currentCy.on('layoutready', () => {
                initCanvas()
            })  
            currentCy.on('zoom', () => {
                resizeCanvas()
            })  
            currentCy.on('pan', () => {
                resizeCanvas()
            })  
        }
        initDataContextMenu(currentCy, renderedGraph, containerFuncs, userDefinedLayout);
        setCy(currentCy);
    }, []);
    useEffect(() => {
        if (!cy) {
            return;
        }
        const changedNodes = renderedGraph.current!.applyIncludeFields(includeFields)
        changedNodes.forEach(c => {
                const cyNode = cy.nodes().$id(c.id)
                cyNode.attr({
                    ...cyNode.data(),
                    label: c.label,
                })
            })        
    }, [includeFields]);

    useEffect(() => {
        if (!cy || !dataGraph || !schemaGraph) {
            return;
        }
        renderedGraph.current = currentQuery ? renderQuery(schemaGraph, dataGraph!, includeFields) : renderOverview(schemaGraph, dataGraph!)
        const nodeElements = [...renderedGraph.current.nodes()].map(r => r.definition())
        const edgeElements =  [...renderedGraph.current.edges()].map(r => r.definition())

        const existingNodeIds = new Set(dataGraph?.loadedNodes().map(n => n.id!).concat(
            currentQuery ? [] : Object.keys(schemaGraph.entities)
        ))
        const existingEdgeIds = new Set(dataGraph?.loadedEdges().map(n => n.id!))
        cy.edges().forEach(e => {
            if (!existingEdgeIds.has(e.id())) {
                e.remove()
            }
            if (!existingNodeIds.has(e.data('source'))
                || !existingNodeIds.has(e.data('target'))) {
                e.remove()
            }
        })
        cy.nodes().forEach(n => {
            if (!existingNodeIds.has(n.id())) {
                n.remove()
            }
        })
        const positions = schemaGraph.positions || {};
        nodeElements
            .forEach(elem => {
                const cur = cy.nodes(`node[id="${elem.data.id}"]`)[0]
                if (!cur) {
                    cy.add({ ...elem, position: { ...positions[elem.data.id!] } as any})
                } else {
                    cur.attr(elem.data)
                }
            })
        edgeElements
            .forEach(elem => {
                const cur = cy.edges(`edge[id="${elem.data.id!}"]`)[0]
                if (!existingNodeIds.has(elem.data.source)
                    || !existingNodeIds.has(elem.data.target)) {
                    return;
                }
                if (!cur) {
                    cy.add(elem)
                } else {
                    cur.attr(elem.data)
                }
            })
        const layout = buildDataLayout(userDefinedLayout);
        cy.layout({ ...layout, 
            ready: () => {
                cy.animate({ fit: { eles: cy.nodes(), padding: 200}, duration: 0, complete: () => {
                    setInit(false)
                } },)
                cy.zoom(0.5);
            }
        }).run()
    }, [cy, dataGraph, schemaGraph, currentQuery])
    return <>
        <div id="cy-data" className={classes.graph} style={{opacity: init ? 0 : 1}}></div>
    </>
}