import { ActionResultType } from "@/components/actions/actions";
import { useContainer } from "@/components/containers/ContainerProvider";
import { Commit, CommitsApi } from "@/libs/client";
import { Button } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { BiTrash } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

type DiscardCommitButtonProps = {
    commit: Commit;
    navigateOnSuccess: string;
}

export default function DiscardCommitButton({ commit, navigateOnSuccess } : DiscardCommitButtonProps) {
    const { t } = useTranslation()
    const { openConfirm } = useContainer()
    const navigate = useNavigate()
    return <Button 
            styles={{label: {fontWeight: 600}}} 
            size="xs" 
            color="red"
            leftSection={<BiTrash></BiTrash>}
            onClick={() => openConfirm('discard_commit', {
                title: t('graph.discard_commit'),
                confirmationText: t('graph.are_you_sure_discard_commit'),
                cancelText: t('common.no_dont_discard'),
                submitText: t('graph.discard_commit'),
                color: 'red',
                onSubmit: async () => {
                    const resp = await new CommitsApi().discardCommit(commit.id!)
                    if (resp.status === 200) {
                        navigate(navigateOnSuccess)
                        return { type: ActionResultType.SUCCESS }
                    }
                    return { type: ActionResultType.FAILURE }
                }
            })}
        >
            {t('common.discard')}
        </Button>
}