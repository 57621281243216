import { ReactNode, useCallback, useMemo, useState } from "react";
import { Button, Group, Stepper } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import classes from './MultiStepForm.module.css';

export type StepContentProps = {
    form: UseFormReturnType<any>
}

export type StepItem = {
    label: string
    description: string
    fields?: string[]
    renderContent: (props: StepContentProps) => ReactNode
    disableNext?: boolean
}

type MultiStepFormProps = {
    form: UseFormReturnType<any>
    steps: StepItem[]
    renderCompleted?: () => ReactNode
    onClose: () => void
    onSubmit: () => void
}

const validate = (form?: UseFormReturnType<any>, fields?: string[]) => {
    return !form || !(fields  || []).map(f => !form.isValid(f)).find(f => f);
}

export default function MultiStepForm({ form, steps, onClose, onSubmit, renderCompleted }: MultiStepFormProps) {
    const [active, setActive] = useState(0);
    const allowNext = useMemo(() => {
        return validate(form, steps[active].fields) && !steps[active].disableNext
    }, [form, steps, active])
    const nextStep = useCallback(() => {
        setActive((current) => (current < steps.length ? current + 1 : current)) 
    }, [steps]);

    const doSubmit = () => {
        onSubmit()
        if (!renderCompleted) {
            onClose()
        } else {
            setActive((current) => current + 1) 
        }
    }
    const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));
    return <><Stepper allowNextStepsSelect={false} className={classes.stepper} size="sm" style={{marginTop: '1rem', maxWidth: 950}} active={active} onStepClick={setActive} onKeyDown={e => {
        const focusedElement = document.activeElement;
        if (e.key == 'Enter' && focusedElement?.tagName !=='INPUT') {
            if (active !== steps.length - 1) {
                nextStep();
            } else {
                doSubmit()
            }
        }
    }}>
        { steps.map(s => <Stepper.Step label={s.label} description={s.description}>
            {s.renderContent({ 
                form,
            })}
        </Stepper.Step>)}
        { renderCompleted ? <Stepper.Completed>
            {renderCompleted()}
        </Stepper.Completed> : undefined}
      </Stepper>

      { active < steps.length ? <Group className={classes.formButtons} justify="flex-end" mt="xl">
        { active !== 0 && <Button variant="default" onClick={prevStep} style={{borderRadius: 20}} size="sm">Back</Button>}
        <Button onClick={active === steps.length - 1 ? doSubmit : nextStep} disabled={!allowNext} style={{borderRadius: 20}} size="sm">{ active < steps.length - 1 ? 'Next step' : 'Submit'}</Button>
      </Group> : <></> }
    </>
}