/// <reference types="vite-plugin-svgr/client" />
import Logo from '@/assets/logo.svg?react';
import OperatorLogo from '@/assets/ops-logo.svg?react';

import LanguagePicker from '@/components/language/LanguagePicker';
import React from 'react';
import HeaderTabs from './HeaderTabs';
import { AvatarDropdown } from './AvatarDropdown';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Divider, Group, Menu } from '@mantine/core';
import { FaCube, FaCubes } from 'react-icons/fa'
import { GlobalSearch } from './GlobalSearch';
import { useProject } from '@/libs/project/ProjectProvider';
import { useAuth } from '@/libs/auth/AuthProvider';
import classes from './Header.module.css';
import AlertDropdown from './AlertDropdown';

type HeaderProps = {
    loggedIn: boolean
    provider?: boolean
}

function Header({ loggedIn }: HeaderProps) {
    const navigate = useNavigate()
    const location = useLocation()
    const { isOperator, userCtx } = useAuth()
    const LogoToUse =  isOperator() ? OperatorLogo : Logo
    const isApiDocs = location.pathname.startsWith('/api-docs');
    return <div className={classes.header}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <LogoToUse className={classes.logo} onClick={() => navigate('/')}/>
            { isOperator() ? <div style={{backgroundColor: '#ff0040', color: 'white', fontSize: '0.6rem', fontWeight: 'bold', padding: '2px 5px', borderRadius: 10}}>OPS</div> : <></> }
        </div>
        <div style={{flex: 1, paddingLeft: 30}}>
            { loggedIn && <HeaderTabs></HeaderTabs> }
        </div>
        <div style={{display: 'flex', justifyContent: 'flex-end', flex: 1, alignItems: 'center'}}>
            { loggedIn ? 
                (<React.Fragment>
                    { isOperator() ? <></> : <ProjectPicker></ProjectPicker>}
                    <AlertDropdown></AlertDropdown>
                    { false && <GlobalSearch></GlobalSearch> }
                    { userCtx.loggedIn ? <></> : <LanguagePicker></LanguagePicker> }
                    <AvatarDropdown></AvatarDropdown>
                </React.Fragment>
                ) : !isApiDocs && <LanguagePicker></LanguagePicker>
            }
        </div>
    </div>
}

function ProjectPicker() {
    const navigate = useNavigate()
    const { projects, curProject, projectUrl, switchProject } = useProject()
    return <Menu offset={{mainAxis: -2}}>
        <Menu.Target>
            <Group gap={8} style={{ height: 45, userSelect: 'none', color: 'white', marginRight: 30, cursor: 'pointer', fontSize: '0.9rem'}}>
                <FaCube></FaCube>
                {curProject?.name || <div style={{color: '#c0c0c0'}}>No Projects</div>}
            </Group>
        </Menu.Target>
        <Menu.Dropdown styles={{dropdown: { boxShadow: '5px 8px 16px -17px rgba(66, 68, 90, 0.5)'}}}>
            <Menu.Label>Projects</Menu.Label>
            { projects.map(p => 
                <Menu.Item key={p.id} onClick={() => {
                    switchProject(p)
                    navigate(projectUrl("/", p.name)) 
                }}>{p.name}</Menu.Item>) 
            }
            <Divider style={{marginBottom: 6, marginTop: 6}}></Divider>
            <Menu.Item style={{padding: 0}}>
            <Button fullWidth size="xs" styles={{ root: {borderRadius: 4, width: 150}} }
                leftSection={<FaCubes></FaCubes>}
                onClick={() => navigate('/admin/projects')}
            >Manage</Button>
            </Menu.Item>
        </Menu.Dropdown>
    </Menu>
}

export default Header;