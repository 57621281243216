
import { DataEdge, DataNode, SchemaEntity, SchemaRelationship } from '@/libs/client';

import cytoscape from 'cytoscape';
// @ts-ignore
import fcose from 'cytoscape-fcose';
// @ts-ignore
import coseBilkent from 'cytoscape-cose-bilkent';

// @ts-ignore
import cola from 'cytoscape-cola';
// @ts-ignore
import compoundDragAndDrop from 'cytoscape-compound-drag-and-drop';
// @ts-ignore
//import { register as htmlnode } from 'cytoscape-html-node';
import { register as htmlnode } from '@/../node_modules/cytoscape-html-node/src';
// @ts-ignore
import nodeHtmlLabel from 'cytoscape-node-html-label';
// @ts-ignore
import autopanOnDrag from 'cytoscape-autopan-on-drag';
// @ts-ignore
import contextMenus from 'cytoscape-context-menus';
// @ts-ignore
import edgehandles from 'cytoscape-edgehandles';

import './ctx.menu.css';

export function initCy() {
    autopanOnDrag(cytoscape);
    cytoscape.use(htmlnode);
    cytoscape.use(compoundDragAndDrop);
    cytoscape.use(fcose);
    cytoscape.use(coseBilkent);
    cytoscape.use(cola)
    nodeHtmlLabel(cytoscape);
    contextMenus(cytoscape);
    cytoscape.use(edgehandles);
}

export function getSelectedIds(cy: cytoscape.Core) : string[] {
    const nodeIds = cy.$('node:selected').map(s => [
        s.nodes().data('rawNode') as DataNode,
        ...s.nodes().children().map(c => c.data('rawNode')) as DataNode[]
    ]).flatMap(a => a).filter(v => v).map(v => v.id!)

    const entityIds = cy.$('node:selected').map(s =>
        s.nodes().data('rawEntity') as SchemaEntity
    ).filter(v => v).map(v => v.type!)

    const edgeIds = cy.$('edge:selected').map(s => [
        s.edges().data('rawEdge') as DataEdge,
        ...(s.edges().data('rawEdges') || []) as DataEdge[],
    ]).flatMap(a => a).filter(e => e).map(e => e.id!)

    const relationshipIds = cy.$('edge:selected').map(s =>
        s.edges().data('rawRelationship') as SchemaRelationship
    ).filter(e => e).map(e => e.name!)
    
    return [...nodeIds, ...entityIds, ...edgeIds, ...relationshipIds]
}