export default {
  common: {
    title: 'Title',
    description: 'Description',
    labels: 'Labels',
    service: 'Service',
    username: 'Username',
    email: 'Email',
    password: 'Password',
    confirm_password: 'Confirm Password',
    actions: 'Actions',
    login: 'Login',
    logout: 'Logout',
    name: 'Name',
    cancel: 'Cancel',
    status: 'Status',
    state: 'State',
    search: 'Search',
    discard: 'Discard',
    manage: 'Manage',
    available: 'Available',
    installed: 'Installed',
    install: 'Install',
    queries: 'Queries',
    custom: 'Custom',
    security: 'Security',
    sign_in_with: 'Sign in to {{displayName}}',
    code: 'Code',
    message: 'Message',
    details: "Details",
    created: "Created",
    approve: 'Approve',
    delete: 'Delete',
    update: 'Update',
    decline: 'Decline',
    force_sync: 'Force Sync',
    yes_delete: "Yes, Delete",
    no_delete: "No don't delete",
    version: 'Version',
    view_details: 'View Details',
    category: 'Category',
    resolved: 'Resolved',
    unresolved: 'Unresolved',
    general: "General",
    library: 'Library',
    latest_version: 'Latest Version',
    installed_version: 'Installed Version',
    source: 'Source',
    last_generated_date: 'Last Generated Date',
    delete_all: 'Delete All',
    edit: 'Edit',
    size: 'Size',
    open: 'Open',
    closed: 'Closed',
    all: 'All',
    since: 'Since',
    close: 'Close',
    submit: 'Submit',
    sign_in: 'Sign in',
    continue: 'Continue',
    contact_us: 'Contact us',
    no_dont_discard: "No don't discard",
  },
  login: {
    sign_in_different_tenant: 'Sign in to a different tenant',
  },
  success: {
    installed: '{{name}} was successfully installed'
  },
  errors: {
    cannot_find_tenant: "Please enter a valid tenant name. If you're unsure, reach out to your administrator for assistance and more information"
  },
  operator: {
    login: 'Operator Login',
  },
  dashboard: {
    label: 'Dashboard',
  },
  graph: {
    label: 'Graph',
    visualize: 'Visualize',
    history: 'History',
    commit: 'Commit',
    discard_commit: 'Discard commit',
    commit_changes: 'Commit Changes',
    are_you_sure_discard_commit: 'Are you sure you want to discard this commit?',
    state: {
      outdated: 'Outdated',
      synching: 'Synching',
      upToDate: 'Up-to-Date',
    }
  },
  authentication: {
    label: 'Authentication'
  },
  catalogs: {
    label: 'Catalog',
    label_other: 'Catalogs',
  },
  data_graph: {
    label: 'Data Graph',
    editor: 'Data Editor',
  },
  schema_graph: {
    label: 'Schema Graph',
    editor: 'Schema Editor',
    update_schema: 'Update Schema',
  },
  reports: {
    label: 'Report',
    label_other: 'Reports',
    add_report: 'Add Report',
    no_reports: 'No reports yet',
    add_success: "Successfully deleted report {{name}}"
  },
  generated_reports: {
    label: 'Generated Report',
    label_other: 'Generated Reports'
  },
  rules: {
    label: 'Rules',
    inputs: 'Inputs',
    actions: 'Actions',
    control_flows: 'Control Flows',
    outputs: 'Outputs',
  },
  sso: {
    microsoft: 'Sign in with Microsoft'
  },
  audit_events: {
    label: 'Audit Events',
    types: {
      auth: {
        login: 'User {{byUser.username}} logged in',
        login_sso: 'User {{byUser.email}} logged in through SSO',
      },
      integrations: {
        install_integration: 'Installed integration {{context.name}}',
      },
      issues: {
        open_issue: "Opened issue {{context.name}}",
        close_issue: "Closed issue {{context.name}}"
      }
    },
  },
  observability: {
    label: 'Observability',
  },
  alerts: {
    label: 'Alerts',
    no_alerts: 'No alerts yet..'
  },
  issues: {
    label: 'Issue',
    label_other: 'Issues',
  },
  integrations: {
    label: 'Integrations',
    label_other: 'Integrations',
    no_integrations: 'No integrations yet',
    install_integration: 'Install Integration',
  },
  installed_integrations: {
    label: 'Installed Integration',
    label_other: 'Installed Integrations'
  },
  insights: {
    label: 'Insights',
  },
  data: {
    label: 'Data',
    management: 'Data Management'
  },
  schema: {
    label: 'Schema',
  },
  analytics: {
    label: 'Analytics'
  },
  buckets: {
    label: 'Buckets',
  },
  views: {
    label: 'Views',
  },
  credentials: {
    label: 'Credentials',
  },
  profile: {
    label: 'Profile',
  },
  user: {
    username_description: 'Unique username for the user you want to create',
    password_description: 'Unique password for the user you want to create',
    confirm_password_description: 'Confirm the password by writing down the same one you wrote in the previous field',
    password_dont_match: "Password don't match",
  },
  blocks: {
    inputs: {
      data_graph: 'Data Graph',
      schema_graph: 'Schema Graph',
      audit_log: 'Audit Log'
    },
    actions: {
      filter_nodes: 'Filter Nodes',
      traversal: 'Traversal',
      transform: 'Transform',
      filter: 'Filter',
    },
    control_flows: {
      if_else: 'If/Else',
      for_each: 'For Each'
    },
    outputs: {
      generate_report: 'Generate Report',
      notify: 'Notify'
    }
  },
  settings: {
    label: 'Settings',
  },
  system: {
    label: 'System'
  },
  tracking: {
    label: 'Tracking'
  },
  tickets: {
    label: "Tickets",
    ticket_service_number: 'Service Ticket Number',
    not_synched_yet: 'Not synched yet',
    no_tickets_yet: 'No tickets yet',
    please_enter_ticket_number_delete: 'Please enter the ticket number of the ticket to delete',
  },
  scans: {
    label: 'Scan',
    label_other: 'Scans',
    condition: 'Condition',
    condition_other: 'Conditions',
    tasks: 'Scan Tasks',
    available_scans: 'Available Scans',
    no_available: "No available scans yet",
    no_scans_yet: 'No scans yet',
    no_conditions_yet: 'No conditions yet',
    categories: {
      vulnerability_management: 'Vulnerability Management',
      asset_management: 'Asset Management',
      risk_assessment: 'Risk Assessment',
      compliance: 'Compliance',
      identity_and_access_management: "Identity and Access Management",
      data_protection: 'Data Protection',
      platform_security: 'Platform Security',
      application_security: 'Application Security',
      data_quality: 'Data Quality',
    },
  },
  scripts: {
    label: 'Scripts',
  },
  projects: {
    label: 'Projects',
  },
  'System': 'System',
  'Users': 'Users',
  'Tenant': 'Tenant',
  'Me': 'Me',
  'Not a customer yet?': 'Not a customer yet?',
  'Contact us': 'Contact us',
  'Get in touch': 'Get in touch with one of our specialists for more information about our services.',
  'Incorrect username or password': 'Incorrect username or password',
  'Role': 'Role',
  'SYS_ADMIN': 'Sys Admin',
  'ADMIN': 'Admin',
  'USER': 'User',
  'READ_ONLY': 'Read-only',
  'No reports yet': 'No reports yet',
  "You don't have any client credentials": "You don't have any client credentials",
  'Enter your gremlin query here': 'Enter your gremlin query here',
  'Upload a graph': 'Upload a graph',
  'Upload graph': 'Upload graph',
  'Entire Graph': 'Entire Graph',
  'Execute': 'Execute',
  'Successfully uploaded graph!': 'Successfully uploaded graph!',
  'Unable to upload graph.': 'Unable to upload graph.',
  'No tenants were found': 'No tenants were found',
  'No users were found': 'No users were found',
}