import { useTranslation } from "react-i18next";
import { useTitle } from "@/libs/hooks/useTitle";
import EntityPage from "@/components/page/EntityPage";
import { useProject } from "@/libs/project/ProjectProvider";


export default function IntegrationsLanding() {
    const { t } = useTranslation()
    const { projectUrl } = useProject()
    useTitle(t("integrations.label_other"))
    return <EntityPage
        category={t('data.management')}
        title={t('integrations.label_other')}
        tabs={[{
            key: "installed",
            route: projectUrl('/collection/integrations/installed'),
            label: t('common.installed')
        }, {
            key: "integrations",
            route: projectUrl('/collection/integrations/library'),
            label: t('integrations.label_other')
        }]}
    ></EntityPage>
}