import { useParams } from 'react-router-dom';
import controlStore from '../control.store';
import { Stack, Table } from '@mantine/core';
import { useMemo } from 'react';
import { ControlCriteriaSection, ControlParameter } from '../control';
import { useDisclosure } from '@mantine/hooks';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';

function ControlParameterRow({ parameterDescriptor } : { parameterDescriptor: ControlParameter }) {
    return <Table.Tr>
        <Table.Td></Table.Td>
        <Table.Td style={{width: '70%'}}>
            <Stack gap={2}>
                <div>{parameterDescriptor.label}</div>
                {parameterDescriptor.description ? <div style={{fontSize: '0.85rem', color: '#a0a0a0'}}>{parameterDescriptor.description}</div> : <></>}
            </Stack>
        </Table.Td>
        <Table.Td><i style={{color: '#a0a0a0'}}>Not set</i></Table.Td>
    </Table.Tr>
}

function ControlCriteriaSectionParameters({ criteriaSection } : { criteriaSection: ControlCriteriaSection }) {
    const allDisabled = useMemo(() => {
        return !criteriaSection.criteria.find(c => c.enabled)
    }, [criteriaSection])
    if (allDisabled || !criteriaSection.parameterDescriptors) {
        return <></>
    }
    const [opened, handlers] = useDisclosure(true);
    return <>
        {criteriaSection.label ? <Table.Tr style={{backgroundColor: '#f8f8f8', fontWeight: 500, borderTop: '1px solid #e8e8e8', borderBottom: '1px solid #e8e8e8', padding: '0.4rem 0.8rem', color: '#808080', textDecoration: allDisabled ? 'line-through' : undefined}}>
            <Table.Td onClick={handlers.toggle}>
                { opened ? <FaChevronDown color='#5f5f5f' size="0.7rem"></FaChevronDown> : <FaChevronRight color='#5f5f5f' size="0.7rem"></FaChevronRight> }
            </Table.Td>
            <Table.Td colSpan={2} onClick={handlers.toggle}>{criteriaSection.label}</Table.Td>
        </Table.Tr> : <></>}
        {opened && criteriaSection.parameterDescriptors.map(p => <ControlParameterRow key={p.id} parameterDescriptor={p}/>)}
    </>
}

export default function ControlParameters() {
    const { controlId } = useParams()
    const control = controlStore.find(controlId!)

    return <Table>
        <Table.Thead>
            <Table.Tr>
                <Table.Th style={{width: 25}}></Table.Th>
                <Table.Th>Parameter</Table.Th>
                <Table.Th>Value</Table.Th>
            </Table.Tr>
        </Table.Thead>
        {control?.parameterDescriptors?.map(p => <ControlParameterRow parameterDescriptor={p}></ControlParameterRow>)}
        {
            control?.sections?.filter(s => (s.parameterDescriptors?.length || 0) > 0)
                .map(s => <ControlCriteriaSectionParameters key={s.id} criteriaSection={s}></ControlCriteriaSectionParameters>)
        }
    </Table>
}