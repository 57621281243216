import { ActionResultType } from "@/components/actions/actions"
import { useContainer } from "@/components/containers/ContainerProvider"
import FormCheckbox from "@/components/form/FormCheckbox"
import SfyForm from "@/components/form/SfyForm"
import UploadBox, { MIME_TYPES_MAP } from "@/components/upload/UploadBox"
import { IntegrationsApi } from "@/libs/client"
import { FileWithPath } from "@mantine/dropzone"
import { useState } from "react"


export default function ImportMappingsForm({ id, onRefresh }: { id: string, onRefresh: () => void }) {
    const { closeAllModals } = useContainer()
    const [files, setFiles] = useState<FileWithPath[]>([])
    const [replace, setReplace] = useState(false)
    return <SfyForm
        onClose={closeAllModals}
        disabled={files.length === 0}
        onSubmit={() => {    
            return new Promise((resolve) => {
                const reader = new FileReader();
                reader.onload = (event) => {
                    const content = event.target!!.result?.toString() || '[]';
                    const parsedJson = JSON.parse(content);
                    new IntegrationsApi().importMappings(id, replace, parsedJson).then(() => {
                        onRefresh()
                        resolve({ type: ActionResultType.SUCCESS });
                    }).catch(() => {
                        resolve({ type: ActionResultType.FAILURE });
                    });
                };
    
                reader.onerror = (error) => {
                    console.error(error)
                    resolve({ type: ActionResultType.FAILURE });
                };
    
                reader.readAsText(files[0]);
            });
        }}
    >
        <UploadBox
            label={files.length ? `You have uploaded a file for mappings. Please submit to proceed.` : 'Drop files here'}
            description="Upload json file containing mappings you have exported"
            onDrop={(files) => { setFiles(files) }}
            files={files}
            maxFiles={1}
            accept={[MIME_TYPES_MAP.JSON]}
        ></UploadBox>
        <FormCheckbox checked={replace} onChange={(e) => setReplace(!!e.currentTarget.value)} label="Do you want this import to replace the existing mappings (i.e. delete mappings that are not in the imported file)?"></FormCheckbox>
    </SfyForm>

}