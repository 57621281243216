import EntityPage from "@/components/page/EntityPage"
import { TabData } from "@/components/page/EntityTabs"
import { Issue, IssuesApi } from "@/libs/client"
import { useTitle } from "@/libs/hooks/useTitle"
import { useProject } from "@/libs/project/ProjectProvider"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

export default function IssueLanding() {
    const { id } = useParams()
    const { projectUrl } = useProject()
    const [issue, setIssue] = useState<Issue>()
    const fetchIssue = async () => {
        setIssue((await new IssuesApi().issue(id!)).data)
    }
    useEffect(() => {
        fetchIssue()
    }, [])
    if (issue)
        useTitle(`${issue.issueCode} - Issue`)

    return issue && <EntityPage
        category={`Issues`}
        title={issue.issueCode || ''}
        tabs={[{
            key: 'details',
            label: "Details",
            route: projectUrl(`/insights/issues/${id}/details`),
        }] as TabData[]}
        breadcrumbs={[
            {
                key: 'issues',
                label: 'Issues',
                link: projectUrl('/insights/issues'),
            },
            {
                key: 'name',
                label: issue.issueCode || '',
            }
        ]}
    ></EntityPage>}