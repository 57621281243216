import { WorkflowNodeContext, WorkflowNodeDescriptor, WorkflowNodeValues } from "../descriptor";

const desc: WorkflowNodeDescriptor = {
    descriptorId: 'inputs/data_graph',
    getOutput: (_: WorkflowNodeContext, _2: WorkflowNodeValues) => {
        //TODO
        return {
            types: [{ type: 'nodes' }],
            many: true,
        };
    },
}
export default desc;