import { Menu } from "@mantine/core";
import { MdLanguage } from 'react-icons/md';
import { IoCheckmarkDoneCircleSharp } from 'react-icons/io5';
import i18n from '../../i18n';
import { useEffect, useState } from "react";

const LANG_KEY = 'i18nextLng';

function LanguageItem(props: {lang: string, selected: string, label: string, onChange: (_: string) => void}) {
    return <Menu.Item onClick={() => props.onChange(props.lang)} leftSection={props.selected === props.lang ? <IoCheckmarkDoneCircleSharp size="1rem"></IoCheckmarkDoneCircleSharp> : undefined}>
            {props.label}
        </Menu.Item>
}

export default function LanguagePicker() {
    const [lang, setLang] = useState('');

    function changeLanguage(newLang: string) {
        localStorage.setItem(LANG_KEY, newLang);
        i18n.changeLanguage(newLang);
        setLang(newLang);
    }
    useEffect(() => {
        setLang(i18n.resolvedLanguage || '');
    }, []);
    return (
        <div style={{marginLeft: 10}}>
            <Menu offset={{mainAxis: 12, crossAxis: -30}}>
                <Menu.Target>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <MdLanguage style={{ cursor: 'pointer' }} color="white" size="1.2rem"></MdLanguage>
                    </div>
                </Menu.Target>
                <Menu.Dropdown>
                    <LanguageItem lang="en" label="English" selected={lang} onChange={changeLanguage}></LanguageItem>
                    <LanguageItem lang="fr" label="Français" selected={lang} onChange={changeLanguage}></LanguageItem>
                </Menu.Dropdown>
            </Menu>
        </div>
    );
}