import { Checkbox } from '@mantine/core';
import { useState } from 'react';
import { VscTriangleDown, VscTriangleUp } from 'react-icons/vsc';
import { useDisclosure } from '@mantine/hooks';
import { useGraphDisplay } from '../../providers/GraphDisplayProvider';
import { useGraphLoader } from '../../providers/GraphLoaderProvider';
import  classes from './FieldOptions.module.css'

export function FieldOptions() {
    const { dataGraph } = useGraphLoader()
    const { setIncludeFields } = useGraphDisplay()
    const [selected, setSelected] = useState<string[]>([])
    const [expanded,  { toggle }] = useDisclosure(false)
    //TODO get fields differently
    const fields = ['id', 'type', 'oid', ...[
        ...new Set([...Object.values(dataGraph?.loadedNodes() || {}), 
            ...Object.values(dataGraph?.loadedEdges() || {})]
                .flatMap(e => Object.keys(e.properties || {})))].sort()]
    return <div>
        <div onClick={toggle} className={classes.fields}>
            <span>Fields ({selected.length})</span>
            <span className={classes.fieldIcon}>
                {expanded ?  <VscTriangleDown></VscTriangleDown> : <VscTriangleUp></VscTriangleUp> }
            </span>
        </div>
        { expanded ? <div className={classes.fieldsBox}>
            <Checkbox.Group value={selected} onChange={f => {setSelected(f); setIncludeFields(f);}} size='xs'>
                {fields.map(f => <Checkbox key={f} label={f} value={f} styles={{label: { color: 'white', fontSize: '0.9rem', userSelect: 'none', cursor: 'pointer' }, input: { cursor: 'pointer' }, labelWrapper: { width: '100%' }, body: {cursor: 'pointer'}, root: { borderBottom: '1px solid #626262', padding: '10px'}}}></Checkbox>)}
            </Checkbox.Group>
        </div> : <></>}
    </div>
}
