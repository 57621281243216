import { useEffect, useState } from "react";
import ChangeFab from "../../../common/commit/ChangeFab";
import { GraphEditorProvider, useGraphEditor } from "@/graph/visualize/providers/GraphEditorProvider";
import { useGraphLoader } from "@/graph/visualize/providers/GraphLoaderProvider";
import SchemaRenderer from "../renderer/SchemaRenderer";
import GraphInteractor from "@/graph/visualize/common/interactor/GraphInteractor";
import { useGraphDisplay } from "@/graph/visualize/providers/GraphDisplayProvider";
import { GraphsApi, SchemaEntity, SchemaGraph, SchemaRelationship } from "@/libs/client";
import SchemaEditorToolbar from "./SchemaEditorToolbar";
import { ModifiedGraph } from "../../../../../libs/graph/modified";
import { buildSchemaNavigator } from "../navigator/schema.navigator";
import { useTranslation } from "react-i18next";
import { useTitle } from "@/libs/hooks/useTitle";
import { buildSchemaChangeProps } from "../changes/schema.changes";
import SyncAnimation from "@/components/animations/SyncAnimation";
import { Button } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { useContainer } from "@/components/containers/ContainerProvider";
import { useProject } from "@/libs/project/ProjectProvider";
import { applyCommit } from "@/libs/graph/schema";

function SchemaEditorImpl() {
    const navigate = useNavigate()
    const {curProject} = useProject()
    const { reloadSchemaGraph } = useGraphLoader()

    const { t } = useTranslation()
    useTitle(t('schema_graph.editor'))
    const { openCommit, loadingCommit, commit, synching } = useGraphEditor()
    const { setActiveType } = useGraphDisplay()
    const [schemaGraph, setSchemaGraph] = useState<SchemaGraph>()
    const [fullSchema, setFullSchema] = useState<SchemaGraph>()
    const [updatedSchemaGraph, setUpdatedSchemaGraph] = useState<ModifiedGraph<SchemaGraph, SchemaEntity, SchemaRelationship> | undefined>()
    const [version, setVersion] = useState<number | undefined>()
    const { openModal, closeAllModals } = useContainer()
    const { projectUrl } = useProject()
    useEffect(() => {
        openCommit('SCHEMA')
        setActiveType("SCHEMA")
    }, [])
    async function loadSchemaGraphForUpdate() {
        setSchemaGraph((await new GraphsApi().schemaGraph(curProject.id!, "core")).data)
    }
    async function loadFullSchema() {
        setFullSchema((await new GraphsApi().schemaGraph(curProject.id!)).data)
    }
    useEffect(() => {
        loadSchemaGraphForUpdate()
        loadFullSchema()
    }, [])
    useEffect(() => {
        if (schemaGraph && commit) {
            const newSchema = applyCommit(schemaGraph!, commit!)
            setUpdatedSchemaGraph(newSchema)
        }
        if (schemaGraph) {
            setVersion(schemaGraph!.version! + 1)
        }
    }, [schemaGraph, commit])
    useEffect(() => {
        if (synching) {
            openModal('sync_commit', <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <SyncAnimation height={400}></SyncAnimation>
                <h3>Cannot open a new commit since the graph is currently synching</h3>
                <Button onClick={() => {
                    closeAllModals()
                }}>Go Back</Button>
            </div>, {
                onClose: () => {
                    navigate(projectUrl('/data/graph/schema'))
                }
            })
        }
    }, [synching])
    const loading = !fullSchema || !schemaGraph || loadingCommit || !updatedSchemaGraph
    return <GraphInteractor navigatorProps={{ 
        title: t('schema_graph.label'),
        loading,
        elements: updatedSchemaGraph && fullSchema ? buildSchemaNavigator(updatedSchemaGraph!, fullSchema!, true) : [],
    }}>
        <SchemaEditorToolbar version={version} commit={commit!} reloadSchemaGraph={async () => {
            await reloadSchemaGraph()
        }} backPath={projectUrl('/data/graph/schema')}></SchemaEditorToolbar>
            { commit && fullSchema && <><SchemaRenderer
                modifiedGraph={updatedSchemaGraph}
                fullSchema={fullSchema}
                update
            ></SchemaRenderer>
            { commit !== null ? <ChangeFab version={version!} commit={commit!} buildChangeProps={buildSchemaChangeProps}></ChangeFab> : undefined }
            </>}
    </GraphInteractor>
}

export default function SchemaEditor() {
    return <GraphEditorProvider>
        <SchemaEditorImpl></SchemaEditorImpl>
    </GraphEditorProvider>
}