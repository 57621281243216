import cytoscape  from 'cytoscape';
import { CursorType } from "../../../providers/GraphDisplayProvider";
import { DATA_GRAPH_STYLES } from "./data.graphStyles";

export function buildDataCy(cursorType: CursorType) {
    return cytoscape({
        container: document.getElementById('cy-data'),
        boxSelectionEnabled: false,
        autoungrabify: false,
        autounselectify: cursorType !== 'POINTER',
        maxZoom: 10,
        minZoom: 0.2,
        style: DATA_GRAPH_STYLES,
    });
}