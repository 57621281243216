import { useTitle } from "@/libs/hooks/useTitle";
import EntityPage from "@/components/page/EntityPage";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { TabData } from "@/components/page/EntityTabs";
import { Catalog, CatalogsApi } from "@/libs/client";
import { useTranslation } from "react-i18next";

export default function OpsCatalogLanding() {
    const { t } = useTranslation()
    const { id } = useParams()
    const [catalog, setCatalog] = useState<Catalog>()
    const fetch = async () => {
        const res = await new CatalogsApi().catalog(id!)
        if (res.status == 200) {
            setCatalog(res.data);
        } else {
            //TODO
        }
    }
    useEffect(() => {
        fetch();
    }, [])
    useEffect(() => {
        if (catalog) {
            useTitle(`${catalog.name} - ${t("catalog.label_other")}`)
        }
    }, [catalog])
    return catalog && <EntityPage
        category={t('catalogs.label_other')}
        title={catalog.name || ''}
        tabs={[{
            key: 'details',
            label: t("common.details"),
            route: `/ops/catalogs/${id}/details`,
        }, {
            key: 'integrations',
            label: t("integrations.label_other"),
            route: `/ops/catalogs/${id}/integrations`,

        }, {
            key: 'scans',
            label: t("scans.label_other"),
            route: `/ops/catalogs/${id}/scans`,

        }, {
            key: 'conditions',
            label: t("scans.condition_other"),
            route: `/ops/catalogs/${id}/conditions`,

        }] as TabData[]}
        breadcrumbs={[
            {
                key: 'catalogs',
                label: t('catalogs.label_other'),
                link: '/ops/catalogs',
            },
            {
                key: 'name',
                label: catalog.name!,
            }
        ]}
    ></EntityPage>
}