import classes from './LoginInput.module.css';
import cx from 'clsx';

type LoginInputProps = {
    type?: string;
    error?: string;
    label?: string;
    value?: string;
    onChange?: (v: any) => void
    onClick?: (v: any) => void
}

export default function LoginInput({ type = 'text', label, value, error, onClick, onChange }: LoginInputProps) {
  return (
    <div className={cx(classes.loginInput, error ? classes.errorBorder : undefined)}>
      <input type={type} value={value} onChange={onChange} onClick={onClick}/>
      <label className={value && classes.filled}>
        {label}
      </label>
      { error && <div className={classes.errorText}>{error}</div> }
    </div>
  );
}