import { TreeElement } from "@/graph/visualize/common/navigator/TreeView"
import { SchemaEntity, SchemaGraph, SchemaRelationship } from "@/libs/client"
import { ModifiedElem, ModifiedGraph } from "../../../../../libs/graph/modified"
import { CgBox } from "react-icons/cg"
import { sortBy } from "@/libs/utils/sort"
import { SCHEMA_NEW_COLOR, SCHEMA_REMOVED_COLOR, SCHEMA_UPDATED_COLOR } from "../config/schema.constants"

const FIELD_ICON_STYLE = {
    fontSize: '0.8rem',
    fontWeight: '500',
    width: '0.9rem',
    height: '0.9rem',
    color: 'white',
    backgroundColor: '#d97445',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%'
}

const buildElements = (nodes: ModifiedElem<SchemaEntity>[]) : TreeElement[] => {
    const getColorValue = (n: ModifiedElem<SchemaEntity>) => {
        if (n.new) {
            return SCHEMA_NEW_COLOR
        } else if (n.removed) {
            return SCHEMA_REMOVED_COLOR
        } else if (n.updated) {
            return SCHEMA_UPDATED_COLOR
        }
    }
    return nodes.map(n => ({
        id: n.type,
        label: n.type,
        renderIcon: (props: any) => <CgBox {...props}></CgBox>,  
        removed: n.removed,
        new: n.new,
        updated: n.updated,   
        color: getColorValue(n),
        children: Object.values(n.fields || {}).map(f => (
            { 
                id: n.type + ':' + f.name, 
                label: f.name + ' : ' + f.type,
                renderIcon: () => <div style={FIELD_ICON_STYLE}>f</div>
            }
        ))
    })).sort(sortBy(n => n.label)) as TreeElement[]
}

const buildElementsFromFull = (nodes: SchemaEntity[]) : TreeElement[] => {
    return nodes.map(n => ({
        id: `${n.type}||full`,
        label: n.type,
        renderIcon: (props: any) => <CgBox {...props}></CgBox>,  
        children: Object.values(n.fields || {}).map(f => (
            { 
                id: n.type + ':' + f.name, 
                label: f.name + ' : ' + f.type,
                renderIcon: () => <div style={FIELD_ICON_STYLE}>f</div>
            }
        ))
    })).sort(sortBy(n => n.label)) as TreeElement[]
}

export const buildSchemaNavigator = (modifiedGraph: ModifiedGraph<SchemaGraph, SchemaEntity, SchemaRelationship>, fullSchema?: SchemaGraph, update: boolean = false): TreeElement[] => {
    if (update) {
        return [{ id: "core", "label": "Customized", children: buildElements(modifiedGraph.nodes), openByDefault: true }, 
            { id: "others", label: "From Integrations", children: buildElementsFromFull(Object.values(fullSchema?.entities || {}))}]
    }
    return buildElements(modifiedGraph.nodes)
}