import { Issue, IssueStateEnum, IssuesApi, PartialInstalledIntegration } from "@/libs/client";
import { useProject } from "@/libs/project/ProjectProvider";
import { Badge, Card, Group, Progress, Stack } from "@mantine/core";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import showdown from "showdown";
import classes from './IssueRow.module.css';
import { FaTrash } from "react-icons/fa";
import notify from "@/libs/notify/notify";
import { ActionResultType, EntityAction } from "@/components/actions/actions";
import { truncate } from "@/libs/utils/strings";
import { removeMarkdown } from "@/libs/utils/markdown";
import { useContainer } from "@/components/containers/ContainerProvider";
import ActionDropdown from "@/components/actions/ActionDropdown";
import { useMemo } from "react";

const converter = new showdown.Converter();

const stateColor = (state: IssueStateEnum) => {
    switch (state) {
        case "OPEN":
            return "green"
        case "CLOSED":
            return "red"
    }
}

export default function IssueRow({ issue, onRefresh }: { issue: Issue, onRefresh: () => void }) {
    const { projectUrl } = useProject()
    const navigate = useNavigate()
    const { openConfirm } = useContainer()
    const actions: EntityAction<Issue>[] = [{
        label: 'Delete',
        icon: <FaTrash></FaTrash>,
        execute: (e) => {
            openConfirm('delete', {
                title: 'Delete',
                confirmationText: <>Are you sure you want to delete the scan result <b>{truncate(removeMarkdown(e.message), 30)}</b>?</>,
                cancelText: "No don't delete",
                submitText: "Yes, Delete",
                color: 'red',
                onSubmit: async () => {
                    await new IssuesApi().deleteIssue(e.key!)
                    onRefresh()
                    //TODO max length
                    notify.success(`Scan result ${truncate(removeMarkdown(e.message), 30)} successfully deleted`)
                    return { type: ActionResultType.SUCCESS }
                }
            })
        }
    }];
    const slaProgress = useMemo(() => {
        if (issue && issue.sla) {
            return 100 * moment().diff(issue.sla.startDate || issue.createdDate) / moment(issue.sla.endDate).diff(issue.sla.startDate || issue.createdDate)
        }
        return 0
    }, [issue])
    return <Card className={classes.issueRow} withBorder onClick={() => navigate(projectUrl(`/insights/issues/${issue.key}`))}>
        <Stack gap={0} style={{position: 'relative'}}>
            <div style={{position: 'absolute', top: 0, right: 0}}>
                <ActionDropdown entity={issue} actions={actions}></ActionDropdown>
            </div>
            <div style={{maxWidth: 'calc(100% - 85px)',fontSize: '0.9rem'}} dangerouslySetInnerHTML={{ __html: converter.makeHtml(issue.message || '') }} />
            <Group>
                <Group style={{flex: 1}}>
                <Badge color={stateColor(issue.state!)}>{issue.state}</Badge>
                <div style={{fontSize: '0.8rem', color: '#c0c0c0'}}>
                    {moment(issue.createdDate || '').fromNow()}
                </div>
                </Group>
                {issue.labels?.map((l: string) => <Badge size="sm">{l}</Badge>)}
                {issue.installedIntegrations?.map((i: PartialInstalledIntegration) => <Badge size="sm" color="purple">{i.name}</Badge>)}
                <Badge size="sm" color={!issue.ticket?.synchedTicketNumber ? 'gray' : undefined}>
                    {issue.ticket?.synchedTicketNumber || 'No ticket yet'}
                </Badge>
            </Group>
            { issue.sla?.endDate && issue.state === 'OPEN' ? <Group style={{marginTop: 10, marginBottom: -10}} justify="flex-end">
            <div style={{fontSize: '0.8rem', color: '#c0c0c0',fontWeight: 'bold'}}>
                    SLA:
                </div>
                <div style={{fontSize: '0.8rem', color: '#c0c0c0', flex: 1}}>
                    {moment(issue.sla.endDate || '').fromNow()}
                </div>
                <div>
            <Progress.Root size="sm" style={{width: 150}}>
                    <Progress.Section color={slaProgress > 80 ? "red" : undefined} value={slaProgress}>
                    </Progress.Section>
                </Progress.Root>
                </div>
            </Group> : <></> }
        </Stack>
    </Card>
}