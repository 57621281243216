import EntityPage from "@/components/page/EntityPage"
import { InstalledScan, ScansApi } from "@/libs/client"
import { useTitle } from "@/libs/hooks/useTitle"
import { useProject } from "@/libs/project/ProjectProvider"
import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"


export default function InstalledScanLanding() {
    const { id, category } = useParams()
    const { t } = useTranslation()
    const { projectUrl } = useProject()
    const [installedScan, setInstalledScan] = useState<InstalledScan>()
    const fetchInstalledScan = useCallback(async () => {
        const fetched = (await new ScansApi().installedScan(id!)).data
        setInstalledScan(fetched)
    }, [])

    useEffect(() => {
        fetchInstalledScan()
    }, [])
    useTitle(t("Installed Scan"))
    return installedScan && <EntityPage 
        category={t('Installed Scans')}
        title={installedScan.scanDefinition?.label!}
        tabs={installedScan.scanDefinition?.category === 'compliance' ? [{
            key: "controls",
            route: projectUrl(`/analysis/scans/installed/${category}/${id}/controls`),
            label: 'Controls'
        }] : [{
            key: "scan_tasks",
            route: projectUrl(`/analysis/scans/installed/${category}/${id}/scan_tasks`),
            label: t('Scan Tasks')
        }]}
        breadcrumbs={[
            {
                key: 'installed_scans',
                label: 'Installed Scans',
                link: projectUrl(`/analysis/scans/installed`),
            }, {
                key: category!,
                label: t(`scans.categories.${category}`),
                link: projectUrl(`/analysis/scans/installed/${category}`),
            }, {
                key: installedScan.id!!,
                label: installedScan.scanDefinition?.label!,
            }
        ]}
    ></EntityPage>
}