import { Script, ScriptsApi } from "@/libs/client"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import CodeEditor from '@uiw/react-textarea-code-editor';

export default function ScriptDetails() {
    const { id } = useParams()
    const [script, setScript] = useState<Script>()
    const fetch = async () => {
        const res = await new ScriptsApi().script(id!)
        if (res.status == 200) {
            setScript(res.data);
        } else {
            //TODO
        }
    }
    useEffect(() => {
        fetch();
    }, [])
    return script && <CodeEditor
        value={script.data}
        language="python"
        readOnly
        padding={15}
        minHeight={200}
        style={{
            borderRadius: 10,
            fontSize: 12,
            backgroundColor: "#fafafa",
            fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
        }}
    />
}