

//export const SCHEMA_DEFAULT_COLOR = '#12b886';
export const SCHEMA_DEFAULT_COLOR = '#4f4f4f';
export const SCHEMA_NEW_COLOR = '#6bdb92';
export const SCHEMA_REMOVED_COLOR = '#db6b6b';
export const SCHEMA_UPDATED_COLOR = '#dbac6b';

export const SCHEMA_SELECTED_COLOR  = '#25549d';

export const SCHEMA_NODE_WIDTH = '300px';
export const SCHEMA_NODE_HEIGHT = '120px';
