import { DataNode, GraphsApi, Integration, IntegrationsApi, Issue, IssuesApi } from "@/libs/client";
import notify from "@/libs/notify/notify";
import { useProject } from "@/libs/project/ProjectProvider";
import { buildApiUrl } from "@/libs/utils/apiUrl";
import { sortBy } from "@/libs/utils/sort";
import { ActionIcon, Badge, Divider, Grid, Group, Stack, Table, TextInput } from "@mantine/core";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FaEdit, FaMinusCircle, FaPlusCircle, FaSave, FaTimes } from "react-icons/fa";
import { useParams } from "react-router-dom";
import showdown from "showdown";

const converter = new showdown.Converter();

type MetadataEntry = {
    key: string;
    value: string;
}

const getMetadataEntries = (issue: Issue) => {
    return Object.entries(issue.userDefinedMetadata || {}).map(e => ({ key: e[0], value: e[1]})).sort(sortBy(e => e.key));
}

function Metadata({ issue }: { issue: Issue }) {
    const [editEnabled, setEditEnabled] = useState(false)
    const [metadata, setMetadata] = useState<MetadataEntry[]>(getMetadataEntries(issue));
    const [metadataOnCancel, setMetadataOnCancel] = useState<MetadataEntry[]>([]);
    const updateMetadata = useCallback(async () => {
        const userDefinedMetadata = metadata.filter(m => m.key).reduce((acc, cur) => {
            acc[cur.key] = cur.value;
            return acc
        }, {} as { [key: string]: string })
        const resp = await new IssuesApi().updateIssueMetadata(issue.key!, userDefinedMetadata)
        if (resp.status === 204) {
            setEditEnabled(false)
            setMetadata(ms => ms.filter(m => m.key))
            notify.success("Successfully updated the metadata of issue #" + issue.issueNumber)
        } else {
            notify.error("Unable to update the metadata of issue #" + issue.issueNumber)
        }
    }, [metadata])
    const cancelMetadata = useCallback(() => {
        setMetadata(metadataOnCancel)
        setEditEnabled(false)
    }, [metadata, metadataOnCancel])
    const startEdit = useCallback(() => {
        setMetadataOnCancel(metadata)
        setEditEnabled(true)
        setMetadata(m => [...m, {key: '', value: ''}])
    }, [metadata])
    return <>
        <div style={{display:'flex', alignItems: 'center'}}>
            <h3 style={{flex: 1}}>Metadata</h3>
            <Group gap={4}>
                {!editEnabled && <ActionIcon onClick={startEdit}><FaEdit></FaEdit></ActionIcon> }
                {editEnabled && <><ActionIcon onClick={updateMetadata}><FaSave></FaSave></ActionIcon>
                <ActionIcon color="red" onClick={cancelMetadata}><FaTimes></FaTimes></ActionIcon>
                </>}
            </Group>
        </div>
        <Table verticalSpacing="xs" striped>
        <Table.Tbody>
            {metadata.map((m, idx) => <Table.Tr key={idx}>
                <Table.Td>{editEnabled ? <TextInput value={m.key} onChange={e => {
                    const value = e.currentTarget.value
                    setMetadata(m => {
                        const updateMetadata = [...m]
                        updateMetadata[idx].key = value;
                        return updateMetadata
                    })
                }}></TextInput> : m.key}</Table.Td>
                <Table.Td>{editEnabled ? <TextInput value={m.value} onChange={e => {
                    const value = e.currentTarget.value
                    setMetadata(m => {
                        const updateMetadata = [...m]
                        updateMetadata[idx].value = value;
                        return updateMetadata
                    })
                }}></TextInput> : m.value}</Table.Td>
                <Table.Td style={{width: 50, height: 50}}>
                    { editEnabled &&
                        (metadata.length - 1 === idx ? 
                            <ActionIcon onClick={() => setMetadata(m => [...m, {key: '', value: ''}])}><FaPlusCircle></FaPlusCircle></ActionIcon> 
                            : <ActionIcon color="red" onClick={() => setMetadata(m => {
                                const updated = [...m]
                                delete updated[idx]
                                return updated.filter(u => u)
                            })}><FaMinusCircle></FaMinusCircle></ActionIcon>)
                    }
                </Table.Td>
                </Table.Tr>)}
        </Table.Tbody>                  
        </Table>
    </>
}

export default function IssueDetails() {
    const { curProject } = useProject()
    const { id } = useParams()
    const [issue, setIssue] = useState<Issue>()
    const [nodesMap, setNodesMap] = useState<{[key: string]: DataNode}>({})
    const [integrationsMap, setIntegrationsMap] = useState<{[key: string]: Integration}>({})

    const fetchNodes = async (fetchedIssue: Issue) => {
        if (fetchedIssue.relatedNodes) {
            setNodesMap(fetchedIssue.relatedNodes)
            return
        }
        const nodeIds = [fetchedIssue.targetId + "", 
            ...(fetchedIssue.conditionOutputs?.flatMap(so => so.reasons.map(d => d.id)) || []),
        ] as string[]
        const resp = (await new GraphsApi().fetchData(curProject.id!, {
            nodeIds,
        })).data
        setNodesMap(resp.nodes.reduce((acc, cur) => {
            acc[cur.id!] = cur
            return acc
        }, {} as {[key: string]: DataNode}))
    }
    const fetchIntegrations = async (fetchIssue: Issue) => {
        const integrationNames = [...new Set(fetchIssue.conditionOutputs?.map(so => so.integrationName).filter(i => i).map(i => i as string))]
        const fetchedMap: {[key: string]: Integration} = {}
        for (const i of integrationNames) {
            const resp = await new IntegrationsApi().integration(i)
            fetchedMap[i] = resp.data
        }
        setIntegrationsMap(fetchedMap)
    }

    const fetchIssue = async () => {
        const fetched = (await new IssuesApi().issue(id!)).data
        await fetchNodes(fetched)
        await fetchIntegrations(fetched)
        setIssue(fetched)
    }
    const integrationConditions = useMemo(() => {
        return issue?.conditionOutputs?.filter(co => co.conditionType === 'CATALOG') || []
    }, [issue])
    const otherConditions = useMemo(() => {
        return issue?.conditionOutputs?.filter(co => co.conditionType !== 'CATALOG') || []
    }, [issue])
    useEffect(() => {
        fetchIssue()
    }, [])
    return issue && <div style={{marginTop: 10}}>
        <Grid>
            <Grid.Col span={issue.targetId ? 8 : 12} style={{paddingRight: 80}}>
                <h3><div dangerouslySetInnerHTML={{ __html: converter.makeHtml(issue.message || '') }} /></h3>
                <div>
                    <Group gap={5}>
                        {issue.labels?.map(l => <Badge color="cyan">{l}</Badge>)}
                    </Group>
                </div>
                <div style={{fontSize: '0.9rem', color: '#2f2f2f', marginTop: 20}}>
                    <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(issue.description || '') }} />
                </div>
                <Stack gap={20}>
                <Stack>
                { integrationConditions?.length ? <>
                <h3 style={{marginTop: 50, marginBottom: 0}}>Reasons</h3>
                {integrationConditions?.map(so => <Stack gap={5}>
                    {so.reasons.length ? <>
                        <Group>
                            {so.integrationName && <img style={{height: 25}} src={buildApiUrl(`/integrations/${so.integrationName}/logo.svg`)}></img>}
                            <div>{so.integrationName ? integrationsMap[so.integrationName].label : 'Built-in'}</div>
                        </Group>
                        { so.conditionConfig ? <Group>
                            {Object.entries(so.conditionConfig).map(e => <Badge color="black" size="sm">{e[0]}={e[1]?.toString()}</Badge>)}
                        </Group> : <></>}
                        <Table verticalSpacing="md" striped>
                            <Table.Thead>
                                <Table.Th>
                                    Relates To
                                </Table.Th>
                                <Table.Th>
                                    Message
                                </Table.Th>
                            </Table.Thead>
                            <Table.Tbody>
                            {so.reasons.map(d => <Table.Tr>
                                    <Table.Td>{nodesMap[d.id!]?.display} ({d.type})</Table.Td>
                                    <Table.Td>{d.reason}</Table.Td>
                                </Table.Tr>)}
                            </Table.Tbody>
                        </Table> 
                    </> : <></>}
                </Stack>)}
                </> : <></> }
                </Stack>
                <Stack>
                { otherConditions.length > 0 ? <><div>Others</div>
                    <Table verticalSpacing="md" striped>
                        <Table.Thead>
                            <Table.Th>
                                Condition
                            </Table.Th>
                        </Table.Thead>
                        <Table.Tbody>
                            {otherConditions
                                .map(i => <Table.Tr>
                                    <Table.Td>{i.name}</Table.Td>
                                </Table.Tr>)}
                        </Table.Tbody>
                    </Table></> : <></> }
                </Stack>
                { issue.ticket?.synchedService && <>
                    <div>
                        <h3>{issue.ticket.synchedService} Ticket Number</h3>
                        <div><a href={issue.ticket.synchedUrl}>{issue.ticket.synchedTicketNumber}</a></div>
                    </div>
                </> }
                </Stack>
            </Grid.Col>
            { issue.targetId ? <Grid.Col span={4}>
                <Stack gap={3} style={{paddingLeft: 15}}>
                    <Metadata issue={issue}></Metadata>
                    <Divider opacity={0.4} style={{marginTop: 20, marginBottom: 10}}></Divider>
                    <Group gap={50} >
                    <h3>{issue.targetType + ""}</h3>
                    <div>{nodesMap[issue.targetId + ""]?.display}</div>
                    </Group>
                    <Table verticalSpacing="xs" striped>
                    <Table.Tbody>
                    {Object.entries(nodesMap[issue.targetId + ""]?.properties || {}).map(d => <Table.Tr>
                            <Table.Td>{d[0]}</Table.Td>
                            <Table.Td style={{wordBreak: 'break-all'}}>{d[1] + ''}</Table.Td>
                        </Table.Tr>)}
                    </Table.Tbody>                  
                    </Table>
                </Stack>
            </Grid.Col> : <></> }
        </Grid>
    </div>
}