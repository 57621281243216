import { Script, ScriptsApi } from "@/libs/client";
import { ScriptForm } from "./ScriptForm";
import { ActionResultType } from "@/components/actions/actions";
import { useEffect, useState } from "react";

export function UpdateScript({ id, onRefresh }: {id: string, onRefresh?: () => void }) {
    const [script, setScript] = useState<Script>()
    const fetchScript = async () => {
        const resp = await new ScriptsApi().script(id)
        setScript(resp.data)
    }
    useEffect(() => {
        fetchScript()
    }, [])
    return script && <ScriptForm
        type={script.type!}
        initialScript={script}
        onSubmit={async (values) => {
            await new ScriptsApi().updateScript(id, {
                ...values,
            })
            if (onRefresh)
                onRefresh()
            return { type: ActionResultType.SUCCESS }
        }}
    ></ScriptForm>
}