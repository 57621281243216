import 'rapidoc';
import Header from '../components/header/Header';
import './rapidoc.bindings';
import { buildApiUrl } from '@/libs/utils/apiUrl';
import { useEffect, useRef } from 'react';

export default function ApiDoc({ ops }: { ops: boolean}) {
    const rapidDoc = useRef<HTMLDivElement>()
    useEffect(() => {
        const listener = (obj: any) => {
            obj.detail.spec.paths = Object.entries(obj.detail.spec.paths).filter(e => ops === e[0].startsWith('/ops/'))
                .reduce((acc, cur) => {
                    acc[cur[0]] = Object.entries(cur[1] as any).reduce((pathAcc, path: any) => {
                        pathAcc[path[0]] = {
                            ...path[1],
                            tags: path[1].tags.map((t: string) => t.replace('Ops/', ''))
                        }
                        return pathAcc
                    }, {} as { [key: string]: any })
                    return acc
                }, {} as { [key: string]: any })
        }
        rapidDoc.current?.addEventListener('before-render', listener)
        return () => {
            rapidDoc.current?.removeEventListener('before-render', listener)
        }
    }, [])
    return <div>
            <Header loggedIn={false}></Header>
            <div style={{height: 'calc(100vh - 65px)', overflowY: 'auto'}}>
                <rapi-doc
                    /** @ts-ignore */
                    ref={rapidDoc}
                    spec-url={buildApiUrl("/docs")}
                    show-header={false}
                    sort-tags={true}
                    schema-style="table"
                    theme="light"
                    nav-bg-color="#f5f5f5"
                    primary-color="#12b886"
                    style = {{ height: 'calc(100vh - 65px)', width: "100%" }}
                    >
                </rapi-doc>
            </div>
        </div>
}