import { DataEdge, DataGraphMeta, DataNode, GraphsApi } from "@/libs/client";


type GraphFilter = {
    integration?: string;
    query?: string;
}

export default class LazyDataGraph {
    projectId: string;
    meta: DataGraphMeta;
    filter: GraphFilter;
    
    private loadedNodesMap: Map<string, DataNode> = new Map();
    private loadedEdgesMap: Map<string, DataEdge> = new Map();
    private loadedTypes: Set<string> = new Set();
    private loadedEdgeTargets: Set<string> = new Set();
    private loadedEdgeSources: Set<string> = new Set();

    constructor(projectId: string, meta: DataGraphMeta, filter: GraphFilter) {
        this.projectId = projectId;
        this.meta = meta;
        this.filter = filter;
    }

    async init() {
        if (this.filter.query) {
            await this.doQuery()
        }
    }

    async doQuery() {
        const resp = await new GraphsApi().dataGraph(this.projectId, this.filter.query, this.filter.integration)
        this.addLoadedNodes(resp.data.nodes || [])
        this.addLoadedEdges(resp.data.edges || [])
    }

    private addLoadedNodes(nodes: DataNode[]) {
        nodes.forEach(n => {
            this.loadedNodesMap.set(n.id!, n)
        })
    }

    private addLoadedEdges(edges: DataEdge[]) {
        edges.forEach(e => {
            this.loadedEdgesMap.set(e.id!, e)
        })
    }

    async edgesWithSource(sourceId: string): Promise<DataEdge[]> {
        if (this.loadedEdgeSources.has(sourceId)) {
            return [...this.loadedEdgesMap.values()].filter(e => e.source?.id === sourceId)
        }
        this.loadedEdgeSources.add(sourceId);
        const resp = await new GraphsApi().fetchData(this.projectId, {
            edgeSourceId: sourceId,
        }, undefined, this.filter.integration)
        this.addLoadedEdges(resp.data.edges)
        return resp.data.edges
    }

    async edgesWithTarget(targetId: string): Promise<DataEdge[]> {
        if (this.loadedEdgeTargets.has(targetId)) {
            return [...this.loadedEdgesMap.values()].filter(e => e.target?.id === targetId)
        }
        this.loadedEdgeTargets.add(targetId)
        const resp = await new GraphsApi().fetchData(this.projectId, {
            edgeTargetId: targetId,
        }, undefined, this.filter.integration)
        this.addLoadedEdges(resp.data.edges)
        return resp.data.edges
    }

    loadedEdges(): DataEdge[] {
        return [...this.loadedEdgesMap.values()]
    }

    loadedNodes(): DataNode[] {
        return [...this.loadedNodesMap.values()]
    }

    loadedNode(id: string) : (DataNode | undefined) {
        return this.loadedNodesMap.get(id)
    }
    
    loadedEdge(id: string) : (DataEdge | undefined) {
        return this.loadedEdgesMap.get(id)
    }

    async nodesOfType(type: string) {
        if (this.loadedTypes.has(type)) {
            return [...this.loadedNodesMap.values()].filter(v => v.type === type);
        }
        this.loadedTypes.add(type);
        const resp = await new GraphsApi().fetchData(this.projectId, {
            nodeType: type,
        }, undefined, this.filter.integration)
        this.addLoadedNodes(resp.data.nodes)
        return resp.data.nodes
    }

    async nodesByIds(ids: string[]): Promise<DataNode[]> {
        const foundNodes = ids.filter(i => this.loadedNodesMap.has(i))
            .map(i => this.loadedNodesMap.get(i)!)
        const notFoundNodes = ids.filter(i => !this.loadedNodesMap.has(i));
        if (notFoundNodes.length === 0) {
            return foundNodes
        }
        const resp = await new GraphsApi().fetchData(this.projectId, {
            nodeIds: notFoundNodes,
        }, undefined, this.filter.integration)
        this.addLoadedNodes(resp.data.nodes)
        return [...foundNodes, ...resp.data.nodes]
    }

    async nodeById(id: string) {
        if (this.loadedNodesMap.has(id)) {
            return this.loadedNodesMap.get(id);
        }
        const resp = await new GraphsApi().fetchData(this.projectId, {
            nodeIds: [id],
        }, undefined, this.filter.integration)
        this.addLoadedNodes(resp.data.nodes)
        return resp.data.nodes[0]
    }

}