import { ActionResultType } from "@/components/actions/actions"
import { AxiosResponse } from "axios"


export async function doResponse(call: Promise<AxiosResponse<any, any>>, onRefresh?: () => void) {
    const resp = await call;
    if (resp.status >= 200 && resp.status < 300) {
        if (onRefresh) {
            onRefresh()
        }
        return { type: ActionResultType.SUCCESS }
    }
    return { type: ActionResultType.FAILURE }

}