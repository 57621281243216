import cytoscape  from 'cytoscape';

export type EdgeEditor = {
    start: (sourceNode: cytoscape.NodeSingular) => void
    stop: () => void
}

export function initEdgeEdit(cy: cytoscape.Core) : EdgeEditor {
    // the default values of each option are outlined below:
    const defaults = {
        canConnect: function(sourceNode: cytoscape.NodeSingular, targetNode: cytoscape.NodeSingular){
            // whether an edge can be created between source and target
            return !sourceNode.same(targetNode); // e.g. disallow loops
        },
        edgeParams: function(){
            // for edges between the specified source and target
            // return element object to be passed to cy.add() for edge
            return {
            };
        },
        hoverDelay: 150, // time spent hovering over a target node before it is considered selected
        snap: true, // when enabled, the edge can be drawn by just moving close to a target node (can be confusing on compound graphs)
        snapThreshold: 10, // the target node must be less than or equal to this many pixels away from the cursor/finger
        snapFrequency: 15, // the number of times per second (Hz) that snap checks done (lower is less expensive)
        noEdgeEventsInDraw: true, // set events:no to edges during draws, prevents mouseouts on compounds
        disableBrowserGestures: true // during an edge drawing gesture, disable browser gestures such as two-finger trackpad swipe and pinch-to-zoom
    };
    // @ts-ignore
    return cy.edgehandles(defaults) as EdgeEditor;
}