import { Button, InputWrapper, Stack, TextInput } from "@mantine/core";
import { FaKey } from "react-icons/fa";


export default function SysGithubSettings() {
    return <div style={{padding: '0 3rem'}}>
        <div>
            <h2 style={{marginTop: 0, fontWeight: 500}}>Github App</h2>
            <Stack>
                <div style={{display: 'flex', gap: 40}}>
                    <div style={{flex: 1}}>
                        <Stack gap={12} style={{maxWidth: 350}}>
                            <TextInput
                                label="Application ID"
                                description="Foobar efdsdffdafdas"
                            ></TextInput>
                            <TextInput
                                label="Installation ID"
                                description="Foobar efdsdffdafdas"
                            ></TextInput>
                        </Stack>
                    </div>
                    <div style={{flex: 1}}>
                        <Stack style={{height: '100%'}}>
                            <InputWrapper
                                label="Private Key"
                                description="sdfdffsfds feadsfds"
                            >
                                <Button variant="filled" size="sm" styles={{root: { marginTop: 4, borderRadius: 6}}}
                                    leftSection={<FaKey></FaKey>}
                                >Upload</Button>
                            </InputWrapper>
                        </Stack>
                    </div>
                </div>
                <div style={{display: 'flex', gap: 4, marginTop: 20}}>
                    <Button variant="default">Reset</Button>
                    <Button disabled>Update</Button>
                </div>

            </Stack>
        </div>
    </div> 
}