
import { View, ViewsApi } from "@/libs/client"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import CodeEditor from '@uiw/react-textarea-code-editor';

export default function ViewDetails() {
    const { id } = useParams()
    const [view, setView] = useState<View>()
    const fetch = async () => {
        const res = await new ViewsApi().view(id!)
        if (res.status == 200) {
            setView(res.data);
        } else {
            //TODO
        }
    }
    useEffect(() => {
        fetch();
    }, [])
    return view && <CodeEditor
        value={view.query}
        language="groovy"
        readOnly
        padding={15}
        minHeight={200}
        style={{
            borderRadius: 10,
            fontSize: 12,
            backgroundColor: "#fafafa",
            fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
        }}
    />
}