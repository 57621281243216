import { useContainer } from "@/components/containers/ContainerProvider";
import SfyForm from "@/components/form/SfyForm";
import FormSelect from "@/components/form/inputs/FormSelect";
import FormTextInput from "@/components/form/inputs/FormTextInput";
import { IntegrationsApi, SaveCustomIntegrationCustomTypeEnum } from "@/libs/client";
import { useProject } from "@/libs/project/ProjectProvider";
import { doResponse } from "@/libs/utils/response";
import { useForm } from "@mantine/form";

export default function AddCustomIntegration({ onRefresh }: { onRefresh?: () => void }) {
    const { closeAllModals } = useContainer();
    const { curProject } = useProject()
    const form = useForm({
        initialValues: {
            name: '',
            label: '',
            customType: SaveCustomIntegrationCustomTypeEnum.Upload,
        },
        validate: {
            name: (v) => !/[a-z][A-Z0-9_]+/.test(v) ? 'Name should only include alphanumberic characters without spaces' : undefined,
        },
    })    
    return <SfyForm 
        onClose={closeAllModals}
        disabled={!form.values.name || !form.values.label}
        onSubmit={() => doResponse(new IntegrationsApi().createCustomIntegration(curProject.id!, {
            ...form.values }), onRefresh)}
    >
        <FormTextInput
            label="Name"
            withAsterisk
            description="Name of the custom integration. This value should not include spaces."
            {...form.getInputProps('name')}
        ></FormTextInput>
        <FormTextInput
            label="Label"
            withAsterisk
            description="Label of the custom integration"
            {...form.getInputProps('label')}
        ></FormTextInput>
        <FormSelect
            label="Type"
            disabled
            description="Type of custom integration you would like to create"
            style={{ maxWidth: ''}}
            data={[{ value: 'UPLOAD', label: 'Upload'}]}
            {...form.getInputProps('customType')}
        >
        </FormSelect>
    </SfyForm>
}