import { ModifiedElem, ModifiedGraph } from "@/libs/graph/modified";
import { Change, Commit, SchemaEntity, SchemaGraph, SchemaRelationship } from "../client";
import { ElementsMap } from "./elements";


export function applyAddEntity(schemaGraph: SchemaGraph, change: Change, 
    entities: ElementsMap<ModifiedElem<SchemaEntity>>) {
    entities[change.elementId!] = {
        ...change.data!.addEntity!.entity,
        new: true,
        dim: false,
    }
    schemaGraph.positions = schemaGraph.positions || {};
    if (change.data!.addEntity?.position) {
        schemaGraph.positions[change.data!.addEntity!.entity.type!] = change.data!.addEntity?.position
    }
}

export function applyRemoveEntity(change: Change, 
    entities: ElementsMap<ModifiedElem<SchemaEntity>>) {
    entities[change.elementId!] = {
        ...entities[change.elementId!],
        removed: true,
        dim: false,
    }
}

export function applyUpdateEntity(change: Change, 
    entities: ElementsMap<ModifiedElem<SchemaEntity>>) {
    const updated = { ...entities[change.elementId!] }
    updated.fields = { ...updated.fields }
    Object.entries(change.data?.updateEntity!.entity || {}).forEach(e => {
        if (e[0] === 'fields') {
            return;
        }
        if (e[1] !== undefined && e[1] !== null) {
            (updated as any)[e[0]] = e[1]
        }
    })
    Object.entries(change.data?.updateEntity!.fields || {}).forEach(e => {
        if (e[1] !== undefined && e[1] !== null) {
            switch (e[1].type) {
                case "ADD": 
                case "UPDATE": updated.fields![e[0]] = e[1].field!
                    return
                case "REMOVE":
                    delete updated.fields![e[0]]
            }
        }
    })
    entities[change.elementId!] = {
        ...updated,
        updated: true,
        dim: false,
    }
}

export function applyLayoutEntity(schemaGraph: SchemaGraph, change: Change) {
    schemaGraph.positions = change.data?.setLayout?.layout || {}
}

export function applyEntityChange(schemaGraph: SchemaGraph, change: Change, 
    entities: ElementsMap<ModifiedElem<SchemaEntity>>) {
    switch(change.type) {
        case 'ADD': return applyAddEntity(schemaGraph, change, entities)
        case 'UPDATE': return applyUpdateEntity(change, entities)
        case 'REMOVE': return applyRemoveEntity(change, entities)
    }
}

export function applyAddRelationship(change: Change, 
    relationships: ElementsMap<ModifiedElem<SchemaRelationship>>) {
    relationships[change.elementId!] = {
        ...change.data!.addRelationship!.relationship,
        new: true,
        dim: false,
    }
}


export function applyRemoveRelationship(change: Change, 
    relationships: ElementsMap<ModifiedElem<SchemaRelationship>>) {
    relationships[change.elementId!] = {
        ...relationships[change.elementId!],
        removed: true,
        dim: false,
    }
}

export function applyRelationship(change: Change, 
    relationships: ElementsMap<SchemaRelationship>) {
    switch(change.type) {
        case 'ADD': return applyAddRelationship(change, relationships)
        case 'REMOVE': return applyRemoveRelationship(change, relationships)
    }
}

export function applyGraphChange(schemaGraph: SchemaGraph, change: Change) {
    switch(change.type) {
        case 'LAYOUT': return applyLayoutEntity(schemaGraph, change)
    }
}


export function applyChange(change: Change, 
    schemaGraph: SchemaGraph,
    entities: ElementsMap<ModifiedElem<SchemaEntity>>, 
    relationships: ElementsMap<ModifiedElem<SchemaRelationship>>) {
    switch(change.target) {
        case 'ENTITY': 
            return applyEntityChange(schemaGraph, change, entities)
        case 'RELATIONSHIP': 
            return applyRelationship(change, relationships)
        case 'GRAPH':
            return applyGraphChange(schemaGraph, change)
        default: return
    }
}

export function applyCommit(schemaGraph: SchemaGraph, commit: Commit) : ModifiedGraph<SchemaGraph, SchemaEntity, SchemaRelationship> {
    const entities = Object.values(schemaGraph.entities)
        .reduce((acc, cur) => {
            const entity = { ...cur } as SchemaEntity
            acc[cur.type!] = { ...entity, dim: true }
            return acc
        }, {} as ElementsMap<ModifiedElem<SchemaEntity>>) || {}
    const relationships = Object.values(schemaGraph.relationships)
        ?.reduce((acc, cur) => {
            const rel = { ...cur } as SchemaRelationship
            acc[cur.name!] = { ...rel, dim: true }
            return acc
        }, {} as ElementsMap<ModifiedElem<SchemaRelationship>>) || {}
    commit.changes!.forEach(c => applyChange(c, schemaGraph, entities, relationships))
    return {
        graph: schemaGraph,
        nodes: Object.values(entities),
        edges: Object.values(relationships),
    }
}