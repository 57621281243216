import { Stylesheet } from "cytoscape";
import { FONT_SIZE, HOVER_COLOR, LIGHT_PRIMARY_COLOR, SELECTED_COLOR } from "./data.constants";

export const DATA_GRAPH_STYLES : Stylesheet[] = [
    {
        selector: 'node',
        style: {
            'font-family': 'Inter, system-ui, Avenir, Helvetica, Arial, sans-serif',
            backgroundColor: 'data(color)',
            'label': 'data(label)',
            'font-size': FONT_SIZE - 10,
            width: 60,
            height: 60,
            "text-wrap": "wrap",
            'text-outline-width': 0.8,
            'text-outline-color': 'white',
            "text-margin-y": -6,
            color: '#1f1f1f'
        }
    },
    {
        selector: "edge",
        style: {
            'font-family': 'Inter, system-ui, Avenir, Helvetica, Arial, sans-serif',
            'width': 1,
            'line-color': LIGHT_PRIMARY_COLOR,
            'target-arrow-color': LIGHT_PRIMARY_COLOR,
            'target-arrow-shape': 'triangle',
            'curve-style': 'straight',
            'arrow-scale': 1.5,
            'label': 'data(label)',
            'font-size': FONT_SIZE - 8,
            'text-outline-width': 2,
            'text-outline-color': 'white',
            'text-rotation': 'autorotate',
            color: '#1f1f1f'
        }
    },
    {
        selector: 'edge[overlaps = "true"]',
        style: {
            'curve-style': 'unbundled-bezier',
        }
    },
    {
        selector: 'edge[edgeType = "relationship-empty"]',
        style: {
            'line-style': 'dashed',
            'line-color': '#c0c0c0',
            'target-arrow-color': '#c0c0c0',
            'color': '#c0c0c0',
        }
    },
    {
        selector: 'edge[edgeType = "relationship"]',
        style: {
            'line-style': 'solid',
            'line-color': '#b0b0b0',
            'target-arrow-color': '#b0b0b0',
            'color': '#808080',
        }
    },
    {
        selector: 'node[type = "grouped"]',
        style: {
            'backgroundColor': 'data(color)',
            width: "data(size)",
            height: "data(size)",
            'label': 'data(label)',
            'font-size': FONT_SIZE,
            "text-wrap": "wrap",
        }
    },
    {
        selector: 'node.hover-mode',
        css: {
            opacity: 0.3,
        }
    },
    {
        selector: 'edge.hover-mode',
        css: {
            'line-color': '#c0c0c0',
            'target-arrow-color': '#c0c0c0',
            opacity: 0.2,
        }
    },
    {
        selector: 'node.hover',
        css: {
            backgroundColor: HOVER_COLOR,
            opacity: 1,
        }
    },
    {
        selector: 'edge.hover',
        css: {
            'line-color': HOVER_COLOR,
            'target-arrow-color': HOVER_COLOR,
            opacity: 1,
        }
    },
    {
        selector: 'node:selected',
        style: {
            'backgroundColor': SELECTED_COLOR,
        },
    },
    {
        selector: 'edge:selected',
        style: {
            'line-color': SELECTED_COLOR,
            'target-arrow-color': SELECTED_COLOR,
            'width': 2,
        },
    },
    {
        selector: ":parent",
        css: {
            shape: 'roundrectangle',
            backgroundColor: 'white',
        }
    },
];
