import { Button, Divider, Flex, Stack } from "@mantine/core";
import { AuthApi, LoginContext } from "@/libs/client";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "@mantine/form";
import { useAuth } from "@/libs/auth/AuthProvider";
import { saveTenantForLogin } from "@/libs/common/tenant";
import LoginInput from "./LoginInput";
import { t } from "i18next";

type LoginFormProps = {
    loginCtx: LoginContext;
}

export default function LoginForm({ loginCtx }: LoginFormProps) {
    const form = useForm({
        initialValues: {
            username: '',
            password: '',
        },
    });
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { reloadCtx } = useAuth();
    const login = async () => {
        try {
            await new AuthApi().login({
                ...form.values,
                tenant: loginCtx.tenant?.name || '',
            });
            await reloadCtx();
            saveTenantForLogin(loginCtx.tenant!)
            navigate((location.pathname.replace('/login', '') || '/') + location.search);
        } catch(err: any) {
            const error = err.response.data.errors[0]
            if (error.code === 'ALREADY_LOGGED_IN') {
                window.location.reload()
            } else {
                form.setFieldError('username', t('Incorrect username or password'));
            }
        }
    }

    return loginCtx && <form onSubmit={async e => {
            form.clearErrors();
            e.preventDefault()
            await login();
        }}>
            <Stack style={{flex: 1, width: '100%', marginTop: 10}} gap={0}>
                <Stack gap={10}>
                    { loginCtx.ssoLogins.map(s => <SsoButton key={s.url} url={s.url} loginCtx={loginCtx}></SsoButton>)}
                </Stack>
                { loginCtx.ssoLogins.length ? <Divider  color="#1f1f1f" label="OR" labelPosition="center" style={{marginTop: 30, marginBottom: 30}}/> : undefined }
            </Stack>
            <Stack gap={10}>
                <LoginInput
                    label={t('common.username') + ' or ' + t('common.email')}
                    {...form.getInputProps('username')}
                ></LoginInput>
                <LoginInput
                    type="password"
                    label={t('common.password')}
                    {...form.getInputProps('password')}
                ></LoginInput>
            </Stack>
            <Flex direction="column" align="flex-start" style={{marginTop: 40}}>
                <Button type="submit" style={{width: "100%"}}>{t('common.sign_in')}</Button>
            </Flex>
        </form>
}

type SsoButtonProps = {
    url: string;
    loginCtx: LoginContext;
}

function SsoButton({ url, loginCtx }: SsoButtonProps) {
    const location = useLocation()
    return  <Button bg={"#1f1f1f"} color="white" onClick={() => {
        saveTenantForLogin(loginCtx.tenant!)

        const path = encodeURIComponent(location.pathname.replace("/login", "") + location.search)
        window.location.href = `${url}&state=${path}&prompt=login`; //TODO add tnenat
    }}>
        <img style={{position: 'absolute', left: '15px', height: 22}} src="/img/microsoft_logo_dark.svg"></img> 
        <div style={{marginLeft: '15px'}}>{t('sso.microsoft')}</div>
    </Button>
}