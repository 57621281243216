export const levenshteinDistance = (a: string, b: string): number => {
    const lenA = a.length;
    const lenB = b.length;
    const matrix: number[][] = [];
  
    for (let i = 0; i <= lenA; i++) {
      matrix[i] = [i];
    }
  
    for (let j = 0; j <= lenB; j++) {
      matrix[0][j] = j;
    }
  
    for (let i = 1; i <= lenA; i++) {
      for (let j = 1; j <= lenB; j++) {
        const cost = a[i - 1] === b[j - 1] ? 0 : 1;
        matrix[i][j] = Math.min(
          matrix[i - 1][j] + 1,
          matrix[i][j - 1] + 1,
          matrix[i - 1][j - 1] + cost
        );
      }
    }
  
    return matrix[lenA][lenB];
}
