import { useGraphDisplay } from "../../providers/GraphDisplayProvider";
import { useNavigate } from "react-router-dom";
import { useProject } from "@/libs/project/ProjectProvider";
import { useGraphLoader } from "../../providers/GraphLoaderProvider";
import classes from './GraphToggle.module.css';

export default function GraphToggle() {
    const navigate = useNavigate()
    const { activeType, setActiveType } = useGraphDisplay()
    const { projectUrl } = useProject()
    const { reloadSchemaGraph } = useGraphLoader()

    return <div className={classes.toggle}>
                <div className={activeType === 'DATA' ? classes.toggleActive : undefined}
                    onClick={async () => {
                        if (activeType !== 'DATA') {
                            setActiveType('DATA')
                            await reloadSchemaGraph()
                            navigate(projectUrl('/data/graph'), { replace: true })
                        }                        
                    }}
                >
                    Data
                </div>
                <div className={activeType === 'SCHEMA' ? classes.toggleActive : undefined}
                    onClick={async () => {
                        if (activeType !== 'SCHEMA') {
                            setActiveType('SCHEMA')
                            await reloadSchemaGraph()
                            navigate(projectUrl('/data/graph/schema'), { replace: true })
                        }           
                    }}>
                    Schema
                </div>
            </div>
} 