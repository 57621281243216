import MultiStepForm, { StepItem } from "@/components/form/MultiStepForm";
import { ConfigureScanTaskActionInner } from "./ConfigureScanTaskAction";
import { useContainer } from "@/components/containers/ContainerProvider";
import { UseFormReturnType, useForm } from "@mantine/form";
import FormTextInput from "@/components/form/inputs/FormTextInput";
import { LoadingOverlay, Stack, TagsInput } from "@mantine/core";
import { useEffect, useMemo, useState } from "react";
import { doResponse } from "@/libs/utils/response";
import { buildFormParameters, buildParameterFormElement, buildParameters, checkParametersValid } from "@/libs/common/params";
import { CreateScanTask, DescribeParam, ScanDefinition, ScansApi, SelectedCondition, UpdateScanTask } from "@/libs/client";
import { ConfigureScanTaskTicketForm } from "./ConfigureScanTaskTicketForm";
import FormCheckbox from "@/components/form/FormCheckbox";
import { ConfigureScanAlertForm } from "./ConfigureScanAlertForm";
import { useInfo } from "@/libs/info/InfoProvider";

function ScanMetaForm({ formData, parameters, loadingParameters } : { formData: UseFormReturnType<any, any>, parameters: DescribeParam[], loadingParameters: boolean, update: boolean }) {
    const { isAiConfigured } = useInfo()
    return <form style={{marginTop: 20}}>
        {loadingParameters ? <LoadingOverlay/ > : <Stack>
            <FormTextInput
                label={"Name"}
                description={"Descriptive name of your custom scan"}
                withAsterisk
                {...formData.getInputProps("name")}
            ></FormTextInput>
            {parameters.map(p => buildParameterFormElement(p, formData))}
            <TagsInput
                label={"Labels"}
                description={"Labels that will be added to the issues resulting of this scan"}            
                {...formData.getInputProps("labels")}
            ></TagsInput>
            { isAiConfigured() && <FormCheckbox
                label={"Enable AI Enhancement"}
                description={"This will enable AI to enhance the results of the scan"}
                {...formData.getInputProps("aiEnhancementEnabled")}
            ></FormCheckbox> }
        </Stack> }
    </form>
}

export default function ScanActionForm({ onRefresh, scanTaskId, installedScanId, scanDefinitionId }: { onRefresh: () => void, scanTaskId?: string, installedScanId: string, scanDefinitionId: string }) {
    const {closeAllModals} = useContainer();
    const [scanDef, setScanDef] = useState<ScanDefinition>()
    const form = useForm({
        initialValues: {
            name: '',
            labels: [] as string[],
            selectedConditions: [] as SelectedCondition[],
            rank: 0,
            parameters: {},
            ticketConfig: {
                enabled: false,
                service: 'JIRA',
                labels: [],
            },
            slaConfig: {
                enabled: false,
                duration: 1,
                period: 'DAYS',
            }
        }
    })
    const loadScanTask = async () => {
        if (scanTaskId) {
            const resp = await new ScansApi().scanTask(scanTaskId)
            form.setValues({ ...resp.data, 
                scanDefinitionId: resp.data.installedScan?.scanDefinition?.id!,
                ...buildFormParameters(resp.data.parameters),
                ticketConfig: {
                    ...resp.data.ticketConfig,
                    labels: resp.data.ticketConfig?.labels || [],
                },
                slaConfig: {
                    ...resp.data.slaConfig,
                }
            } as any)
        }
    }
    useEffect(() => {
        loadScanTask()
    }, [])
    const [parameters, setParameters] = useState<DescribeParam[]>([])
    const [loadedParameters, setLoadedParameters] = useState<string>()
    const fetchParameters = async () => {
        const resp = await new ScansApi().scanDefinitionParameters(scanDefinitionId!)
        setParameters(resp.data)
        setLoadedParameters(scanDefinitionId)
    }
    const fetchScanDef = async () => {
        const resp = await new ScansApi().scanDefinition(scanDefinitionId!)
        setScanDef(resp.data)
    }
    useEffect(() => {
        if (scanDefinitionId!) {
            fetchParameters()
            fetchScanDef()
        } else {
            setParameters([])
            setLoadedParameters(undefined)
            setScanDef(undefined)
        }
    }, [scanDefinitionId])
    const validStepOne = useMemo(() => {
        return form.values.name && scanDefinitionId
            && loadedParameters === scanDefinitionId
            && checkParametersValid(parameters || [], form)
    }, [form.values, parameters])
    return <MultiStepForm
        form={form}
        onClose={closeAllModals}
        steps={[{
            label: 'Set Up',
            description: 'Details', 
            fields: [],
            disableNext: !validStepOne,
            renderContent: () => <ScanMetaForm formData={form} parameters={parameters} loadingParameters={loadedParameters !== scanDefinitionId} update={!!scanTaskId}></ScanMetaForm>
        },
        scanDef?.target ? {
            label: 'Configure',
            description: 'Choose scan options', 
            fields: [],
            renderContent: () => <div style={{marginTop: 20}}>
                <ConfigureScanTaskActionInner 
                    scanDefinitionId={scanDefinitionId}
                    initialValues={form.values.selectedConditions}
                    onValues={(vs) => {
                        form.setFieldValue('selectedConditions', Object.values(vs).filter(v => v) as SelectedCondition[])
                    }}></ConfigureScanTaskActionInner>
            </div>
        } : undefined,
        {
            label: 'Ticket',
            description: 'Choose ticket options', 
            fields: [],
            renderContent: () => <div style={{marginTop: 20}}>
                <ConfigureScanTaskTicketForm
                    formData={form}
                    update={!!scanTaskId}
                ></ConfigureScanTaskTicketForm>
            </div>
        },
        {
            label: 'Alert',
            description: 'Choose alert options', 
            fields: [],
            renderContent: () => <div style={{marginTop: 20}}>
                <ConfigureScanAlertForm
                    formData={form}
                    update={!!scanTaskId}
                ></ConfigureScanAlertForm>
            </div>
        },
        ].filter(s => s) as StepItem[]}
        onSubmit={() => {
            const resp = scanTaskId ? 
                new ScansApi().updateScanTask(scanTaskId!, { ...form.values, parameters: buildParameters(parameters, form)} as UpdateScanTask) :
                new ScansApi().createScanTask(installedScanId!, { ...form.values, parameters: buildParameters(parameters, form)} as CreateScanTask)
            return doResponse(resp, onRefresh)
        }}
    ></MultiStepForm>
}