import { ScriptTypeEnum, ScriptsApi } from "@/libs/client";
import { ScriptForm } from "./ScriptForm";
import { useProject } from "@/libs/project/ProjectProvider";
import { ActionResultType } from "@/components/actions/actions";

export function AddScript({ onRefresh, type }: { onRefresh?: () => void, type: ScriptTypeEnum }) {
    const { curProject } = useProject()
    return <ScriptForm
        type={type}
        onSubmit={async (values) => {
            await new ScriptsApi().createScript(curProject.id!, {
                ...values,
            })
            if (onRefresh)
                onRefresh()
            return { type: ActionResultType.SUCCESS }
        }}
    ></ScriptForm>
}