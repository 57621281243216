import { ScanTask, ScansApi } from "@/libs/client";
import { ConfigureScanTaskActionInner } from "../../actions/ConfigureScanTaskAction";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export default function ScanTaskDetails() {
    const { taskId } = useParams()
    const [scanTask, setScanTask] = useState<ScanTask>()
    const fetchScan = async () => {
        setScanTask((await new ScansApi().scanTask(taskId!)).data)
    }
    useEffect(() => {
        fetchScan()
    }, [])
    return scanTask && <>
        <ConfigureScanTaskActionInner initialValues={scanTask?.selectedConditions} scanDefinitionId={scanTask.installedScan?.scanDefinition!.id!} readonly></ConfigureScanTaskActionInner>
    </>
}