import { useInfo } from "@/libs/info/InfoProvider";
import { useProject } from "@/libs/project/ProjectProvider";
import { Tabs, useMantineTheme } from "@mantine/core";
import { useMemo } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

export default function DashboardLanding() {
    const { isExperimental } = useInfo()
    const location = useLocation()
    const navigate = useNavigate()
    const theme = useMantineTheme();
    const { projectUrl } = useProject();
    const tab = useMemo(() => {
        return location.pathname.split("/").reverse()[0];
    }, [location])
    return <div style={{padding: '2rem 3rem', minHeight: theme.other.contentHeight, maxWidth: 1400, margin: 'auto'}}>
        <Tabs
            style={{marginBottom: 10}}
            radius="md"
            variant="outline"
            value={tab}
        >
            <Tabs.List>
                <Tabs.Tab value="vulnerability" onClick={() => navigate(projectUrl("/dashboard/vulnerability"))}>Vulnerability Proritization</Tabs.Tab>
                { isExperimental() && <Tabs.Tab value="posture" onClick={() => navigate(projectUrl("/dashboard/posture"))}>Maturity</Tabs.Tab> }
            </Tabs.List>
        </Tabs>
        <Outlet />
    </div>
}