import classes from './GraphMetrics.module.css';

type GraphMetric = {
    value?: any
    unit: string
}

type GraphMetricsProps = {
    metrics: GraphMetric[]
}

export default function GraphMetrics({ metrics }: GraphMetricsProps) {
    return <div className={classes.tabs}>
            {
                metrics.map(m => <div className={classes.tab}>
                    {m.value?.toString()} {m.unit}
                </div>)
            }
        </div>
}