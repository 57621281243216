import { useAuth } from "@/libs/auth/AuthProvider";
import { TenantSettingsApi, TicketSettings, TicketSettingsServiceEnum } from "@/libs/client";
import { Button, PasswordInput, Select, Stack, TagsInput, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { openConfirmModal } from "@mantine/modals";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function TenantTicketSettings() {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(true)
    const ctx = useAuth()
    const tenantId = ctx.userCtx.user?.tenant?.id!
    const form = useForm({
        initialValues: {
            service: undefined,
            clientId: '',
            clientSecret: '',
            apiToken: '',
            labels: []
        } as TicketSettings
    })
    const fetchTicketSettings = async () => {
        const fetched = (await new TenantSettingsApi().ticketSettings(tenantId)).data
        form.setValues({
            ...fetched,
            ...fetched.azureDevops,
            ...fetched.jira,
        })
        setLoading(false)
    }

    const updateTicketSettings = async () => {
        openConfirmModal({
            title: 'Please confirm your action',
            children: (
            <Text size="sm">
                This will override the current ticket settings. Please make sure they are correct, since they can break existing features.
            </Text>
            ),
            labels: { confirm: 'Confirm', cancel: 'Cancel' },
            onCancel: () => {
            },
            onConfirm: async () => { 
                if (!form.values.service) {
                    await new TenantSettingsApi().updateTicketSettings(tenantId, {})
                } else if (form.values.service === 'AZURE_DEVOPS') {
                    await new TenantSettingsApi().updateTicketSettings(tenantId, {
                        service: 'AZURE_DEVOPS',
                        azureDevops: {
                            ...(form.values as any),
                        },
                    })                
                } else if (form.values.service === 'JIRA') {
                    await new TenantSettingsApi().updateTicketSettings(tenantId, {
                        service: 'JIRA',
                        jira: {
                            ...(form.values as any),
                        },
                    })  
                }
            },
        })
    }

    useEffect(() => {
        fetchTicketSettings()
    }, [])

    return !loading && <div style={{padding: '0 3rem'}}>
        <div>
            <h2 style={{marginTop: 0, fontWeight: 600}}>Ticket Configuration</h2>
            <Stack>
                <Select
                    label="Service"
                    description="Service where you want to send your tickets"
                    allowDeselect={false}
                    data={[{
                        value: 'NONE',
                        label: 'None'
                    }, {
                        value: 'JIRA',
                        label: 'Jira'
                    }]}
                    {...form.getInputProps('service')}
                ></Select>
                { form.values.service === TicketSettingsServiceEnum.AzureDevops ? <>
                    <TextInput
                        label="Organization"
                        description="Name of the organization in Azure DevOps"
                        {...form.getInputProps('organization')}
                    ></TextInput>
                    <TextInput
                        label="Project"
                        description="Name of the project in Azure DevOps"
                        {...form.getInputProps('project')}
                    ></TextInput>
                    <TextInput
                        label="Client ID"
                        description="Application ID of the application registration"
                        {...form.getInputProps('clientId')}
                    ></TextInput>
                    <PasswordInput
                        label="Client Secret"
                        description="Client secret of the application registration"
                        {...form.getInputProps('clientSecret')}
                    ></PasswordInput>
                </> : <></>}
                { form.values.service === TicketSettingsServiceEnum.Jira ? <>
                    <TextInput
                        label="Username"
                        description="Username of the API token"
                        {...form.getInputProps('username')}
                    ></TextInput>
                    <PasswordInput
                        label="API Token"
                        description="Client secret of the application registration"
                        {...form.getInputProps('apiToken')}
                    ></PasswordInput>
                    <TextInput
                        label="Host"
                        description="URL of your JIRA to make the api calls"
                        {...form.getInputProps('host')}
                    ></TextInput>    
                    <TextInput
                        label="Default Project Key"
                        description="Default Project key to send the tickets to"
                        {...form.getInputProps('projectKey')}
                    ></TextInput>
                    <TagsInput
                        label={t('common.labels')}
                        description="Labels you want to add to all your tickets"
                        placeholder="Press enter to add the label"
                        {...form.getInputProps('labels')}
                    >
                    </TagsInput>
                </> : <></>
                }
            </Stack>
            <div style={{display: 'flex', gap: 4, marginTop: 20}}>
                <Button variant="default" onClick={() => fetchTicketSettings()}>Reset</Button>
                <Button onClick={() => updateTicketSettings()}>Update</Button>
            </div>
        </div>
    </div>
}