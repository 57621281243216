import { GraphsApi, SchemaGraph } from "@/libs/client";
import { useProject } from "@/libs/project/ProjectProvider";
import { Select } from "@mantine/core";
import { useCallback, useEffect, useState } from "react";
import { WorkflowNodeValues } from "../../descriptor";

export default function FilterNodesForm({ values } : {values: WorkflowNodeValues}) {
    const { curProject } = useProject()
    const [schema, setSchema] = useState<SchemaGraph>()
    const fetchSchema = async () => {
        const resp = await new GraphsApi().schemaGraph(curProject.id!);
        if (resp.status === 200) {
            setSchema(resp.data);
        }
    }
    useEffect(() => {
        fetchSchema()
    }, [])
    const [opened, setOpened] = useState(false)
    const handleWindowClick = useCallback(() => {
        setOpened(false);
    }, []);
    useEffect(() => {
        if (opened) {
            window.addEventListener('click', handleWindowClick);
        } else {
            window.removeEventListener('click', handleWindowClick);
        }
    }, [opened])
    return schema && <Select
        data={Object.keys(schema.entities).sort().map(k => ({ value: k, label: k}))}
        placeholder="Select entity"
        label="Entity"
        onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            setOpened(true)
        }}
        onSelect={e => values.setValue("entity", e.currentTarget.value)}
        allowDeselect={false}
        dropdownOpened={opened}
    ></Select>
}