import { useContainer } from "@/components/containers/ContainerProvider";
import SfyForm from "@/components/form/SfyForm";
import { DescribeParam, PartialIntegration, ScanTask, ScanDefinition, ScansApi, Script, ScriptsApi, SelectedCondition, SelectedConditionTypeEnum, UpdateScanTask, View, ViewsApi } from "@/libs/client";
import { buildParameterFormElement, buildParameters } from "@/libs/common/params";
import { useProject } from "@/libs/project/ProjectProvider";
import { buildApiUrl } from "@/libs/utils/apiUrl";
import { doResponse } from "@/libs/utils/response";
import { sortBy } from "@/libs/utils/sort";
import { Switch, Group, Stack } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useEffect, useMemo, useState } from "react";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";

export function ScanSwitch({ initialValue, label, disabled, onValue }: {disabled?: boolean, initialValue?: boolean, label: string, onValue?: (v: boolean) => void }) {
    const [checked, setChecked] = useState(initialValue)
    return <Switch
        disabled={disabled}
        defaultChecked={initialValue}
        size="xs"
        onChange={(event) => {
            setChecked(event.currentTarget.checked); 
            if (onValue)
                onValue(event.currentTarget.checked);
        }}
        thumbIcon={!checked ? <FaTimesCircle style={{marginTop: -4.5}} size="1.2rem" color="#e93a3a"></FaTimesCircle> : <FaCheckCircle style={{marginTop: -4.5}} size="1.2rem" color="#15b886"></FaCheckCircle>} 
        label={label}
    ></Switch>
}

function SubscanRow({ label, onValue, initialValue, readonly, type, conditionDefinitionId } : { label: string, initialValue?: SelectedCondition, type: SelectedConditionTypeEnum, onValue: (selected?: SelectedCondition) => void, readonly?: boolean, conditionDefinitionId?: string }) {
    const [enabled, setEnabled] = useState<boolean>(!!initialValue)
    const [parameters, setParameters] = useState<DescribeParam[]>()
    const conditionForm = useForm({
        initialValues: {
            ...initialValue,
            ...Object.entries(initialValue?.config || {}).reduce((acc, cur) => {
                acc[`param_${cur[0]}`] = cur[1]
                return acc
            }, {} as {[key: string]: any})
        },
    })
    useEffect(() => {
        if (enabled) {
            onValue({
                id: conditionDefinitionId,
                type,
                config: buildParameters(parameters || [], conditionForm)
            })
        } else {
            onValue(undefined)
        }
    }, [enabled, conditionForm.values])
    const fetchParams = async () => {
        if (conditionDefinitionId && enabled && !parameters) {
            setParameters((await new ScansApi().scanDefinitionParameters(conditionDefinitionId)).data)
        }
    }
    useEffect(() => {
        fetchParams()
    }, [enabled])
    return <div style={{paddingLeft: 40}}>
        <Stack>
            <ScanSwitch label={label} initialValue={!!initialValue} onValue={setEnabled} disabled={readonly}></ScanSwitch>
            <Stack gap={4}>
                { enabled && parameters && parameters.map(p => buildParameterFormElement(p, conditionForm, readonly))}
            </Stack>
        </Stack>
    </div>
}


function CustomScriptScans({ scanDefinitionId, onValues, initialValues, readonly } : { 
    scanDefinitionId: string,
    initialValues: SelectedCondition[],
    readonly?: boolean, 
    onValues: (selected?: { [key: string]: SelectedCondition | undefined }) => void }) {
    const { curProject } = useProject()
    const [scripts, setScripts] = useState<Script[]>([])
    const [selected, setSelected] = useState<{[key: string]: SelectedCondition | undefined}>({})
    const onSubscanRowChange = (scanId: string, s?: SelectedCondition) => {
        const newSelected = { ...selected } as { [key: string]: SelectedCondition | undefined }
        newSelected[scanId] = s
        setSelected(newSelected);
        onValues(newSelected)
    }
    const fetch = async () => {
        const metascan = (await new ScansApi().scanDefinition(scanDefinitionId)).data; //TODO improve 
        const page = 1;
        let done = false;
        let allScripts: Script[] = []
        while(!done) {
            const pageData = await new ScriptsApi().scripts(curProject.id!, "CONDITION", page)
            done = page >= (pageData.data.totalPages || 1)
            allScripts = allScripts.concat(pageData.data.list || [])
        }
        setScripts(allScripts.filter(s => s.target === metascan.target))
    }
    useEffect(() => {
        fetch()
    }, [])
    const initialValuesMap = useMemo(() => {
        return initialValues.reduce((acc, cur) => {
            acc[cur.id!] = cur
            return acc
        }, {} as {[key: string]: SelectedCondition})
    }, [initialValues])
    return scripts.length ? <Stack gap={20}>
        <Group gap={8}>
            <label style={{fontSize: '1.1rem'}}>Custom Subscans</label>
        </Group>
        { scripts.map(s => <SubscanRow 
                    key={s.id} 
                    label={s.name!} 
                    readonly={readonly} 
                    initialValue={initialValuesMap[s.id!]} 
                    onValue={v => onSubscanRowChange(s.id!, v)}
                    type="SCRIPT"
                ></SubscanRow>) }
    </Stack> : <></>
}

function CustomViewScans({ onValues, initialValues, readonly } : { 
    initialValues: SelectedCondition[],
    readonly?: boolean, 
    onValues: (selected?: { [key: string]: SelectedCondition | undefined }) => void }) {
    const { curProject } = useProject()
    const [views, setViews] = useState<View[]>([])
    const [selected, setSelected] = useState<{[key: string]: SelectedCondition | undefined}>({})
    const onSubscanRowChange = (scanId: string, s?: SelectedCondition) => {
        const newSelected = { ...selected } as { [key: string]: SelectedCondition | undefined }
        newSelected[scanId] = s
        setSelected(newSelected);
        onValues(newSelected)
    }
    const fetch = async () => {
        const resp = await new ViewsApi().views(curProject.id!)
        setViews(resp.data)
    }
    useEffect(() => {
        fetch()
    }, [])
    const initialValuesMap = useMemo(() => {
        return initialValues.reduce((acc, cur) => {
            acc[cur.id!] = cur
            return acc
        }, {} as {[key: string]: SelectedCondition})
    }, [initialValues])
    return views.length ? <Stack gap={20}>
        <label style={{fontSize: '1.1rem'}}>Views</label>
        { views.map(s => <SubscanRow 
                    key={s.id} 
                    label={s.name!} 
                    readonly={readonly} 
                    initialValue={initialValuesMap[s.id!]} 
                    onValue={v => onSubscanRowChange(s.id!, v ? { ...v, id: s.id! } : undefined)}
                    type="VIEW"
                ></SubscanRow>) }
    </Stack> : <></>
}

function IntegrationScans({ integration, scans, onValues, initialValues, readonly } : { 
    integration?: PartialIntegration,
    initialValues: SelectedCondition[],
    readonly?: boolean, 
    scans: ScanDefinition[], 
    onValues: (selected?: { [key: string]: SelectedCondition | undefined }) => void,
}) {
    const [selected, setSelected] = useState<{[key: string]: SelectedCondition | undefined}>({})
    const onSubscanRowChange = (scanId: string, s?: SelectedCondition) => {
        const newSelected = { ...selected, [scanId]: s }
        setSelected(newSelected);
        onValues(newSelected)
    }
    const initialValuesMap = useMemo(() => {
        return initialValues.reduce((acc, cur) => {
            acc[cur.id!] = cur
            return acc
        }, {} as {[key: string]: SelectedCondition})
    }, [initialValues])
    return <Stack gap={20}>
        { integration && <Group gap={8}>
            <img style={{height: 20}} src={buildApiUrl(`/integrations/${integration.id}/logo.svg`)}></img>
            <label style={{fontSize: '1.1rem'}}>{integration.label}</label>
        </Group>}
        { scans.map(s => <SubscanRow 
                key={s.name} 
                conditionDefinitionId={s.id} 
                label={s.label!} 
                readonly={readonly} 
                initialValue={initialValuesMap[s.id!]} 
                onValue={v => onSubscanRowChange(s.id!, v)}
                type="CATALOG"
            ></SubscanRow>) }
    </Stack>
}


export function ConfigureScanTaskActionInner({ scanDefinitionId, onValues, initialValues, readonly } : {
    scanDefinitionId: string, 
    initialValues?: SelectedCondition[],
    onValues?: (selected: {[key: string]: SelectedCondition | undefined}) => void, 
    readonly?: boolean }) {
    const { curProject } = useProject()
    const [scans, setScans] = useState<{ [key: string]: ScanDefinition[] }>()
    const fetchScans = async () => {
        const fetched = (await new ScansApi().conditionDefinitions(scanDefinitionId, curProject.id!)).data
            .filter(c => !c.deprecated || initialValues?.find(iv => iv.id === c.id))
        setScans(fetched.reduce((acc: any, cur: any) => {
            acc[cur.integration?.name] = acc[cur.integration?.name] || []
            acc[cur.integration?.name].push(cur)
            return acc
        }, {} as { [key: string]: ScanDefinition[] }))
    }
    const scanCategories = useMemo(() => {
        return scans ? Object.entries(scans).sort(sortBy(e => e[0])) : undefined
    }, [scans])
    const [selected, setSelected] = useState<{[key: string]: SelectedCondition | undefined}>((initialValues || []).reduce((acc, cur) => {
        acc[cur.id!] = cur
        return acc
    }, {} as { [key: string]: SelectedCondition }))
    useEffect(() => {
        fetchScans();
    }, [])
    return scanCategories && <Stack gap={40}>
        {scanCategories.map(sc => <IntegrationScans
                key={sc[0]} 
                integration={sc[1][0].integration!} 
                scans={sc[1]}
                initialValues={initialValues || []}
                onValues={vs => { 
                    const newSelected = { ...selected, ...vs }
                    if(onValues) onValues(newSelected)
                    setSelected(newSelected)
                }}
                readonly={readonly}
            ></IntegrationScans>)}
            <CustomScriptScans
                scanDefinitionId={scanDefinitionId}
                initialValues={initialValues || []}
                readonly={readonly}
                onValues={vs => { 
                    const newSelected = { ...selected, ...vs }
                    if(onValues) onValues(newSelected)
                    setSelected(newSelected)
                }}></CustomScriptScans>
            <CustomViewScans
                initialValues={initialValues || []}
                readonly={readonly}
                onValues={vs => { 
                    const newSelected = { ...selected, ...vs }
                    if(onValues) onValues(newSelected)
                    setSelected(newSelected)
                }}
            ></CustomViewScans>
        </Stack>
}

export default function ConfigureScanTaskAction({ scanId, onRefresh }: { scanId: string, onRefresh: () => void }) {
    const form = useForm({
        initialValues: {
            selectedConditions: [] as SelectedCondition[]
        }
    })
    const [scan, setScan] = useState<ScanTask>()
    const { closeAllModals } = useContainer();
    const fetchScan = async () => {
        setScan((await new ScansApi().scanTask(scanId)).data)
    }
    useEffect(() => {
        fetchScan();
    }, [])
    return scan && <SfyForm onClose={closeAllModals}
            onSubmit={() => {
                const resp = new ScansApi().updateScanTask(scan.id!, { ...scan, ...form.values } as UpdateScanTask)
                return doResponse(resp, onRefresh)
            }}        
    >
        <ConfigureScanTaskActionInner 
            initialValues={scan?.selectedConditions?.filter(sc => sc.id && sc.type)}
            onValues={(vs) => {
                form.setFieldValue('selectedConditions', Object.values(vs).filter(v => v) as SelectedCondition[])
            }}
            scanDefinitionId={scan.installedScan?.scanDefinition!.id!}
        ></ConfigureScanTaskActionInner>
    </SfyForm>
}