export default {
  common: {
    title: 'Titre',
    description: 'Description',
    labels: 'Étiquettes',
    service: 'Service',
    username: "Nom d'utilisateur",
    password: 'Mot de passe',  
    confirm_password: 'Confirmez le mot de passe',
    action: 'Actions',
    login: 'Se connecter',
    logout: 'Se déconnecter',
    name: 'Nom',
    cancel: 'Annuler',
    status: 'Status',
    search: 'Recherche',
    discard: 'Défausser',
    manage: 'Gestion',
    disponible: 'Disponible',
    installed: 'Installée',
    install: 'Installer',
    queries: 'Requêtes',
    custom: 'Personnalisé',
    security: 'Sécurité',
    code: 'Code',
    message: 'Message',
    details: "Détails",
    created: 'Crée le',
    approve: 'Approuver',
    delete: 'Supprimer',
    update: 'Modifier',
    decline: 'Refuser',
    force_sync : 'Forcer la synchronisation',
    yes_delete: "Oui, Supprimer",
    no_delete : "Non, ne pas supprimer",
    version: 'Version',
    view_details: 'Voir les détails',
    category: 'Catégorie',
    resolved: 'Résolu',
    unresolved : 'Non résolu',
    general: "Générale",
    library: 'Bibliothèque',
    latest_version: 'Dernière version',
    installed_version: "Version installée",
    source: 'Source',
    last_generated_date: 'Dernière date générée',
    delete_all: 'Supprimer tout',
    edit: 'Modifier',
    size: 'Taille',
    open: 'Ouvert',
    closed: 'Fermé',
    all: 'Tous',
    since: 'Depuis',
    close: 'Fermer',
    submit: 'Soumettre',
    sign_in: 'Se connecter',
    continue: 'Continuer',
    contact_us: 'Contactez-nous',
    no_dont_discard: 'Non, ne pas défaussez'
  },
  login: {
    sign_in_different_tenant: 'Se connecter à un autre organization'
  },
  success: {
    installed: '{{name}} a été installé avec succès'
  },
  operator: {
    login: 'Connexion opérateur',
  },
  graph: {
    label: 'Graphe',
    visualization: 'Visualiser',
    history: 'Historique',
    commit: 'Commit',
    discard_commit: 'Défausser le commit',
    commit_changes: 'Commit les changements',
    are_you_sure_discard_commit: 'Êtes-vous sûr de vouloir défausser ce commit?',
    state: {
      outdated: 'En retard',
      synching: 'Synchronisation',
      upToDate: 'À jour',
    }
  },
  authentication: {
    label: 'Authentication'
  },
  catalogs: {
    label: 'Catalogue',
    label_other: 'Catalogues'
  },
  data_graph: {
    label: 'Graphe de Données',
    editor: 'Éditeur de Données',
  },
  schema_graph: {
    label: 'Graphe de Schéma',
    editor: 'Éditeur de Schéma',
    update_schema: 'Mettre à jour le schéma',
  },
  reports: {
    label: 'Rapport',
    label_other: 'Rapports',
    add_report: 'Ajouter un rapport',
    no_reports: 'Aucun rapport pour le moment',
    add_success: "Le rapport {{name}} a été supprimé avec succès",
  },
  generated_reports: {
    label: 'Rapport généré',
    label_other: 'Rapport générés'
  },
  sso: {
    microsoft: 'Connectez-vous avec Microsoft'
  },
  rules: {
    label: 'Règles',
    inputs: 'Entrées',
    decisions: 'Décisions',
    output: 'Sorties',
  },
  audit_events: {
    label: "Journal d'audit",
    types: {
      auth: {
        login: 'Utilisateur {{byUser.username}} connecté',
        login_sso: 'Utilisateur {{byUser.email}} connecté via SSO',
      },
      integrations: {
        install_integration: 'Intégration installée {{context.name}}',
      },
      issues: {
        open_issue: "Problème ouvert {{context.name}}",
        close_issue: "Problème fermer {{context.name}}"
      }
    },
  },
  observability: {
    label: 'Observabilité',
  },
  alerts: {
    label: 'Alertes',
    no_alerts: 'Aucune alerte pour le moment..'
  },
  issues: {
    label: "Problème",
    label_other: "Problèmes",
  },
  integrations: {
    label: 'Intégration',
    label_other: 'Intégrations',
    no_integrations: "Aucune intégration pour le moment",
    install_integration: "Installer l'intégration",
  },
  insights: {
    label: 'Aperçus',
  },
  data: {
    label: 'Données',
    management: 'Gestion des Données'
  },
  schema: {
    label: 'Schéma',
  },
  analytics: {
    label: 'Analytiques',
  },
  buckets: {
    label: 'Bac de Données',
  },
  views: {
    label: 'Vues',
  },
  credentials: {
    label: 'Identifiants',
  },
  profile: {
    label: 'Profile',
  },
  user: {
    username_description: "Nom d'utilisateur unique pour l'utilisateur que vous souhaitez créer",
    password_description: "Mot de passe unique pour l'utilisateur que vous souhaitez créer",
    confirm_password_description: "Confirmez le mot de passe en écrivant le même que celui que vous avez écrit dans le champ précédent",
    password_dont_match: "Le mot de passe ne correspond pas",
  },
  tracking: {
    label: 'Traquer'
  },
  tickets: {
    label: "Billets",
    ticket_service_number: 'Numéro de billet de service',
    not_synched_yet: 'Pas encore synchronisé',
    no_tickets_yet: 'Pas encore de billets',
    please_enter_ticket_number_delete: 'Veuillez saisir le numéro du ticket à supprimer',
  },
  scans: {
    label: 'Scan',
    label_other: 'Scans',
    condition: 'Condition',
    condition_other: 'Conditions',
    tasks: 'Tâches de scan',
    available_scans: 'Scans disponibles',
    no_available: "Aucun scan disponible",
    no_scans_yet: 'Aucun scan pour le moment',
    no_conditions_yet: 'Aucune condition pour le moment',
    categories : {
      vulnerability_management : 'Gestion des vulnérabilités',
      asset_management : 'Gestion des actifs',
      risk_assessment : 'Évaluation des risques',
      compliance : 'Conformité',
      identity_and_access_management : "Gestion des identités et des accès",
      data_protection : 'Protection des données',
      platform_security : 'Sécurité de la plateforme',
      application_security : 'Sécurité des applications',
      data_quality: 'Qualité des données',
    },
  },
  scripts: {
    label: "Scripts"
  },
  projects: {
    label: 'Projets'
  },
  'System': 'Système',
  'Users': 'Utilisateurs',
  'Tenant': 'Organisation',
  'Moi': 'Moi',
  'Not a customer yet?': 'Pas encore un client?',
  'Contact Us': 'Contactez-nous',
  'Get in touch': "Contactez l'un de nos spécialistes pour plus d'informations sur nos services.",
  'Incorrect username or password': 'Identifiant ou mot de passe incorrect',
  'Role': 'Role',
  'SYS_ADMIN': 'Administrateur système',
  'ADMIN': 'Administrateur',
  'USER': 'Utilisateur',
  'READ_ONLY': 'Lecture-seulement',
  'No reports yet': 'Aucun rapport disponible',
  "You don't have any client credentials": "Vous n'avez pas d'identification client",
  'Enter your gremlin query here': 'Entrez votre requête gremlin ici',
  'Upload a graph': 'Téléverser un graph',
  'Upload graph': 'Téléverser le graph',
  'Entire Graph': 'Graph Entier',
  'Execute': 'Executer',
  'Successfully uploaded graph!': 'Graph téléversé avec succès!',
  'Unable to upload graph.': 'Impossible de téléverser le graph.',
  'No tenants were found': "Aucun organisation n'a été trouvé",
  'No users were found': "Aucun utilisateur n'a été trouvé",
}