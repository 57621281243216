import NotifyConfigure from "../../configure/outputs/NotifyConfigure";
import { WorkflowNodeContext, WorkflowNodeDescriptor, WorkflowNodeValues } from "../descriptor";

const notify_desc: WorkflowNodeDescriptor = {
    descriptorId: 'outputs/notify',
    configureForm: (ctx: WorkflowNodeContext, values: WorkflowNodeValues) => {
        return <NotifyConfigure ctx={ctx} values={values}></NotifyConfigure>
    },
    getOutput: (_: WorkflowNodeContext, _2: WorkflowNodeValues) => {
        return {};
    },
}
export default notify_desc;