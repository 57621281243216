import { useTranslation } from "react-i18next";
import { DisplayElemSize } from "@/components/table/EntityTable";
import { useTitle } from "@/libs/hooks/useTitle";
import ListSection, { ListSectionCallbacks } from "@/components/page/sections/ListSection";
import { useProject } from "@/libs/project/ProjectProvider";
import { InstalledIntegration, InstalledIntegrationStateEnum, IntegrationsApi } from "@/libs/client";
import { useNavigate } from "react-router-dom";
import { FaBroom, FaCheckCircle, FaEdit, FaTrash, FaUpload } from "react-icons/fa";
import { FaCirclePlay } from 'react-icons/fa6';
import UploadFromFiles from "./actions/UploadFromFiles";
import { useContainer } from "@/components/containers/ContainerProvider";
import AddCustomIntegration from "./actions/AddCustomIntegration";
import { useRef } from "react";
import { Badge, Group, ThemeIcon } from "@mantine/core";
import { MdOutlineIntegrationInstructions, MdOutlineSystemUpdateAlt } from "react-icons/md";
import { PiPackageDuotone, PiWarningFill } from "react-icons/pi";
import { buildApiUrl } from "@/libs/utils/apiUrl";
import UpdateInstallIntegration from "./actions/UpdateInstallIntegration";
import notify from "@/libs/notify/notify";
import { ActionResultType } from "@/components/actions/actions";

function InstallIntegrationLabel({ label }: { label: string }) {
    const labelSplit = label.split("/")
    if (labelSplit.length === 1) {
        return <div>{labelSplit[0]}</div>
    }
    return <div style={{display: 'flex', gap: 3, alignItems: 'flex-end'}}>
            <span style={{color: '#c0c0c0', fontSize: '0.75rem', paddingBottom: '0.1rem'}}>{labelSplit[0]} / </span><span>{labelSplit[1]}</span>
        </div>
}

export default function InstalledIntegrationList() {
    const { openModal, openConfirm } = useContainer()
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { curProject, projectUrl } = useProject()
    const listRef = useRef<ListSectionCallbacks>()

    useTitle(t("Installed"))
    const headers = [
        t('common.name'),
        t('common.state'),
        t('common.installed_version'),
    ];
    const stateColor = (e: InstalledIntegration) => {
        switch(e.state) {
            case InstalledIntegrationStateEnum.Active: 
                return 'green';
            case InstalledIntegrationStateEnum.Installing:
                return 'blue';
        }
    }
    return <ListSection<InstalledIntegration>
            callbacks={listRef}
            headers={headers}

            renderRow={(e) => ({
                cols: [{
                    display: [{
                        elem:<Group gap={6}>
                            <InstallIntegrationLabel label={e.name || ''}></InstallIntegrationLabel>
                            <Group gap={3}>
                                {e.integration?.catalog?.custom ? <Badge size="xs" color="blue" leftSection={<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}><MdOutlineIntegrationInstructions></MdOutlineIntegrationInstructions></div>}>Custom</Badge> : <></> }
                                <Badge size="xs" color="green" leftSection={<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}><FaCheckCircle></FaCheckCircle></div>}>Installed</Badge>
                            </Group>
                        </Group>,
                        size: DisplayElemSize.lg,
                    },
                    {
                        label: e.integration?.description || 'No Description',
                        size: DisplayElemSize.xs,
                        secondary: true,
                    }],
                }, {
                    display: [{
                        label: e.lastRun?.state === 'FAILURE' ? 'FAILURE' : (e.state || ''),
                        size: DisplayElemSize.sm,
                        status: e.lastRun?.state === 'FAILURE' ? 'red' : stateColor(e),
                    }],
                }, {
                    display: [{
                        elem: e.integration?.catalog?.custom ? <></> : <>
                            {e.devMode || e.installedVersion === e.integration?.latestVersion ? e.installedVersion : <Group gap={5}>
                                    <ThemeIcon color="yellow" variant="light"><PiWarningFill /></ThemeIcon>
                                    <span style={{color: '#a0a0a0'}}>{e.installedVersion} &rarr;</span> {e.integration?.latestVersion}
                                </Group>}
                        </>,
                    }],
                }],
            })}
            load={async (page: number) => {  
                const resp =  await new IntegrationsApi().installedIntegrations(curProject.id!, {
                    page,
                });
                if (resp.status === 200) {
                    return resp.data;
                }
                return { error: 'TODO' };
            }}
            onRowClick={b => navigate(projectUrl(`/collection/integrations/installed/${b.id}`))}
            rowActions={(e) => { 
                const uploadFromFile = {
                    label: 'Upload From File...',
                    icon: <FaUpload></FaUpload>,
                    execute: () => openModal('upload_from_files', <UploadFromFiles id={e.id!}></UploadFromFiles>, {
                        title: 'Upload From File'
                    })
                };
                const run = {
                    label: 'Run Integration',
                    icon: <FaCirclePlay></FaCirclePlay>,
                    execute: async () => {
                        await new IntegrationsApi().runInstalledIntegration(e.id!)
                        listRef.current?.refresh()
                        notify.info('Started running integration ' + e.name)
                    }
                }
                const edit = {
                    label: 'Edit',
                    icon: <FaEdit></FaEdit>,
                    execute: () => openModal('update_install_integration', <UpdateInstallIntegration installed={e} onRefresh={() => listRef.current?.refresh()}></UpdateInstallIntegration>, {
                        title: t('Edit Installed') || ''
                    })
                }
                const updateVersion = {
                    label: 'Update Version',
                    icon: <MdOutlineSystemUpdateAlt />,
                    execute: async () => {
                        const version = e.integration?.latestVersion!
                        await new IntegrationsApi().updateVersion(e.id!, {
                            version,
                        })
                        listRef.current?.refresh()
                        notify.success(`Successfully updated integration ${e.name} to version ${version}`)
                    }
                    
                }
                const clearData = {
                    label: 'Clear All Data',
                    icon: <FaBroom></FaBroom>,
                    execute: async () => {
                        openConfirm('clear_all_data', {
                            title: 'Clear All Data',
                            confirmationText: <>Are you sure you want to clear all the data in the installed integration <b>{e.name}</b>?</>,
                            cancelText: "No don't clear",
                            submitText: "Yes, Clear",
                            confirmInput: {
                                data: e.name || '',
                                placeholder: 'Please enter the name of the installed integration'
                            },
                            color: 'red',
                            onSubmit: async () => {
                                const resp = await new IntegrationsApi().clearDataInstalledIntegration(e.id!!)
                                if (resp.status < 300) {
                                    listRef.current?.refresh();
                                    notify.success("Cleared all data in " + e.name)
                                    return { type: ActionResultType.SUCCESS }
                                }
                                return { type: ActionResultType.FAILURE }
                            }
                        })
                    }
                }
                const uninstall = {
                    label: 'Uninstall',
                    icon: <FaTrash></FaTrash>,
                    execute: async () => {
                        openConfirm('uninstall_integration', {
                            title: 'Uninstall integration',
                            confirmationText: <>Are you sure you want to uninstall this integration <b>{e.name}</b>?</>,
                            cancelText: "No don't uninstall",
                            submitText: "Yes, Uninstall",
                            confirmInput: {
                                data: e.name || '',
                                placeholder: 'Please enter the name of the installed integration'
                            },
                            color: 'red',
                            onSubmit: async () => {
                                const resp = await new IntegrationsApi().uninstall(e.id!)
                                listRef.current?.refresh()
                                if (resp.status < 300) {
                                    listRef.current?.refresh();
                                    notify.success(`Successfully uninstalled integration ${e.name}`)
                                    return { type: ActionResultType.SUCCESS }
                                }
                                return { type: ActionResultType.FAILURE }
                            }
                        })
                    }
                }
                if (e.integration?.catalog?.custom) {
                    return [uploadFromFile, clearData, uninstall]
                }
                if (!e.devMode && e.installedVersion !== e.integration?.latestVersion) {
                    return [run, edit, updateVersion, clearData, uninstall]
                }
                return [run, edit, clearData, uninstall]
            } }
            logo={{
                getUrl: (e) => e.integration?.catalog?.custom ? `/img/integrations/custom_upload.svg` : buildApiUrl(`/integrations/${e.integration!.id}/logo.svg`),
                width: 30,
                maxHeight: 30,
                headerIcon: <PiPackageDuotone></PiPackageDuotone>
            }}
            addLabel={t('Add Custom')}
            onAdd={() => openModal('add_custom_integration', <AddCustomIntegration onRefresh={() => listRef.current?.refresh()}></AddCustomIntegration>, {
            title: t('Add Custom Integration') || ''
            })}
            emptyLabel={t('No installed yet')}
        ></ListSection>
}