import { useTranslation } from "react-i18next"
import EntityPage from "@/components/page/EntityPage"
import EmptyList from "@/components/empty/EmptyList"
import { useTitle } from "@/libs/hooks/useTitle"
import { useEffect, useState } from "react"
import { AlertLevelEnum, AlertsApi, PageDtoAlert } from "@/libs/client"
import classes from './AlertsLanding.module.css';
import { Badge } from "@mantine/core"
import moment from "moment"
import RenderHTML from "@/libs/utils/RenderHTML"
import { boldify } from "@/libs/utils/strings"
import { FaCube } from "react-icons/fa"

export const getLevelColor = (level: AlertLevelEnum) => {
    switch(level) {
        case "LOW":
            return "blue"
        case "NORMAL":
            return undefined
        case "MEDIUM":
            return "yellow"
        case "HIGH":
        case "CRITICAL":
            return "red"
    }
}

export default function AlertsLanding() {
    const { t } = useTranslation()
    const [alerts, setAlerts] = useState<PageDtoAlert>();
    useTitle(t('alerts.label'))
    const fetchAlerts = async () => {
        const resp = await new AlertsApi().alerts();
        setAlerts(resp.data);
    }
    useEffect(() => {
        fetchAlerts()
    }, [])
    //TODO paging
    return alerts && <EntityPage
        category={t("system.label")}
        title={t("alerts.label")}
    >
        { alerts.totalElements === 0 ? <EmptyList emptyLabel={t('alerts.no_alerts')}></EmptyList> : undefined }
        { alerts.list?.map(a => <div className={classes.alert}>
            <div>
                <Badge style={{float: 'right'}} color={getLevelColor(a.level!)}>{a.level}</Badge>
                <RenderHTML>
                    {boldify(a.message || '', a.context)}
                </RenderHTML>
            </div>
            <div style={{float: 'right', marginTop: 10, fontSize: '0.85rem', color: '#a0a0a0'}}> 
            {moment(a.createdDate).fromNow()}
            </div>
            <div style={{display: 'flex', gap: 5, marginTop: 5}}>
                <Badge size="sm" variant="default" leftSection={<FaCube></FaCube>}>
                    {a.project?.name}
                </Badge>
                <Badge size="sm" variant="default">{a.resolved ? t('common.resolved') : t('common.unresolved')}</Badge>
            </div>
        </div>)}
    </EntityPage>
}