import GenerateReportConfigure from "../../configure/outputs/GenerateReportConfigure";
import { WorkflowNodeDescriptor, WorkflowNodeContext, WorkflowNodeValues } from "../descriptor";

const generate_report_desc: WorkflowNodeDescriptor = {
    descriptorId: 'outputs/generate_report',
    configureForm: (ctx: WorkflowNodeContext, values: WorkflowNodeValues) => {
        return <GenerateReportConfigure ctx={ctx} values={values}></GenerateReportConfigure>
    },
    getOutput: (_: WorkflowNodeContext, _2: WorkflowNodeValues) => {
        return {};
    },
}
export default generate_report_desc;