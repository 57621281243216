import { ReactNode } from 'react';
import './SfyBreadcrumb.css';
import { useNavigate } from 'react-router-dom';

export type SfyBreadcrumbItemProps = {
    key: string
    renderIcon?: () => ReactNode
    label: string
    link?: string
}

function SfyBreadcrumbItem({ link, label, renderIcon } : SfyBreadcrumbItemProps) {
    const navigate = useNavigate()
    const icon = renderIcon ? <span>renderIcon()</span> : undefined
    return <li><a href={link} onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        navigate(link!)
    }}>{icon} {label}</a></li>
}

type SfyBreadcrumbProps = {
    breadcrumbs: SfyBreadcrumbItemProps[]
}

export default function SfyBreadcrumb({ breadcrumbs } : SfyBreadcrumbProps) {
    return <ul className="breadcrumb">
        {breadcrumbs.map(b => <SfyBreadcrumbItem {...b}></SfyBreadcrumbItem>)}
    </ul>
}