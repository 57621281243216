
import { ActionResultType } from "@/components/actions/actions"
import SfyForm from "@/components/form/SfyForm"
import FormTextInput from "@/components/form/inputs/FormTextInput"
import { Commit, CommitsApi } from "@/libs/client"
import { doResponse } from "@/libs/utils/response"
import { sleep } from "@/libs/utils/wait"
import { useForm } from "@mantine/form"
import { useTranslation } from "react-i18next"

type CommitGraphFormProps = {
    commit: Commit
    onClose: () => void
    onSuccess: () => Promise<void>
}

export default function CommitGraphForm({ onSuccess, commit, onClose }: CommitGraphFormProps) {
    const form = useForm({
        initialValues: {
            message: '',
        },
        validate: {
            message: (value) => !!value,
        },
    });
    const { t } = useTranslation()
    return <SfyForm 
        submitLabel={t("Commit")} 
        onClose={onClose}
        onSuccess={() => {
            onSuccess()
        }}
        onSubmit={async () => {
                const resp = await doResponse(new CommitsApi().approveCommit(commit.id!, {
                    ...form.values,
                }))
                if (resp.type === ActionResultType.SUCCESS) {
                    for (let i = 0; i < 20; i++) {
                        const poll = await new CommitsApi().commit(commit.id!)
                        await sleep(2000)
                        if (poll.data.synched) {
                            return resp
                        }
                    }
                }
                return resp
            }
        }
        disabled={form.isValid()}
        successLabel={t("Successfully commited!")}
        errorLabel={t("Unable to commit.")}
    >
        { <FormTextInput 
            label={t('Message')} 
            description={'Describe what you are doing in this commit'}
            {...form.getInputProps('message')}
        ></FormTextInput>  }
    </SfyForm>
}