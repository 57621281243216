import { GraphType } from '@/libs/utils/graph';
import { deepEqual } from '@/libs/utils/obj';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';

export type CursorType = 'POINTER' | 'HOVER'
export type Mode = 'READ' | 'UPDATE_SCHEMA' | 'UPDATE_DATA'

type GraphDisplayContextProps = {
    includeFields: string[]
    setIncludeFields: (fields: string[]) => void
    selected: string[]
    setSelected: (selected: string[]) => void
    clearSelected: () => void
    activeType: GraphType
    setActiveType: (type: GraphType) => void
    cursorType: CursorType
    setCursorType: (cursor: CursorType) => void
    mode: Mode
    setMode: (mode: Mode) => void,
    hasSelected: boolean,
}


export const GraphDisplayContext = createContext({
} as GraphDisplayContextProps);

export const useGraphDisplay = () => useContext(GraphDisplayContext);

export const GraphDisplayProvider = ({ children } : { children: JSX.Element | JSX.Element[]}) => {
    const [includeFields, setIncludeFields] = useState<string[]>([])
    const [selected, setSelected] = useState<string[]>([])
    const [activeType, setActiveType] = useState<GraphType>("DATA")
    const [cursorType, setCursorType] = useState<CursorType>("POINTER")
    const [mode, setMode] = useState<Mode>("READ")
    const clearSelected = () => setSelected([])
    const hasSelected = useMemo(() => {
        return selected.length > 0
    }, [selected])
    const setSelectedIfChanged = useCallback((s: string[]) => {
        if (!deepEqual(s, selected)) {
            setSelected(s)
        }
    }, [selected])
    return (
        <GraphDisplayContext.Provider value={{ 
            includeFields,
            setIncludeFields,
            selected,
            setSelected: setSelectedIfChanged,
            hasSelected,
            clearSelected,
            activeType,
            mode,
            setMode,
            setActiveType: (type: GraphType) => {
                setActiveType(type)
                clearSelected()
            },
            cursorType,
            setCursorType,
        }}>
            {children}
        </GraphDisplayContext.Provider>
    );
};