import ListSection, { ListSectionCallbacks } from "@/components/page/sections/ListSection"
import { ScanDefinition, ScansApi } from "@/libs/client"
import { useTitle } from "@/libs/hooks/useTitle"
import notify from "@/libs/notify/notify"
import { useProject } from "@/libs/project/ProjectProvider"
import { Badge, Group } from "@mantine/core"
import { useRef } from "react"
import { useTranslation } from "react-i18next"
import { FaCheckCircle } from "react-icons/fa"
import { GrInstallOption } from "react-icons/gr"


export default function AvailableScansList() {
    const { t } = useTranslation()
    const { curProject } = useProject()

    const listRef = useRef<ListSectionCallbacks>()
    useTitle(t("scans.available_scans"))
    const headers = [
        t('common.name'),
        t('common.category'),
        t('common.version')
    ];
    return <ListSection<ScanDefinition>
        callbacks={listRef}
        headers={headers}
        renderRow={(e) => ({
            cols: [{
                display: [{
                    elem:<Group gap={6}>
                    <div>{e.label || ''}</div>
                        <Group gap={3}>
                            {e.installed ? <Badge size="xs" color="green" leftSection={<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}><FaCheckCircle></FaCheckCircle></div>}>{t('common.installed')}</Badge> : <></>}
                        </Group>
                    </Group>,                   
                },],
            }, {
                display: [{
                    label: e.category ? t(`scans.categories.${e.category}`) : 'n/a',
                }]
            }, {
                display: [{
                    label: '0.1'
                }]
            }],
        })}
        load={async (page) => {
            const resp = await new ScansApi().scanDefinitions(curProject.id!, page);
            if (resp.status === 200) {
                return resp.data;
            }
            return { error: 'TODO' };
        }}
        rowActions={(e) => e.installed ? [] : [{
            label: t('install'),
            icon: <GrInstallOption></GrInstallOption>,
            execute: async (e) => {
                const resp = await new ScansApi().installScan(e.id!, {
                    projectId: curProject.id!,
                })
                if (resp.status < 300) {
                    notify.success(t('success.installed', { name: e.name }))
                }
                listRef.current?.refresh()
            }
        }]}
        emptyLabel={t('scans.no_available')}
    ></ListSection>
}