import { Change } from "@/libs/client";
import { ChangeItemProps } from "@/graph/visualize/common/commit/ChangeFab";
import { RiAddFill, RiCloseFill, RiEditFill } from "react-icons/ri";
import { FaBroom } from "react-icons/fa";

export function buildDataChangeProps(change: Change): ChangeItemProps {
    switch(change.type) {
        case 'ADD': return {
            title: change.target === 'NODE' ? `Added Node` : `Added Edge`,
            detail: getDataDetails(change),
            renderIcon: (props: any) => <RiAddFill {...props}></RiAddFill>,
            color: "#62b59c",
            change,
        }
        case 'UPDATE': return {
            title: change.target === 'NODE' ? `Updated Node` : `Updated Edge`,
            detail: getDataDetails(change),
            renderIcon: (props: any) => <RiEditFill {...props}></RiEditFill>,
            color: "#b59c62",
            change,
        }
        case 'REMOVE': return {
            title: change.target === 'NODE' ? `Remove Node` : `Remove Edge`,
            detail: getDataDetails(change),
            renderIcon: (props: any) => <RiCloseFill {...props}></RiCloseFill>,
            color: "#b56262",
            change,
        }
        case 'CLEAR_ALL': return {
            title: 'Clear all',
            detail: '',
            renderIcon: (props: any) => <FaBroom {...props}></FaBroom>,
            color: "#8b588",
            change,
        }
    }
    throw Error("Cannot find change")
}

function getDataDetails(change: Change) {
    if (change.data?.addNode) {
        return `${change.data.addNode.node?.type} (${change.data.addNode.node?.oid})`   
    } else if (change.data?.addEdge) {
        const source = change.data.addEdge.edge.source
        const target = change.data.addEdge.edge.target
        return `${source?.type} (${source?.oid}) - ${change.data.addEdge.edge?.name} -> ${target?.type} (${target?.oid})`
    } else if (change.data?.removeNode) {
        return `${change.data.removeNode.meta?.type} (${change.data.removeNode.meta?.oid})`   
    } else if(change.data?.removeEdge) {
        const source = change.data.removeEdge.meta?.source
        const target = change.data.removeEdge.meta?.target
        return `${source?.type} (${source?.oid}) - ${change.data.removeEdge.meta?.name} -> ${target?.type} (${target?.oid})`
    } else if (change.data?.updateNode) {
        return `${change.data.updateNode.node?.type} (${change.data.updateNode.node?.oid})`
    }
    return '';
}