import { useTranslation } from "react-i18next"
import { useTitle } from "@/libs/hooks/useTitle"
import ConstructionAnimation from "@/components/animations/ConstructionAnimation"

export default function ProfileLanding() {
    const { t } = useTranslation()
    useTitle(t("Profile"))
    return <div>
        Profile coming soon
        <ConstructionAnimation height={400}></ConstructionAnimation>
    </div>
}