import { useContainer } from "@/components/containers/ContainerProvider";
import SfyForm from "@/components/form/SfyForm";
import FormSelect from "@/components/form/inputs/FormSelect";
import { Button, Stack, TagsInput } from "@mantine/core";

export default function StartCampaignAction() {
    const {closeAllModals} = useContainer()
    return <SfyForm onClose={closeAllModals}>
        <Stack gap={30}>
            <Stack gap={16}>
            <FormSelect
                label="Category"
                allowDeselect={false}
                description="Category in which the form you would like is present"
                data={[{
                    value: 'FAIR',
                    label: 'FAIR'
                }]}
                defaultValue={"FAIR"}
            ></FormSelect>
            <FormSelect
                label="Form"
                allowDeselect={false}
                description="Form you would like to be sent"
                data={[{
                    value: 'financial_impact',
                    label: 'Financial Impact'
                }]}
                defaultValue={"financial_impact"}
            ></FormSelect>
            <TagsInput
                label="Recipients"
                description="Emails of people you would like to send the form to"
                placeholder="Press enter to add email"
            ></TagsInput>
            </Stack>
            <Button style={{maxWidth: 160}} color="black">Preview Form</Button>
        </Stack>
    </SfyForm>
}