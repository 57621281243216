import { useTitle } from "@/libs/hooks/useTitle";
import EntityPage from "@/components/page/EntityPage";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { TabData } from "@/components/page/EntityTabs";
import { useProject } from "@/libs/project/ProjectProvider";
import { View, ViewsApi } from "@/libs/client";

export default function ViewLanding() {
    const { id } = useParams()
    const { projectUrl } = useProject()
    const [view, setView] = useState<View>()
    const fetch = async () => {
        const res = await new ViewsApi().view(id!)
        if (res.status == 200) {
            setView(res.data);
        } else {
            //TODO
        }
    }
    useEffect(() => {
        fetch();
    }, [])
    useEffect(() => {
        if (view) {
            useTitle(`${view.name} - View`)
        }
    }, [view])
    return view && <EntityPage
        category={`View`}
        title={view.name || ''}
        tabs={[{
            key: 'details',
            label: "Details",
            route: projectUrl(`/data/views/${id}/details`),
        }] as TabData[]}
        breadcrumbs={[
            {
                key: 'views',
                label: 'Views',
                link: projectUrl('/data/views'),
            },
            {
                key: 'name',
                label: view.name!,
            }
        ]}
    ></EntityPage>
}