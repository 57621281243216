import { ActionIcon, CopyButton, Grid, Group, rem, Stack, Tooltip } from "@mantine/core";
import { FaCheck, FaCopy } from "react-icons/fa";

export function DetailsSection({ id } : { id: string }) {
    return <Grid>
        <Grid.Col span={6}>
            <Stack gap={4}>
                <label>ID</label>
                <Group gap={2}>
                    <div style={{fontSize: '0.85rem'}}>{id}</div>
                    <CopyButton value={id + ''} timeout={2000}>
                    {({ copied, copy }) => (
                        <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position="right">
                        <ActionIcon color={copied ? 'teal' : '#c0c0c0'} variant="subtle" onClick={copy}>
                            {copied ? (
                            <FaCheck style={{ width: rem(12) }} />
                            ) : (
                            <FaCopy style={{ width: rem(12) }} />
                            )}
                        </ActionIcon>
                        </Tooltip>
                    )}
                    </CopyButton>
                </Group>
            </Stack>
        </Grid.Col>
    </Grid>
}