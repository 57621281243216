import { useContainer } from "@/components/containers/ContainerProvider";
import UploadGraph from "@/graph/visualize/common/actions/UploadGraph";
import CommitButton from "@/graph/visualize/common/commit/CommitButton";
import DiscardCommitButton from "@/graph/visualize/common/commit/DiscardCommitButton";
import GraphToolbar from "@/graph/visualize/common/toolbar/GraphToolbar";
import { useGraphDisplay } from "@/graph/visualize/providers/GraphDisplayProvider";
import { useGraphEditor } from "@/graph/visualize/providers/GraphEditorProvider";
import { useGraphLoader } from "@/graph/visualize/providers/GraphLoaderProvider";
import { useProject } from "@/libs/project/ProjectProvider";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const UPLOAD_GRAPH_KEY = 'upload_graph';

export default function DataEditorToolbar() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { openDrawer, closeDrawer } = useContainer()
    const { reloadDataGraph } = useGraphLoader()
    const { commit, reloadCommit } = useGraphEditor()
    const { activeType } = useGraphDisplay()
    const { projectUrl } = useProject()
    return <>
        <GraphToolbar
            breadcrumb={['Data Graph', t('common.edit')]}
            rhs={[[
                <CommitButton reloadGraph={async () => { 
                    await reloadDataGraph()
                }} commit={commit!} navigateOnSuccess={projectUrl("/data/graph")}></CommitButton>,
                <DiscardCommitButton commit={commit!} navigateOnSuccess={projectUrl("/data/graph")}></DiscardCommitButton>
            ]]}
            moreActions={[{
                label: 'Data Graph',
                items: [{
                    label: 'Upload From File',
                    onClick: () => openDrawer(UPLOAD_GRAPH_KEY, <UploadGraph 
                        activeType={activeType}
                        commit={commit!} 
                        onSuccess={async () => reloadCommit()}
                        onRefresh={() => {}}
                        onClose={() => closeDrawer(UPLOAD_GRAPH_KEY)}></UploadGraph>, { title: 'Upload From File' })
                },
                {
                    label: 'Cancel',
                    onClick: () => navigate(projectUrl('/data/graph'))
                }]}]
            }
        ></GraphToolbar>
    </>
}