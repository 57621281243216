import { OpsSettingsApi } from "@/libs/client";
import notify from "@/libs/notify/notify";
import { Button, PasswordInput, Stack, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { openConfirmModal } from "@mantine/modals";
import { useEffect } from "react";

export default function SysCosmosDbSettings() {
    const form = useForm({
        initialValues: {
            name: '',
            readWritePrimaryKey: '',
            readPrimaryKey: '',
        }
    })
    const fetchCosmosDb = async () => {
        const fetched = (await new OpsSettingsApi().cosmosDbSettings()).data
        form.setValues(fetched)
    }

    const updateCosmosDb = async () => {
        openConfirmModal({
            title: 'Please confirm your action',
            children: (
            <Text size="sm">
                This will override the current settings for Cosmos DB. Please make sure they are correct, since they can break existing features.
            </Text>
            ),
            labels: { confirm: 'Confirm', cancel: 'Cancel' },
            onCancel: () => {
            },
            onConfirm: async () => { 
                await new OpsSettingsApi().updateCosmosDbSettings(form.values)
                notify.success("Successfully updated your CosmosDB settings")
            },
        })
    }

    useEffect(() => {
        fetchCosmosDb()
    }, [])

    return <div style={{padding: '0 3rem'}}>
        <div>
            <h2 style={{marginTop: 0, fontWeight: 500}}>Cosmos DB</h2>
            <Stack>
                <TextInput
                    label="Name"
                    description="Name of the Cosmos DB instance"
                    {...form.getInputProps('name')}
                ></TextInput>
                <PasswordInput
                    label="Read/Write Primary Key"
                    description="Primary key to access the Cosmos Db instance (Read-Write)"
                    {...form.getInputProps('readWritePrimaryKey')}
                ></PasswordInput>
                <PasswordInput
                    label="Read Primary Key"
                    description="Primary key to access the Cosmos Db instance (Read)"
                    {...form.getInputProps('readPrimaryKey')}
                ></PasswordInput>
            </Stack>
            <div style={{display: 'flex', gap: 4, marginTop: 20}}>
                <Button variant="default" onClick={() => fetchCosmosDb()}>Reset</Button>
                <Button onClick={() => updateCosmosDb()}>Update</Button>
            </div>
        </div>
    </div>
}