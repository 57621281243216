import { Group, Table } from "@mantine/core"
import { useTranslation } from "react-i18next"
import { DataEdge, DataNode, SchemaGraph } from "@/libs/client"
import classes from './DataDetails.module.css';
import { ReactNode } from "react";
import { isURL } from "@/libs/utils/strings";
import LazyDataGraph from "@/graph/visualize/render/data/lazy/LazyDataGraph";

const adaptAttributeValue = (value?: string): ReactNode => {
    if (!value) {
        return "";
    }
    if (isURL(value)) {
        return <a href={value} target="_blank" rel="noopener noreferrer">{value}</a>
    }
    return value;
}

function ManyElementsDetail({ ids, dataGraph } : { ids: string[], dataGraph: LazyDataGraph }) {
    const nodes = ids.map(id => dataGraph.loadedNodes().find(n => n.id = id)).filter(n => n)
    const edges = ids.map(id => dataGraph.loadedEdges().find(e => e.id == id)).filter(e => e)
    return <div className={classes.metaRow}>
        <label>Summary</label>
        <Table>
            <tbody>
                <tr>
                    <td># of Nodes</td>
                    <td>{nodes.length}</td>
                </tr>
                <tr>
                    <td># of Edges</td>
                    <td>{edges.length}</td>
                </tr>
            </tbody>
        </Table>
    </div>
}

function SingleElementDetail({ id, dataGraph } : { dataGraph: LazyDataGraph, id: string }) {
    const { t } = useTranslation()
    const selectedElement = (dataGraph.loadedNode(id) || dataGraph.loadedEdge(id))
    if (!selectedElement) {
        return <div>No Info</div>
    }
    return <>
        <Group>
        <div className={classes.metaRow}>
        <label>{t('Element')}</label>
            <div>{(selectedElement as DataEdge).source ? 'Edge' : 'Node'}</div>
        </div>
        <div className={classes.metaRow}>
            <label>Type</label>
            <div>{
                //@ts-ignore
                selectedElement.type
            }</div>
        </div>
        </Group>
        { (selectedElement as DataNode).id && <div className={classes.metaRow}>
            <label>Id</label>
            <div>{(selectedElement as DataNode).id || ''}</div>
        </div>}
        { (selectedElement as DataNode).oid && (<div className={classes.metaRow}>
            <label>Oid</label>
            <div>{(selectedElement as DataNode).oid || ''}</div>
        </div>)}
        { (selectedElement as DataEdge).source && <div className={classes.metaRow}>
            <label>Source</label>
            <Table>
                <tbody>
                    <tr>
                        <td>ID</td>
                        <td>{(selectedElement as DataEdge).source?.id}</td>
                    </tr>
                    <tr>
                        <td>Type</td>
                        <td>{(selectedElement as DataEdge).source?.type}</td>
                    </tr>
                    <tr>
                        <td>OID</td>
                        <td>{(selectedElement as DataEdge).source?.oid}</td>
                    </tr>
                </tbody>
            </Table>
        </div>}
        { (selectedElement as DataEdge).target?.id && <div className={classes.metaRow}>
            <label>Target</label>
            <Table>
                <tbody>
                    <tr>
                        <td>ID</td>
                        <td>{(selectedElement as DataEdge).target?.id}</td>
                    </tr>
                    <tr>
                        <td>Type</td>
                        <td>{(selectedElement as DataEdge).target?.type}</td>
                    </tr>
                    <tr>
                        <td>OID</td>
                        <td>{(selectedElement as DataEdge).target?.oid}</td>
                    </tr>
                </tbody>
            </Table>
        </div>}
        { !!Object.keys(selectedElement.properties || {}).length &&
        <div className={classes.metaRow}>
            <label>Attributes</label>
            <Table withTableBorder style={{marginTop: 10}}>
                <thead style={{ backgroundColor: '#fafafa'}}>
                    <tr>
                        <th style={{fontWeight: 600, color: '#555'}}>Property</th>
                        <th style={{fontWeight: 600, color: '#555'}}>Value</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(selectedElement.properties || {})
                        .map(e => <tr key={e[0]}>
                            <td>{e[0]}</td>
                            <td>{adaptAttributeValue(e[1]?.toString())}</td>
                        </tr>)}
                </tbody>
            </Table>
        </div>}
    </>
}

type ViewDetailsProps = {
    selected: string[]
    dataGraph: LazyDataGraph
    schemaGraph: SchemaGraph
    onClose?: () => void
}

export function DataDetails({ selected, dataGraph }: ViewDetailsProps) {
    const total = selected.length
    return total > 1 ?
                <ManyElementsDetail ids={selected} dataGraph={dataGraph}></ManyElementsDetail> :
                <SingleElementDetail id={selected[0]} dataGraph={dataGraph}></SingleElementDetail>
}