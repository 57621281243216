import { ActionIcon, Button } from "@mantine/core"
import { useCallback, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { FaArrowRight, FaTimes, FaArrowDown, FaArrowUp } from "react-icons/fa"
import { BiError } from 'react-icons/bi'
import CodeMirror from 'react-codemirror'
import 'codemirror/lib/codemirror.css'
import 'codemirror/mode/groovy/groovy'
import 'codemirror/theme/neo.css'
import { useGraphLoader } from "../../../providers/GraphLoaderProvider"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import GraphMetrics from "@/graph/visualize/common/metrics/GraphMetrics"
import { millisecondsToTimeInfo } from "@/libs/utils/metrics"
import classes from './DataBottomBar.module.css';
import cx from 'clsx';
import LazyDataGraph from "../lazy/LazyDataGraph"

type QueryBoxProps = {
    executeQuery: (query: string) => Promise<void>
    executing: boolean
    errors: string[]
    clearErrors: () => void
    showQuery: boolean
}

function QueryBox({ showQuery, executeQuery, executing, errors, clearErrors } : QueryBoxProps) {
    const [forceHideQuery, setForceHideQuery] = useState(false)
    const [searchParams] = useSearchParams()
    const [executedQuery, setExecutedQuery] = useState("")
    const [query, setQuery] = useState("")
    const [init, setInit] = useState(false)
    const { t } = useTranslation()
    const cm = useRef<any>();
    useEffect(() => {
        setQuery(searchParams.get("query") || "")
        setExecutedQuery(searchParams.get("query") || "")
        setInit(true)
    }, [])
    useEffect(() => {
        setForceHideQuery(false)
    }, [showQuery])
    const onQueryArrowClick = useCallback(() => {
        setForceHideQuery(!forceHideQuery)
    }, [forceHideQuery])
    if (!init) {
        return <></>
    }
    return <>
    { showQuery && <ActionIcon className={classes.queryArrow} variant="filled" onClick={onQueryArrowClick}>
        { !forceHideQuery && <FaArrowDown></FaArrowDown> }
        { forceHideQuery && <FaArrowUp></FaArrowUp> }
    </ActionIcon>}
    <div className={cx(classes.query, showQuery && !forceHideQuery ? classes.showQuery : undefined)}>
    {!!errors.length && 
        <div className={classes.queryError}>
            <BiError></BiError> <span style={{flex: 1}}>{ errors.join("; ") }</span>
            <div style={{marginRight: 10, cursor: 'pointer'}} onClick={clearErrors}><FaTimes></FaTimes></div>
        </div>
    }
    <CodeMirror ref={cm} className={classes.queryArea} value={query} onChange={(text) => { 
        clearErrors()
        setQuery(text)
    }} options={{
        lineNumbers: true, 
        mode: 'groovy', 
        theme: 'neo',
        extraKeys: {
            'Cmd-Enter': () => {
                if (!executing) {
                    executeQuery(query)
                    setExecutedQuery(query)
                }
            },
            'Ctrl-Enter': () => {
                if (!executing) {
                    executeQuery(query)
                    setExecutedQuery(query)
                }
            },
        }
    }}
    ></CodeMirror>
    <Button
    radius={"xl"}
    rightSection={<FaArrowRight></FaArrowRight>}
    loading={executing}
    onClick={() => {executeQuery(query); setExecutedQuery(query)}}
    >
        {t('Execute')}
    </Button>
    <div className={classes.clear} onClick={() => { 
        setQuery(""); 
        cm.current.codeMirror.setValue(""); 
        if (executedQuery) {
            executeQuery("")
            setExecutedQuery("")
        }
    }}>{t('Clear')}</div>
</div> 
</>
}

type GraphBottomBarProps = {
    showQuery: boolean
    dataGraph?: LazyDataGraph
}

export default function DataBottomBar({ showQuery, dataGraph }: GraphBottomBarProps) {
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const location = useLocation()
    const { loadDataGraph, loadingData } = useGraphLoader()
    const [executing, setExecuting] = useState(false)
    const [errors, setErrors] = useState<string[]>([])
    const executeQuery = async (query: string) => {
        setExecuting(true)
        setErrors([])
        try {
            const res = await loadDataGraph(query)
            navigate(location.pathname + (query ? `?query=${query}` : ''), { replace: true})
            if (!res.success) {
                setErrors(res.errors || [])
            }
        } catch(err) {
            setErrors(["Unexpected error occured"])
        }
        setExecuting(false);
    }
    useEffect(() => {
        if(searchParams.get("query")) {
            executeQuery(searchParams.get("query") || "")
        }
    }, [])
    //TODO
    return <>
        <GraphMetrics
            metrics={[
                { value: dataGraph?.meta.stats.totalNodes || 0, unit: 'Nodes'},
                { value: dataGraph?.meta.stats.totalEdges || 0, unit: 'Edges'},
                millisecondsToTimeInfo(loadingData.timeInMs),
            ]}
        ></GraphMetrics>
        <QueryBox
            showQuery={showQuery}
            executeQuery={executeQuery}
            executing={executing}
            errors={errors}
            clearErrors={() => setErrors([])}
        ></QueryBox>
    </>
}