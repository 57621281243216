import { DotLottiePlayer } from '@dotlottie/react-player';
import '@dotlottie/react-player/dist/index.css';
import AnimationProps from './AnimationProps';

export default function SyncAnimation({ height } : AnimationProps) {
    return <DotLottiePlayer
    style={{height, width: 'auto'}}
    src="/animations/SyncAnimation.lottie"
    autoplay
    loop
  ></DotLottiePlayer>
}