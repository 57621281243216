import { useTranslation } from "react-i18next";
import { DisplayElemSize } from "@/components/table/EntityTable";
import { ClientCredential, ClientCredentialsApi } from "@/libs/client";
import { useTitle } from "@/libs/hooks/useTitle";
import EntityPage from "@/components/page/EntityPage";
import ListSection from "@/components/page/sections/ListSection";

export default function CredentialsLanding() {
    const { t } = useTranslation();
    useTitle(t("Credentials"))
    const headers = [
        t('common.name')
    ];
    return <EntityPage
        category={t("Me")}
        title={t("Credentials")}
    >
        <ListSection<ClientCredential>
            headers={headers}
            load={async (page) => {
                const resp = await new ClientCredentialsApi().credentials({
                    page,
                });
                if (resp.status === 200) {
                    return resp.data;
                }
                return { error: 'TODO' };
            }}
            renderRow={(e) => ({
                cols: [{
                    display: [{
                        label: e.clientId || '',
                        size: DisplayElemSize.lg,
                    }],
                }],
                })
            }
            emptyLabel={t("You don't have any client credentials")}
        ></ListSection>
    </EntityPage>
}