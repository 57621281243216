import '@mantine/core/styles.css';

import { Button, CSSVariablesResolver, Input, MantineProvider } from '@mantine/core';
import { BrowserRouter } from 'react-router-dom';

import './App.css';
import AppRoutes from './AppRoutes';
import './i18n';
import { AuthProvider } from './libs/auth/AuthProvider';
import { ModalsProvider } from '@mantine/modals';
import { useEffect, useState } from 'react';
import classes from './Variants.module.css';

import axios from 'axios';
import { BASE_PATH } from './libs/client/base';
import { InfoProvider } from './libs/info/InfoProvider';
axios.defaults.withCredentials = true;

let csrfToken: string;
let loadingCsrfToken = false;

const loadCsrfToken = async (force?: boolean): Promise<string> => {
  if (csrfToken && !force) {
    return csrfToken
  }
  if (loadingCsrfToken) {
    return new Promise((resolve, reject) => {
      const inter = setInterval(() => {
          if (csrfToken) {
            clearInterval(inter);
            resolve(csrfToken)
          } else if (!loadingCsrfToken) {
            clearInterval(inter);
            reject()
          }
      }, 100);
    })
  }
  loadingCsrfToken = true;
  const resp = await fetch(BASE_PATH + '/csrf', { credentials: 'include' })
  try {
    csrfToken = (await resp.json()).token;
  } catch(err) {
  }
  loadingCsrfToken = false;
  return csrfToken;
}

axios.interceptors.request.use(async (config) => {
  await loadCsrfToken()
  config.headers["X-CSRF-TOKEN"] = csrfToken;
  config.headers["X-XSRF-TOKEN"] = csrfToken;
  return config;
});

axios.interceptors.response.use((resp) => {
  return resp
}, (error) => {
  if (error.response 
      && error.response.status === 401
      && !window.location.pathname.includes("/login")) {
    window.location.reload();
  }
  return Promise.reject(error);
});

const headerHeight = '50px'
const toolbarHeight = '38px'
const navigatorWidth = '250px'
const bottomBarHeight = '150px'


const resolver: CSSVariablesResolver = (theme) => ({
  variables: {
    '--mantine-other-secondaryColor': theme.other.secondaryColor,
    '--mantine-other-contentWidth': theme.other.contentWidth,
    '--mantine-other-contentHeight': theme.other.contentHeight,
    '--mantine-other-headerHeight': theme.other.headerHeight,
    '--mantine-other-graph-navigatorWidth': theme.other.graph.navigatorWidth,
    '--mantine-other-graph-toolbarHeight': theme.other.graph.toolbarHeight,
    '--mantine-other-graph-contentWidth': theme.other.graph.contentWidth,
    '--mantine-other-graph-contentHeight': theme.other.graph.contentHeight,
    '--mantine-other-graph-bottomBarHeight': theme.other.graph.bottomBarHeight,
    '--mantine-other-ruleEngine-editorHeaderHeight': theme.other.ruleEngine.editorHeaderHeight,
    '--mantine-other-ruleEngine-trayWidth': theme.other.ruleEngine.trayWidth,
  },
  light: {
  },
  dark: {
  },
});

function App() {
  const [operatorFromPath, setOperatorFromPath] = useState(false)
  useEffect(() => {
    const favicon = document.querySelector('link[rel="icon"]')
    if (!favicon) {
      return;
    }
    if (operatorFromPath) {
      const href = favicon.getAttribute('href')!
      favicon.setAttribute('href', href.replace('/favicon.svg', '/operator-favicon.svg'))
    } else {
      const href = favicon.getAttribute('href')!
      favicon.setAttribute('href', href.replace('/operator-favicon.svg', '/favicon.svg'))
    }
  }, [operatorFromPath])
  useEffect(() => {
    const handlePathChange = () => {
      setOperatorFromPath(window.location.pathname.startsWith("/ops"));
    };
    handlePathChange();
    window.addEventListener('locationchange', handlePathChange);
    return () => {
      window.removeEventListener('locationchange', handlePathChange);
    };
  }, []); 
  return (
    <div>
      <MantineProvider theme={{
        other: {
          secondaryColor: '#1f1f1f',
          headerHeight,
          contentHeight: `calc(100vh - ${headerHeight})`,
          graph: {
            navigatorWidth,
            toolbarHeight,
            bottomBarHeight,
            contentHeight: `calc(100vh - ${headerHeight} - ${toolbarHeight})`,
            contentWidth: `calc(100vw - ${navigatorWidth})`,
          },
          ruleEngine: {
            editorHeaderHeight: '50px',
            trayWidth: '280px',
          },
        },
        components: {
          Button: Button.extend({ classNames: { root: classes.button } }),
          Input: Input.extend({ classNames: { wrapper: classes.input } }),
        },
        colors: {
          ops: [
            "#EADADE",
            "#DEBDC5",
            "#D79FAD",
            "#D57E94",
            "#DB5A7A",
            "#E8305F",
            "#FF0040",
            "#CF1745",
            "#A92546",
            "#8D2D45",
          ],
          'sfy-green': [
            '#99ED96',
            '#99ED96',
            '#93DD90',
            '#80CD7D',
            '#79C776',
            '#71BF6E',
            '#6AB668',
            '#63A861',
            '#5D9C5B',
            '#559054',
          ],
          'sfy-dark-blue': [
            '#007BB9',
            '#007BB9',
            '#006FA7',
            '#02679A',
            '#005A87',
            '#00486C',
            '#003955',
            '#012B41',
            '#011E2E',
            '#000304',
          ]
        },
        primaryColor: operatorFromPath ? 'ops' : 'sfy-dark-blue',
        
      }} cssVariablesResolver={resolver} withCssVariables>
        <InfoProvider>
          <AuthProvider reloadCsrf={() => loadCsrfToken(true)}>
            <ModalsProvider>
                <BrowserRouter>
                  <AppRoutes></AppRoutes>
                </BrowserRouter>
            </ModalsProvider>
          </AuthProvider>
        </InfoProvider>
      </MantineProvider>
    </div>
  );
}

export default App;
