

export function labelFrom(label: string, prefix?: string) {
    // Remove spaces, dashes, and underscores, and split the label into words
    const words = label.toLowerCase().split(/[\s\-_]+/);
    // Capitalize the first word and lowercase the rest to create camel case
    const camelCaseLabel = words
        .map((word, index) => (index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)))
        .join('');

    // Add the prefix (if provided)
    if (prefix) {
        return prefix + camelCaseLabel;
    }

    return camelCaseLabel;
}