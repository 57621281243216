import { Modal, useMantineTheme } from "@mantine/core";
import { ReactNode } from "react";

export type SfyModalProps = {
    children?: ReactNode
    opened?: boolean
    onClose?: () => void
    title?: string
    simple?: boolean
    overlay?: boolean
    closeButton?: boolean
    type?: 'normal' | 'graph'
}

export default function SfyModal({ children, opened, onClose, title, simple, overlay, closeButton = true, type } : SfyModalProps) {
    const theme = useMantineTheme();

    const styles = {
        inner: {
            ...(type === 'graph' ? {
                top: theme.other.headerHeight,
                left: theme.other.graph.navigatorWidth,
                maxWidth: theme.other.graph.contentWidth,
            } : {})
        },
        content: {
            ...(type === 'graph' ? {
                maxHeight: theme.other.contentHeight,
            } : {}),
        },
        title: {
            fontSize: '1.7rem',
            color: '#12b886',
            fontWeight: 200
        },
        body: {
            padding: '40px 80px',
        },
        header: {
            padding: '40px 80px',
            paddingBottom: 20,
        }
    }
    return <Modal
        opened={opened || false} 
        title={<>
            {title}
            <div id="sfy-modal-header"></div>
        </>}
        overlayProps={!overlay ? { opacity: 0 } : undefined}
        onClose={onClose || (() => {})}
        styles={!simple ? styles : { body: {padding: '0'}}}
        withOverlay={overlay}
        withCloseButton={closeButton}
        fullScreen
        transitionProps={{
            transition: 'slide-left',
            duration: 600,
            exitDuration: 600,
        }}
    >
        {children}
    </Modal>
}