
import { useContainer } from "@/components/containers/ContainerProvider";
import SfyForm from "@/components/form/SfyForm";
import FormTextInput from "@/components/form/inputs/FormTextInput";
import { OpsTenantsApi } from "@/libs/client";
import { doResponse } from "@/libs/utils/response";
import { useForm } from "@mantine/form";
import { useEffect } from "react";

type AddTenantProps = {
    onRefresh: () => void;
}

export function AddTenant({ onRefresh } : AddTenantProps) {
    const { closeAllModals } = useContainer();
    const form = useForm({
        initialValues: {
            name: '',
            displayName: '',
        },
        validate:{
            name: (v) => !v.length ? 'This is a required field' : null,
        }
    })
    useEffect(() => {
        const fixedName = form.values.name.toLowerCase()
            .replace(/[^a-z0-9_-]/g, '');
        if (fixedName == form.values.name) {
            form.setFieldValue('name', fixedName);
        }
    }, [form.values])
    
    return <SfyForm 
        onClose={closeAllModals}
        onSubmit={async () => {
            return await doResponse(new OpsTenantsApi().createTenant({
                ...form.values,
            }), onRefresh)
        }}
        disabled={!form.isValid()}
    >
        <FormTextInput
            label="Name"
            required
            description="Unique name for the tenant you want to create"
            {...form.getInputProps('name')}
        ></FormTextInput>
        <FormTextInput
            label="Display Name"
            description="Unique name for the tenant you want to create"
            {...form.getInputProps('displayName')}
        ></FormTextInput>
    </SfyForm>
}