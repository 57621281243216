export function deepEqual(obj1: any, obj2: any): boolean {
    if (obj1 === obj2) {
      return true;
    }
  
    if (typeof obj1 !== typeof obj2) {
      return false;
    }
  
    if (typeof obj1 !== 'object' || obj1 === null || obj2 === null) {
      return false;
    }
  
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
  
    if (keys1.length !== keys2.length) {
      return false;
    }
  
    for (const key of keys1) {
      if (!keys2.includes(key)) {
        return false;
      }
  
      if (!deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }
  
    return true;
}
  

export function deepCopy<T>(obj: T): T {
    if (obj === null || typeof obj !== 'object') {
      return obj;
    }
  
    if (Array.isArray(obj)) {
      const arrCopy: any[] = [];
      for (const item of obj) {
        arrCopy.push(deepCopy(item));
      }
      return arrCopy as T;
    }
  
    if (typeof obj === 'object') {
      const objCopy: Record<string, any> = {};
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          objCopy[key] = deepCopy(obj[key]);
        }
      }
      return objCopy as T;
    }
  
    return obj;
}

export function shallowChangedFields<T>(original: T, updated: T): Partial<T> {
  const changedFields: any = {};
  const keys = [
    ...Object.keys(original as any),
    ...Object.keys(updated as any),
  ]
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i]
    if (!deepEqual((original as any)[key], (updated as any)[key])) {
      changedFields[key] = (updated as any)[key];
    }
  }

  return changedFields as Partial<T>;
}
