import { DataEdge, DataEdgePoint } from "@/libs/client";
import { Group, Stack, ThemeIcon } from "@mantine/core";
import { PiWarningFill } from "react-icons/pi";

type EdgeWarning = {
    edge: DataEdge
    missing: DataEdgePoint[]
}

export function buildEdgeWarnings(): EdgeWarning[] {
    /*
    TODO
    const nodesMap = groupBy(dataGraph.nodes || [], n => n.id!)
    return dataGraph.edges
        ?.map(e => ({
            edge: e,
            missing: getMissingPoints(nodesMap, e),
        }))?.filter(e => e.missing.length > 0) || []*/
    return []
}

/**
function getMissingPoints(nodesMap: Map<string, DataNode>, edge: DataEdge) {
    const missingNodes: DataEdgePoint[] = []
    if (!nodesMap.has(edge.source?.id!)) {
        missingNodes.push(edge.source!)
    }
    if (!nodesMap.has(edge.target?.id!)) {
        missingNodes.push(edge.target!)
    }
    return missingNodes
}
 */
function WarningRow({ warning }: { warning: EdgeWarning }) {
    return <Group style={{marginTop: 10, padding: '1rem 2rem', border: '1px solid #e0e0e0', borderRadius: 4}}>
        <ThemeIcon color="yellow" variant="light">
            <PiWarningFill size="2rem"></PiWarningFill>
        </ThemeIcon>
        <Stack gap={3} style={{flex: 1}}>
            <div style={{fontSize: '0.95rem', color: '#5f5f5f', paddingRight: '0.5rem'}}>
                <strong>For Edge: </strong>
                {warning.edge.source?.type}{'-'}{warning.edge.name}{'->'}{warning.edge.target?.type}
            </div>
            <div style={{fontSize: '0.75rem', wordBreak: 'break-all', color: '#a0a0a0'}}>
                <strong>Missing Node(s):</strong>
            </div>
            <ul style={{fontSize: '0.75rem', wordBreak: 'break-all', color: '#a0a0a0', marginTop: 0}}>
                {warning.missing.map(ep => <li><strong>{ep.type}:</strong> {ep.oid}</li>)}
            </ul>
        </Stack>
    </Group>
}


export function DataGraphWarnings({ warnings }: { warnings: EdgeWarning[] }) {
    return <>
        {warnings.map(w => <WarningRow warning={w}></WarningRow>)}
    </>
}