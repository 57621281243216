import filter_desc from "./filter_desc";
import filter_nodes_desc from "./filter_nodes/filter_nodes_desc";
//import transform_desc from "./transform_desc";
//import traversal_desc from "./traversal_desc";

const actions_desc_list = [
    filter_nodes_desc,
    filter_desc,
    //transform_desc,
    //traversal_desc,
]
export default actions_desc_list;