import { useContainer } from "@/components/containers/ContainerProvider";
import EntityPage from "@/components/page/EntityPage";
import { useTitle } from "@/libs/hooks/useTitle";
import { ActionIcon, Card, Group, SimpleGrid, Stack, Switch, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import EnableSso from "./actions/EnableSso";
import { openConfirmModal } from "@mantine/modals";
import { SsoProviderProviderEnum, SsoProvidersApi } from "@/libs/client";
import { useEffect, useState } from "react";
import { FaCheck, FaTimes } from "react-icons/fa";
import { BiEdit } from "react-icons/bi";

type AuthenticationCardProps = {
    provider?: SsoProviderProviderEnum;
    logoPath: string;
    title: string;
    description: string;
    disabled?: boolean;
    ssosMap: { [key: string]: boolean };
    refreshSsosMap: () => void;
}

export function AuthenticationCard({ provider, logoPath, title, description, disabled, ssosMap, refreshSsosMap } : AuthenticationCardProps) {
    const { openModal, closeAllModals } = useContainer();
    const [checked, setChecked] = useState(!provider || ssosMap[provider.toString()])
    useEffect(() => {
        if (provider) {
            setChecked(!!ssosMap[provider.toString()])
        }
    }, [ssosMap])
    const [successEnableModal, setSuccessEnabledModal] = useState(false)
    return <Card shadow="sm" padding="lg" radius="md" withBorder>
        <Card.Section withBorder style={{ padding: '1.5rem 0rem', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <img style={{height: 80}} src={logoPath}></img> 
            <Group gap={6} style={{position: 'absolute', top: 5, right: 5}} >
            <Switch checked={checked} color="sfy-green" 
                disabled={!provider}
                thumbIcon={checked ? <FaCheck color="#0bb30b"></FaCheck> : <FaTimes color="#da0c0c"></FaTimes>}
                onChange={(event) => {
                    if (disabled || ! provider) {
                        return;
                    }
                    setChecked(!checked)
                    setSuccessEnabledModal(false)
                    if (event.currentTarget.checked) {
                        openModal('enable_sso', <EnableSso provider={provider} onSuccess={() => {
                            setSuccessEnabledModal(true)
                        }} onClose={() => {
                            closeAllModals();
                            refreshSsosMap();
                        }} update={false}></EnableSso>, {
                            title: 'Enable ' + title,
                            onClose: () => {
                                if (!successEnableModal) {
                                    setChecked(false)
                                }
                            },
                        })
                    } else {
                        openConfirmModal({
                            title: 'Please confirm your action',
                            children: (
                            <Text size="sm">
                                This action is so important that you are required to confirm it with a modal. Please click
                                one of these buttons to proceed.
                            </Text>
                            ),
                            labels: { confirm: 'Confirm', cancel: 'Cancel' },
                            onCancel: () => {
                                refreshSsosMap();
                            },
                            onConfirm: async () => { 
                                await new SsoProvidersApi().disableSsoProvider(provider)
                                refreshSsosMap();
                            },
                        })
                    }
                }}
            ></Switch>
            {provider && checked && <ActionIcon variant="subtle" onClick={() =>   openModal('update_sso', <EnableSso provider={provider} update onClose={() => {
                closeAllModals();
                refreshSsosMap();
            }} ></EnableSso>, {
                title: 'Update ' + title,
            })}><BiEdit></BiEdit></ActionIcon> }
        </Group>
        </Card.Section>
        <Card.Section style={{padding: '1rem'}}>
            <Stack gap={2}>
                <div style={{ fontWeight: 500}}>{title}</div>
                <div style={{fontSize: '0.8rem', color: '#a0a0a0'}}>{description}</div>
            </Stack>
        </Card.Section>
    </Card>
}


export default function AuthenticationLanding() {
    const { t } = useTranslation();
    useTitle(t("Authentication"))
    const [ssosMap, setSsosMap] = useState<{ [key: string]: boolean }>({});
    const [loading, setLoading] = useState(true);
    const refreshSsosMap = async () => {
        const resp = await new SsoProvidersApi().ssoProviders()
        setSsosMap(resp.data.reduce((acc, cur) => {
            acc[cur.provider!.toString()] = !!cur.enabled
            return acc
        }, {} as { [key: string]: boolean }))
    }
    useEffect(() => {
        refreshSsosMap()
        setLoading(true)
    }, [])
    return loading && <EntityPage
        category={t('common.security')}
        title={t("Authentication")}>
        <SimpleGrid cols={4}>
        <AuthenticationCard
                title="Local Authentication"
                logoPath="/img/local_auth.svg"
                description="Choose traditional sign-in security to safeguard your Sentryfy account with a unique username and password."
                disabled
                ssosMap={ssosMap}
                refreshSsosMap={refreshSsosMap}
            ></AuthenticationCard>
            <AuthenticationCard
                provider={SsoProviderProviderEnum.Microsoft}
                title="Microsoft Authentication"
                logoPath="/img/microsoft_logo_light.svg"
                description="Elevate your Sentryfy experience with seamless access – enable Microsoft Single Sign-On for one-click sign-in using your Microsoft credentials."
                ssosMap={ssosMap}
                refreshSsosMap={refreshSsosMap}
            ></AuthenticationCard>
        </SimpleGrid>
    </EntityPage>
}