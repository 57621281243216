import { Select, SelectProps } from "@mantine/core"
import { useEffect } from "react"


type FormSelectProps = SelectProps & {
}

export default function FormSelect(props: FormSelectProps) {
    useEffect(() => {
        if (props.defaultValue !== undefined && props.defaultValue !== null && props.onChange) {
            props.onChange(props.defaultValue, null as any)
        }
    }, [])
    return <Select
        {...props}
        styles={{
            ...props.styles,
        }}
    >
    </Select>
}
