import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import LoginLanding from "./login/LoginLanding";
import React, { ReactNode, useEffect } from "react";
import ApiDoc from "./docs/ApiDoc";
import CredentialsLanding from "./me/credentials/CredentialsLanding";
import Header from "./components/header/Header";
import GraphsLanding from "./graph/visualize/GraphsLanding";
import UsersLanding from "./admin/users/UsersLanding";
import ProfileLanding from "./me/ProfileLanding";
import NotFound from "./NotFound";
import { useAuth } from "./libs/auth/AuthProvider";
import AuditLanding from "./admin/audit/AuditLanding";
import WorkflowEditor from "./analysis/workflows/editor/WorkflowEditor";
import DeploymentsLanding from "./operator/deployments/DeploymentsLanding";
import AlertsLanding from "./admin/alerts/AlertsLanding";
import DataViewer from "./graph/visualize/render/data/viewer/DataViewer";
import { DataEditor } from "./graph/visualize/render/data/editor/DataEditor";
import SchemaViewer from "./graph/visualize/render/schema/viewer/SchemaViewer";
import SchemaEditor from "./graph/visualize/render/schema/editor/SchemaEditor";
import IntegrationsLanding from "./collection/integrations/IntegrationsLanding";
import WorkflowsList from "./analysis/workflows/WorkflowsList";
import InstalledIntegrationList from "./collection/integrations/installedIntegrations/InstalledIntegrationList";
import IntegrationList from "./collection/integrations/integrations/IntegrationList";
import ViewsLanding from "./graph/views/ViewsLanding";
import InstalledIntegrationLanding from "./collection/integrations/installedIntegrations/get/InstalledIntegrationLanding";
import MappingList from "./collection/integrations/installedIntegrations/get/mappings/MappingList";
import InstalledIntegrationRuns from "./collection/integrations/installedIntegrations/get/runs/InstalledIntegrationRuns";
import InstalledIntegrationDetails from "./collection/integrations/installedIntegrations/get/details/InstalledIntegrationDetails";
import { ProjectProvider, useProject } from "./libs/project/ProjectProvider";
import ProjectsLanding from "./admin/projects/ProjectsLanding";
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import AuthenticationLanding from "./admin/authentication/AuthenticationLanding";
import ScriptsList from "./analysis/scripts/ScriptsList";
import ScriptLanding from "./analysis/scripts/get/ScriptLanding";
import ScriptDetails from "./analysis/scripts/get/details/ScriptDetails";
import GeneratedReportLanding from "./insights/reports/generated_reports/get/GeneratedReportLanding";
import ViewLanding from "./graph/views/details/ViewLanding";
import ViewDetails from "./graph/views/details/ViewDetails";
import TenantsLanding from "./operator/tenants/TenantsLanding";
import { ContainerProvider } from "./components/containers/ContainerProvider";
import SysSettingsLanding from "./operator/sys_settings/SysSettingsLanding";
import SysGeneralSettings from "./operator/sys_settings/SysGeneralSettings";
import SysGithubSettings from "./operator/sys_settings/SysGithubSettings";
import SysLicenseSettings from "./operator/sys_settings/SysLicenseSettings";
import SysOpenAiSettings from "./operator/sys_settings/SysOpenAiSettings";
import SysCosmosDbSettings from "./operator/sys_settings/SysCosmosDbSettings";
import DataHistoryList from "./graph/history/DataHistoryList";
import SchemaHistoryList from "./graph/history/SchemaHistoryList";
import HistoryLanding from "./graph/history/HistoryLanding";
import InstalledIntegrationCommits from "./collection/integrations/installedIntegrations/get/commits/InstalledIntegrationCommits";
import TicketsLanding from "./insights/tickets/TIcketsLanding";
import TicketLanding from "./insights/tickets/get/TicketLanding";
import TicketDetails from "./insights/tickets/get/details/TicketDetails";
import TenantSettingsLanding from "./admin/settings/TenantSettingsLanding";
import TenantGeneralSettings from "./admin/settings/TenantGeneralSettings";
import TenantTicketSettings from "./admin/settings/TenantTicketSettings";
import { useInfo } from "./libs/info/InfoProvider";
import OpsCatalogsLanding from "./operator/catalogs/OpsCatalogsLanding";
import OpsCatalogLanding from "./operator/catalogs/get/OpsCatalogLanding";
import OpsCatalogDetails from "./operator/catalogs/get/details/OpsCatalogDetails";
import OpsCatalogIntegrations from "./operator/catalogs/get/integrations/OpsCatalogIntegrations";
import ScanTaskList from "./analysis/scans/installed_scans/scan_tasks/ScanTaskList";
import ScanTaskLanding from "./analysis/scans/installed_scans/scan_tasks/get/ScanTaskLanding";
import ScanTaskDetails from "./analysis/scans/installed_scans/scan_tasks/get/details/ScanTaskDetails";
import ScriptsLanding from "./analysis/scripts/ScriptsLanding";
import IssueList from "./insights/issues/IssueList";
import CampaignList from "./collection/dataforms/CampaignList";
import IssueLanding from "./insights/issues/get/IssueLanding";
import IssueDetails from "./insights/issues/get/details/IssueDetails";
import IntegrationSchemaEditor from "./operator/catalogs/get/integrations/IntegrationSchemaEditor";
import DashboardLanding from "./dashboard/DashboardLanding";
import PostureBoard from "./dashboard/boards/PostureBoard";
import VulnerabilityPrioritizationBoard from "./dashboard/boards/VulnerabilityPrioritizationBoard";
import ReportsLanding from "./insights/reports/ReportsLanding";
import ScansLandingPage from "./analysis/scans/ScansLandingPage";
import InstalledScansList from "./analysis/scans/installed_scans/InstalledScansList";
import AvailableScansList from "./analysis/scans/available_scans/AvailableScansList";
import InstalledScanLanding from "./analysis/scans/installed_scans/InstalledScanLanding";
import GeneratedReportsList from "./insights/reports/generated_reports/GeneratedReportsList";
import ReportLanding from "./insights/reports/get/ReportLanding";
import GeneratedReportDetails from "./insights/reports/generated_reports/get/details/GeneratedReportDetails";
import DeploymentLanding from "./operator/deployments/get/DeploymentLanding";
import DeploymentDetails from "./operator/deployments/get/DeploymentDetails";
import DeploymentSecretKeys from "./operator/deployments/get/DeploymentSecretKeys";
import ScanCategories from "./analysis/scans/installed_scans/ScanCategories";
import DeploymentEvents from "./operator/deployments/get/DeploymentEvents";
import SysSlackNotifierSettings from "./operator/sys_settings/SysSlackNotifierSettings";
import OpsCatalogScans from "./operator/catalogs/get/scans/OpsCatalogScans";
import OpsCatalogConditions from "./operator/catalogs/get/scans/OpsCatalogConditions";
import ControlsLanding from "./analysis/scans/installed_scans/controls/ControlsLanding";
import ControlLanding from "./analysis/scans/installed_scans/controls/get/ControlLanding";
import ControlDetails from "./analysis/scans/installed_scans/controls/details/ControlDetails";
import ControlParameters from "./analysis/scans/installed_scans/controls/details/ControlParameters";

function TenantSection() {
    const { isOperator } = useAuth()
    if (isOperator()) {
        return <Navigate to={'/ops'} replace={true} />
    }
    return <Outlet />
}

function OperatorSection() {
    const { isOperator } = useAuth()
    if (!isOperator()) {
        return <Navigate to={'/'} replace={true} />
    }
    return <Outlet />
}

function AppRoutes() {
    const { isSaas } = useInfo()
    const location = useLocation()
    useEffect(() => {
        window.dispatchEvent(new Event('locationchange'))
    }, [location])
    return <Routes>
        <Route path="ops">
            <Route path="api-docs" element={<ApiDoc ops={true}></ApiDoc>}></Route>
            <Route element={ <AuthPage /> }>
                <Route element={ <OperatorSection />}>
                    <Route path="" element={<Navigate to="tenants" replace={true} />}></Route>
                    <Route path="tenants" element={<TenantsLanding />}></Route>
                    { isSaas() ? <Route path="deployments">
                        <Route path="" element={<DeploymentsLanding></DeploymentsLanding>}></Route>
                        <Route path=":id" element={<DeploymentLanding></DeploymentLanding>}>
                            <Route path="" element={<Navigate to="details" replace={true} />}></Route>
                            <Route path="details" element={<DeploymentDetails />}></Route>
                            <Route path="secrets" element={<DeploymentSecretKeys />}></Route>
                            <Route path="events" element={<DeploymentEvents />}></Route>
                        </Route>
                    </Route> : <></>}
                    <Route path="catalogs">
                        <Route path="" element={<OpsCatalogsLanding></OpsCatalogsLanding>}></Route>
                        <Route path=":id" element={<OpsCatalogLanding></OpsCatalogLanding>}>
                            <Route path="" element={<Navigate to="details" replace={true} />}></Route>
                            <Route path="details" element={<OpsCatalogDetails />}></Route>
                            <Route path="integrations" element={<OpsCatalogIntegrations />}></Route>
                            <Route path="scans" element={<OpsCatalogScans />}></Route>
                            <Route path="conditions" element={<OpsCatalogConditions />}></Route>
                        </Route>
                        <Route path=":id/integrations/:integrationId/schema/edit" element={<IntegrationSchemaEditor />}></Route>
                    </Route>
                    <Route path="settings">
                        <Route path="" element={<Navigate to="general" replace={true} />}></Route>
                        <Route element={<SysSettingsLanding />}>
                            <Route path="general" element={<SysGeneralSettings />}></Route>
                            <Route path="license" element={<SysLicenseSettings />}></Route>
                            <Route path="slack" element={<SysSlackNotifierSettings />}></Route>
                            <Route path="cosmosdb" element={<SysCosmosDbSettings />}></Route>
                            <Route path="github" element={<SysGithubSettings />}></Route>
                            <Route path="openai" element={<SysOpenAiSettings />}></Route>
                        </Route>
                    </Route>
                    <Route path="404" element={<NotFound></NotFound>}></Route>
                    <Route path="*" element={<Navigate to="/404" replace={true} />}></Route>
                </Route>
            </Route>
        </Route>
        <Route path="" element={ <Outlet /> }>
            <Route element={<ContainerProvider><Outlet></Outlet></ContainerProvider>}>
                <Route path="api-docs" element={<ApiDoc ops={false}></ApiDoc>}></Route>
                { window.location.host.startsWith("app.") ? <Route path="*" element={<LoginLanding findTenant={true} />} /> :
                <Route path="login" element={<Outlet></Outlet>}>
                    <Route path="find-tenant/*" element={<RedirectIfLoggedIn><LoginLanding findTenant={true} /></RedirectIfLoggedIn>}></Route>  
                    <Route path="" element={<RedirectIfLoggedIn><LoginLanding /></RedirectIfLoggedIn>}></Route>      
                    <Route path="*" element={<RedirectIfLoggedIn><LoginLanding /></RedirectIfLoggedIn>}></Route>    
                </Route> }
            </Route>
            {authRoutes()}
        </Route>
    </Routes>
}

function CheckAuthRoute(params: { children: ReactNode, auth: boolean, redirect: string }) {
    const redirectIf = !params.auth;
    const { userCtx } = useAuth()
    return <React.Fragment>
        { redirectIf === userCtx.loggedIn ? <Navigate to={params.redirect} replace={true} /> : params.children }
    </React.Fragment>
}

function RedirectIfLoggedIn(params: {children: ReactNode}) {
    return <CheckAuthRoute auth={false} redirect={"/"}>{params.children}</CheckAuthRoute>
}

function AuthenticatedRoute(params: {children: ReactNode}) {
    const location = useLocation()
    return <CheckAuthRoute auth={true} redirect={`/login` + location.pathname + location.search}>{params.children}</CheckAuthRoute>
}

function AuthPage() {
    return <AuthenticatedRoute>
        <ProjectProvider>
            <ContainerProvider>
                <Header loggedIn={true}></Header>
                <ToastContainer />
                <Outlet />
            </ContainerProvider>
        </ProjectProvider>
    </AuthenticatedRoute>
}

function ProjectRedirecter() {
    const { projectUrl, projects } = useProject()
    if (!projects.length) {
        return <Navigate to={"/admin/projects"} replace={true} />
    }
    return <Navigate to={projectUrl('/dashboard')} replace={true} />
}   

function authRoutes() {
  return <Route element={ <AuthPage />}>
    <Route element={<TenantSection/>}>
        <Route path="" element={<ProjectRedirecter></ProjectRedirecter>}></Route>
        <Route path="p/:projectName" element={<Outlet></Outlet>}>
            <Route path="" element={<ProjectRedirecter></ProjectRedirecter>}></Route>
            <Route path="dashboard" element={<DashboardLanding></DashboardLanding>}>
                <Route path="" element={<Navigate to="vulnerability"></Navigate>}></Route>
                <Route path="vulnerability" element={<VulnerabilityPrioritizationBoard></VulnerabilityPrioritizationBoard>}></Route>
                <Route path="posture" element={<PostureBoard></PostureBoard>}></Route>
            </Route>
            <Route path="data" element={<Outlet></Outlet>}>
                <Route path="" element={<Outlet></Outlet>}>
                    <Route path="views" element={<ViewsLanding />}>
                    </Route>
                    <Route path="graph" element={<GraphsLanding></GraphsLanding>}>
                        <Route path="" element={<DataViewer />}></Route>
                        <Route path="edit" element={<DataEditor />}></Route>
                        <Route path="schema" element={<SchemaViewer />}></Route>
                        <Route path="schema/edit" element={<SchemaEditor />}></Route>
                    </Route>
                    <Route path="views/:id" element={<ViewLanding />}>
                        <Route path="" element={<Navigate to="details" replace={true} />}></Route>
                        <Route path="details" element={<ViewDetails />}></Route>
                    </Route>
                    <Route path="commits">
                        <Route path="" element={<HistoryLanding />}>
                            <Route path="" element={<Navigate to="data" replace={true} />}></Route>
                            <Route path="data" element={<DataHistoryList />}></Route>
                            <Route path="schema" element={<SchemaHistoryList />}></Route>
                        </Route>
                    </Route>
                </Route>
            </Route>
            <Route path="collection" element={<Outlet />}>
                <Route path="integrations" element={<Outlet />}>
                    <Route path="" element={<IntegrationsLanding />}>
                        <Route path="" element={<Navigate to="installed" replace={true} />}></Route>
                        <Route path="installed" element={<InstalledIntegrationList />}></Route>
                        <Route path="library" element={<IntegrationList />}></Route>
                    </Route>
                    <Route path="installed/:id" element={<InstalledIntegrationLanding />}>
                        <Route path="" element={<Navigate to="details" replace={true} />}></Route>
                        <Route path="details" element={<InstalledIntegrationDetails />}></Route>
                        <Route path="commits" element={<InstalledIntegrationCommits />}></Route>
                        <Route path="runs" element={<InstalledIntegrationRuns />}></Route>
                        <Route path="mappings" element={<MappingList />}></Route>
                    </Route>
                </Route>
                <Route path="campaigns" element={<Outlet></Outlet>}>
                    <Route path="" element={<CampaignList></CampaignList>}></Route>
                </Route>
            </Route>
            <Route path="analysis" element={<Outlet />}>
                <Route path="" element={<Outlet />}>
                    <Route path="" element={<Navigate to="scans" replace={true} />}></Route>
                    <Route path="scans" element={<ScansLandingPage />}>
                        <Route path="" element={<Navigate to="installed" replace={true} />}></Route>
                        <Route path="installed" element={<ScanCategories />}></Route>
                        <Route path="available" element={<AvailableScansList />}></Route>
                    </Route>
                </Route>
                <Route path="scans/installed/:category" element={<InstalledScansList />}>
                </Route>
                <Route path="scans/installed/:category/:id" element={<InstalledScanLanding />}>
                    <Route path="" element={<Navigate to="scan_tasks" replace={true} />}></Route>
                    <Route path="scan_tasks" element={<ScanTaskList />}></Route>
                    <Route path="controls" element={<ControlsLanding />}></Route>
                </Route>
                <Route path="scans/installed/:id/scan_tasks/:taskId" element={<ScanTaskLanding />}>
                    <Route path="" element={<Navigate to="details" replace={true} />}></Route>
                    <Route path="details" element={<ScanTaskDetails />}></Route>
                </Route>
                <Route path="scans/installed/:id/controls/:controlId" element={<ControlLanding />}>
                    <Route path="" element={<Navigate to="details" replace={true} />}></Route>
                    <Route path="details" element={<ControlDetails />}></Route>
                    <Route path="parameters" element={<ControlParameters />}></Route>
                </Route>
                <Route path="scripts" element={<ScriptsLanding />}>
                    <Route path="" element={<Navigate to="reports" replace={true} />}></Route>
                    <Route path="reports" element={<ScriptsList key="REPORT" type="REPORT" />}></Route>
                    <Route path="conditions" element={<ScriptsList key="CONDITION" type="CONDITION" />}></Route>
                </Route>
                <Route path="scripts/:id" element={<ScriptLanding />}>
                    <Route path="" element={<Navigate to="details" replace={true} />}></Route>
                    <Route path="details" element={<ScriptDetails />}></Route>
                </Route>
                <Route path="workflows" element={<Outlet />}>
                    <Route path="" element={<WorkflowsList />}></Route>
                    <Route path="add" element={<WorkflowEditor />}></Route>
                    <Route path=":id/edit" element={<WorkflowEditor />}></Route>
                </Route>
            </Route>
            <Route path="insights" element={<Outlet />}>
                <Route path="" element={<Navigate to="reports" replace={true} />}></Route>
                <Route path="reports" element={<ReportsLanding />}></Route>
                <Route path="reports/:id" element={<ReportLanding />}>
                    <Route path="" element={<Navigate to="generated_reports" replace={true} />}></Route>
                    <Route path="generated_reports" element={<GeneratedReportsList />}></Route>
                </Route>
                <Route path="reports/:id/generated_reports/:gid" element={<GeneratedReportLanding />}>
                    <Route path="" element={<Navigate to="details" replace={true} />}></Route>
                    <Route path="details" element={<GeneratedReportDetails />}></Route>
                </Route>
                <Route path="issues" element={<IssueList />}></Route>
                <Route path="issues/:id" element={<IssueLanding />}>
                    <Route path="" element={<Navigate to="details" replace={true} />}></Route>
                    <Route path="details" element={<IssueDetails />}></Route>
                </Route>
                <Route path="tickets" element={<TicketsLanding></TicketsLanding>}></Route>
                <Route path="tickets/:id" element={<TicketLanding />}>
                    <Route path="" element={<Navigate to="details" replace={true} />}></Route>
                    <Route path="details" element={<TicketDetails />}></Route>
                </Route>
            </Route>
        </Route>
        <Route path="admin" element={<Outlet></Outlet>}>
            <Route path="" element={<Navigate to="/404" replace={true} />}></Route>
            <Route path="users" element={<UsersLanding />}></Route>
            <Route path="projects" element={<ProjectsLanding />}></Route>
            <Route path="authentication" element={<AuthenticationLanding />}></Route>
            <Route path="settings">
                <Route path="" element={<Navigate to="general" replace={true} />}></Route>
                <Route element={<TenantSettingsLanding />}>
                    <Route path="general" element={<TenantGeneralSettings />}></Route>
                    <Route path="tickets" element={<TenantTicketSettings />}></Route>
                </Route>
            </Route>
            <Route path="alerts" element={<AlertsLanding />}></Route>
            <Route path="audit_events" element={<AuditLanding />}></Route>
        </Route>
        <Route path="me" element={<Outlet></Outlet>}>
            <Route path="" element={<Navigate to="/404" replace={true} />}></Route>
            <Route path="profile" element={<ProfileLanding></ProfileLanding>}></Route>
            <Route path="credentials" element={<CredentialsLanding></CredentialsLanding>}></Route>
        </Route>
        <Route path="404" element={<NotFound></NotFound>}></Route>
        <Route path="*" element={<Navigate to="/404" replace={true} />}></Route>
    </Route>
  </Route>
}

export default AppRoutes;