import { useTranslation } from "react-i18next";
import { useTitle } from "@/libs/hooks/useTitle";
import EntityPage from "@/components/page/EntityPage";
import { useParams } from "react-router-dom";
import { useProject } from "@/libs/project/ProjectProvider";
import { useCallback, useEffect, useState } from "react";
import { Report, ReportsApi } from "@/libs/client";

export default function ReportLanding() {
    const { id } = useParams()
    const { projectUrl } = useProject()
    const { t } = useTranslation()
    const [report, setReport] = useState<Report>()

    const fetchReport = useCallback(async () => {
        setReport((await new ReportsApi().report(id!)).data)
    }, [])

    useEffect(() => {
        fetchReport()
    }, [])

    useTitle(report?.name + " - " + t("reports.label", { count: 2 }))
    return report && <EntityPage
        category={t('reports.label', { count: 2 })}
        title={report.name || ''}
        tabs={[{
            key: "generated",
            route: projectUrl(`/insights/reports/${id}/generated_reports`),
            label: t('generated_reports.label', { count: 2 })
        }]}
        breadcrumbs={[
            {
                key: 'reports',
                label: t('reports.label', { count: 2 }),
                link: projectUrl('/insights/reports'),
            },
            {
                key: 'name',
                label: report.name!,
            }
        ]}>
    </EntityPage>
}