import { getWorkflowNodeType } from "../descriptors/descriptor";
import ActionBlock from "./ActionBlock";
import ControlFlowBlock from "./ControlFlowBlock";
import InputBlock from "./InputBlock";
import OutputBlock from "./OutputBlock";
import { WorkflowNodeProps } from "./WorkflowNodeTemplate";

export default function WorkflowNode(props: WorkflowNodeProps) {
    const blockType = getWorkflowNodeType(props.descriptorId)
    if (blockType === 'inputs') {
        return <InputBlock {...props}></InputBlock>
    } else if (blockType === 'outputs') {
        return <OutputBlock {...props}></OutputBlock>
    } else if (blockType === 'control_flows') {
        return <ControlFlowBlock {...props}></ControlFlowBlock>
    } else if (blockType === 'actions') {
        return <ActionBlock {...props}></ActionBlock>
    }
    return <div>{blockType} {JSON.stringify(props)}</div>
}