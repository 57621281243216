import { ReportsApi } from "@/libs/client"
import { useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import Spreadsheet from "react-spreadsheet"
import { parse } from 'csv-parse/browser/esm/sync';

export default function GeneratedReportDetails() {
    const { gid } = useParams()
    const [reportCsv, setReportCsv] = useState<string>()
    const formattedCsv = useMemo(() => {
        return parse(reportCsv || '', {
            skip_empty_lines: true
        })  as string[][];
    }, [reportCsv])
    const fetch = async () => {
        const res = await new ReportsApi().downloadGeneratedReport(gid!)
        if (res.status == 200) {
            setReportCsv(res.data.toString());
        } else {
            //TODO
        }
    }
    useEffect(() => {
        fetch();
    }, [])
    return reportCsv && <div style={{minWidth: '100%', minHeight: '250px', marginBottom: 20, maxWidth: '100%', overflowX: 'scroll', paddingTop: 5, paddingLeft: 5,
        paddingRight: 5, backgroundColor: '#fcfcfc', border: '1px solid #f0f0f0'}}>
        <Spreadsheet data={formattedCsv.slice(1).map(s => s.map(value => ({ value })))} columnLabels={formattedCsv[0]} />
    </div>
}