import FormTextInput from "@/components/form/inputs/FormTextInput";
import { StepContentProps } from "@/components/form/MultiStepForm";

type EntitySetupFormProps = {
    update?: boolean;
} & StepContentProps

export default function EntitySetupForm({ form, update } : EntitySetupFormProps) {
    return <form style={{display: 'flex'}}>
        <div style={{flex: 1, maxWidth: 550}}>
            <FormTextInput
            placeholder="e.g. Asset"
            label="Entity Name"
            description="Unique name for the Entity"
            withAsterisk
            disabled={update}
            {...form.getInputProps('type')}
            />
            <FormTextInput
            placeholder="e.g. Holds some information"
            label="Description"
            description="Describes the Entity"
            {...form.getInputProps('description')}
            />
            <FormTextInput
            placeholder="e.g. ${name}"
            label="Display Label"
            description="Label that will be used for a vertex of this entity. Keyword-templating support. Defaults to ${id}."
            {...form.getInputProps('displayLabel')}
            />
            <FormTextInput
            placeholder="e.g. CMDB"
            label="Source"
            description="Where does this Entity come from"
            {...form.getInputProps('source')}
            />
        </div>
    </form>
}