import { sortBy } from "@/libs/utils/sort";
import { WorkflowNodeContext, WorkflowNodeDescriptor, WorkflowNodeOutput, WorkflowNodeValues } from "../descriptor";

const for_each_desc: WorkflowNodeDescriptor = {
    descriptorId: 'control_flows/for_each',
    getOutput: (ctx: WorkflowNodeContext, _: WorkflowNodeValues): (WorkflowNodeOutput | { invalid: boolean }) => {
        if (Object.values(ctx.inputs).find(e => !e.many)) {
            return { invalid: true }
        }
        return { 
                types: Object.entries(ctx.inputs)
                    .sort(sortBy(e => e[0]))
                    .flatMap(e => e[1].types), 
                many: false 
            }
        },
}

export default for_each_desc;