import { useEffect, useState } from "react";
import CatalogForm from "./CatalogForm";
import { Catalog, CatalogsApi } from "@/libs/client";

export default function UpdateCatalog({ onRefresh, id } : { id: string, onRefresh: () => void }) {
    const [catalog, setCatalog] = useState<Catalog>()
    const fetchCatalog = async () => {
        const catalog = (await (new CatalogsApi().catalog(id))).data
        setCatalog(catalog)
    }
    useEffect(() => {
        fetchCatalog()
    }, [])
    return catalog && <CatalogForm onRefresh={onRefresh} catalog={catalog}></CatalogForm>
}