import { useTranslation } from "react-i18next";
import { DisplayElemSize } from "@/components/table/EntityTable";
import { useTitle } from "@/libs/hooks/useTitle";
import EntityPage from "@/components/page/EntityPage";
import ListSection, { ListSectionCallbacks } from "@/components/page/sections/ListSection";
import { View, ViewsApi } from "@/libs/client";
import { useRef } from "react";
import AddView from "./actions/AddView";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useContainer } from "@/components/containers/ContainerProvider";
import { ActionResultType } from "@/components/actions/actions";
import { useProject } from "@/libs/project/ProjectProvider";
import { useNavigate } from "react-router-dom";
import UpdateView from "./actions/UpdateView";

export default function ViewsLanding() {
    const { t } = useTranslation()
    const { curProject, projectUrl } = useProject();
    const listRef = useRef<ListSectionCallbacks>()
    const navigate = useNavigate()

    const { closeAllModals } = useContainer()

    const { openModal, openConfirm } = useContainer();
    useTitle(t("Views"))
    const headers = [
        t('common.name'),
    ];
    return <EntityPage
        category={t('Queries')}
        title={t('Views')}
    >
        <ListSection<View>
                headers={headers}
                callbacks={listRef}
                renderRow={(e) => ({
                    cols: [
                        {
                            display: [{
                                label: e.name || '',
                                size: DisplayElemSize.lg,
                            }],
                        },
                    ],
                })}
                load={async () => {
                    return {
                        list: (await new ViewsApi().views(curProject.id!)).data
                    }
                }}
                addLabel={t('Add View')}
                onAdd={() => openModal('add_view', <AddView projectId={curProject.id!} onClose={() => {
                    listRef.current?.refresh()
                    closeAllModals()
                }}></AddView>, {
                    title: t('Add View') || ''
                })}
                onRowClick={b => navigate(projectUrl(`/data/views/${b.id}`))}
                rowActions={(v) => {
                    return [{
                        label: 'Update',
                        icon: <FaEdit></FaEdit>,
                        execute: (v) => {
                            openModal('update_view', <UpdateView view={v} onClose={() => {
                                listRef.current?.refresh()
                                closeAllModals()
                            }}></UpdateView>, {
                                title: t('Update View') || ''
                            }) 
                        }
                    }, {
                        label: 'Delete',
                        icon: <FaTrash></FaTrash>,
                        execute: async () => {
                            openConfirm('delete_View', {
                                title: 'Delete View',
                                confirmationText: <>Are you sure you want to delete the view <b>{v.name}</b>?</>,
                                cancelText: "No don't delete",
                                submitText: "Yes, Delete",
                                confirmInput: {
                                    data: v.name || '',
                                    placeholder: 'Please enter the view name to delete'
                                },
                                color: 'red',
                                onSubmit: async () => {
                                    const resp = await new ViewsApi().deleteView(v.id!)
                                    if (resp.status === 204) {
                                        listRef.current?.refresh();
                                        return { type: ActionResultType.SUCCESS }
                                    }
                                    return { type: ActionResultType.FAILURE }
                                }
                            })
                        }
                    }]
                }}
                emptyLabel={t('No views yet')}
            ></ListSection>
    </EntityPage>
}