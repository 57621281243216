import { ActionResultType } from "@/components/actions/actions";
import SfyForm from "@/components/form/SfyForm";
import FormTextInput from "@/components/form/inputs/FormTextInput";
import { buildCallbackUrl } from "@/libs/auth/sso";
import { SsoProviderProviderEnum, SsoProvidersApi } from "@/libs/client";
import { Button, CopyButton, Stack, Switch } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useEffect } from "react";

type EnableSsoProps = {
    provider?: SsoProviderProviderEnum;
    onClose: () => void;
    onSuccess?: () => void;
    update: boolean
}

export default function EnableSso({ provider, onClose, update, onSuccess }: EnableSsoProps) {
    const form = useForm({
        initialValues: {
            clientId: '',
            clientSecret: '',
            autoApproval: false,
            customOptions: {
                tenantId: '',
                groups: []
            } as any,
        },
        validate: {
            clientId: (str) => !str,
            clientSecret: (str) => !str,
            customOptions: {
                tenantId: (str) => !str,
            },
        },
    });
    const fetchExisting = async () => {
        const resp = await new SsoProvidersApi().ssoProvider(provider!)
        form.setValues(resp.data)
    }
    useEffect(() => {
        if (update) {
            fetchExisting()
        }
    }, [])

    const doSubmit = async () => {
        if (provider) {
            if (update) { 
                await new SsoProvidersApi().updateSsoProvider(provider, {
                    provider,
                    ...form.values,
                })
            } else {
                await new SsoProvidersApi().enableSsoProvider(provider, {
                    provider,
                    ...form.values,
                })
            }
            if (onSuccess) {
                onSuccess()
            }
            return { type: ActionResultType.SUCCESS };    
        }
        return { type: ActionResultType.FAILURE };    
    }
    return <SfyForm onClose={onClose} onSubmit={doSubmit} disabled={!form.isValid()}> 
        <div style={{flex: 1, maxWidth: 550}}>
            <Stack style={{marginBottom: 25}} gap={2}>
                <label style={{textTransform: 'capitalize'}}>{provider?.toLowerCase()}</label>
                <div>
                    <img src={`/img/${provider?.toLowerCase()}_logo_light.svg`} style={{height: 50}}></img>
                </div>
            </Stack>
            <Stack gap={5}>
            <FormTextInput
                label="Client ID"
                description="Identifies your application with the OAuth provider"
                withAsterisk
                {...form.getInputProps('clientId')}
            />
            <FormTextInput
                label="Client Secret"
                description="Secret key for secure communication, known only to your application and the provider"
                withAsterisk
                sensitive
                {...form.getInputProps('clientSecret')}
            />
            <FormTextInput
                label="Tenant ID"
                description="A unique identifier for your organization in Azure, essential for authentication and authorization."
                withAsterisk
                {...form.getInputProps('customOptions.tenantId')}
            >
                
            </FormTextInput>
            <Switch
                style={{marginTop: 20}}
                label="Enable auto-approval of users"
                {...form.getInputProps('autoApproval')}
            ></Switch>
            { provider && <Stack style={{marginTop: 25}} gap={8}>
                <label>Callback URL</label>
                <CopyButton value={buildCallbackUrl(provider)}>
                    {({ copied, copy }) => (
                        <Button color={copied ? 'sfy-green' : 'blue'} onClick={copy}>
                            {copied ? 'Copied Callback URL' : 'Copy Callback URL'}
                        </Button>
                    )}
                </CopyButton>
                <div style={{fontSize: '0.8rem', color: '#a0a0a0'}}>
                    {buildCallbackUrl(provider)}
                </div>
            </Stack>}
            </Stack>
        </div>
    </SfyForm>
}