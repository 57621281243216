import MultiStepForm from "@/components/form/MultiStepForm";
import EntityFieldsForm from "./EntityFieldsForm";
import EntitySetupForm from "./EntitySetupForm";
import { useCallback } from "react";
import { CommitsApi, FieldChange, SchemaEntity } from "@/libs/client";
import { deepCopy, deepEqual, shallowChangedFields } from "@/libs/utils/obj";
import { useForm } from "@mantine/form";

type UpdateEntityProps = {
    updatableEntity: SchemaEntity;
    materializedEntity?: SchemaEntity;
    onClose: () => void;
    commitId: string;
    reloadCommit: () => void;
}

function getPartialEntity(original: SchemaEntity, updated: SchemaEntity): SchemaEntity {
    return {
        ...shallowChangedFields(original, updated),
        fields: undefined,
    }
}

function getFieldChanges(original: SchemaEntity, updated: SchemaEntity): { [key: string]: FieldChange } {
    const fieldChanges = Object.entries(updated.fields || {})
    .reduce((acc, cur) => {
        const ogf = original.fields![cur[0]]
        if (!ogf) {
            acc[cur[0]] = {
                type: 'ADD',
                field: cur[1],
            }
        } else if (!deepEqual(ogf, cur[1])) {
            acc[cur[0]] = {
                type: 'UPDATE',
                field: cur[1],
            }
        }
        return acc
    }, {} as { [key:string] : FieldChange })
    Object.keys(original.fields || {}).forEach(f => {
        if (!updated.fields![f]) {
            fieldChanges[f] = {
                type: 'REMOVE',
            }
        }
    })
    return fieldChanges
}

export default function UpdateEntity({ updatableEntity, onClose, commitId, reloadCommit, materializedEntity } : UpdateEntityProps) {  
    const form = useForm({
        initialValues: deepCopy(updatableEntity)
    })
    const onSubmit = useCallback(async () => {
        const partialEntity: Partial<SchemaEntity> = getPartialEntity(updatableEntity, form.values)
        const resp = await new CommitsApi().addChanges(commitId, [{
            updateEntity: {
                entity: { ...partialEntity, type: updatableEntity.type },
                fields: getFieldChanges(updatableEntity, form.values),
            }
        }])
        if (resp.status === 200) {
            reloadCommit()
        }
    }, [commitId, updatableEntity, form.values])
    return <MultiStepForm
        form={form}
        onSubmit={onSubmit}
        onClose={onClose}
        steps={[
            {
                label: 'Update Entity',
                description: 'Set Up',
                renderContent: (props) => <EntitySetupForm {...props} update></EntitySetupForm>,
            },
            {
                label: 'Update Fields',
                description: 'Describe Fields',
                renderContent: (props) => <EntityFieldsForm {...props} materializedEntity={materializedEntity}></EntityFieldsForm>
            },
        ]}
    ></MultiStepForm>
}