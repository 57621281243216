import { Group, Text, rem } from "@mantine/core";
import { Dropzone, DropzoneProps, FileWithPath } from "@mantine/dropzone";
import UploadAnimation from "../animations/UploadAnimation";
import ErrorWarnAnimation from "../animations/ErrorWarnAnimation";
import SuccessAnimation from "../animations/SuccessAnimation";

export const MIME_TYPES_MAP = {
  CSV: 'text/csv',
  JSON: 'application/json',
} as {[key: string]: string}


type UploadBoxProps = {
    label: string
    description: string
    files: FileWithPath[]
} & Partial<DropzoneProps>

export default function UploadBox(props: UploadBoxProps) {
    return (
      <Dropzone
        style={{marginTop: 30, marginBottom: 30, border: props.files.length ? '1px solid #00c885' : ''}}
        onDrop={(files) => { props.onDrop && props.onDrop(files) }}
        {...props}
      >
        <Group justify="center" gap="xl" style={{ minHeight: rem(220), pointerEvents: 'none' }}>
          <Dropzone.Accept>
            <div style={{width: 180}}>
              <UploadAnimation height={180}></UploadAnimation>
            </div>
          </Dropzone.Accept>
          <Dropzone.Reject>
            <div style={{width: 180}}>
              <ErrorWarnAnimation height={180}></ErrorWarnAnimation>
            </div>
          </Dropzone.Reject>
          <Dropzone.Idle>
            <div style={{width: 180}}>
              { props.files?.length ? 
                <SuccessAnimation height={180}></SuccessAnimation> :
                <UploadAnimation height={180}></UploadAnimation>
              }
            </div>
          </Dropzone.Idle>
  
          <div>
            <Text size="xl" inline>
                { props.label }
            </Text>
            <Text size="sm" color="dimmed" inline mt={7}>
                { props.description }
            </Text>
          </div>
        </Group>
      </Dropzone>
    );
}