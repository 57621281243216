import { Commit, CommitsApi } from "@/libs/client"
import { useProject } from "@/libs/project/ProjectProvider"
import { useEffect, useState } from "react"
import CommitHistoryList from "./CommitHistoryList"
import { buildDataChangeProps } from "../visualize/render/data/changes/data.changes"

export default function DataHistoryList() {
    const { curProject } = useProject()
    const [commits, setCommits] = useState<Commit[]>([])
    const [page, setPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0)
    const [loading, setLoading] = useState(true)
    const fetchCommits = async (page = 1) => {
        setLoading(true)
        const resp = await new CommitsApi().listCommitsForGraph(curProject.id!, 'DATA', page, 15)
        setCommits(resp.data.list || [])
        setPage(resp.data.page || 0)
        setTotalPages(resp.data.totalPages || 0)
        setLoading(false)
    }
    useEffect(() => {
        fetchCommits()
    }, [])
    return !loading && <CommitHistoryList commits={commits} page={page} totalPages={totalPages} onPageChange={fetchCommits} getDetails={buildDataChangeProps}></CommitHistoryList>

}