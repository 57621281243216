import { WorkflowTray } from "./tray/WorkflowTray";
import 'reactflow/dist/style.css';
import { Background, Connection, Edge, Node, ReactFlow, ReactFlowProvider, addEdge, getOutgoers, useEdgesState, useNodesState } from "reactflow";
import { useCallback, useEffect, useRef, useState } from "react";
import { useWorkflowEditor } from "./WorkflowEditorProvider";
import { v4 as uuidv4 } from 'uuid';
import CustomEdge from "./CustomEdge";
import WorkflowNode from "./rules/WorkflowNode"
import classes from './WorkflowRenderer.module.css';
import { RawWorkflowNodeValues, getWorkflowNodeType } from "./descriptors/descriptor";
import { Workflow } from "@/libs/client";

const proOptions = { hideAttribution: true };

const nodeTypes = {
    WorkflowNode: (props: any) => <WorkflowNode {...props} {...props.data}></WorkflowNode>,
};  

const edgeTypes = {
    custom: CustomEdge,
};

function getReactFlowNodes(workflow?: Workflow): Node[] {
  if (!workflow)
    return []
  return (workflow.definition?.nodes || []).map(n => ({
    id: n.id,
    position: n.position,
    type: 'WorkflowNode',
    data: {
      descriptorId: n.descriptorId,
    },
    selectable: true,
  }) as Node)
}

function getReactFlowEdges(workflow?: Workflow): Edge[] {
  if (!workflow)
    return []
  return (workflow.definition?.edges || [])
    .map(e => ({
      id: e.id,
      source: e.sourceId,
      target: e.targetId,
      type: 'custom',
      animated: true,
    }) as Edge)
}

function getAllWorkflowValuesFromInitial(workflow?: Workflow): { [key: string]: RawWorkflowNodeValues } {
  if (!workflow)
    return {}
  return (workflow.definition?.nodes || []).reduce((acc, cur) => {
    acc[cur.id!] = {
      name: cur.name || '',
      descriptorId: cur.descriptorId!,
      configuration: (cur.config || {}),
    }
    return acc
  }, {} as { [key: string]: RawWorkflowNodeValues })
}

type WorkflowRendererProps = {
  initialWorkflow?: Workflow;
}

export default function WorkflowRenderer({ initialWorkflow }: WorkflowRendererProps) {
  const reactFlowWrapper = useRef<any>(null);
  const { 
    refreshEngine, 
    reactFlowInstance, 
    setReactFlowInstance,
    setAllWokflowValues
  } = useWorkflowEditor()

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  useEffect(() => {
    setAllWokflowValues(getAllWorkflowValuesFromInitial(initialWorkflow))
    setNodes(getReactFlowNodes(initialWorkflow))
    setEdges(getReactFlowEdges(initialWorkflow))
  }, [])
  const [changeHash, setChangeHash] = useState("")
  const onDragOver = useCallback((event: any) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
}, [reactFlowInstance]);

  const onDrop = useCallback(
    (event: any) => {
      event.preventDefault();
      
      const data = JSON.parse(event.dataTransfer.getData('application/reactflow'));
      const wtype = getWorkflowNodeType(data.descriptorId)
      const hasInputAlready = !!reactFlowInstance.getNodes().find((n: Node) => getWorkflowNodeType(n.data.descriptorId) === 'inputs')
      if (wtype === 'inputs' && hasInputAlready) {
        return
      }

      const position = reactFlowInstance.screenToFlowPosition({
        x: event.clientX - 200,
        y: event.clientY - 50,
      });
      const newNode = {
        id: uuidv4(),
        position,
        type: 'WorkflowNode',
        data,
        selectable: true,
      };
      setNodes((nds) => nds.concat(newNode));
    },
    [reactFlowInstance]
  )
  useEffect(refreshEngine, [edges])
  useEffect(() => {
    const newChangeHash = [...nodes.map(n => n.id), ...edges.map(e => e.id)].sort().join(":")
    if (changeHash === newChangeHash) {
        return;
    }
    setChangeHash(newChangeHash)
  }, [nodes, edges]);
  
  const isValidConnection = useCallback(
    (connection: Connection) => {
      const nodes = reactFlowInstance.getNodes();
      const edges = reactFlowInstance.getEdges();
      const target = nodes.find((node: Node) => node.id === connection.target)!;
      const hasCycle = (node: Node, visited = new Set()) => {
        if (visited.has(node.id)) return false;

        visited.add(node.id);

        for (const outgoer of getOutgoers(node, nodes, edges)) {
          if (outgoer.id === connection.source) return true;
          if (hasCycle(outgoer, visited)) return true;
        }
      };

      if (target.id === connection.source) return false;
      return !hasCycle(target);
    },
    [reactFlowInstance],
  );
  const onConnect = useCallback((params: any) => setEdges((eds) => 
    addEdge({...params, id: uuidv4(), type: 'custom'}, eds)
  ), [setEdges]);
  return <div className={classes.canvas}>
    <ReactFlowProvider>
      <WorkflowTray></WorkflowTray>
      <div className={classes.wrapper} ref={reactFlowWrapper}>
        <ReactFlow
          onInit={setReactFlowInstance}
          nodes={nodes}
          edges={edges}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          onNodesChange={onNodesChange}
          onEdgesChange={(changes) => onEdgesChange(changes)}
          onConnect={onConnect}
          proOptions={proOptions}
          onDragOver={onDragOver}
          onDrop={onDrop}
          defaultEdgeOptions={{
              animated: true,
          }}
          isValidConnection={isValidConnection}
          fitView
        >
          <Background></Background>
        </ReactFlow>
      </div>
    </ReactFlowProvider>
  </div>
}