import { Button } from "@mantine/core"
import { useTranslation } from "react-i18next"
import { ActionResult } from "../actions/actions"
import classes from './FormButtonContainer.module.css';

type FormButtonContainerProps = {
    submitLabel?: string | null
    onClose: () => void
    disabled?: boolean
    onSubmit?: () => Promise<ActionResult>
    successLabel?: string | null
    hideSubmit?: boolean
    onSuccess?: () => void
}

export function FormButtonContainer({ onClose, disabled, submitLabel, hideSubmit, onSubmit }: FormButtonContainerProps) {
    const { t } = useTranslation()
    return <div className={classes.buttonContainer}>
        <Button onClick={onClose} variant="white" styles={{
            label: {
                textDecoration: 'underline',
                color: '#1f1f1f'
            },
        }}>{t('Cancel')}</Button>
        { !hideSubmit && <Button disabled={disabled} type="submit" onClick={onSubmit}>{ submitLabel || 'Submit' }</Button> }
    </div>
}