import { Button, Group, Modal, Stack } from "@mantine/core"
import { useTranslation } from "react-i18next"
import { ActionResult } from "../actions/actions"
import FormTextInput from "../form/inputs/FormTextInput"
import { ReactNode, useState } from "react"

export type SfyConfirmProps = {
    opened?: boolean
    onClose?: () => void
    title: string
    confirmationText: string | ReactNode
    cancelText?: string
    submitText?: string
    color?: string
    onSubmit: () => Promise<ActionResult>
    confirmInput?: { data: string, placeholder: string }
}

export default function SfyConfirmDialog({ 
    opened, 
    onClose, 
    confirmationText,
    cancelText,
    submitText,
    onSubmit,
    title,
    color,
    confirmInput,
 } : SfyConfirmProps) {
    const [userConfirmData, setUserConfirmData] = useState('')
    const { t } = useTranslation()
    return <Modal
        title={title}
        opened={opened || false} 
        onClose={onClose || (() => {})}
        withCloseButton={true}
        styles={{
            title: {
                fontSize: '1.7rem',
                color: '#12b886',
                fontWeight: 200
            },
            content: { padding: '0.5rem 1rem', minWidth: 600}
        }}
    >
        <Stack gap={10}>
            <div style={{fontSize: '1rem'}}>{confirmationText}</div>
            { confirmInput ? <FormTextInput
                size="sm"
                value={userConfirmData}
                onChange={e => setUserConfirmData(e.currentTarget.value)}
                placeholder={confirmInput.placeholder}
            ></FormTextInput> : undefined } 
            <Group gap={5} justify="flex-end" style={{marginTop: '1.2rem'}}>
                <Button variant="default" color="dark" size="sm" onClick={onClose}>{ cancelText || t('common.cancel')}</Button>
                <Button size="sm" 
                    disabled={confirmInput && confirmInput.data !== userConfirmData}
                    color={color} onClick={() => { onSubmit(); if(onClose) onClose() }}>{ submitText || t('common.submit')}</Button>
            </Group>
        </Stack>
    </Modal>
}