import cytoscape from "cytoscape";

const GRID_SPACING = 50

function createDottedGridSVG(spacingFactor: number, canvasWidth: number, canvasHeight: number, initialValueX: number, initialValueY: number) {
    // Calculate the radius of each dot based on the spacing factor
    const dotRadius = spacingFactor < 18 ? 0.4 : 0.6;
  
    // Create an SVG element
    const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    svg.setAttribute("width", canvasWidth + '')
    svg.setAttribute("height", canvasHeight + '');
  
    // Define the pattern for the dotted grid
    const pattern = document.createElementNS("http://www.w3.org/2000/svg", "pattern");
    pattern.setAttribute("id", "gridPattern");
    pattern.setAttribute("width", `${spacingFactor}px`);
    pattern.setAttribute("height", `${spacingFactor}px`);
    pattern.setAttribute("patternUnits", "userSpaceOnUse");
  
    // Create a circle to represent each dot in the pattern
    const circle = document.createElementNS("http://www.w3.org/2000/svg", "circle");
    circle.setAttribute("cx", dotRadius.toString());
    circle.setAttribute("cy", dotRadius.toString());
    circle.setAttribute("r", dotRadius.toString());
    circle.setAttribute("fill", "#5f5f5f");
  
    // Append the circle to the pattern
    pattern.appendChild(circle);
  
    // Append the pattern to the SVG's definitions
    const defs = document.createElementNS("http://www.w3.org/2000/svg", "defs");
    defs.appendChild(pattern);
    svg.appendChild(defs);
  
    // Create a rectangle with the dotted grid pattern as its fill
    const rect = document.createElementNS("http://www.w3.org/2000/svg", "rect");
    rect.setAttribute("width", "100%");
    rect.setAttribute("height", "100%");
    rect.setAttribute("fill", "url(#gridPattern)");
    rect.setAttribute("transform", `translate(${initialValueX}, ${initialValueY})`)
  
    // Append the rectangle to the SVG
    svg.appendChild(rect);
    // Return the generated SVG as a string
    return new XMLSerializer().serializeToString(svg);
}

function debounce<T extends (...args: any[]) => any>(
    func: T,
    wait: number
  ): (...args: Parameters<T>) => void {
    let timeoutId: ReturnType<typeof setTimeout> | null;
  
    return function (...args: Parameters<T>) {
      clearTimeout(timeoutId!);
  
      timeoutId = setTimeout(() => {
        func(...args);
      }, wait);
    };
  }
  
export function createGrid(cy: cytoscape.Core) {    
    const offset = function (elt: HTMLElement) {
      const rect = elt.getBoundingClientRect();
      
      return {
        top: rect.top + document.documentElement.scrollTop,
        left: rect.left + document.documentElement.scrollLeft,
      };
    };
    
    const $canvas = document.createElement('canvas');
    const $container = cy.container()!;
    const ctx = $canvas.getContext('2d')!;
    $container.appendChild($canvas);
    
    const resetCanvas = function () {
      $canvas.height = 0;
      $canvas.width = 0;
      $canvas.style.position = 'absolute';
      $canvas.style.top = '0';
      $canvas.style.left = '0';
      $canvas.style.zIndex = '0';
    };
    
    resetCanvas();
    
    const drawGrid = function () {
      const zoom = cy.zoom();
      const canvasWidth = cy.width();
      const canvasHeight = cy.height();
      const increment = GRID_SPACING * zoom;
      const pan = cy.pan();
      const initialValueX = pan.x % increment;
      const initialValueY = pan.y % increment;
            
    // Call createDottedGridSVG to generate the SVG string
    const svgString = createDottedGridSVG(increment, canvasWidth, canvasHeight, initialValueX, initialValueY);

    const img = new Image();
    const encodedData = encodeURIComponent(svgString);
      
      img.onload = function () {
        clearDrawing();
        ctx.drawImage(img, 0, 0);
      };
      
      img.src = 'data:image/svg+xml,' + encodedData;
    };
    
    const clearDrawing = function () {
      const width = cy.width();
      const height = cy.height();
      
      ctx.clearRect(0, 0, width, height);
    };
    
    const resizeCanvas = debounce(function () {
      $canvas.height = cy.height();
      $canvas.width = cy.width();
      $canvas.style.position = 'absolute';
      $canvas.style.top = '0';
      $canvas.style.left = '0';
      $canvas.style.zIndex = '0';
      
      setTimeout(function () {
        $canvas.height = cy.height();
        $canvas.width = cy.width();
        
        const canvasBb = offset($canvas);
        const containerBb = offset($container);
        $canvas.style.top = -(canvasBb.top - containerBb.top) + 'px';
        $canvas.style.left = -(canvasBb.left - containerBb.left) + 'px';
        drawGrid();
      }, 0);
      
    }, 0);
    
    return {
      initCanvas: resizeCanvas,
      resizeCanvas: resizeCanvas,
      resetCanvas: resetCanvas,
      clearCanvas: clearDrawing,
      drawGrid: drawGrid,
      sizeCanvas: drawGrid,
    };
  }
  