import { Tabs } from "@mantine/core"
import { useLocation, useNavigate } from "react-router-dom";

export type TabData = {
  key: string
  route: string;
  label: string
}

type EntityTabsProps = {
  tabs: TabData[]
}

export default function EntityTabs({ tabs }: EntityTabsProps) {
  const navigate = useNavigate()
  const location = useLocation()
  return <Tabs 
      radius="lg" 
      defaultValue={tabs[0].key}
      value={tabs.find(t => location.pathname.startsWith(t.route))?.key}
      onChange={tc => navigate(tabs.find(t => t.key === tc)?.route || '')}
      style={{flex: 1}}
      color="sfy-dark-blue"
    > 
    <Tabs.List>
      {tabs.map(t => <Tabs.Tab key={t.key} value={t.key}>{t.label}</Tabs.Tab>)}
    </Tabs.List>
  </Tabs>
}