import { useMapper } from "./MapperProvider";
import { useMemo } from "react";
import cx from 'clsx';
import classes from './MapperDot.module.css';

const DEFAULT_SIZE = 10;

type MapperDotProps = {
    value: string;
    type: 'start' | 'end';
    size?: number;
}

export function MapperDot({ value, type, size } : MapperDotProps) {
    const { arrows, onArrowStart, onArrowEnd } = useMapper()
    const active = useMemo(() => {
        return !!arrows.find(a => a.start === value || a.end == value);
    }, [arrows])
    return <div
        id={`mapper-dot-${value}`}
        style={{ width: size || DEFAULT_SIZE, height: size || DEFAULT_SIZE }}
        className={cx(classes.mapperDot, `mapper-dot mapper-dot-${type}`, active ? classes.active : undefined)}
    >
        <div 
            className={classes.hitTarget} 
            style={{ width: size || DEFAULT_SIZE, height: size || DEFAULT_SIZE }}
            onClick={(e) => type === 'start' ? onArrowStart(e, value) : onArrowEnd(e, value)}></div>
    </div>
}