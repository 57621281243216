import { Button, LoadingOverlay, Stack } from "@mantine/core"
import { ReactNode, useState } from "react"
import { useTranslation } from "react-i18next"
import AnimatedSuccess from "../icons/AnimatedSuccess"
import AnimatedError from "../icons/AnimatedError"
import { ActionResult, ActionResultType } from "../actions/actions"
import { FormButtonContainer } from "./FormButtonContainer"
import classes from './SfyForm.module.css';

type SfyFormProps = {
    children?: ReactNode
    submitLabel?: string | null
    onClose: () => void
    disabled?: boolean
    onSubmit?: () => Promise<ActionResult>
    successLabel?: string | null
    errorLabel?: string | null
    hideSubmit?: boolean
    onSuccess?: () => void
}

type ActionResultDisplayProps = {
    result: ActionResult
    successLabel?: string | null
    errorLabel?: string | null
}

//TODO i18n errors
function ActionResultDisplay({ result, successLabel, errorLabel } : ActionResultDisplayProps) {
    const isSuccess = result.type === ActionResultType.SUCCESS;
    const hasErrors = result.errors && result.errors.length > 0
    return <div className={classes.result}>
        { isSuccess ? 
            <AnimatedSuccess></AnimatedSuccess> : <AnimatedError></AnimatedError>}
        <div style={{marginTop: 10}}>{isSuccess ? successLabel : errorLabel}</div>
        {hasErrors && <ul className={classes.errors}>
            { result.errors?.map(e => <li key={e}>{e}</li>) }
        </ul>}
    </div>
}

export default function SfyForm(props : SfyFormProps) {
    const { onSuccess, children, onClose, onSubmit, successLabel, errorLabel } = props;
    const [submitting, setSubmitting] = useState(false);
    const [result, setResult] = useState<ActionResult>({ type: ActionResultType.NONE })
    const { t } = useTranslation()
    if (result.type !== ActionResultType.NONE) {
        return <div>
                <ActionResultDisplay result={result} successLabel={successLabel} errorLabel={errorLabel}></ActionResultDisplay>
                <div className={classes.closeContainer}>
                    <Button onClick={onClose} variant="outline">{t('common.close')}</Button>
                </div>
            </div>
    }
    return <form className={classes.form} onSubmit={async (event) => {
                    event.preventDefault();
                    setSubmitting(true);
                    try {
                        if (onSubmit) {
                            const res = await onSubmit()
                            if (res.type === ActionResultType.FORM_ERROR) {
                                setSubmitting(false)
                                return
                            }
                            setResult(res)
                            if (res.type === ActionResultType.SUCCESS
                                && onSuccess) {
                                onSuccess()
                            }
                        }
                    } catch(err) {
                        //TODO
                    }
                    setSubmitting(false)
                }}>
        <LoadingOverlay visible={submitting} overlayProps={{ blur: 2}} />
        <Stack style={{width: '100%'}}>
            { children }
        </Stack>
        <FormButtonContainer {...props} onSubmit={undefined}></FormButtonContainer>
    </form>
}