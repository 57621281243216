import { useTranslation } from "react-i18next";
import { DisplayElemSize } from "@/components/table/EntityTable";
import { ReportsApi, GeneratedReport } from "@/libs/client";
import { useTitle } from "@/libs/hooks/useTitle";
import ListSection, { ListSectionCallbacks } from "@/components/page/sections/ListSection";
import { useProject } from "@/libs/project/ProjectProvider";
import { FaDownload, FaTrash } from "react-icons/fa";
import { buildApiUrl } from "@/libs/utils/apiUrl"
import { useRef } from "react";
import notify from "@/libs/notify/notify";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { downloadFileFromUrl } from "@/libs/utils/download";


export default function GeneratedReportsList() {
    const { id } = useParams()
    const { t } = useTranslation()
    const { projectUrl } = useProject()
    const navigate = useNavigate()


    const listRef = useRef<ListSectionCallbacks>()
    useTitle(t("generated_reports.label", { count: 2 }))
    const headers = [
        t('Created On'),
        t('Number of Rows'),
        t('Size')
    ];
    return <ListSection<GeneratedReport>
        callbacks={listRef}
        headers={headers}
        renderRow={(e) => ({
            cols: [{
                display: [{
                    label: moment(e.createdDate).format("YYYY-MM-DD - HH:mm:ss") || '',
                    size: DisplayElemSize.lg,
                }],
            }, {
                display: [{
                    label: e.numberOfRows! >= 0 ? e.numberOfRows + "" : "",
                }],
            }, {
                display: [{
                    label: e.sizeInBytes! >= 0 ? formatBytes(e.sizeInBytes || 0) : '',
                }],
            }],
        })}
        load={async (page) => {
            const resp = await new ReportsApi().generatedReports(id!, page);
            if (resp.status === 200) {
                return resp.data;
            }
            return { error: 'TODO' };
        }}
        rowActions={r => [{
            label: 'Download',
            icon: <FaDownload></FaDownload>,
            execute: () => {
                downloadFileFromUrl(buildApiUrl(`/generated_reports/${r.id}/download`), (r.report!.name!+ moment(r.createdDate)) || '')
            }
        }, {
            label: t('common.delete'),
            icon: <FaTrash></FaTrash>,
            execute: async () => {
                await new ReportsApi().deleteGeneratedReport(r.id!)
                listRef.current?.refresh()
                notify.success("Successfully deleted report " + moment(r.createdDate).fromNow())
            }
        }]}
        onRowClick={r => navigate(projectUrl(`/insights/reports/${id}/generated_reports/${r.id}`))}
        emptyLabel={t('No generated reports yet')}
    ></ListSection>
}


function formatBytes(bytes: number, decimals = 2): string {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
