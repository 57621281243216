import { TreeElement } from "@/graph/visualize/common/navigator/TreeView"
import { PiGraphFill } from "react-icons/pi";
import { RxDotFilled } from "react-icons/rx";
import LazyDataGraph from "../lazy/LazyDataGraph";
import { CgSpinner } from "react-icons/cg";
import classes from './data.navigator.module.css';
import { DataNode, SchemaGraph } from "@/libs/client";
import { sortBy } from "@/libs/utils/sort";

export const buildNavigatorElements = (dataGraph: LazyDataGraph, schemaGraph: SchemaGraph) : TreeElement[] => {
    const stats = dataGraph.meta.stats!
    const map = Object.values(schemaGraph.entities).reduce((acc, cur) => {
        const count = stats.nodeStats[cur.type!] || 0
        acc[cur.type!] = {
            id: cur.type!,
            label: `${cur.type!} (${count})`,
            renderIcon: (props: any) => <PiGraphFill {...props}></PiGraphFill>,
            children: count > 0 ? [
                {
                    id: cur.type + '_loading',
                    label: '...',
                    renderIcon: (props: any) => <CgSpinner {...props} className={classes.loading}></CgSpinner>,
                }
            ] : undefined,
        }
        return acc
    }, {} as { [key: string]: TreeElement})
    return Object.values(map).sort(sortBy(e =>e.label))
    /*
    return Object.values(nodes.reduce((acc, cur) => {
        if (!acc[cur.type!]) {
            return acc
        }
        acc[cur.type!].children?.push({
            id: cur.id!,
            label: cur.display!,
            renderIcon: (props: any) => <RxDotFilled {...props}></RxDotFilled>,     
        })
        acc[cur.type!].label = `${cur.type!} (${acc[cur.type!].children?.length})`
        return acc
    }, map)).sort(sortBy(e => e.label))*/
}

export const buildDataNodeElement = (node: DataNode): TreeElement => ({
    id: node.id!,
    label: node.display || node.oid || '',
    renderIcon: (props: any) => <RxDotFilled {...props}></RxDotFilled>,     
});