import { WorkflowNodeProps, WorkflowNodeTemplate } from "./WorkflowNodeTemplate";
import { MdOutput } from "react-icons/md";

export default function OutputBlock(props : WorkflowNodeProps) {
    return <WorkflowNodeTemplate
            {...props}
            icon={<MdOutput color="white" size="0.85rem"></MdOutput>}
            headerColor="#8e483d"
            targetEnabled
        >
        </WorkflowNodeTemplate>
}