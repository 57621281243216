import { StepContentProps } from "@/components/form/MultiStepForm";
import FormSelect from "@/components/form/inputs/FormSelect";
import FormTextInput from "@/components/form/inputs/FormTextInput";
import { SchemaGraph } from "@/libs/client";
import { sortBy } from "@/libs/utils/sort";
import { Stack } from "@mantine/core";
import { useEffect, useMemo } from "react";

type MappingSetupFormProps = {
    schema: SchemaGraph;
    custom: boolean;
} & StepContentProps

export default function MappingSetupForm({ form, schema, custom } : MappingSetupFormProps) {
    useEffect(() => {
        if (form.values.elementType === 'NODE') {
            form.setFieldValue('source', '');
            form.setFieldValue('target', '');
        }
    }, [form.values.mappedType])
    useEffect(() => {
        if ((form.values.elementType === 'NODE' && !schema.entities[form.values.mappedType])
            || (form.values.elementType === 'EDGE' && !schema.relationships[form.values.mappedType])) {
            form.setFieldValue('mappedType', '');
        }
    }, [form.values.elementType, form.values.source, form.values.target])
    const entityOpts = useMemo(() => {
        return Object.keys(schema.entities).map(e => ({ value: e, label: e })).sort(sortBy(e => e.label))
    }, [schema])
    return <form>
        <Stack>
            <FormTextInput
                label={"File Pattern"}
                withAsterisk
                description="File name pattern to match on for this mapping. (e.g. mydata-*.csv)"
                {...form.getInputProps('filePattern')}
            ></FormTextInput>
            { custom && <FormSelect
                label={"File Type"}
                data={[{value: 'CSV', label: 'csv'}]}
                defaultValue="CSV"
                description="Type of the file that's going to be uploaded"
                {...form.getInputProps('fileType')}
            ></FormSelect> }
            <FormSelect
                label="Element"
                description="Type of element you are mapping"
                data={[{value: 'NODE', label: 'Data Node'}, { value: 'EDGE', label: 'Data Edge'}]}
                {...form.getInputProps('elementType')}
            ></FormSelect>
            {
                form.values.elementType === 'NODE' && <>
                    <FormSelect
                        label="Entity"
                        description="Entity that will be mapped"
                        searchable
                        data={entityOpts}
                        {...form.getInputProps('mappedType')}
                    ></FormSelect>
                </>
            }
            {
                form.values.elementType === 'EDGE' && <>
                    <FormSelect
                        label="Source"
                        description="Source Entity"
                        searchable
                        data={entityOpts}
                        {...form.getInputProps('source')}
                    ></FormSelect>
                    <FormSelect
                        label="Target"
                        description="Target Entity"
                        searchable
                        data={entityOpts}
                        {...form.getInputProps('target')}
                    ></FormSelect>
                    <FormSelect
                        label="Relationship"
                        description="Relationship that will be mapped"
                        searchable
                        data={Object.values(schema.relationships)
                            .filter(e => e.source === form.values.source &&
                                e.target === form.values.target)
                            .map(e => ({ value: e.name!, label: e.name! }))}
                        {...form.getInputProps('mappedType')}
                    ></FormSelect>
                </>
            }
        </Stack>
    </form>
}