import { ActionResultType } from "@/components/actions/actions";
import { useContainer } from "@/components/containers/ContainerProvider";
import SfyForm from "@/components/form/SfyForm";
import FormSelect from "@/components/form/inputs/FormSelect";
import FormTextInput from "@/components/form/inputs/FormTextInput";
import { Catalog, CatalogSourceEnum, CatalogsApi } from "@/libs/client";
import { useForm } from "@mantine/form";

export default function CatalogForm({catalog, onRefresh} : { catalog?: Catalog, onRefresh: () => void }) {
    const {closeAllModals} = useContainer()
    const form = useForm({
        initialValues: {
            name: '',
            description: '',
            url: '',
            source: CatalogSourceEnum.Github,
            ...catalog,
        },
        validate: {
            name: v => !v,
            url: v => !v,
        }
    })

    return <SfyForm onClose={closeAllModals} disabled={!form.isValid()}
        onSubmit={async () => {
            if (catalog) {
                await (new CatalogsApi().updateCatalog(catalog.id!, form.values as any))
            } else {
                await (new CatalogsApi().createCatalog(form.values as any))
            }
            onRefresh()
            return { type: ActionResultType.SUCCESS }
        }}
    >
        <FormTextInput
            label="Name"
            description=""
            withAsterisk
            {...form.getInputProps('name')}
        >
        </FormTextInput>
        <FormTextInput
            label="Description"
            description=""
            {...form.getInputProps('description')}
        >
        </FormTextInput>
        <FormTextInput
            label="URL"
            description=""
            withAsterisk
            {...form.getInputProps('url')}
        >
        </FormTextInput>
        <FormSelect
            label="Source"
            description=""
            data={[
                {
                    value: 'GITHUB',
                    label: 'Github'
                },
                {
                    value: 'SENTRYFY',
                    label: 'Sentryfy'
                }
            ]}
            {...form.getInputProps('source')}
        ></FormSelect>
    </SfyForm>
}