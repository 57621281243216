import { LoadingOverlay } from "@mantine/core"
import SfyBreadcrumb, { SfyBreadcrumbItemProps } from "@/components/breadcrumb/SfyBreadcrumb";
import EntityTabs, { TabData } from "./EntityTabs";
import { Outlet } from "react-router-dom";
import { ReactNode } from "react";
import classes from './EntityPage.module.css';

type EntityPageProps = {
    category?: string | null
    title: string
    loading?: boolean
    breadcrumbs?: SfyBreadcrumbItemProps[]
    tabs?: TabData[]
    children?: ReactNode
}

export default function EntityPage({ title, tabs, children, category, loading, breadcrumbs }: EntityPageProps) {
    return <div className={classes.page}>
        <div className={classes.head}>
            { !!category &&  <div className={classes.category}>{ category }</div> }
            <div className={classes.breadcrumbWrapper}>
                { breadcrumbs ? <SfyBreadcrumb breadcrumbs={breadcrumbs}></SfyBreadcrumb> : undefined }
            </div>

            <h1 style={{ fontWeight: 'normal', fontSize: '1.5rem' }}>{title}</h1>
            <div style={{flex: 1, paddingLeft: 30}}>
                { tabs?.length ? <EntityTabs tabs={tabs}></EntityTabs> : <></>}
            </div>
        </div>
        <LoadingOverlay visible={loading || false} overlayProps={{ blur: 2 }} />
        { children ? children : <Outlet></Outlet>}
    </div>
}