import Chart from 'react-apexcharts';
import "./PostureBoard.css";

function PostureChart() {
    return <div className="posture-board"><Chart
    series={[{
        name: 'Target Score',
        data: [3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3],
      }, {
        name: 'Policy Score',
        data: [3.2, 2.7, 3.2, 2.3, 4.5, 3, 2.6, 3.1, 2.9, 4, 2.8, 3.5, 2, 3.1, 3.5, 2.4, 3.5, 3, 2.5, 2.5],
      }, {
        name: 'Practice Score',
        data: [2.8, 2.7, 3.1, 4, 2.5, 2, 3.2, 2.7, 3.2, 2, 3.5, 1.5, 4, 2.5, 3.5, 3, 2.5, 3, 3.5, 3.5],
      }]}
    type="radar"
    height={600}
    width={'100%'}
    labels={['Overall Average Score', 'Organizational Context (GV. OC)', 'Risk Management Strategy (GV. RM)', 'Roles, Responsibilities and Authorities (GV. RR)', 'Policies (GV. PO)', 'Oversight (GV. OV)', '']}
    options={{
        chart: {
            type: 'radar',
            dropShadow: {
                enabled: true,
                blur: 1,
                left: 1,
                top: 1
            },
            toolbar: {
                show: false,
            },
            selection: {
                enabled: false,
            },
            zoom: {
                enabled: false,
            },            
        },
        xaxis: {
            categories: [
                ['Overall Average Score'], 
                ['Organizational Context', '(GV. OC)'], 
                ['Risk Management', 'Strategy (GV. RM)'], 
                ['Roles, Responsibilities', 'and Authorities (GV. RR)'], 
                ['Policies', '(GV. PO)'], 
                ['Oversight', '(GV. OV)'], 
                ['Cybersecurity Supply', 'Chain Risk Management', '(GV. SC)'], 
                ['Asset Management', '(ID. AM)'], 
                ['Risk Assessment', '(ID. RA)'], 
                ['Improvement', '(ID. IM)'], 
                ['Identity Management,', 'Authentication, and Access Control', '(PR. AA)'], 
                ['Awareness and Training', '(PR. AT)'],
                ['Data Security', '(PR. DS)'],
                ['Platform Security', '(PR. PS)'],
                ['Technology Infrastructure', 'Resilience (PR. IR)'],
                ['Continuous Monitoring', '(DE. CM)'],
                ['Adverse Event Analysis', '(DE. AE)'],
                ['Incident Management', '(RS. MA)'],
                ['Incident Analysis', '(RS. AN)'],
                ['Incidence Response Reporting', 'and Communication (RS. CO)'],
            ],
            labels: {
              show: true,
              style: {
                colors: ["#a8a8a8"],
                fontSize: "10px",
              },
            },
        },
        
    }}></Chart></div>
}

export default function PostureBoard() {
    return <PostureChart></PostureChart>
}