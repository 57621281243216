import { useContainer } from "@/components/containers/ContainerProvider";
import SfyForm from "@/components/form/SfyForm";
import FormSelect from "@/components/form/inputs/FormSelect";
import FormTextInput from "@/components/form/inputs/FormTextInput";
import { Button, Group } from "@mantine/core";
import { WorkflowNodeContext, WorkflowNodeValues } from "../../descriptors/descriptor";
import { ActionResultType } from "@/components/actions/actions";

function getPropertyFields(_: WorkflowNodeContext) {
    return []
}

export default function NotifyConfigure({ ctx } : {values: WorkflowNodeValues, ctx: WorkflowNodeContext}) {
    const { closeAllDrawers } = useContainer();

    const saveFilter = async () => {
        closeAllDrawers()
        return { type: ActionResultType.NONE }
    }
    return <SfyForm onSubmit={saveFilter} onClose={closeAllDrawers}>        
        <label>Report Type</label>
        <FormSelect data={[{ value: 'CSV', label: 'csv' }]}></FormSelect>
        <label>Mappings</label>
        <Group>
            <FormTextInput label="Column" asField placeholder="Column Name" size="sm" styles={{input: {paddingLeft: 0, borderWidth: 0}}}></FormTextInput>
            <FormSelect
                label="Field"
                data={getPropertyFields(ctx)}
            ></FormSelect>
        </Group>
        <div>
            <Button fullWidth={false}>Add Column</Button>
        </div>
    </SfyForm>
}