import { ActionResultType } from "@/components/actions/actions";
import { useContainer } from "@/components/containers/ContainerProvider";
import SfyForm from "@/components/form/SfyForm";
import FormTextInput from "@/components/form/inputs/FormTextInput";
import { useAuth } from "@/libs/auth/AuthProvider";
import { SaveUser, UsersApi } from "@/libs/client";
import { doResponse } from "@/libs/utils/response";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export function AddUser({ onRefresh }: { onRefresh?: () => void }) {
    const { t } = useTranslation()
    const { closeAllModals } = useContainer();
    const [mismatchError, setMismatchError] = useState('')
    const form = useForm()
    const ctx = useAuth()
    
    useEffect(() => {
        setMismatchError('')
    }, [form.values])
    return <SfyForm 
        onClose={closeAllModals}
        onSubmit={async () => {
            if (form.values.password !== form.values.confirmPassword) {
                setMismatchError("user.password_dont_match")
                return { type: ActionResultType.FORM_ERROR }
            } else {
                return doResponse(new UsersApi().createUser({
                    ...form.values,
                    role: 'ADMIN',
                    tenantId: ctx.userCtx.user?.tenant?.id,
                } as SaveUser), onRefresh)
            }
        }}
    >
        <FormTextInput
            label={t("common.username")}
            description={t('user.username_description')}
            {...form.getInputProps('username')}
        ></FormTextInput>
        <FormTextInput
            label={t("common.password")}
            description={t('user.password_description')}
            type="password"
            {...form.getInputProps('password')}
            error={t(mismatchError)}
        ></FormTextInput>
        <FormTextInput
            label={t("common.confirm_password")}
            description={t('user.confirm_password_description')}
            type="password"
            {...form.getInputProps('confirmPassword')}
            error={t(mismatchError)}
        ></FormTextInput>
    </SfyForm>
}