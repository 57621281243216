import { Change, Commit, CommitsApi } from "@/libs/client"
import { sortBy } from "@/libs/utils/sort"
import { Group, Loader, Table } from "@mantine/core"
import moment from "moment-timezone"
import { ReactNode, useMemo, useState } from "react"


export type ChangeDetails = {
    title: string
    detail: string
    renderIcon: (props: any) => ReactNode
    color: string
}

type HistoryCommitRowProps = {
    commit: Commit
    getDetails: (change: Change) => ChangeDetails
}

export default function HistoryCommitRow({ commit, getDetails } : HistoryCommitRowProps) {
    const [changes, setChanges] = useState<Change[]>([])
    const sortedChanges = useMemo(() => [...changes].sort(sortBy(c => c.elementId)), [changes])
    const [expand, setExpand] = useState(false)
    const toggleRow = async () => {
        setExpand(v => !v)
        if (changes.length == 0) {
            const resp = await new CommitsApi().commit(commit.id!)
            setChanges(resp.data.changes || [])
        }
    }
    return <div key={commit.id} style={{position: 'relative', border: '1px solid #f0f0f0', borderRadius: 10, width: '100%', padding: '1.2rem 1.2rem'}}>
    <div onClick={toggleRow} style={{cursor: 'pointer'}}>
        <div style={{position: 'absolute', top: 10, right: 20, fontSize: '0.75rem'}}>
            <Group gap={10}>
                <span>{commit.stats?.totalChanges}</span>
                <span style={{color: '#2fcc90'}}>+{commit.stats?.additions || 0}</span>
                <span style={{color: '#ffd500'}}>{commit.stats?.updates || 0}</span>
                <span style={{color: '#e65a0a'}}>-{commit.stats?.deletions || 0}</span>
            </Group>
        </div>
        <div><b style={{fontWeight: 500}}>#{commit.graphVersion} {commit.message || `Commit from integration ${commit.bucket?.name}` }</b></div>
        <div style={{fontSize: '0.8rem', color: '#c0c0c0'}}>
            <span>{commit.bucket?.name}</span>
            <span> • </span>
            <span>{moment(commit.createdDate).tz(moment.tz.guess()).fromNow()}</span>
        </div>
    </div>
    { expand && <div style={{marginTop: 10, textAlign: 'left'}}>
        { changes.length ? <Table border={1} cellPadding={10} striped style={{ tableLayout: 'fixed', fontSize: '0.85rem'}} styles={{table: {borderColor: '#fafafa55'}}}>
            <thead>
                <th style={{width: '25%'}}>Name</th>
                <th>Detail</th>
            </thead>
            {sortedChanges.map(c => { 
                const details = getDetails(c)
                return <tr>
                <td>{details.title}</td>
                <td style={{overflowWrap: 'break-word'}}>{details.detail}</td>
            </tr>})}</Table>: <Loader size="sm"></Loader> }
    </div> }
</div>
}