import { Change, Commit } from "@/libs/client"
import HistoryCommitChart from "./HistoryCommitChart"
import { Pagination, Stack } from "@mantine/core"
import HistoryCommitRow, { ChangeDetails } from "./HistoryCommitRow"

type CommitHistoryListProps = {
    commits: Commit[]
    page: number
    totalPages: number
    onPageChange: (newPage: number) => void
    getDetails: (change: Change) => ChangeDetails
}

export default function CommitHistoryList({ commits, page, totalPages, onPageChange, getDetails } : CommitHistoryListProps) {
    return <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', paddingBottom: '3rem'}}>
    <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
        <HistoryCommitChart commits={commits}></HistoryCommitChart>
    </div>
    <Stack style={{width: '100%', marginTop: 20, alignItems: 'center', display: 'flex'}}>
        { commits.map(c => <HistoryCommitRow key={c.id} commit={c} getDetails={getDetails}></HistoryCommitRow>)}
        <div style={{marginTop: 20, display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            <Pagination value={page} onChange={onPageChange} total={totalPages || 0} radius="md" />
        </div>
    </Stack>
</div>
}