import { useContainer } from "@/components/containers/ContainerProvider";
import UploadGraph from "@/graph/visualize/common/actions/UploadGraph";
import CommitButton from "@/graph/visualize/common/commit/CommitButton";
import DiscardCommitButton from "@/graph/visualize/common/commit/DiscardCommitButton";
import GraphToolbar from "@/graph/visualize/common/toolbar/GraphToolbar";
import { useGraphDisplay } from "@/graph/visualize/providers/GraphDisplayProvider";
import { useGraphEditor } from "@/graph/visualize/providers/GraphEditorProvider";
import { Commit } from "@/libs/client";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type SchemaEditorToolbarProps = {
    commit: Commit
    version?: string | number
    reloadSchemaGraph: () => Promise<void>
    backPath: string
}

const UPLOAD_GRAPH_KEY = 'upload_graph'

export default function SchemaEditorToolbar({ version, reloadSchemaGraph, backPath } : SchemaEditorToolbarProps) {
    const { t } = useTranslation()
    const { openDrawer, closeDrawer } = useContainer()
    const { commit, reloadCommit } = useGraphEditor()
    const { activeType } = useGraphDisplay()
    const navigate = useNavigate()
    return <>
        <GraphToolbar
            breadcrumb={['Schema Graph', `Edit${version !== undefined ? ` (v${version})`: ''}`]}
            rhs={[[
                <CommitButton reloadGraph={async () => {
                    await reloadSchemaGraph()
                }} commit={commit!} navigateOnSuccess={backPath}></CommitButton>,
                <DiscardCommitButton commit={commit!} navigateOnSuccess={backPath}></DiscardCommitButton>
            ]]}
            moreActions={[{
                label: 'Schema Graph',
                items: [{
                label: 'Upload From File',
                onClick: () => openDrawer(UPLOAD_GRAPH_KEY, <UploadGraph 
                    activeType={activeType}
                    commit={commit!} 
                    onSuccess={async () => reloadCommit()}
                    onRefresh={() => {}} 
                    onClose={() => closeDrawer(UPLOAD_GRAPH_KEY)}></UploadGraph>, { title: 'Upload From File' })
            },
            {
                label: t('common.cancel'),
                onClick: () => navigate(backPath)
            }]}]}
        ></GraphToolbar>
    </>
}