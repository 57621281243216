import { MutableRefObject, createContext, useContext, useRef, useState } from 'react';
import { RawWorkflowNodeValues, WorkflowNodeContext, WorkflowNodeInput, WorkflowNodeOutput, WorkflowNodeValues } from './descriptors/descriptor';
import { ReactFlowInstance } from 'reactflow';
import { getWorkflowNode } from './descriptors';

type WorkflowEditorContextProps = {
    reactFlowInstance?: any
    setReactFlowInstance: (reactFlowInstance?: any) => void
    allWokflowValues: MutableRefObject<{ [key: string]: RawWorkflowNodeValues}>
    getContextOf: (id: string) => WorkflowNodeContext
    getWorkflowNodeValuesById: (id: string) => WorkflowNodeValues
    refreshEngine: () => void
    setAllWokflowValues: (values: { [key: string]: RawWorkflowNodeValues}) => void
    changeWatcher: number,
}

export const WorkflowEditorContext = createContext({
} as WorkflowEditorContextProps);

export const useWorkflowEditor = () => useContext(WorkflowEditorContext);

export default function WorkflowEditorProvider({ children } : { children: JSX.Element | JSX.Element[]}) {
    const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance>()
    const allWokflowValues = useRef<{ [key: string]: RawWorkflowNodeValues}>({})
    const [changeWatcher, setChangeWatcher] = useState(0)
    const refresh = () => {
        allWokflowValues.current = { ...allWokflowValues.current };
        setChangeWatcher(cur => cur + 1)
    }
    const getWorkflowNodeValuesById = (id: string) => {
        if (!allWokflowValues.current[id]) {
            const node = reactFlowInstance?.getNodes().find(n => n.id === id)!
            allWokflowValues.current = {
                ...allWokflowValues.current,
                [id]: { name: '', configuration: {}, descriptorId: node.data.descriptorId },
            }
            refresh()
        }
        return {
            setValue(key: string, value: any) {
                allWokflowValues.current[id].configuration[key] = value;
                refresh()
            },
            getValues() {
                return { ...allWokflowValues.current[id].configuration }
            },
            getValue(key: string) {
                return allWokflowValues.current[id].configuration[key]
            },
            asRaw() {
                return {...allWokflowValues.current[id]};
            },
            getName() {
                return allWokflowValues.current[id].name;
            },
            setName(name: string) {
                allWokflowValues.current[id].name = name;
                refresh()
            },
            getDescriptorId() {
                return allWokflowValues.current[id].descriptorId;
            }
        }
    }
    const getContextOf = (id: string) => {
        const edges = (reactFlowInstance?.getEdges() || [])
            .filter(e => e.target === id)
        return {
            inputs: edges.reduce((acc, cur) => {
                const sourceNode = reactFlowInstance?.getNode(cur.source)!
                const sourceDescriptor = getWorkflowNode(sourceNode.data.descriptorId)
                const ctx = getContextOf(cur.source)
                const values = getWorkflowNodeValuesById(cur.source)
                const output = sourceDescriptor.getOutput(ctx, values)
                if ((output as any).invalid) {
                    return acc
                }
                acc[cur.source] = output as WorkflowNodeOutput
                return acc
            }, {} as { [id: string]: WorkflowNodeInput }),
        }
    }
    return (
        <WorkflowEditorContext.Provider value={{ 
            reactFlowInstance,
            setReactFlowInstance,
            allWokflowValues: allWokflowValues,
            
            getContextOf,
            getWorkflowNodeValuesById,
            setAllWokflowValues: (values: { [key: string]: RawWorkflowNodeValues}) => {
                allWokflowValues.current = values
            },
            refreshEngine: refresh,
            changeWatcher,
        }}>
            {children}
        </WorkflowEditorContext.Provider>
    );
}