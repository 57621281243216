import { Group, Menu, Tooltip, UnstyledButton } from "@mantine/core"
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Handle, Position } from "reactflow"
import { useWorkflowEditor } from "../WorkflowEditorProvider"
import { useTranslation } from "react-i18next"
import { useContainer } from "@/components/containers/ContainerProvider"
import { TiWarning } from "react-icons/ti"
import { getWorkflowNode } from "../descriptors"
import { WorkflowNodeContext, WorkflowNodeDescriptor, WorkflowNodeValues, getWorkflowNodeLabel } from "../descriptors/descriptor"
import { HiDotsVertical } from "react-icons/hi"
import classes from './WorkflowNodeTemplate.module.css';
import cx from 'clsx';
import { DrawerMode } from "@/components/containers/SfyDrawer"

export type WorkflowNodeProps = {
    id?: string;
    trayMode: boolean;
    descriptorId: string;
    filled?: boolean;
    selected?: boolean;
}

export type WorkflowNodeTemplatePropsProps = {
    icon: ReactNode
    headerColor: string
    children?: ReactNode
    sourceEnabled?: boolean;
    targetEnabled?: boolean
} & WorkflowNodeProps

type ActionMenuProps = {
    id: string
    descriptorId: string
    workflowNodeValues?: WorkflowNodeValues
    workflowCtx?: WorkflowNodeContext
}

function ActionMenu({ id, descriptorId, workflowNodeValues, workflowCtx }: ActionMenuProps) {
    const { t } = useTranslation()
    const { openDrawer } = useContainer()
    const block = getWorkflowNode(descriptorId)
    const label = t(getWorkflowNodeLabel(descriptorId))

    const { reactFlowInstance, } = useWorkflowEditor()
    const [opened, setOpened] = useState(false)
    const handleWindowClick = useCallback(() => {
        setOpened(false);
    }, []);
    useEffect(() => {
        if (opened) {
            window.addEventListener('click', handleWindowClick);
        } else {
            window.removeEventListener('click', handleWindowClick);
        }
    }, [opened])
    const onConfigure = () => block.configureForm && openDrawer(descriptorId, <div style={{padding: '1.4rem'}}>{block.configureForm(workflowCtx!, workflowNodeValues!)}</div>, {
        size: 'xl',
        title: `Configure ${label}`,
        closeButton: true,
        mode: DrawerMode.WORKFLOW,
    })
    return <Menu shadow="md" width={150} offset={{ crossAxis: -60, mainAxis: 10 }} opened={opened} onClose={() => setOpened(false)}>
        <Menu.Target>
            <UnstyledButton onClick={(e) => { e.preventDefault(); e.stopPropagation(); setOpened(true) }}>
                <HiDotsVertical style={{marginTop: 4}} size={14} color="white"></HiDotsVertical>
            </UnstyledButton>
        </Menu.Target>
        <Menu.Dropdown>
            <Menu.Label style={{fontSize: '0.7rem'}}>Actions</Menu.Label>
            { block.configureForm && <Menu.Item style={{fontSize: '0.7rem'}} onClick={onConfigure}>
                Configure
            </Menu.Item>}
            <Menu.Item style={{fontSize: '0.7rem'}}  onClick={() => reactFlowInstance?.deleteElements({nodes: [{ id }]})}>
                Delete
            </Menu.Item>
        </Menu.Dropdown>
    </Menu>
}

const isInput = (block: WorkflowNodeDescriptor) => block.descriptorId.startsWith("inputs/");
const isOutput = (block: WorkflowNodeDescriptor) => block.descriptorId.startsWith("outputs/");
const isControlFlow = (block: WorkflowNodeDescriptor) => block.descriptorId.startsWith("control_flows/");


export function WorkflowNodeTemplate({ trayMode, id, descriptorId, icon, filled, children, headerColor, sourceEnabled, targetEnabled, selected } : WorkflowNodeTemplatePropsProps) {
    const { t } = useTranslation()
    const block = getWorkflowNode(descriptorId)
    const { getContextOf, getWorkflowNodeValuesById } = useWorkflowEditor()
    const label = t(getWorkflowNodeLabel(descriptorId))
    const nameInput = useRef<HTMLInputElement>(null)
    const allowCustomName = useMemo(() => {
        return !isInput(block) && !isOutput(block) && !isControlFlow(block);
    }, [block])
    const [workflowNodeValues, setWorkflowNodeValues] = useState<WorkflowNodeValues>()
    const [workflowCtx, setWorkflowCtx] = useState<WorkflowNodeContext>()
    useEffect(() => {
        setWorkflowNodeValues(id ? getWorkflowNodeValuesById(id) : undefined)
        setWorkflowCtx(id ? getContextOf(id) : undefined)
        setTimeout(() => {
            nameInput.current?.focus();
        }, 100);
    }, []);
    return <div className={cx(selected ? classes.selected : undefined, classes.block)}>
        { !trayMode && sourceEnabled && <Handle
            type="source"
            position={Position.Bottom}
            style={{ background: '#555',  width: 7, height: 7 }}
            onConnect={(params) => console.log('handle onConnect', params)}
            isConnectable={true} /> }
        { !trayMode && targetEnabled && <Handle
            type="target"
            position={Position.Top}
            style={{ background: '#555', borderRadius: '0px', width: 7, height: 7 }}
            onConnect={(params) => console.log('handle onConnect', params)}
            isConnectable={true}
        />}
            <Group gap={8} className={classes.header} style={{backgroundColor: headerColor, position: 'relative'}}>
                <div style={{marginTop: 5}}>{ icon }</div>
                <div>{trayMode || !allowCustomName ? label : <div style={{display: 'inline-block'}}>
                <Tooltip label={label}>
                    <input ref={nameInput} 
                        autoComplete="off" 
                        className={classes.headerInput} 
                        type="text" 
                        placeholder="Enter name of rule"
                        onInput={e => workflowNodeValues?.setName(e.currentTarget.value)}
                    ></input>
                    </Tooltip>

                    </div>}</div>

                <div style={{position: 'absolute', right: 10}}>
                    { !trayMode && <ActionMenu id={id!} descriptorId={descriptorId} workflowCtx={workflowCtx} workflowNodeValues={workflowNodeValues}></ActionMenu> }
                </div>
            </Group>
            <Group justify="space-between" className={cx(classes.content, !trayMode && (filled || !block.configureForm) ? classes.filled : undefined)} gap={5}>
                { !trayMode && <div style={{color: '#c0c0c0', position: 'absolute', fontSize: '0.7rem', bottom: 4, right: 8}}>{label}</div> }
                { trayMode && <div style={{color: '#c0c0c0'}}>Drag & Drop</div> }
                <div>
                    { (block.configureForm && !trayMode) && <>
                        { block.embeddedForm ? <div style={{paddingBottom: 15}}>{block.embeddedForm(workflowCtx!, workflowNodeValues!)}</div> : <div style={{display:'flex', alignItems: 'center', gap: 5}}>Configure <TiWarning color="#e6c200" /> </div> }
                    </> }
                </div>
            </Group>
            {children}
        </div>
}
