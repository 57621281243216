import { createContext, useContext, useEffect, useState } from 'react'
import { ContextApi, MeContext } from '../client'
import moment from 'moment'

type ContextProps = {
    userCtx: MeContext;
    reloadCtx: () => Promise<MeContext>;
    isOperator: () => boolean;
}

const AuthProviderContext = createContext({ userCtx: { loggedIn: false } } as ContextProps)

type AuthInfo = {
    userCtx?: MeContext
    date?: Date
}

async function getUserCtx(cur: AuthInfo, force: boolean) : Promise<AuthInfo> {
    try {
        if (force || !cur.userCtx || moment.duration(moment().diff(cur.date)).asMinutes() >= 5) {
            cur.date = new Date()
            cur.userCtx = (await new ContextApi().meContext()).data
        }
    } catch(err) {
        delete cur.date;
        delete cur.userCtx;
    }
    return cur;
}

export function AuthProvider({ children, reloadCsrf }: { children: JSX.Element | JSX.Element[], reloadCsrf: () => Promise<string>}) {
    const [authInfo, setAuthInfo] = useState({} as AuthInfo)
    const [userCtx, setUserCtx] = useState({ loggedIn: false } as MeContext)
    const [loaded, setLoaded] = useState(false)
    async function init() {
        const a = await getUserCtx(authInfo, false)
        setAuthInfo(a)
        setUserCtx(a.userCtx || { loggedIn: false })
        setLoaded(true);
    }
    useEffect(() => {
        init()
    }, []);
    return (
        <AuthProviderContext.Provider value={{ 
            userCtx: userCtx,
            reloadCtx: () => getUserCtx(authInfo, true)
                .then(a => {
                    setAuthInfo(a);
                    const u = a.userCtx || { loggedIn: false }
                    if (u.loggedIn) {
                        reloadCsrf()
                    }
                    setUserCtx(u)
                    return u
                }),
            isOperator: () => !!userCtx.user?.tenant?.ops,
         }}>
            { loaded && children }
        </AuthProviderContext.Provider>
    )
}

export const useAuth = () => {
  return useContext(AuthProviderContext)
}