import GraphInteractor from "@/graph/visualize/common/interactor/GraphInteractor";

import DataRenderer from "../renderer/DataRenderer";
import DataBottomBar from "../components/DataBottomBar";
import { useGraphLoader } from "@/graph/visualize/providers/GraphLoaderProvider";
import { useGraphDisplay } from "@/graph/visualize/providers/GraphDisplayProvider";
import { useEffect, useMemo, useState } from "react";
import DataViewerToolbar from "./DataViewerToolbar";
import { useTranslation } from "react-i18next";
import { useTitle } from "@/libs/hooks/useTitle";
import { GraphsApi, InstalledIntegration, IntegrationsApi, SchemaGraph, View, ViewsApi } from "@/libs/client";
import { buildDataNodeElement, buildNavigatorElements } from "../navigator/data.navigator";
import GraphBox from "../../../common/box/GraphBox";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { labelFrom } from "@/libs/utils/label";
import { useProject } from "@/libs/project/ProjectProvider";
import { useContainer } from "@/components/containers/ContainerProvider";
import AiDrawer from "../components/AiDrawer";
import classes from './DataViewer.module.css';
import LazyDataGraph from "../lazy/LazyDataGraph";
import { TreeElement } from "@/graph/visualize/common/navigator/TreeView";
import { sortBy } from "@/libs/utils/sort";
import { DrawerMode } from "@/components/containers/SfyDrawer";
import { deepCopy } from "@/libs/utils/obj";
import { useInfo } from "@/libs/info/InfoProvider";

type DataGraphBoxProps = {
    openBottom: (v: string) => void
    closeBottom: () => void
    dataGraph?: LazyDataGraph
}

function DataGraphBox({ openBottom, closeBottom, dataGraph }: DataGraphBoxProps) {
    if (!dataGraph) {
        return <></>
    }
    const { isAiConfigured } = useInfo()
    const { openDrawer, closeDrawer } = useContainer()
    const { t } = useTranslation()
    const [searchParams] = useSearchParams()
    const { curProject } = useProject()
    const { loadDataGraph, reloadDataGraph, reloadSchemaGraph } = useGraphLoader()
    const [views, setViews] = useState<View[]>([])
    const [values, setValues] = useState<{[key: string]: string}>({
        version: 'v0',
        view: 'none',
        query: searchParams.get("query") ? 'gremlin' : 'none',
        installed: '',
    })
    const fetchViews = async () => {
        setViews((await new ViewsApi().views(curProject.id!)).data)
    }
    useEffect(() => {
        searchParams.get("query") ? openBottom('gremlin') : undefined;
        fetchViews();
    }, [])
    const [installedIntegrations, setInstalledIntegrations] = useState<InstalledIntegration[]>([])
    const fetchInstalledIntegrations = async () => {
        const resp = await new IntegrationsApi().installedIntegrations(curProject.id!, {
            page: 1,
        });
        setInstalledIntegrations(resp.data.list!);
    }
    useEffect(() => {
        fetchInstalledIntegrations()
    }, []);
    return <GraphBox
        title={t("data_graph.label")}
        onValues={vs => setValues(vs)}
        values={values}
        rows={[
            { 
                label: 'Status', 
                key: 'status', 
                valueLabel: t(labelFrom(dataGraph.meta.state!, 'graph.state.')) || '', 
                type: 'BADGE',
                color: { UP_TO_DATE: 'green', SYNCHING: 'blue', OUTDATED: 'red' }[dataGraph.meta.state!]
            },
            { label: 'Version', key: 'version', valueLabel: 'v' + dataGraph.meta.version, type: 'LINK' },
            { label: 'View', key: 'view', options: [
                { value: 'none', label: 'None' },
                ...views.map(v => ({value: v.id!, label: v.name!})),
            ], type: 'SELECT', onChange: (vId) => {
                const view = views.find(v => v.id === vId)
                if (view)
                    loadDataGraph(view.query!)
                else
                    loadDataGraph("")
            } },
            { label: 'Installed', key: 'installed', options: [{ value: '', label: 'All'}, ...installedIntegrations.map(ii => ({
                value: ii.name || '',
                label: ii.name || '',
            }))], type: 'SELECT', onChange: async (v) => {
                await reloadSchemaGraph(v);
                reloadDataGraph(v);
            } },
            { label: 'Query', key: 'query', options: [
                { value: 'none', label: 'None' },
                { value: 'gremlin', label: 'Gremlin' },
                ...(isAiConfigured() ? [{ value: 'ai', label: 'AI' }] :[]),
            ], type: 'SELECT', onChange: (v) => {
                if (values.query === v) {
                    return;
                }
                //TODO include previous
                if (v === 'none') {
                    loadDataGraph("")
                    closeDrawer('ai');    
                    closeBottom();
                } else if(v === 'gremlin') {
                    openBottom(v);
                    closeDrawer('ai');    
                } else if (v === 'ai') {
                    closeBottom();
                    openDrawer('ai', <AiDrawer projectId={curProject.id!} loadDataGraph={loadDataGraph}></AiDrawer>, {
                        hideHeader: true,
                        collapsible: true,
                        mode: DrawerMode.GRAPH,
                    })
                }
            }},
        ]}
    ></GraphBox>
}

export default function DataViewer() {
    const location = useLocation()
    const navigate = useNavigate()
    const {curProject} = useProject()
    const { t } = useTranslation()
    useTitle(t('data_graph.label'))
    const { dataGraph, loadDataGraph, currentQuery, isLoading } = useGraphLoader()
    const { setActiveType } = useGraphDisplay()
    const { includeFields } = useGraphDisplay()
    const [showQuery, setShowQuery] = useState(false)
    const [navElements, setNavElements] = useState<TreeElement[]>([])
    const [fullSchema, setFullSchema] = useState<SchemaGraph>()
    async function loadFullSchema() {
        setFullSchema((await new GraphsApi().schemaGraph(curProject.id!)).data)
    }
    const schemaGraph = useMemo(() => {
        if (!fullSchema || currentQuery) {
            return dataGraph?.meta.schema
        }
        const adaptedSchema = deepCopy(dataGraph?.meta.schema)
        Object.keys(dataGraph?.meta.stats.nodeStats || {})
            .forEach(k => {
                if (adaptedSchema?.entities && fullSchema.entities[k]) {
                    adaptedSchema.entities[k] = fullSchema.entities[k]
                }
            })
        Object.keys(dataGraph?.meta.stats.edgeStats || {})
            .forEach(k => {
                if (adaptedSchema?.relationships && fullSchema.relationships[k]) {
                    adaptedSchema.relationships[k] = fullSchema.relationships[k]
                }
            }) 
        return adaptedSchema
    }, [dataGraph, fullSchema])
    useEffect(() => {
        loadFullSchema()
        loadDataGraph("")
        setActiveType("DATA")
    }, [])
    useEffect(() => {
        if (dataGraph && schemaGraph) {
            setNavElements(buildNavigatorElements(dataGraph, schemaGraph))
        }
    }, [dataGraph, schemaGraph])
    const loading = isLoading() && !fullSchema
    return <GraphInteractor navigatorProps={{ 
            title: t('data_graph.label'), 
            loading,
            elements: navElements,
            onExpand: async (type: string) => {
                const nodes = await dataGraph?.nodesOfType(type) || []
                setNavElements(elems => elems.map(e => {
                    if (e.id === type) {
                        e.children = nodes.map(n => buildDataNodeElement(n)).sort(sortBy(e => e.label))
                    }
                    return e
                }))
            },
        }}>
            <DataViewerToolbar dataGraph={dataGraph}></DataViewerToolbar>
            <div className={classes.window}>
                <DataGraphBox dataGraph={dataGraph!} openBottom={() => setShowQuery(true)} closeBottom={() => {
                    const { pathname } = location;
                    navigate(pathname)
                    setShowQuery(false)
                }}></DataGraphBox>
                { !loading && <DataRenderer
                    dataGraph={dataGraph}
                    schemaGraph={schemaGraph!}
                    includeFields={includeFields}
                    currentQuery={currentQuery || ""}
                ></DataRenderer> }
                <DataBottomBar dataGraph={dataGraph!} showQuery={showQuery}></DataBottomBar>
            </div>
        </GraphInteractor>
}


