import { DataEdge, DataNode } from "@/libs/client"
import { groupBy, groupByMany } from "@/libs/utils/arrays";
import { calculateAverage } from "@/libs/utils/math";
import { levenshteinDistance } from "@/libs/utils/search";
import { sortBy } from "@/libs/utils/sort";
import { Button, Checkbox, Group, Portal, Stack, TextInput, ThemeIcon } from "@mantine/core";
import { useEffect, useMemo, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { PiWarningFill } from "react-icons/pi";
import LazyDataGraph from "../lazy/LazyDataGraph";

type ExpandEdgesProps = {
    id: string;
    dataGraph: LazyDataGraph;
    onSelect: (edges: DataEdge[]) => void
}

function ExpandEdgeRow({ dataGraph, node, edge, selected, onClick }: { dataGraph: LazyDataGraph, node: DataNode, edge: DataEdge, selected: boolean, onClick: () => void }) {
    const [sourceNode, setSourceNode] = useState<DataNode>()
    const [targetNode, setTargetNode] = useState<DataNode>()
    const [loaded, setLoaded] = useState(false)
    const fetchNodes = async () => {
        await dataGraph.nodesByIds([edge.source?.id!, edge.target?.id!])
        setSourceNode(await dataGraph.nodeById(edge.source?.id!));
        setTargetNode(await dataGraph.nodeById(edge.target?.id!))
        setLoaded(true)
    }
    useEffect(() => {
        fetchNodes()
    }, [])
    return loaded && <Group style={{userSelect: sourceNode && targetNode ? 'none' : undefined, marginTop: 5, cursor: sourceNode && targetNode ? 'pointer' : undefined, padding: '1rem 2rem', border: '1px solid #e0e0e0', borderRadius: 4, backgroundColor: selected ? '#12b886': undefined}} onClick={sourceNode && targetNode ? onClick : undefined}>
        { sourceNode && targetNode ? <Checkbox checked={selected}></Checkbox> : <ThemeIcon color="yellow" variant="light">
            <PiWarningFill size="2rem"></PiWarningFill>
        </ThemeIcon> }
        <Stack gap={3} style={{flex: 1}}>
            <div style={{fontSize: '0.95rem', color: selected ? 'white' : '#5f5f5f', paddingRight: '0.5rem'}}>
                {
                    sourceNode?.id === node.id ? targetNode?.display : sourceNode?.display
                }
                { !sourceNode ?  `Missing ${edge.source?.type}: ` + edge.source?.oid : '' }
                { !targetNode ?  `Missing ${edge.target?.type}: ` + edge.target?.oid : '' }
            </div>
            <div style={{fontSize: '0.75rem', wordBreak: 'break-all', color: selected ? 'white' : '#a0a0a0'}}>
                {
                    sourceNode?.id === node.id ? targetNode?.id : sourceNode?.id
                }
            </div>
        </Stack>
    </Group>
}
export function ExpandEdges({ id, dataGraph, onSelect } : ExpandEdgesProps) {
    const [search, setSearch] = useState("")
    const [selected, setSelected] = useState<{[key: string]: boolean}>({})
    const [node, setNode] = useState<DataNode>()
    const [availableEdges, setAvailableEdges] = useState<DataEdge[]>([])
    const [loading, setLoading] = useState(true);
    const fetchNode = async () => {
        setNode(await dataGraph.nodeById(id))
        setLoading(true)
        setAvailableEdges((await Promise.all([dataGraph.edgesWithSource(id), dataGraph.edgesWithTarget(id)])).flatMap(e => e))
        setLoading(false)
    }
    useEffect(() => {
        fetchNode()
    }, [id])
    const edgesMap = useMemo(() => groupBy(availableEdges || [], e => e.id!), [availableEdges])
    const edgeGroups = useMemo(() => {
        const keyBuilder = (e: DataEdge) => {
            if (e.source?.type === node?.type) {
                return `${e.name} -> ${e.target?.type}`;
            }
            return `${e.source?.type} <- ${e.name}`
        }
        const edges = groupByMany(availableEdges, (e) => {
            return keyBuilder(e)
        });
        return [...edges.entries()].sort(sortBy(e => e[0]))
    }, [availableEdges])
    const selectedAsList = useMemo(() => {
        return Object.entries(selected).filter(s => s[1]).map(s => edgesMap.get(s[0])!)
    }, [selected, edgesMap])
    const allSelected = useMemo(() => {
        return availableEdges.length === selectedAsList.length;
    }, [selectedAsList])
    const selectAll = () => {
        if (allSelected) {
            setSelected({})
        } else {
            setSelected(availableEdges.reduce((acc, cur) => {
                acc[cur.id!] = true
                return acc;
            }, {} as {[key: string]: boolean}))
        }
    }
    const selectedEdgeTypes = useMemo(() => {
        return edgeGroups.reduce((acc, cur) => {
            acc[cur[0]] = !cur[1].find(c => !selected[c.id!])
            return acc;
        }, {} as {[key:string]: boolean})
    }, [selected, edgeGroups]);
    const selectEdgeType = (key: string) => {
        const edgeToSelect = edgeGroups.find(eg => eg[0] === key)![1]
        const newSelected = {
            ...selected,
        } 
        edgeToSelect.forEach(es => {
            newSelected[es.id!] = !selectedEdgeTypes[key]
        })
        setSelected(newSelected);
    }
    const searchedEdgeGroups: [string, DataEdge[]][] = useMemo(() => {
        const splitSearch = search.split(/\s+/).map(s => s.toLowerCase()).filter(s => s)
        if (!splitSearch.length) {
            return edgeGroups;
        }
        const searchRank = (vs: string[]) => {
            const avgRanks = vs.map(v => calculateAverage(splitSearch.map(ss => v?.includes(ss) ? 0 : levenshteinDistance(ss, v || ''))))
            const minRanks = vs.map(v => Math.min(...splitSearch.map(ss => v?.includes(ss) ? 0 : levenshteinDistance(ss, v || ''))))
            return [Math.min(...minRanks), calculateAverage(avgRanks)]
        }
        const doSearchEdges = (edges: DataEdge[]): [DataEdge[], number] => {
            const edgeWithScores = edges.map(e => [e, ...searchRank([e.source?.oid, e.source?.type, e.source?.id, e.name, e.target?.oid,  e.target?.type, e.target?.id].map(p => p?.toLowerCase() || ''))] as [DataEdge, number, number])
            .filter(e => e[1] < 6)
            .sort(sortBy(e => e[2]))
            .sort(sortBy(e => e[1]))
            return [edgeWithScores.map(e => e[0]), Math.min(...edgeWithScores.map(e => e[2]))]
        }
        return edgeGroups.map(eg => [eg[0], ...doSearchEdges(eg[1])] as [string, DataEdge[], number])
            .sort(sortBy(e => e[2]))
            .filter(e => e[1].length)
            .map(e => [e[0], e[1]])
    }, [edgeGroups, search])
    return node && !loading && <div style={{paddingBottom: 30}}>
        <Portal target="#sfy-modal-header">
            <div style={{position: 'absolute', marginTop: 20, paddingBottom: 5, right: 0, paddingLeft: 60, paddingRight: 60, backgroundColor: 'white', width: '100%'}}>
                <Group>
                <div style={{flex: 1, fontSize: '1rem'}}>
                <TextInput
                    leftSection={<FaSearch color="#12b886"></FaSearch>}
                    size="md"
                    styles={{ input: { border: 0, borderBottom: '1px solid #12b886', borderRadius: '0px !important'}}}
                    placeholder={`Search for Edge`}
                    value={search}
                    onInput={e => setSearch(e.currentTarget.value)}
                ></TextInput>
                    <div style={{marginTop: 15, marginBottom: 10}}>
                        <Checkbox checked={allSelected} onClick={selectAll} label={"Select All"}></Checkbox>
                    </div>
                </div>
                <Group>
                    <Button onClick={() => {
                        onSelect(selectedAsList);
                    }}>{'Done'}</Button>
                </Group>
            </Group>
            </div>

        </Portal>
        <div style={{paddingTop: 100}}>
            {
                searchedEdgeGroups.map((e) => <Stack gap={0} style={{marginTop: 20}}>
                    <div style={{ backgroundColor: '#1f1f1f', borderTopRightRadius: 10, borderTopLeftRadius: 10, padding: '15px 5px'}}>
                        <Group style={{paddingLeft: 20}}>
                            <Checkbox styles={{ label: {color: 'white'}}} checked={selectedEdgeTypes[e[0]]} onClick={() => selectEdgeType(e[0])} label={e[0]}></Checkbox>
                        </Group>
                    </div>
                    <div style={{border: '1px solid #f0f0f0', padding: 10, paddingTop: 5}}>
                        {e[1].map(edge => 
                            <ExpandEdgeRow key={edge.id!} dataGraph={dataGraph} node={node} edge={edge} selected={selected[edge.id!]} onClick={() => setSelected({ ...selected, [edge.id!]: !selected[edge.id!] })}></ExpandEdgeRow>
                        )}
                    </div>
                </Stack>)
            }
        </div>
    </div>
}