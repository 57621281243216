import { useTranslation } from "react-i18next";
import SfyForm from "@/components/form/SfyForm";
import { Group, Text, useMantineTheme, rem, Avatar, useMantineColorScheme } from '@mantine/core';
import { Dropzone, DropzoneProps, FileWithPath, MIME_TYPES } from '@mantine/dropzone';
import { ImUpload } from "react-icons/im";
import { FaRegTimesCircle } from "react-icons/fa";
import { useCallback, useState } from "react";
import { TbFileZip } from "react-icons/tb";
import { prettyBytes } from "@/libs/utils/bytes";
import { AxiosError } from "axios";
import { ApiError } from "@/libs/common/errors";
import { Commit } from "@/libs/client";
import isApiError from "@/libs/utils/errors";
import { ActionResultType } from "@/components/actions/actions";

function ZipFile({ name, size }: {name: string, size: number}) {
  return <Group wrap="nowrap">
        <Avatar size={94} radius="md">
          <TbFileZip></TbFileZip>
        </Avatar>
        <div>
          <Text fz="lg" fw={500}>
            {name}
          </Text>
          <Group wrap="nowrap" gap={10} mt={3}>
            <Text fz="xs" c="dimmed">
              { prettyBytes(size) }
            </Text>
          </Group>
        </div>
      </Group>
}

function UploadBox(props: Partial<DropzoneProps>) {
  const theme = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  return (
    <Dropzone
      onDrop={(files) => { props.onDrop && props.onDrop(files) }}
      onReject={() => alert('rejected file')}
      maxSize={50 * 1024 ** 2}
      accept={[MIME_TYPES.zip]}
      maxFiles={1}
      {...props}
    >
      <Group justify="center" gap="xl" style={{ minHeight: rem(220), pointerEvents: 'none' }}>
        <Dropzone.Accept>
          <ImUpload
            size="5.2rem"
            color={theme.colors[theme.primaryColor][colorScheme === 'dark' ? 4 : 6]}
          />
          
        </Dropzone.Accept>
        <Dropzone.Reject>
          <FaRegTimesCircle
            size="3.2rem"
            color={theme.colors.red[colorScheme === 'dark' ? 4 : 6]}
          />
        </Dropzone.Reject>
        <Dropzone.Idle>
          <ImUpload size="5.2rem" ></ImUpload>
        </Dropzone.Idle>

        <div>
          <Text size="xl" inline>
            Drag a zip file or click to select one from finder
          </Text>
          <Text size="sm" color="dimmed" inline mt={7}>
            Zip file should not exceed 50mb
          </Text>
        </div>
      </Group>
    </Dropzone>
  );
}

type UploadGraphProps = {
  activeType: string
  commit: Commit
  onClose: () => void
  onRefresh: () => void
  onSuccess: () => Promise<void>
}

export default function UploadGraph({ activeType, onSuccess, onClose, onRefresh } : UploadGraphProps) {
    const [files, setFiles] = useState<FileWithPath[]>([])
    const { t } = useTranslation()

    const onSubmit = useCallback(async () => {
      try {
        if (activeType === 'DATA') {
          //TODO await new GraphsApi().uploadData(commit?.id!, files[0])
        } else if(activeType === 'SCHEMA') {
          //TODO await new GraphsApi().uploadSchema(commit?.id!, files[0])
        }
      } catch(err) {
        let errors = undefined
        if (isApiError(err)) {
          errors = (err as AxiosError<ApiError[]>).response?.data.map(e => `${e.args?.path}: ${e.message}`)
        }
        alert('err')
        return { type: ActionResultType.FAILURE, errors }
      }
      if (onRefresh) {
        onRefresh()
      }
      return { type: ActionResultType.SUCCESS }
    }, [activeType, files])
    return <SfyForm 
          submitLabel={t("Upload graph")} 
          onClose={onClose}
          disabled={!files.length}
          onSuccess={onSuccess}
          onSubmit={onSubmit}
          successLabel={t("Successfully uploaded graph!")}
          errorLabel={t("Unable to upload graph.")}
        >
        <div style={{marginTop: 20}}>
            <UploadBox onDrop={setFiles}></UploadBox>
            <div style={{marginTop: 20}}>
              {files.map(f => <ZipFile key={f.name} name={f.name} size={f.size}></ZipFile>)}
            </div>
        </div>
    </SfyForm>
}