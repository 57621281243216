import { ActionResultType } from "@/components/actions/actions";
import { useContainer } from "@/components/containers/ContainerProvider";
import FormSelect from "@/components/form/inputs/FormSelect";
import SfyForm from "@/components/form/SfyForm";
import { Deployment, DeploymentsApi } from "@/libs/client";
import { useEffect, useState } from "react";


export default function RedeployForm({ deployment, onRefresh }: { deployment: Deployment, onRefresh: () => void }) {
    const { closeAllModals } = useContainer()
    const [version, setVersion] = useState<string>()
    const [availableVersions, setAvailableVersions] = useState<string[]>()
    const doSubmit = async () => {
        const resp = await new DeploymentsApi().redeployDeployment(deployment.id!!, version!)
        if (resp.status >= 300) {
            return { type: ActionResultType.FAILURE };    
        }
        onRefresh()
        return { type: ActionResultType.SUCCESS };    
    }
    const fetchAvailableVersions = async () => {
        const resp = (await new DeploymentsApi().availableVersions(deployment.id!!)).data
        setAvailableVersions(resp)
        setVersion(resp[0])
    }
    useEffect(() => {
        fetchAvailableVersions()
    }, [])
    return availableVersions && <SfyForm onClose={closeAllModals} onSubmit={doSubmit} disabled={version === deployment.deploymentVersion?.version}> 
        <FormSelect
            label="Versions"
            description="Choose a version to deploy"
            withAsterisk
            defaultValue={availableVersions[0]}
            data={availableVersions.map(av => ({
                label: av + (av === availableVersions[0] ? ' (Latest)' : ''),
                value: av,
            }))}
            onChange={e => setVersion(e!)}
        />
    </SfyForm>
}