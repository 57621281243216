import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useTitle } from "@/libs/hooks/useTitle";
import { GraphLoaderProvider } from "./providers/GraphLoaderProvider";
import { GraphDisplayProvider } from "./providers/GraphDisplayProvider";
import { Outlet } from "react-router-dom";
import { initCy } from "./render/cy.utils";
import classes from './GraphsLanding.module.css';

initCy()


export default function GraphsLanding() {
    const { t } = useTranslation()
    useTitle(t("graph.label"))

    useEffect(() => {
        //fetchGraph()
            //.then(res => setGraph(res.graph))
    }, [])
    return <div className={classes.graphContainer}>
        <GraphDisplayProvider>
            <GraphLoaderProvider>
                <Outlet></Outlet>
            </GraphLoaderProvider>
        </GraphDisplayProvider>
    </div>
}