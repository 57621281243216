import MultiStepForm from "@/components/form/MultiStepForm";
import EntityFieldsForm from "./EntityFieldsForm";
import EntitySetupForm from "./EntitySetupForm";
import { useCallback } from "react";
import { CommitsApi, Position, SchemaEntity } from "@/libs/client";
import { useForm } from "@mantine/form";

type AddEntityProps = {
    onClose: () => void;
    commitId: string;
    reloadCommit: () => void;
    position: Position;
    mergedEntity?: SchemaEntity;
}

export default function AddEntity({ position, onClose, commitId, reloadCommit } : AddEntityProps) {    
    const form = useForm({
        initialValues: {} as SchemaEntity,
        validate: {
            type: v => !v ? 'Entity type is required' : undefined,
        }
    })
    const onSubmit = useCallback(async () => {
        const resp = await new CommitsApi().addChanges(commitId, [{
            addEntity: {
                entity: { ...form.values },
                position: position,
            }
        }])
        if (resp.status === 200) {
            reloadCommit()
        }
    }, [commitId, form.values])
    
    return <MultiStepForm
        form={form}
        onSubmit={onSubmit}
        onClose={onClose}
        steps={[
            {
                label: 'Entity',
                description: 'Set Up',
                renderContent: (props) => <EntitySetupForm {...props}></EntitySetupForm>,
            },
            {
                label: 'Fields',
                description: 'Describe Fields',
                renderContent: (props) => <EntityFieldsForm {...props}></EntityFieldsForm>
            },
        ]}
    ></MultiStepForm>
}