
export function buildDataLayout(userDefined: boolean) : any {
    if (userDefined) {
        return {
            name: 'cose-bilkent',
            idealEdgeLength: 800,
            nodeDimensionsIncludeLabels: true,
            edgeElasticity: 0.005,
            fit: true,
            randomize: false,
            nodeRepulsion: 5000,
        };
    }
    return {
        name: 'cola',
        edgeLength: 500,
        nodeDimensionsIncludeLabels: true,
        fit: true,
        randomize: true,
        centerGraph: false,
        avoidOverlap: true,
        convergenceThreshold: 1,
        nodeSpacing: function(){ return 50; },
        handleDisconnected: true,
        maxSimulationTime: 500,
    };
}