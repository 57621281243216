import { Stylesheet } from "cytoscape";
import { SCHEMA_NEW_COLOR, SCHEMA_NODE_HEIGHT, SCHEMA_NODE_WIDTH, SCHEMA_REMOVED_COLOR, SCHEMA_SELECTED_COLOR } from "./schema.constants";


export const SCHEMA_GRAPH_STYLES : Stylesheet[] = [
    {
        selector: 'node',
        css: {
          content: ' ',
        },
        style: {
          content: '',
          'background-color': 'white',
          shape: 'round-rectangle',
          width: SCHEMA_NODE_WIDTH,
          height: SCHEMA_NODE_HEIGHT,
        },
      },
    {
        selector: "edge",
        style: {
            'color': 'black',
            'width': 1,
            'line-color': '#a0a0a0',
            'target-arrow-color': '#a0a0a0',
            'target-arrow-shape': 'triangle',
            'curve-style': 'bezier',
            'arrow-scale': 2,
            'label': 'data(label)',
            'font-size': 20,
            'text-outline-width': 2,
            'text-outline-color': 'white',
            'text-rotation': 'autorotate',
        },
    },
    {
      selector: 'edge[type = "new"]',
      style: {
          'font-size': 24,
          'width': 2,
          'color': SCHEMA_NEW_COLOR,
          'line-color': SCHEMA_NEW_COLOR,
          'target-arrow-color': SCHEMA_NEW_COLOR,
      },
  },
  {
    selector: 'edge[type = "removed"]',
    style: {
        'font-size': 24,
        'width': 2,
        'color': SCHEMA_REMOVED_COLOR,
        'line-color': SCHEMA_REMOVED_COLOR,
        'target-arrow-color': SCHEMA_REMOVED_COLOR,
    },
  },
    {
        selector: 'edge:selected',
        style: {
            'line-color': SCHEMA_SELECTED_COLOR,
            'target-arrow-color': SCHEMA_SELECTED_COLOR,
            'width': 2,
        },
    },
    {
      selector: ".eh-preview, .eh-ghost-edge",
      style: {
        'background-color': SCHEMA_NEW_COLOR,
        'line-color': SCHEMA_NEW_COLOR,
        'target-arrow-color': SCHEMA_NEW_COLOR,
        'source-arrow-color': SCHEMA_NEW_COLOR,
      },
    },
    {
      selector: ".eh-ghost-edge.eh-preview-active",
      style: {
          'color': 'transparent',
          'width': 0,
      },
    },
];
