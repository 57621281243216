import { Commit, CommitsApi } from '@/libs/client';
import { useProject } from '@/libs/project/ProjectProvider';
import { GraphType } from '@/libs/utils/graph';
import { createContext, useContext, useState } from 'react';


type GraphEditorContextProps = {
    commit?: Commit
    openCommit: (gt: GraphType) => void
    loadingCommit: boolean
    reloadCommit: () => void
    synching: boolean
    setCommit: (commit: Commit) => void
}


export const GraphEditorContext = createContext({
} as GraphEditorContextProps);

export const useGraphEditor = () => useContext(GraphEditorContext);

export const GraphEditorProvider = ({ children } : { children: JSX.Element | JSX.Element[]}) => {
    const [commit, setCommit] = useState<Commit | undefined>()
    const [loadingCommit, setLoadingCommit] = useState(true)
    const [synching, setSynching] = useState(false)
    const { curProject } = useProject()

    const openCommit = async (gt: GraphType) => {
        setLoadingCommit(true)
        try {
            const resp = await new CommitsApi().openCommit(curProject.id!, {
                graphType: gt,
            })
            setCommit(resp.data)
            setLoadingCommit(false)
            return true
        } catch(err: any) {
            const error = err.response.data.errors[0]
            if (error.code === 'GRAPH_SYNCHING_ERROR') {
                setSynching(true)
            } else {
                //TODO
            }
        }
        setLoadingCommit(false)
        return false
    }
    const reloadCommit = async () => {
        setLoadingCommit(true)
        const resp = await new CommitsApi().commit(commit!.id!)
        setCommit(resp.data)
        setLoadingCommit(false)
    }
    return (
        <GraphEditorContext.Provider value={{ 
            commit,
            openCommit,
            setCommit: (commit: Commit) => {
                setCommit(commit)
                setLoadingCommit(false)
            },
            loadingCommit,
            reloadCommit,
            synching,
        }}>
            {children}
        </GraphEditorContext.Provider>
    );
};