
export function replaceKeywords(inputString: string, replacements: any): string {
  const regex = /\${([^{}]+)}/g;
  return inputString.replace(regex, (_, key) => replacements[key] || '');
}

export function truncate(str: string, length: number) {
  if (str.length > length) {
      return str.substring(0, length) + '...';
  }
  return str;
}


function escapeRegExp(str: string): string {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export const boldify = (message: string, context: {[key: string]: any}) : string => {
  const valuesToBold = Object.values(context);
  
  const regex = new RegExp(valuesToBold.map(value => `(${typeof value === 'string' ? escapeRegExp(value) : value})`).join('|'), 'g');

  return message.replace(regex, '<b>$&</b>');
}

export function isURL(str: string): boolean {
  const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
  return urlRegex.test(str);
}

export function isBase64(str: string): boolean {
  try {
      const base64Regex = /^(?:[A-Za-z0-9+\/]{4})*?(?:[A-Za-z0-9+\/]{2}(?:==)?|[A-Za-z0-9+\/]{3}=?)?$/;
      return base64Regex.test(str);
  } catch (error) {
      return false;
  }
}