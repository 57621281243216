import EntityPage from "@/components/page/EntityPage"
import { TabData } from "@/components/page/EntityTabs"
import { useProject } from "@/libs/project/ProjectProvider"
import { useTranslation } from "react-i18next"

export default function ScriptsLanding() {
    const { t } = useTranslation()
    const { projectUrl } = useProject()
    return <EntityPage
        category={t('common.code')}
        title={t('Scripts')}
        tabs={[{
            key: 'report_scripts',
            label: "Reports",
            route: projectUrl(`/analysis/scripts/reports`),
        }, {
            key: 'condition_scripts',
            label: "Conditions",
            route: projectUrl(`/analysis/scripts/conditions`),
        }] as TabData[]}
    ></EntityPage>
}