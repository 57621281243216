import { ActionResultType } from "@/components/actions/actions";
import SfyForm from "@/components/form/SfyForm";
import FormTextInput from "@/components/form/inputs/FormTextInput";
import { View, ViewsApi } from "@/libs/client";
import { useForm } from "@mantine/form";
import CodeMirror from "react-codemirror"
import classes from './AddView.module.css';

type UpdateViewProps = {
    view: View;
    onClose: () => void;
}

export default function UpdateView({ view, onClose }: UpdateViewProps) {
    const form = useForm({
        initialValues: {
            name: view.name || '',
            query: view.query || '',
        },
        validate: {
            name: (str) => !str,
        },
    });

    const doSubmit = async () => {
        await new ViewsApi().updatedView(view.id!, {
            ...form.values,
        })
        return { type: ActionResultType.SUCCESS };    
    }
    return <SfyForm onClose={onClose} onSubmit={doSubmit} disabled={!form.isValid()}> 
        <FormTextInput
            label="Name"
            description="Name of the view"
            withAsterisk
            {...form.getInputProps('name')}
        />
        <label className={classes.label}>Gremlin Query</label>
        <CodeMirror className={classes.query} {...form.getInputProps('query')} options={{lineNumbers: true, mode: 'groovy', theme: 'neo'}}></CodeMirror>
    </SfyForm>
}