import { ActionResultType } from "@/components/actions/actions";
import { useContainer } from "@/components/containers/ContainerProvider";
import SfyForm from "@/components/form/SfyForm"
import FormTextInput from "@/components/form/inputs/FormTextInput";
import { Project, ProjectsApi, CreateProject } from "@/libs/client";
import { useProject } from "@/libs/project/ProjectProvider";
import { useForm } from "@mantine/form";

type ProjectFormProps = {
    onRefresh: () => void
    update: boolean
    project?: Project,
}

export default function ProjectForm({ update, project, onRefresh }: ProjectFormProps) {
    const { loadProjects } = useProject()
    const form = useForm({
        initialValues: {
            name: '',
            ...project,
            backend: 'SQL',
        },
    })
    const { closeAllModals } = useContainer()
    return <SfyForm
        onSuccess={() => {
            onRefresh()
        }}
        onSubmit={async () => {
            const resp = update ? 
                await new ProjectsApi().updateProject(project!.id!, form.values as CreateProject) :
                await new ProjectsApi().createProject(form.values as CreateProject)
                await loadProjects()
            if (resp.status === 201 || resp.status === 204) {
                return { type: ActionResultType.SUCCESS }
            }
            return { type: ActionResultType.FAILURE }
        }}
        disabled={!form.values.name}
        onClose={closeAllModals}
    >
        <FormTextInput
            label="Name"
            description="Unique name of the project"
            {...form.getInputProps('name')}
        ></FormTextInput>
    </SfyForm>
}