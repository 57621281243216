

import { buildDataChangeProps } from "@/graph/visualize/render/data/changes/data.changes"
import { buildSchemaChangeProps } from "@/graph/visualize/render/schema/changes/schema.changes"
import CommitHistoryList from "@/graph/history/CommitHistoryList"
import { Commit, CommitsApi, IntegrationsApi } from "@/libs/client"
import { useProject } from "@/libs/project/ProjectProvider"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

export default function InstalledIntegrationCommits() {
    const { curProject } = useProject()
    const [commits, setCommits] = useState<Commit[]>([])
    const [page, setPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0)
    const [loading, setLoading] = useState(true)
    const { id } = useParams()

    const fetchInstalledIntegration = async () => {
        const resp = await new IntegrationsApi().installedIntegration(id!)
        return resp.data
    }

    const fetchCommits = async (page = 1) => {
        setLoading(true)
        const installed = await fetchInstalledIntegration()
        const resp = await new CommitsApi().listCommitsForBuckets(curProject.id!, installed.buckets?.map(b => b.id!) || [], page, 15)
        setCommits(resp.data.list || [])
        setPage(resp.data.page || 0)
        setTotalPages(resp.data.totalPages || 0)
        setLoading(false)
    }
    useEffect(() => {
        fetchCommits()
    }, [])
    return !loading && <CommitHistoryList commits={commits} page={page} totalPages={totalPages} onPageChange={fetchCommits} getDetails={c => {
        const props = buildDataChangeProps(c)
        if (props.detail) {
            return props
        }
        return buildSchemaChangeProps(c)
    }}></CommitHistoryList>

}