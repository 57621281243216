import { useProject } from "@/libs/project/ProjectProvider"
import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { useTitle } from "@/libs/hooks/useTitle"
import EntityPage from "@/components/page/EntityPage"
import { TabData } from "@/components/page/EntityTabs"
import { ScanTask, ScansApi } from "@/libs/client"
import { useTranslation } from "react-i18next"

export default function ScanTaskLanding() {
    const { t } = useTranslation()
    const { taskId, id } = useParams()
    const { projectUrl } = useProject()
    const [scanTask, setScanTask] = useState<ScanTask>()
    const fetchScan = async () => {
        setScanTask((await new ScansApi().scanTask(taskId!)).data)
    }
    useEffect(() => {
        fetchScan()
    }, [])
    if (scanTask)
        useTitle(`${scanTask.name} - Scan Task`)

    return scanTask && <EntityPage
        category={`Scans`}
        title={scanTask.name || ''}
        tabs={[{
            key: 'details',
            label: "Details",
            route: projectUrl(`/analysis/scans/installed/${id}/scan_tasks/${taskId}/details`),
        }] as TabData[]}
        breadcrumbs={[
            {
                key: 'installed_scans',
                label: 'Installed Scans',
                link: projectUrl(`/analysis/scans/installed`),
            }, {
                key: scanTask.installedScan?.scanDefinition?.category!,
                label: t(`scans.categories.${scanTask.installedScan?.scanDefinition?.category}`),
                link: projectUrl(`/analysis/scans/installed/${scanTask.installedScan?.scanDefinition?.category}`),
            }, {
                key: scanTask.installedScan?.id!!,
                label: scanTask.installedScan?.scanDefinition?.label!,
            },
            {
                key: 'scan_tasks',
                label: "Scan Tasks",
                link: projectUrl(`/analysis/scans/installed/${scanTask.installedScan?.scanDefinition?.category}/${scanTask.installedScan?.id}/scan_tasks`),
            },
            {
                key: scanTask.id!,
                label: scanTask.name!,
            }
        ]}
    ></EntityPage>
}