import FormCheckbox from "@/components/form/FormCheckbox"
import FormSelect from "@/components/form/inputs/FormSelect"
import { NumberInput, Stack } from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import { useEffect } from "react"

export function ConfigureScanAlertForm({ formData, update }: { formData: UseFormReturnType<any, any>, update: boolean }) {
    useEffect(() => {
        if (!update) {
            formData.setFieldValue('slaConfig', {
                ...formData.values.slaConfig,
            })
        }
    }, [])
    return <form>
        <Stack>
            <FormCheckbox 
                label="Enable alerting of issue"
                {...formData.getInputProps("slaConfig.enabled")}
            ></FormCheckbox>
            <FormSelect
                label="Period"
                defaultValue={"DAYS"}
                data={[{
                    value: 'DAYS',
                    label: 'Days'
                },{
                    value: 'HOURS',
                    label: 'Hours'
                },]}
                disabled={!formData.values.slaConfig.enabled}
            ></FormSelect>
            <NumberInput
                label="Duration"
                min={0}
                max={1000}
                disabled={!formData.values.slaConfig.enabled}
                {...formData.getInputProps('slaConfig.duration')}
            ></NumberInput>
        </Stack>
    </form>
}