import WorkflowEditorProvider from "./WorkflowEditorProvider";
import WorkflowEditorHeader from "./WorkflowEditorHeader";
import WorkflowRenderer from "./WorkflowRenderer";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Workflow, WorkflowsApi } from "@/libs/client";

export default function WorkflowEditor() {
    const { id } = useParams()
    const [loading, setLoading] = useState(id != null);
    const [initialWorkflow, setInitialWorkflow] = useState<Workflow>()
    const fetchWorkflow = async () => {
        if (id) {
            const resp = await new WorkflowsApi().workflow(id)
            setInitialWorkflow(resp.data)
            setLoading(false)
        }
    }
    useEffect(() => {
        fetchWorkflow()
    }, [])
    return !loading && <div>
            <WorkflowEditorProvider>
                <WorkflowEditorHeader initialWorkflow={initialWorkflow}></WorkflowEditorHeader>
                <WorkflowRenderer initialWorkflow={initialWorkflow}></WorkflowRenderer>
            </WorkflowEditorProvider>
        </div>
}