
export function groupBy<K, V>(arr: V[], keyBuilder: (v: V) => K): Map<K, V> {
    return arr.reduce((acc, cur) => {
        acc.set(keyBuilder(cur), cur)
        return acc
    }, new Map<K, V>())
}


export function groupByMany<K, V>(arr: V[], keyBuilder: (v: V) => K): Map<K, V[]> {
    return arr.reduce((acc, cur) => {
        const key = keyBuilder(cur)
        acc.set(key, acc.get(key) || [])
        acc.get(key)?.push(cur)
        return acc
    }, new Map<K, V[]>())
}

export function uniqueBy<K, V>(arr: V[], keyBuilder: (v: V) => K): V[] {
    return [...groupBy(arr, keyBuilder).values()]
}