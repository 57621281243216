import { useTranslation } from "react-i18next";
import { DisplayElemSize } from "@/components/table/EntityTable";
import { Project, ProjectsApi } from "@/libs/client";
import { useTitle } from "@/libs/hooks/useTitle";
import EntityPage from "@/components/page/EntityPage";
import ListSection, { ListSectionCallbacks } from "@/components/page/sections/ListSection";
import { useContainer } from "@/components/containers/ContainerProvider";
import { useRef } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import ProjectForm from "./actions/ProjectForm";
import { ActionResultType } from "@/components/actions/actions";
import { useProject } from "@/libs/project/ProjectProvider";


export default function ProjectsLanding() {
    const { t } = useTranslation();
    const { openModal, openConfirm } = useContainer();
    const { loadProjects } = useProject()
    const listRef = useRef<ListSectionCallbacks>()
    useTitle(t("projects.label"))
    const headers = [
        t('common.name'),
    ];
    return <EntityPage
        category={t('common.manage')}
        title={t("projects.label")}>
        <ListSection<Project>
            callbacks={listRef}
            load={async (page) => {
                const resp = await new ProjectsApi().projects(page);
                if (resp.status === 200) {
                    return resp.data;
                }
                return { error: 'TODO' };
            }}
            headers={headers}
            renderRow={(e) => ({
                cols: [{
                        display: [{
                            label: e.name || '',
                            size: DisplayElemSize.lg,
                        }], 
                    },
                ],
            })}
            addLabel={t('Add Project')}
            onAdd={() => openModal('add_project_form', <ProjectForm update={false} onRefresh={() => listRef.current?.refresh()}></ProjectForm>, {
                title: t('Add Project') || ''
            })}
            rowActions={() => {
                return [{
                    label: t('common.update'),
                    icon: <FaEdit></FaEdit>,
                    execute: async (p) => {
                        openModal('update_project_form',
                            <ProjectForm update={true} project={p} onRefresh={() => listRef.current?.refresh()}></ProjectForm>,
                            { title: t('Update Project') || '' }
                        )
                    }
                }, {
                    label: t('common.delete'),
                    icon: <FaTrash></FaTrash>,
                    execute: async (p) => {
                        openConfirm('delete_project', {
                            title: 'Delete Project',
                            confirmationText: <>Are you sure you want to delete the project <b>{p.name}</b>? This will delete all data related to this project.</>,
                            cancelText: "No don't delete",
                            submitText: "Yes, Delete",
                            confirmInput: {
                                data: p.name || '',
                                placeholder: 'Please enter the name of the project to delete'
                            },
                            color: 'red',
                            onSubmit: async () => {
                                const resp = await new ProjectsApi().deleteProject(p.id!)
                                await loadProjects()
                                if (resp.status === 204) {
                                    listRef.current?.refresh();
                                    return { type: ActionResultType.SUCCESS }
                                }
                                return { type: ActionResultType.FAILURE }
                            }
                        })
                    }
                }]
            }}
            emptyLabel={t("No projects were found")}
        ></ListSection>
    </EntityPage>
}