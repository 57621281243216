import { Table } from "@mantine/core"
import { useTranslation } from "react-i18next"
import { SchemaGraph, SchemaRelationship } from "@/libs/client"
import { buildEntitiesMap, buildRelationshipsMap } from "@/libs/utils/graph"

function ManyElementsDetail({ ids, schemaGraph } : { ids: string[], schemaGraph: SchemaGraph }) {
    const entitiesMap = buildEntitiesMap(schemaGraph!)
    const relationshipsMap = buildRelationshipsMap(schemaGraph!)
    const nodes = ids.map(id => entitiesMap[id]).filter(n => n)
    const edges = ids.map(id => relationshipsMap[id]).filter(e => e)
    return <div>
        <label>Summary</label>
        <Table>
            <tbody>
                <tr>
                    <td># of Entities</td>
                    <td>{nodes.length}</td>
                </tr>
                <tr>
                    <td># of Relationships</td>
                    <td>{edges.length}</td>
                </tr>
            </tbody>
        </Table>
    </div>
}

function SingleElementDetail({ id, schemaGraph } : { id: string, schemaGraph: SchemaGraph }) {
    const { t } = useTranslation()
    const entitiesMap = buildEntitiesMap(schemaGraph!)
    const relationshipsMap = buildRelationshipsMap(schemaGraph!)
    const selectedElement = (entitiesMap[id] || relationshipsMap[id])!
    return <>
    <div>
        <label>{t('Element')}</label>
            <div>{(selectedElement as SchemaRelationship).source ? 'Relationship' : 'Entity'}</div>
        </div>
        <div>
            <label>Type</label>
            <div>{selectedElement.type}</div>
        </div>
        <div>
            <label>Type</label>
            <div>{selectedElement.type || ''}</div>
        </div>
        { /* TODO FIELDS */ }
    </>
}

type ViewDetailsProps = {
    selected: string[]
    schemaGraph: SchemaGraph
    onClose?: () => void
}

export function SchemaDetails({ selected, schemaGraph }: ViewDetailsProps) {
    const total = selected.length
    return total > 1 ?
            <ManyElementsDetail ids={selected} schemaGraph={schemaGraph}></ManyElementsDetail> :
            <SingleElementDetail id={selected[0]} schemaGraph={schemaGraph}></SingleElementDetail>
}