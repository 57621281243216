import EntityPage from "@/components/page/EntityPage"
import { useTitle } from "@/libs/hooks/useTitle"
import { useProject } from "@/libs/project/ProjectProvider"
import { useTranslation } from "react-i18next"

export default function ScansLandingPage() {
    const { t } = useTranslation()
    const { projectUrl } = useProject()
    useTitle(t("Scans"))
    return <EntityPage
        category={t('Analysis')}
        title={t('Scans')}
        tabs={[{
            key: "installed",
            route: projectUrl('/analysis/scans/installed'),
            label: t('common.installed')
        }, {
            key: "available",
            route: projectUrl('/analysis/scans/available'),
            label: t('Available')
        }]}
    ></EntityPage>
}