import EmptyList from "@/components/empty/EmptyList";
import { IntegrationRunDto, IntegrationsApi } from "@/libs/client";
import { Badge, Group, LoadingOverlay, Stack } from "@mantine/core";
import moment from "moment-timezone";
import { useCallback, useEffect, useState } from "react";
import { FaMinus, FaPlus } from "react-icons/fa";
import { useParams } from "react-router-dom";

type RunRowProps = {
    run: IntegrationRunDto
}

type LogLine = {
    date: string,
    level: string,
    log: string
}

const stateColor = (state?: string) => {
    switch(state) {
        case "SUCCESS": 
            return 'green';
        case "PENDING": 
            return 'blue';
        case "FAILURE": 
            return 'red';
    }
}

const levelColor = (level: string) => {
    const levellc = level.toLocaleLowerCase()
    if (levellc.includes('error')) {
        return '#fb6060'
    } else if (levellc.includes('warn')) {
        return '#fbd460'
    }
    return '#98fb60'
}

function formatExecutionTime(milliseconds?: number): string {
    if (!milliseconds) return 'n/a';
  
    if (milliseconds < 1000) {
      return `${milliseconds} ms`;
    } else if (milliseconds < 60000) {
      const seconds = (milliseconds / 1000).toFixed(2);
      return `${seconds} s`;
    } else {
      const minutes = (milliseconds / 60000).toFixed(2);
      return `${minutes} min`;
    }
}  

function RunRow({ run }: RunRowProps) {
    const [open, setOpen] = useState(false)
    const [logs, setLogs] = useState<LogLine[]>([])
    const [loading, setLoading] = useState(false)

    const openLogs = useCallback(async () => {
        if (open) {
            setOpen(false);
            setLogs([])
            return;
        }
        setOpen(true);
        setLoading(true);
        const resp = await new IntegrationsApi().installedRunLogs(run.id!)
        //TODO
        setLogs(resp.data.split('\n').filter(l => l).map(l => ({
            date: moment(new Date(Number.parseInt(l.substring(0, l.indexOf(' '))))).format('YYYY-MM-DD HH:mm:ss'),
            level: l.split(' ')[1].replace(':', '').replace('[', '').replace(']', ''),
            log: l.split(' ').slice(2).join(' '),
        })))
        setLoading(false);
    }, [open, logs])

    return <div style={{padding: 20, border: '1px solid #f0f0f0', borderRadius: 10}}>
        <Group onClick={openLogs} style={{cursor: 'pointer'}}>
            <div style={{flex: 1}}>
                { run.runDate ? <>
                    <div>{moment(run.runDate).tz(moment.tz.guess()).format("YYYY-MM-DD HH:mm:ss")}</div>
                    <div style={{fontSize: '0.9rem', color: '#a0a0a0'}}>{moment(run.runDate).tz(moment.tz.guess()).fromNow()}</div>
                </> : <div>Pending</div>}
            </div>
            <div>
                <Badge color={stateColor(run.state)}>{run.state}</Badge>
            </div>
            <div>{formatExecutionTime(run.executionInMs)}</div>
            <div>{open ? <FaMinus></FaMinus> : <FaPlus></FaPlus> }</div>
        </Group>
        { open ? <div style={{position: 'relative', marginTop: 10}}>
            <LoadingOverlay visible={loading}></LoadingOverlay>
            <div style={{backgroundColor: '#1f1f1f', color: 'white', fontFamily: 'monospace', padding: 10, fontSize: '0.8rem'}}>
                {logs.map(l => <div style={{whiteSpace: 'pre-wrap', wordBreak: 'break-word'}}><span style={{color: '#a0a0a0'}}>{l.date}</span> [<span style={{color: levelColor(l.level)}}>{l.level}</span>]: {l.log}</div>)}
            </div>
        </div> :<></> }
    </div>
}

export default function InstalledIntegrationRuns() {
    const { id } = useParams()
    const [runs, setRuns] = useState<IntegrationRunDto[]>()
    const [loading, setLoading] = useState(true)

    async function fetchRuns() {
        const resp = await new IntegrationsApi().installedRuns(id!)
        setRuns(resp.data)
        setLoading(false)
    }

    useEffect(() => {
        fetchRuns()
    }, [])
    return loading ? <LoadingOverlay visible></LoadingOverlay> : <Stack>
        {
            runs?.length ?
            runs?.map(r => <RunRow key={r.id} run={r}></RunRow>) :
            <EmptyList emptyLabel="No runs yet"></EmptyList>
        }
    </Stack>
}