import cytoscape  from 'cytoscape';
import { SCHEMA_DEFAULT_COLOR, SCHEMA_NEW_COLOR, SCHEMA_REMOVED_COLOR, SCHEMA_UPDATED_COLOR, SCHEMA_NODE_HEIGHT, SCHEMA_NODE_WIDTH, SCHEMA_SELECTED_COLOR } from "./schema.constants";

const htmlStyles = {
    node: (dim: boolean) => ({
        userSelect: 'none',
        display: 'flex',
        width: SCHEMA_NODE_WIDTH,
        height: SCHEMA_NODE_HEIGHT,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        overflow: 'hidden',
        opacity: dim ? 0.4 : 1,
        borderRadius: "10px",
    }),
    header: (backgroundColor: string) => ({
        width: SCHEMA_NODE_WIDTH,
        backgroundColor,
        color: 'white',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        fontSize: '1.8rem',
        padding: '2px 5px',
        textAlign: 'center',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    }),
    content: (backgroundColor: string) => ({
        backgroundColor: 'white',
        width: SCHEMA_NODE_WIDTH,
        border: `2px solid ${backgroundColor}`,
        flex: 1,
        borderTopWidth: 0,
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
    })
}

const FIELD_ICON_STYLE = {
    fontSize: '0.7rem',
    fontWeight: '500',
    width: '0.85rem',
    height: '0.85rem',
    color: 'white',
    backgroundColor: '#d97445',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%'
}


const convertStylesObj = (styles: { [key: string]: any }): string => {
    return Object.entries(styles)
      .map(([key, value]) => `${key.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()}:${value}`)
      .join(';');
}

const buildSchemaHtmlTemplate = (color: string, dim: boolean) => `
    <div style="${convertStylesObj(htmlStyles.node(dim))}">
        <div style="${convertStylesObj(htmlStyles.header(color))}">#{data.label}</div>
        <div style="${convertStylesObj(htmlStyles.content(color))}">
            <div style="text-align: center; color: #c0c0c0;font-style: italic;flex:1;">#{data.description}</div>
            <div style="display: flex;justify-content:flex-end;align-items:center;gap:4px;padding-right: 10px;"> <div style="padding-bottom:1px;color: #d97445;font-size:0.85rem">#{data.fieldCount}</div><div style="${convertStylesObj(FIELD_ICON_STYLE)}">f</div></div>
        </div>
    </div>
`

export function initHtmlNode(cy: cytoscape.Core) {
    // @ts-ignore
    const htmlnode = cy.htmlnode();
    htmlnode.createHtmlNode(cytoscape, cy, {
        reg: {
            query: 'node[type = "reg"]',
            staticZoomLevel: 0.3,
            template: [
            {
                zoomRange: [0.001, 100],
    
                template: {
                    html: buildSchemaHtmlTemplate(SCHEMA_DEFAULT_COLOR, false),
                    cssClass: 'htmlEventTest',
                },
            },
            ],
        },
        dimmed: {
            query: 'node[type = "dim"]',
            staticZoomLevel: 0.3,
            template: [
            {
                zoomRange: [0.001, 100],
    
                template: {
                    html: buildSchemaHtmlTemplate(SCHEMA_DEFAULT_COLOR, true),
                    cssClass: 'htmlEventDim',
                },
            },
            ],
        },
        new: {
            query: 'node[type = "new"]',
            staticZoomLevel: 0.3,
            template: [
            {
                zoomRange: [0.001, 100],
    
                template: {
                    html: buildSchemaHtmlTemplate(SCHEMA_NEW_COLOR, false),
                    cssClass: 'htmlEventNew',
                },
            },
            ],
        },
        removed: {
            query: 'node[type = "removed"]',
            staticZoomLevel: 0.3,
            template: [
            {
                zoomRange: [0.001, 100],
    
                template: {
                    html: buildSchemaHtmlTemplate(SCHEMA_REMOVED_COLOR, false),
                    cssClass: 'htmlEventNew',
                },
            },
            ],
        },
        updated: {
            query: 'node[type = "updated"]',
            staticZoomLevel: 0.3,
            template: [
            {
                zoomRange: [0.001, 100],
    
                template: {
                    html: buildSchemaHtmlTemplate(SCHEMA_UPDATED_COLOR, false),
                    cssClass: 'htmlEventNew',
                },
            },
            ],
        },
        selected: {
            query: 'node:selected',
            staticZoomLevel: 0.3,
            template: [
            {
                zoomRange: [0.001, 100],
    
                template: {
                    html: buildSchemaHtmlTemplate(SCHEMA_SELECTED_COLOR, false),
                    cssClass: 'htmlEventSelected',
                },
            },
            ],
        }
    })
}