import { useProject } from "@/libs/project/ProjectProvider"
import { useParams } from "react-router-dom"
import { useTitle } from "@/libs/hooks/useTitle"
import EntityPage from "@/components/page/EntityPage"
import { TabData } from "@/components/page/EntityTabs"
import { useTranslation } from "react-i18next"
import { useEffect, useMemo, useState } from "react"
import { InstalledScan, ScansApi } from "@/libs/client"
import controlStore from '../control.store';

export default function ControlLanding() {
    const { t } = useTranslation()
    const { id, controlId } = useParams()
    const { projectUrl } = useProject()
    const [installedScan, setInstalledScan] = useState<InstalledScan>()
    const control = controlStore.find(controlId!)
    const fetchInstalledScan = async () => {
        const resp = await new ScansApi().installedScan(id!)
        setInstalledScan(resp.data)
    }

    useEffect(() => {
        fetchInstalledScan()
    }, [])
    const parameterDescriptors = useMemo(() => [...(control?.parameterDescriptors || []), ...(control?.sections?.filter(s => s.criteria.find(c => c.enabled))?.flatMap(s => s.parameterDescriptors || []) || [])], [control])
    useTitle(`${control?.label} - Control`)

    return installedScan && <EntityPage
        category={`Controls`}
        title={control?.label!}
        tabs={[{
            key: 'details',
            label: "Criteria",
            route: projectUrl(`/analysis/scans/installed/${id}/controls/${controlId}/details`),
        }, parameterDescriptors.length > 0 ? {
            key: 'parameters',
            label: "Parameters",
            route: projectUrl(`/analysis/scans/installed/${id}/controls/${controlId}/parameters`),
        } : undefined].filter(c => c) as TabData[]}
        breadcrumbs={[
            {
                key: 'installed_scans',
                label: 'Installed Scans',
                link: projectUrl(`/analysis/scans/installed`),
            }, {
                key: 'compliance',
                label: t(`scans.categories.compliance`),
                link: projectUrl(`/analysis/scans/installed/compliance`),
            }, {
                key: installedScan.id!,
                label: installedScan.scanDefinition?.label!,
            },
            {
                key: 'controls',
                label: "Controls",
                link: projectUrl(`/analysis/scans/installed/compliance/${installedScan.id}/controls`),
            },
            {
                key: control?.id || '',
                label: control?.id?.toUpperCase() || '',
            }
        ]}
    ></EntityPage>
}