import { WorkflowNodeProps, WorkflowNodeTemplate } from "./WorkflowNodeTemplate";
import { MdPlaylistPlay } from "react-icons/md";


export default function ActionBlock(props : WorkflowNodeProps) {
    return <WorkflowNodeTemplate
            {...props}
            icon={<MdPlaylistPlay color="white" size="0.85rem"></MdPlaylistPlay>}
            headerColor="#3d558e"
            sourceEnabled
            targetEnabled
        ></WorkflowNodeTemplate>
}