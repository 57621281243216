import { ActionResult } from "@/components/actions/actions"
import SfyForm from "@/components/form/SfyForm"
import FormTextInput from "@/components/form/inputs/FormTextInput"
import { SchemaRelationship } from "@/libs/client"
import { useForm } from "@mantine/form"
import { useCallback } from "react"

type AddRelationshipProps = {
    onSubmit: (relationship: SchemaRelationship) => Promise<ActionResult>
    onClose: () => void
    source: string
    target: string
}

export default function AddRelationship({ onSubmit, onClose, source, target } : AddRelationshipProps) {
    const form = useForm({
        initialValues: {
            name: '',
            source,
            target,
        },
    });
    const doSubmit = useCallback(() => {
        return onSubmit(form.values as SchemaRelationship)
    }, [form.values])
    return <SfyForm onClose={onClose} onSubmit={doSubmit}> 
        <div style={{flex: 1, maxWidth: 550}}>
            <FormTextInput
                placeholder="e.g. has A"
                label="Edge Name"
                description="Unique name for the Entity"
                withAsterisk
                {...form.getInputProps('name')}
            />
            <FormTextInput
                label="Source"
                description="Type of the source node"
                value={source}
                disabled
            />
            <FormTextInput
                label="Target"
                description="Type of the target node"
                value={target}
                disabled
            />
        </div>
    </SfyForm>
}