import { Button, Group, Menu } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { FaCaretDown } from "react-icons/fa";
import { EntityAction } from "./actions";

export default function ActionDropdown<T>({ entity, actions } : {entity: T, actions: EntityAction<T>[] }) {
    const { t } = useTranslation()
    return <Menu position="bottom-end" offset={0}>
        <Menu.Target>
            <Button 
                style={{ visibility: actions?.length ? 'visible' : 'hidden'}}
                variant="subtle"
                radius="md"
                color="gray"
                styles={{ root: { fontWeight: 'normal' } }}
                onClick={(e) => {
                    e.stopPropagation()
                }}
                rightSection={<FaCaretDown size={10} style={{marginLeft: -5}}></FaCaretDown>}>
                    { t('common.actions') }
            </Button>
        </Menu.Target>
        <Menu.Dropdown style={{ minWidth: 120 }} onClick={(e) => {
            e.stopPropagation()
        }}>
            {actions?.map(a => <Menu.Item
                key={a.label}
                onClick={() => a.execute(entity)}
            >
                <Group gap={8}>
                    {a.icon}
                    <div>{a.label}</div>
                </Group>
            </Menu.Item>)}
        </Menu.Dropdown>
    </Menu>
}