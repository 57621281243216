import { WorkflowNodeProps, WorkflowNodeTemplate } from "./WorkflowNodeTemplate";
import { MdInput } from "react-icons/md";

export default function InputBlock(props : WorkflowNodeProps) {
    return <WorkflowNodeTemplate
            {...props}
            icon={<MdInput color="white" size="0.85rem"></MdInput>}
            headerColor="#3d8e78"
            sourceEnabled
        >
        </WorkflowNodeTemplate>
}