import ListSection, { ListSectionCallbacks } from "@/components/page/sections/ListSection"
import { ScanCategory, ScansApi } from "@/libs/client"
import { useTitle } from "@/libs/hooks/useTitle"
import { useProject } from "@/libs/project/ProjectProvider"
import { useRef } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

export default function ScanCategories() {
    const { t } = useTranslation()
    const { projectUrl, curProject } = useProject()
     const navigate = useNavigate()

    const listRef = useRef<ListSectionCallbacks>()
    useTitle(t("Installed Scans"))
    const headers = [
        t('Category'),
        t('Installed scans'),
    ];
    return <ListSection<ScanCategory>
        callbacks={listRef}
        headers={headers}
        renderRow={(e) => ({
            cols: [{
                display: [{
                    label: t(`scans.categories.${e.key}`),
                }],
            }, {
                display: [{
                    label: (e.installedCount || 0) + '',
                }],
            }],
        })}
        load={async () => {
            const resp = await new ScansApi().installedScansCategories(curProject.id!);
            if (resp.status === 200) {
                return { list: resp.data }
            };
            return { error: 'TODO' };
        }}
        onRowClick={b => navigate(projectUrl(`/analysis/scans/installed/${b.key}`))}
        emptyLabel={t('No installed scans yet')}
    ></ListSection>
}