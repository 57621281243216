import cytoscape from 'cytoscape';
import { SCHEMA_GRAPH_STYLES } from "./schema.graphStyles";


export function buildSchemaCy() : cytoscape.Core {
    return cytoscape({
        container: document.getElementById('cy-schema'),
        boxSelectionEnabled: false,
        autoungrabify: false,
        maxZoom: 10,
        minZoom: 0.2,
        style: SCHEMA_GRAPH_STYLES,
    });
}