import { useTranslation } from "react-i18next"
import { useTitle } from "@/libs/hooks/useTitle"
import EntityPage from "@/components/page/EntityPage"
import { NavLink } from "@mantine/core"
import { PiGearSixDuotone, PiPuzzlePieceDuotone } from "react-icons/pi"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { FaTicket } from "react-icons/fa6"

export default function TenantSettingsLanding() {
    const { t } = useTranslation()
    useTitle(t("Tenant Settings"))
    const location = useLocation()
    const navigate = useNavigate()
    const isSection = (section: string) => location.pathname.endsWith(`/${section}`)
    const goToSection = (newSection: string) => !isSection(newSection) && navigate(`/admin/settings/${newSection}`)
    return <EntityPage
        category={t("tenant.label")}
        title={t("settings.label")}
    >
        <div style={{display: 'flex'}}>
            <div>
                <NavLink
                    active={isSection('general')}
                    label="General"
                    leftSection={<PiGearSixDuotone size="1rem" />}
                    onClick={() => goToSection('general')}
                />
                <NavLink
                    label="Services"
                    leftSection={<PiPuzzlePieceDuotone size="1rem" />}
                    childrenOffset={15}
                    defaultOpened
                    description="Configure services used by Sentryfy"
                >
                    <NavLink
                        active={isSection('tickets')}
                        label="Tickets"
                        leftSection={<FaTicket size="1rem" />}
                        onClick={() => goToSection('tickets')}
                    />
                </NavLink>
            </div>
            <div style={{flex: 1, marginTop: '-5%'}}>
                <Outlet></Outlet>
            </div>
      </div>
    </EntityPage>
}