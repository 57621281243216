import { ActionResultType } from "@/components/actions/actions";
import { useContainer } from "@/components/containers/ContainerProvider";
import FormTextInput from "@/components/form/inputs/FormTextInput";
import SfyForm from "@/components/form/SfyForm";
import { Deployment, DeploymentsApi } from "@/libs/client";
import { useForm } from "@mantine/form";

type AddDeploymentProps = {
    deployment?: Deployment
    onRefresh: () => void
}

export default function DeploymentForm({ deployment, onRefresh } : AddDeploymentProps) {
    const { closeAllModals } = useContainer()
    const form = useForm({
        initialValues: {
            name: deployment?.name || '',
        },
    });

    const doSubmit = async () => {
        if (deployment) {
            await new DeploymentsApi().updateDeployment(deployment.id!, {
                ...form.values,
            })
        } else {
            await new DeploymentsApi().createDeployment({
                ...form.values,
            })
        }
        onRefresh()
        return { type: ActionResultType.SUCCESS };    
    }
    return <SfyForm onClose={closeAllModals} onSubmit={doSubmit} disabled={!form.isValid()}> 
        <FormTextInput
            label="Name"
            description="Name of the deployment"
            withAsterisk
            {...form.getInputProps('name')}
        />
    </SfyForm>
}