import { ReactNode } from "react"

type TrayItemProps = {
    descriptorId: string
    onDragStart: (event: any, data: { descriptorId: string }) => void
    children: ReactNode
}

export default function TrayItem({ descriptorId, children, onDragStart } : TrayItemProps) {
    return <div style={{maxWidth: 220, marginBottom: 20}} onDragStart={evt => {onDragStart(evt, { descriptorId }) }} draggable={true}>
        { children }
    </div>
}