type Paged<T> = {
    totalPages?: number;
    list?: T[];
}

export async function getAllElements<T>(getPage: (page: number) => Promise<Paged<T>>): Promise<T[]> {
    const allElements: T[] = [];
    let page = 1;
    let totalPages = 1;

    while (page <= totalPages) {
        const resp = await getPage(page);

        allElements.push(...(resp.list || []));

        totalPages = resp.totalPages!;
        page++;
    }

    return allElements;
}
