
/**
 * Build API url based on the current domain
 * @returns API Url for current domain
 */
export const buildApiUrl = (path = "") => {
    const currentHost = window.location.host;
    let apiHost = '';
  
    if (currentHost.startsWith('localhost')) {
      // Use localhost if the host is localhost
      apiHost = 'http://localhost:8080';
    } else {
      apiHost = `${window.location.protocol}//${currentHost.replace("5173", "8080")}/api`;
    }
    let actualPath = path;
    if (actualPath && !actualPath.startsWith('/')) {
        actualPath = "/" + actualPath;
    }
    return apiHost + actualPath;
};
