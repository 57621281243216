import { useContainer } from "@/components/containers/ContainerProvider";
import SfyForm from "@/components/form/SfyForm";
import { Group, TextInput } from "@mantine/core";
import { FaPlusCircle } from "react-icons/fa";


export default function ConfigureControlForm({ }: { }) {
    const { closeAllModals } = useContainer();

    return <SfyForm onClose={closeAllModals}>
        <div>
            <label>Job to Roles Mapping</label>
            <Group style={{marginTop: 10}}>
                <TextInput placeholder="Job Title"></TextInput>
                <TextInput placeholder="Role Name"></TextInput>
                <FaPlusCircle color="teal"></FaPlusCircle>
            </Group>
        </div>
    </SfyForm>
}