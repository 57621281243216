import EntityPage from "@/components/page/EntityPage"
import { Issue, IssueFilterSectionDto, IssuesApi, IssueStateEnum, PartialInstalledIntegration } from "@/libs/client"
import { useTitle } from "@/libs/hooks/useTitle"
import { useProject } from "@/libs/project/ProjectProvider"
import { Badge, Card, Grid, GridCol, Group, Pagination, Select, Stack, TextInput, UnstyledButton } from "@mantine/core"
import { MouseEvent, useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"
import IssueRow from "./IssueRow"
import { CgSearch } from "react-icons/cg"
import { FaTimes } from "react-icons/fa"
import classes from './IssueList.module.css';
import cx from 'clsx';

function FilterCard({ filter }: { filter: IssueFilterSectionDto }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const isActive = useCallback((value: string) => {
        return searchParams.get(filter.key!) === value
    }, [searchParams])
    const onClick = (event: MouseEvent<HTMLAnchorElement>, value: string) => {
        event.stopPropagation();
        event.preventDefault();
        const sp = [...searchParams.keys()].reduce((acc, cur) => {
            if (searchParams.has(cur)) {
                acc[cur] = searchParams.get(cur)!
            }
            return acc;
        }, {} as { [key: string]: string })
        setSearchParams({
            ...sp,
            [filter.key!]: value,
        })
    }
    return <Card withBorder>
        <label>{filter.label}</label>
        {filter.filters?.map(f => 
        <a className={cx(classes.filter, isActive(f.value!) ? classes.filterActive : undefined)} 
            key={f.value} 
            href="#"
            onClick={(e) => onClick(e, f.value!)}>
            {f.label}
        </a>)}
    </Card>
}

type AppliedFilter = {
    key: string;
    value: string;
    label: string;
}

function IssueBadges({ filterSections } : { filterSections : IssueFilterSectionDto[] }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const filterLabels = useMemo(() => {
        return filterSections.reduce((acc, cur) => {
            acc[cur.key!] = cur.label!
            return acc
        }, {} as { [key: string]: string })
    }, [filterSections])
    const valueLabels = useMemo(() => {
        return filterSections.reduce((acc, cur) => {
            cur.filters?.forEach(f => {
                acc[`${cur.key}:${f.value}`] = f.label!
            })
            return acc
        }, {} as { [key: string]: string })
    }, [filterSections])
    const clearFilter = (toClear: string) => {
        const sp = [...searchParams.keys()].reduce((acc, cur) => {
            if (searchParams.has(cur) && cur != toClear) {
                acc[cur] = searchParams.get(cur)!
            }
            return acc;
        }, {} as { [key: string]: string })
        setSearchParams(sp)
    }
    const filters: AppliedFilter[] = useMemo(() => {
        return [...searchParams.keys()].map(sp => ({
            key: sp,
            value: valueLabels[`${sp}:${searchParams.get(sp)!}`],
            label: filterLabels[sp],
        }))
    }, [searchParams, filterLabels])

    return <>
        {filters.filter(f => f.label).map(f => <Badge variant="light" rightSection={<UnstyledButton onClick={() => clearFilter(f.key)}><FaTimes size="0.8rem"></FaTimes></UnstyledButton>}>{f.label}: {f.value}</Badge>)}
    </>
}

export default function IssueList() {
    const { t } = useTranslation()
    const { curProject } = useProject()
    const [searchParams, setSearchParams] = useSearchParams();
    const [installedIntegrations, setInstalledIntegrations] = useState<PartialInstalledIntegration[]>()
    const [installedIntegrationId, setInstalledIntegrationId] = useState<string | ''>(searchParams.get("installedIntegrationId") || '')
    const [state, setState] = useState<IssueStateEnum | ''>(searchParams.get("state") || '' as any)
    const filtersMap = useMemo(() => {
        return [...searchParams.keys()].reduce((acc, cur) => {
            if (searchParams.has(cur)) {
                acc[cur] = searchParams.get(cur)!
            }
            return acc;
        }, {} as { [key: string]: string })
    }, [searchParams])
    useTitle(t("issues.label_other"))
    const [page, setPage] = useState(1);
    const [issues, setIssues] = useState<Issue[]>([])
    const [total, setTotal] = useState(0)
    const [totalElements, setTotalElements] = useState(0)
    const [filterSections, setFilterSections] = useState<IssueFilterSectionDto[]>([])
    const loadPage = async (page: number) => {
        const shortCode = filtersMap["shortCode"] as string | undefined
        const priority = filtersMap["priority"] as string | undefined
        const entities = [filtersMap["entities"]].filter(e => e) as string[]
        const labels = [filtersMap["labels"]].filter(e => e) as string[]
        const installedIntegrationIdParam = filtersMap["installedIntegrationId"] ? filtersMap["installedIntegrationId"] : undefined;
        const stateParam = (filtersMap["state"] ? filtersMap["state"] : "OPEN") as any;
        const resp = await new IssuesApi().issues(curProject.id!, stateParam, shortCode, priority, entities, labels, installedIntegrationIdParam, page, 10);
        if (resp.status === 200) {
            const data = resp.data;
            setIssues(data.list || [])
            setTotal(data.totalPages || 0)
            setTotalElements(data.totalElements || 0)
        }
        return { error: 'TODO' };
    }
    const loadFilterSections = async () => {
        const resp = await new IssuesApi().issueFilters(curProject.id!, installedIntegrationId || undefined);
        setFilterSections(resp.data)
    }
    const loadInstalledIntegrations = async () => {
        const resp = await new IssuesApi().issueInstalledIntegrations(curProject.id!);
        setInstalledIntegrations(resp.data)
    }
    useEffect(() => {
        loadInstalledIntegrations()
    }, [])
    useEffect(() => {
        loadPage(page)
    }, [page])
    useEffect(() => {
        loadFilterSections()
    }, [installedIntegrationId])
    useEffect(() => {
        loadPage(1)
    }, [searchParams])
    return <EntityPage
        category={t('insights.label')}
        title={t('issues.label_other')}
    >
        <Grid>
            <GridCol span={3}>
                <TextInput
                    styles={{ input: {
                        maxWidth: '350px',
                        borderRadius: 0,
                        border: 'none',
                        borderBottom: '1px solid #f0f0f0',
                        marginBottom: 15,
                    }}}
                    leftSection={<CgSearch color="#333"></CgSearch>}
                    placeholder={t('common.search') + '...'}
                ></TextInput>
                <Stack gap={8}>
                    { filterSections.map(f => <FilterCard key={f.key} filter={f}></FilterCard>)}
                </Stack>
            </GridCol>
            <GridCol span={9}>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '1.5rem', marginBottom: '1rem'}}>
                    <Group>
                        <label style={{color: '#505050'}}>{t('common.state')}</label>
                        <Select style={{maxWidth: 120}} defaultValue={""} allowDeselect={false}
                            value={state}
                            onChange={e =>{
                                setState(e as IssueStateEnum)
                                setSearchParams((sp: URLSearchParams) => {
                                    if (e) {
                                        sp.append("state", e)
                                    } else {
                                        sp.delete("state")
                                    }
                                    return sp
                                })
                            }}
                            data={[
                                { value: "", label: t("common.open")},
                                { value: "CLOSED", label: t("common.closed")},
                            ]}></Select>
                    </Group>
                    <Group>
                        <label style={{color: '#505050'}}>{t('installed_integrations.label_other')}</label>
                        <Select defaultValue={""} allowDeselect={false}
                            style={{minWidth: 250}}
                            value={installedIntegrationId}
                            onChange={e => {
                                setInstalledIntegrationId(e || '')
                                setSearchParams((sp: URLSearchParams) => {
                                    if (e) {
                                        sp.append("installedIntegrationId", e)
                                    } else {
                                        sp.delete("installedIntegrationId")
                                    }
                                    return sp
                                })
                            }}
                            data={[
                                { value: "", label: t('common.all')},
                                ...(installedIntegrations || []).map(i => ({
                                    value: i.id!,
                                    label: i.name!,
                                }))
                            ]}></Select>
                    </Group>
                    <div>
                        {totalElements} {t('issues.label', { count: totalElements })}
                    </div>
                </div>
                <Stack>
                    <Group>
                        <div style={{flex: 1}}>
                        <IssueBadges filterSections={filterSections}></IssueBadges>
                        </div>
                    </Group>
                    {issues.map(i => <IssueRow key={i.issueNumber} issue={i} onRefresh={() => loadPage(page)}></IssueRow>)}
                    <div style={{marginTop: 20, display: 'flex', justifyContent: 'flex-end',  paddingRight: 30 }}>
                        <Pagination value={page} onChange={setPage} total={total || 0} radius="md" />
                    </div>
                </Stack>
            </GridCol>
        </Grid>
    </EntityPage>
}