import FormCheckbox from "@/components/form/FormCheckbox"
import FormSelect from "@/components/form/inputs/FormSelect"
import FormTextInput from "@/components/form/inputs/FormTextInput"
import { Stack, TagsInput } from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import { useEffect, useState } from "react"

export function ConfigureScanTaskTicketForm({ formData, update }: { formData: UseFormReturnType<any, any>, update: boolean }) {
    const [selectedIssueType, setSelectedIssueType] = useState<string>()
    useEffect(() => {
        if (!update) {
            formData.setFieldValue('ticketConfig', {
                ...formData.values.ticketConfig,
                labels: [...formData.values.labels],
            })
            if (['Bug', 'Task'].includes(formData.values.ticketConfig.issueType)) {
                setSelectedIssueType(formData.values.ticketConfig.issueType)    
            } else {
                setSelectedIssueType("_CUSTOM")    
            }
        }
    }, [])
    useEffect(() => {
        if (selectedIssueType != "_CUSTOM") {
            formData.setFieldValue('ticketConfig.issueType', selectedIssueType)
        }
    }, [selectedIssueType])
    return <form>
        <Stack>
            <FormCheckbox 
                label="Enable ticketing of issue"
                {...formData.getInputProps("ticketConfig.enabled")}
            ></FormCheckbox>
            <FormSelect
                label="Ticketing Service"
                defaultValue={"JIRA"}
                data={[{
                    value: 'JIRA',
                    label: 'Jira'
                }]}
                disabled={!formData.values.ticketConfig.enabled}
            ></FormSelect>
            <FormTextInput
                label="Project Key"
                disabled={!formData.values.ticketConfig.enabled}
                {...formData.getInputProps('ticketConfig.projectKey')}
            ></FormTextInput>
            <FormSelect
                label="Issue Type"
                allowDeselect={false}
                defaultValue={'Bug'}
                data={[{
                    value: 'Bug',
                    label: 'Bug'
                },
                {
                    value: 'Task',
                    label: 'Task',
                },
                { value: "_CUSTOM", label: 'Enter custom one'},
                ]}
                disabled={!formData.values.ticketConfig.enabled}
                onChange={e => setSelectedIssueType(e || '')}
            ></FormSelect>
            { selectedIssueType === '_CUSTOM' && <FormTextInput
                label="Custom Issue Type"
                disabled={!formData.values.ticketConfig.enabled}
                {...formData.getInputProps('ticketConfig.issueType')}
            ></FormTextInput>}
            <FormTextInput
                label="Priority"
                description="ID or name of the priority"
                withAsterisk
                disabled={!formData.values.ticketConfig.enabled}
                {...formData.getInputProps('ticketConfig.priority')}
            ></FormTextInput>
            <TagsInput
                label="Labels"
                placeholder="Press enter to add label"
                disabled={!formData.values.ticketConfig.enabled}
                {...formData.getInputProps('ticketConfig.labels')}
            ></TagsInput>
            <FormCheckbox label="Auto-approve the ticket"
                disabled={!formData.values.ticketConfig.enabled}
                {...formData.getInputProps('ticketConfig.autoApprove')}
            ></FormCheckbox>
        </Stack>
    </form>
}