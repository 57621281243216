import { Badge, Group, Select, Stack } from "@mantine/core";
import { useCallback, useState } from "react"
import { FaChevronDown, FaChevronRight } from "react-icons/fa"
import classes from './GraphBox.module.css';
import cx from 'clsx';

type GraphBoxOption = {
    value: string
    label: string
}

export type RowItem = {
    label: string
    key: string
    type: 'LINK' | 'SELECT' | 'BADGE'
    color?: string;
    valueLabel?: string
    options?: GraphBoxOption[]
    onChange?: (value: string) => void
    onClick?: (value: string) => void
}

type GraphBoxRowProps = {
    onDropdownOpen: () => void
    onDropdownClose: () => void
    rowItem: RowItem
    values: { [key: string]: string }
    onValues: (values: { [key: string]: string }) => void
}

function GraphBoxRow({ values, onValues, onDropdownOpen, onDropdownClose, rowItem }: GraphBoxRowProps) {
    const onChange = useCallback((v: string | null) => {
        rowItem.onChange ? rowItem.onChange(v || '') : undefined
        onValues({ ...values, [rowItem.key]: v || ''})
    }, [rowItem, values])
    return <Group>
        <div style={{width: '120px', color: '#808080'}}>{rowItem.label}</div>
        { rowItem.type === 'SELECT' ? <Select
            allowDeselect={false}
            styles={{ root: {flex: 1}, 
                input: {fontSize: '0.85rem', padding: 0, border: 0, minHeight: 0, height: '0.85rem'},
                dropdown: { minWidth: 120}
            }}
            value={values[rowItem.key]}
            data={rowItem.options || []}
            onChange={onChange}
            onDropdownOpen={onDropdownOpen}
            onDropdownClose={onDropdownClose}
            comboboxProps={{ position: "bottom" }} /> : <></> }
        { rowItem.type === 'BADGE' ? <Badge style={{marginLeft: -10}} color={rowItem.color}>{rowItem.valueLabel}</Badge>: <></>}
        { rowItem.type === 'LINK' ? <div style={{flex: 1}}>{rowItem.valueLabel}</div> : <></> }
    </Group>
}

type GraphBoxProps = {
    title: string
    rows: RowItem[]
    values: { [key: string]: string }
    onValues: (values: { [key: string]: string }) => void
}

export default function GraphBox({ values, onValues, title, rows }: GraphBoxProps) {
    const [open, setOpen] = useState(true)
    const [overflowHidden, setOverflowHidden] = useState(true)
    return  <div className={classes.root}>
                <Group className={classes.header} onClick={() => {
                    setOpen(!open)
                    setOverflowHidden(true)
                }}>
                    { title }
                     <span style={{display: 'flex', flex: 1, justifyContent: 'flex-end'}}>
                        {open && <FaChevronDown size="10px"></FaChevronDown>}
                        {!open && <FaChevronRight size="10px"></FaChevronRight>}
                    </span>
                </Group>
                <div className={cx(classes.content, overflowHidden ? classes.overflowHidden : undefined)} style={{height: open ? 29 * rows.length : 0}}>
                    <Stack gap={4}>
                        { rows.map(r => <GraphBoxRow
                                key={r.label}
                                values={values}
                                onValues={onValues}
                                onDropdownOpen={() => setOverflowHidden(false)}
                                onDropdownClose={() => setOverflowHidden(true)}
                                rowItem={r}                    
                            ></GraphBoxRow>) }
                    </Stack>
                </div>
             </div>
}