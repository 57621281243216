import { ActionResultType } from "@/components/actions/actions"
import { useContainer } from "@/components/containers/ContainerProvider"
import EntityPage from "@/components/page/EntityPage"
import ListSection, { ListSectionCallbacks } from "@/components/page/sections/ListSection"
import { DisplayElemSize } from "@/components/table/EntityTable"
import { InstalledScan, ScansApi } from "@/libs/client"
import { useTitle } from "@/libs/hooks/useTitle"
import notify from "@/libs/notify/notify"
import { useProject } from "@/libs/project/ProjectProvider"
import { Badge, Group } from "@mantine/core"
import { useRef } from "react"
import { useTranslation } from "react-i18next"
import { FaCheckCircle, FaPlay, FaTrash } from "react-icons/fa"
import { useNavigate, useParams } from "react-router-dom"

export default function InstalledScansList() {
    const { t } = useTranslation()
    const { projectUrl, curProject } = useProject()
    const { openConfirm } = useContainer()
    const navigate = useNavigate()
    const { category } = useParams()

    const listRef = useRef<ListSectionCallbacks>()
    useTitle(t("Installed Scans"))
    const headers = [
        t('common.name'),
        t('common.state'),
        t('Version'),
    ];
    return <EntityPage
        title={t(`scans.categories.${category}`)}
        breadcrumbs={[
            {
                key: 'installed_scans',
                label: 'Installed Scans',
                link: projectUrl(`/analysis/scans/installed`),
            }, {
                key: category!,
                label: t(`scans.categories.${category}`),
                link: projectUrl(`/analysis/scans/installed/${category}`),
            },
        ]}
    ><ListSection<InstalledScan>
        callbacks={listRef}
        headers={headers}
        renderRow={(e) => ({
            cols: [{
                display: [{
                    elem: <Group gap={3}>
                        <div>{e.scanDefinition?.label}</div>
                        <Badge size="xs" color="green" leftSection={<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}><FaCheckCircle></FaCheckCircle></div>}>Installed</Badge>
                    </Group>
                }],
            }, {
                display: [{
                    label: 'Active',
                    size: DisplayElemSize.sm,
                    status: 'green',
                }],
            },  {
                display: [{
                    label: '0.1',
                }],
            }],
        })}
        load={async (page) => {
            const resp = await new ScansApi().installedScans(curProject.id!, category, page);
            if (resp.status === 200) {
                return resp.data
            };
            return { error: 'TODO' };
        }}
        onRowClick={b => navigate(projectUrl(`/analysis/scans/installed/${category}/${b.id}/${b.scanDefinition?.category == 'compliance' ? 'controls' : 'scan_tasks'}`))}
        rowActions={() => [{
            label: 'Run',
            icon: <FaPlay></FaPlay>,
            execute: async (e) => {
                await new ScansApi().runScan(e.id!)
                listRef.current?.refresh()
                notify.info('Started scan ' + e.scanDefinition?.label)
            }
        }, {
            label: 'Uninstall',
            icon: <FaTrash></FaTrash>,
            execute: (e) => {
                openConfirm('delete', {
                    title: 'Delete',
                    confirmationText: <>Are you sure you want to uninstall the scan <b>{e.scanDefinition?.label}</b>?</>,
                    cancelText: "No don't delete",
                    submitText: "Yes, Delete",
                    color: 'red',
                    onSubmit: async () => {
                        await new ScansApi().uninstallScan(e.id!)
                        listRef.current?.refresh()
                        notify.success(`Scan ${e.scanDefinition?.label} successfully uninstalled`)
                        return { type: ActionResultType.SUCCESS }
                    }
                })
            }
        }]}

        emptyLabel={t('No installed scans yet')}
    ></ListSection>
    </EntityPage>
}