import { useTranslation } from "react-i18next"
import { useTitle } from "@/libs/hooks/useTitle"
import EntityPage from "@/components/page/EntityPage"
import { NavLink } from "@mantine/core"
import { PiGearSixDuotone, PiGithubLogoDuotone, PiKeyDuotone, PiPuzzlePieceDuotone, PiRobotDuotone, PiSlackLogo } from "react-icons/pi"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { useInfo } from "@/libs/info/InfoProvider"

export default function SysSettingsLanding() {
    const { isExperimental, isSaas } = useInfo()
    const { t } = useTranslation()
    useTitle(t("System Settings"))
    const location = useLocation()
    const navigate = useNavigate()
    const isSection = (section: string) => location.pathname.endsWith(`/${section}`)
    const goToSection = (newSection: string) => !isSection(newSection) && navigate(`/ops/settings/${newSection}`)
    return <EntityPage
        category={t("system.label")}
        title={t("settings.label")}
    >
        <div style={{display: 'flex'}}>
            <div>
                <NavLink
                    active={isSection('general')}
                    label={t('common.general')}
                    leftSection={<PiGearSixDuotone size="1rem" />}
                    onClick={() => goToSection('general')}
                />
                { isExperimental() && <NavLink
                    active={isSection('license')}
                    label="License"
                    leftSection={<PiKeyDuotone size="1rem" />}
                    onClick={() => goToSection('license')}
                /> }
                <NavLink
                    label="External Services"
                    leftSection={<PiPuzzlePieceDuotone size="1rem" />}
                    childrenOffset={15}
                    defaultOpened
                    description="Configure services used by Sentryfy"
                >
                    { isSaas() && <NavLink
                        active={isSection('slack')}
                        label="Slack"
                        leftSection={<PiSlackLogo size="1rem" />}
                        onClick={() => goToSection('slack')}
                    />}
                    { isExperimental() && <NavLink
                        active={isSection('github')}
                        label="Github"
                        leftSection={<PiGithubLogoDuotone size="1rem" />}
                        onClick={() => goToSection('github')}
                    /> }
                    { isExperimental() && <NavLink
                        active={isSection('openai')}
                        label="OpenAI"
                        leftSection={<PiRobotDuotone size="1rem" />}
                        onClick={() => goToSection('openai')}
                    /> }
                </NavLink>
            </div>
            <div style={{flex: 1, marginTop: '-5%'}}>
                <Outlet></Outlet>
            </div>
      </div>
    </EntityPage>
}