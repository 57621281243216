import { useContainer } from "@/components/containers/ContainerProvider";
import SfyForm from "@/components/form/SfyForm";
import { ActionIcon, Checkbox, CheckboxGroup, Group, Stack, Tooltip } from "@mantine/core";
import { ReactNode } from "react";
import { FaInfoCircle } from "react-icons/fa";

function CheckboxWithTooltip({ value, label, tooltip } : {value: string, label: string, tooltip: string}) {
    return (
      <Group gap={4}>
        <Checkbox color="green" value={value} label={label} />
        <Tooltip label={tooltip} position="right" withArrow>
          <ActionIcon variant="transparent"><FaInfoCircle size={12} /></ActionIcon>
        </Tooltip>
      </Group>
    );
}
  
function ChecksSection({ children, label, defaultValue } : { label: string, children: ReactNode, defaultValue?: string[] }) {
    return <CheckboxGroup label={label} style={{marginBottom: 30}} styles={{label: {fontSize: 16}}} defaultValue={defaultValue}>
        <Stack gap={6} style={{marginTop: 14}}>
            {children}
        </Stack>
    </CheckboxGroup>
}

export default function ManualChecksForm({ }: { }) {
    const { closeAllModals } = useContainer();

    return <SfyForm onClose={closeAllModals}>
        <ChecksSection label="1. Account Creation" defaultValue={['authorization', 'uniqueIDs']}>
          <CheckboxWithTooltip
            value="documentedProcedures"
            label="Documented Procedures"
            tooltip="Check if account creation procedures are documented"
          />
        </ChecksSection>
    </SfyForm>
}