import { Group, TextInput } from "@mantine/core"
import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { FaChevronDown, FaChevronRight, FaSearch } from "react-icons/fa"
import WorkflowNode from "../rules/WorkflowNode"
import TrayItem from "./TrayItem"
import BLOCK_DESCRIPTORS from "../descriptors"
import { WorkflowNodeDescriptor, getWorkflowNodeType } from "../descriptors/descriptor"
import classes from './WorkflowTray.module.css';

function TrayBlock({ descriptorId, onDragStart} : { descriptorId: string, onDragStart: (event: any, data: { descriptorId: string }) => void }) {
    return <TrayItem descriptorId={descriptorId} onDragStart={onDragStart}>
        <WorkflowNode
            descriptorId={descriptorId}
            trayMode
        ></WorkflowNode>
    </TrayItem>
}

function RuleGroup({ title, children }: { title: string, children: JSX.Element | JSX.Element[] }) {
    const [show, setShow] = useState(true)
    return <>
        <Group className={classes.title} onClick={() => setShow(!show)}>
            {show ? <FaChevronDown size="0.6rem"></FaChevronDown> : <FaChevronRight size="0.6rem"></FaChevronRight> }
            {title}
        </Group>
        { show && <div className={classes.blocks}>
            {children}
        </div>}
    </>
}

export function WorkflowTray() {
    const { t }= useTranslation()
    const onDragStart = (event: any, data: any) => {
        event.dataTransfer.setData('application/reactflow', JSON.stringify(data));
        event.dataTransfer.effectAllowed = 'move';
    };
    const descriptors = useMemo(() => BLOCK_DESCRIPTORS.reduce((acc, cur) => {
        const blockType = getWorkflowNodeType(cur.descriptorId)
        let category = acc.find(c => c.type === blockType)
        if (!category) {
            category = {
                type: blockType,
                elements: [cur]
            }
            return [...acc, category]
        } else {
            category.elements.push(cur);
        }
        return acc;
    }, [] as { type: string, elements: WorkflowNodeDescriptor[]}[]), []);
    return <div className={classes.tray}>
        <TextInput styles={{ input: { border: 0 }, root: {marginTop: '0.3rem', marginBottom: '0.3rem'}}} size="sm" placeholder="Search rules..."
        leftSection={<FaSearch size="0.8rem"></FaSearch>}></TextInput>
        {
            descriptors.map(c => <RuleGroup key={c.type} title={t(`rules.${c.type}`)}>
                {c.elements.map(e => 
                    <TrayBlock key={e.descriptorId} descriptorId={e.descriptorId} onDragStart={onDragStart}></TrayBlock>)}
            </RuleGroup>)
        }
    </div>
}