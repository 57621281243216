import { ReactNode } from "react";

export type RawWorkflowNodeValues = { 
    name: string,
    descriptorId: string;
    configuration: { [key: string]: any },
}

export type WorkflowNodeValues = {
    getDescriptorId(): string;
    getValue(key: string): any;
    setValue(key: string, value: any): void;
    getValues(): {[key: string]: any};
    setName(name: string): void;
    getName(): string;
    asRaw(): RawWorkflowNodeValues
}

export type WorkflowNodeIOType = {
    type: string;
    many?: boolean;
}

export type WorkflowNodeInput = {
    types: WorkflowNodeIOType[]
    many: boolean
}

export type WorkflowNodeOutput = {
    types: WorkflowNodeIOType[]
    many: boolean
}

export type WorkflowNodeContext = {
    inputs: { [id: string]: WorkflowNodeInput }
}

export type WorkflowNodeDescriptor = {
    descriptorId: string;
    configureForm?: (ctx: WorkflowNodeContext, values: WorkflowNodeValues) => ReactNode
    embeddedForm?: (ctx: WorkflowNodeContext, values: WorkflowNodeValues) => ReactNode
    getOutput: (ctx: WorkflowNodeContext, values: WorkflowNodeValues) => WorkflowNodeOutput | { invalid?: boolean }
}

export const getWorkflowNodeLabel = (descriptorId: string) => `blocks.${descriptorId.replace('/', '.')}`

export const getWorkflowNodeType = (descriptorId: string) => descriptorId.split('/')[0];