import { Button, Group, TextInput } from "@mantine/core";
import { useState } from "react";
import { FaChevronRight, FaEdit, FaSave, FaTimes } from "react-icons/fa";
import classes from './WorkflowEditorHeader.module.css';
import { useNavigate } from "react-router-dom";
import { useProject } from "@/libs/project/ProjectProvider";
import { ActionResultType } from "@/components/actions/actions";
import { useContainer } from "@/components/containers/ContainerProvider";
import { useWorkflowEditor } from "./WorkflowEditorProvider";
import { Edge, Node } from "reactflow";
import { WorkflowNode, WorkflowEdge, Workflow, WorkflowsApi } from "@/libs/client";
import notify from "@/libs/notify/notify";


type WorkflowEditorProps = {
    initialWorkflow?: Workflow;
}

export default function WorkflowEditorHeader({ initialWorkflow }: WorkflowEditorProps) {
    const [edit, setEdit] = useState(false)
    const [name, setName] = useState(initialWorkflow?.name || "Untitled Workflow")
    const { projectUrl, curProject } = useProject()
    const { reactFlowInstance, getWorkflowNodeValuesById } = useWorkflowEditor()
    const { openConfirm } = useContainer()
    const navigate = useNavigate()
    const submit = async () => {
        const nodes = (reactFlowInstance.getNodes() as Node[])
            .map(n => {
                const b = getWorkflowNodeValuesById(n.id)
                return ({
                    id: n.id,
                    descriptorId: b.getDescriptorId(),
                    position: n.position,
                    config: b.getValues(),
                    name: b.getName(),
                }) as WorkflowNode
            })
        const edges = (reactFlowInstance.getEdges() as Edge[])
            .map(e => ({
                id: e.id,
                sourceId: e.source,
                targetId: e.target,
            }) as WorkflowEdge)
        const toSave = {
            name,
            definition: {
                nodes,
                edges,
            }
        }
        if (initialWorkflow == null) {
            await new WorkflowsApi().createWorkflow(curProject.id!, toSave)
            notify.success('Successfully created workflow ' + name)
        } else {
            await new WorkflowsApi().updateWorkflow(initialWorkflow.id!, toSave)
            notify.success('Successfully updated workflow ' + name)
        }
        //TODO errors
        navigate(projectUrl('/analysis/workflows'))
    }
    const cancel = () => {
        openConfirm('cancel_workflow', {
            title: 'Cancel workflow',
            confirmationText: 'Are you sure you want to leave the workflow editor? You will lose changes you made',
            cancelText: "No don't",
            submitText: "It's okay",
            color: 'red',
            onSubmit: async () => {
                navigate(projectUrl('/analysis/workflows'))
                return { type: ActionResultType.NONE }
            }
        })
    }
    return <Group className={classes.header} grow>
            <Group gap={7}>
                <div>Workflow</div>
                <FaChevronRight size="0.7rem"></FaChevronRight>
                { edit && <TextInput value={name} autoFocus onChange={evt => setName(evt.target.value)} style={{width: 'auto'}} onBlur={() => setEdit(false)}></TextInput>}
                { !edit && <>{name} <FaEdit className={classes.editIcon} size="0.75rem" onClick={() => setEdit(true)}></FaEdit></>}
                
            </Group>
            <Group style={{paddingRight: 30}} justify="flex-end" gap={8}>
                <Button
                    onClick={submit}
                    size="sm" variant="filled" leftSection={<FaSave></FaSave>}>Save Workflow</Button>
                <Button 
                    onClick={cancel}
                    variant="filled"  size="sm" color="red" leftSection={<FaTimes></FaTimes>}>Cancel</Button>
            </Group>
        </Group>
}