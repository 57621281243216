import { Button } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { TbListDetails } from "react-icons/tb";
import { useContainer } from "@/components/containers/ContainerProvider";
import { ReactNode } from "react";
import { DrawerMode } from "@/components/containers/SfyDrawer";

export default function ViewDetailsButton({ renderDetails } : { renderDetails: () => ReactNode }) {
        const { t } = useTranslation()
        const { openDrawer } = useContainer()
        return <Button variant="selected" 
                styles={{label: {fontWeight: 600}, root: {marginRight: 8}}}
                size="xs"
                onClick={() => openDrawer('view_details', renderDetails(), { title: t('common.view_details'), size: 'lg', mode: DrawerMode.GRAPH })}
                leftSection={<TbListDetails></TbListDetails>}>{t('common.view_details')}</Button>
}
