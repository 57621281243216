import { ActionIcon, Drawer, MantineSize, useMantineTheme } from '@mantine/core';
import { ReactNode, useMemo, useState } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import classes from './Container.module.css';

export type SfyDrawerProps = {
    children?: ReactNode
    opened?: boolean
    onClose?: () => void
    title?: string
    details?: string
    noSpacing?: boolean
    backgroundColor?: string
    size?: MantineSize
    hideHeader?: boolean
    collapsible?: boolean
    closeButton?: boolean
    mode?: DrawerMode
}

export enum DrawerMode {
    GRAPH,
    WORKFLOW,
}

export default function SfyDrawer({ title, details, opened, children, onClose, noSpacing, backgroundColor, size, hideHeader, collapsible, closeButton = true, mode } : SfyDrawerProps) {
    const theme = useMantineTheme();
    const modes = useMemo(() => ({
        [DrawerMode.GRAPH]: {
            inner: {
                top: `calc(${theme.other.headerHeight} + ${theme.other.graph.toolbarHeight})`,
            },
            content: {
            },
        },
        [DrawerMode.WORKFLOW]: {
            inner: {
                top: `calc(${theme.other.headerHeight} + ${theme.other.ruleEngine.editorHeaderHeight})`,
            },
            content: {
                minWidth: `calc(100vw - ${theme.other.ruleEngine.trayWidth})`,
            },
        }
    }), [])
    const modeTheme = mode !== undefined ? modes[mode] : { inner: {}, content: {} }
    const [collapsed, setCollapsed] = useState(false)
    return <Drawer opened={opened || false} onClose={onClose || (() => {})}
        title={<>{title}<div className={classes.drawerDetails}>{details}</div></>}
        position="right"
        withOverlay={false}
        closeOnEscape={closeButton && !hideHeader}
        withCloseButton={closeButton}
        styles={{
            content: {
                backgroundColor,
                overflow: collapsible ? 'visible !important': undefined,
                transform: collapsed ? 'translateX(400px) !important' : undefined,
                ...modeTheme.content,
            },
            inner: {
                height: theme.other.graph.contentHeight,
                ...modeTheme.inner,
            },
            header: {
                backgroundColor: '#1f1f1f',
                padding: '0.9rem !important',
                display: hideHeader ? 'none' : undefined,
            },
            title: {
                fontSize: '1.1rem',
                fontWeight: 300,
                color: 'white',
            },
            close: {
            },
            body: {
                marginTop: noSpacing ? 0 : 20,
                padding: noSpacing ? 0 : undefined,
            },
        }}
        size={size || 'md'}
    >
        { collapsible ? <ActionIcon style={{position: 'absolute', border: '3px solid #f8f8f8', borderRadius: '50%', top: '50%', left: collapsed ? '-30px': '-15px', background: 'white', transition: 'left 0.5s' }}
                onClick={() => setCollapsed(!collapsed)}
            >
                { !collapsed ? <FaChevronRight></FaChevronRight> : <FaChevronLeft></FaChevronLeft> }
            </ActionIcon> : undefined}
        { children }
    </Drawer>
}