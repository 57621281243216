import { createContext, useContext, useEffect, useState } from 'react'
import { Project, ProjectsApi } from '../client'

const SELECTED_PROJECT = "sfy::selected_project"

type ProjectProps = {
    curProject: Project
    projects: Project[]
    projectUrl: (path: string, projectName?: string) => string
    switchProject: (project: Project) => void
    loadProjects: () => Promise<void>
}

const ProjectProviderContext = createContext({} as ProjectProps)

export function ProjectProvider({ children }: { children: JSX.Element | JSX.Element[]}) {
    const [curProject, setCurProject] = useState<Project>({} as Project)
    const [projects, setProjects] = useState<Project[]>([])
    const [loaded, setLoaded] = useState(false)
    async function load() {
        try {
            const resp = await new ProjectsApi().projects()
            //TODO handle error + paging
            setProjects(resp.data.list!)
            let projectName = localStorage.getItem(SELECTED_PROJECT)
            const pathname = window.location.pathname
            if (pathname.startsWith('/p/')) {
                projectName = pathname.replace("/p/", "").split("/")[0]
            }
            const selected = resp.data.list!.find(p => p.name === projectName) || resp.data.list![0]
            setCurProject(selected)
            localStorage.setItem(SELECTED_PROJECT, selected.name!)
        } catch(err) {
            setProjects([])
        }
        setLoaded(true)
    }
    useEffect(() => {
        load()
    }, []);
    return (
        <ProjectProviderContext.Provider value={{ 
            curProject,
            projects,
            loadProjects: load,
            projectUrl: (path: string, projectName?: string) => {
                let actual = projectName || curProject?.name
                if (!actual) {
                    actual = localStorage.getItem(SELECTED_PROJECT) || undefined
                }
                return `/p/${actual || curProject.name}/${path.startsWith("/") ? path.slice(1) : path}`;
            },
            switchProject: (project: Project) => {
                localStorage.setItem(SELECTED_PROJECT, project.name!)
                setCurProject(project)
            }
        }}>
            { loaded && children }
        </ProjectProviderContext.Provider>
    )
}

export const useProject = () => {
  return useContext(ProjectProviderContext)
}