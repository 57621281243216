import EntityPage from "@/components/page/EntityPage";
import ListSection, { ListSectionCallbacks } from "@/components/page/sections/ListSection";
import { DisplayElemSize } from "@/components/table/EntityTable";
import { Catalog, CatalogsApi } from "@/libs/client";
import { useTitle } from "@/libs/hooks/useTitle";
import notify from "@/libs/notify/notify";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { BiRefresh, BiTrash } from "react-icons/bi";
import { FaEdit, FaPlus } from "react-icons/fa";
import { PiPackageDuotone } from "react-icons/pi";
import AddCatalog from "./actions/AddCatalog";
import { useContainer } from "@/components/containers/ContainerProvider";
import UpdateCatalog from "./actions/UpdateCatalog";
import { useNavigate } from "react-router-dom";
import { ActionResultType } from "@/components/actions/actions";

export default function OpsCatalogsLanding() {
    const { t } = useTranslation()
    useTitle(t("Catalogs"))
    const { openModal, openConfirm } = useContainer()
    const navigate = useNavigate()

    const listRef = useRef<ListSectionCallbacks>()
    const headers = [
        t('common.name'),
        'State'
    ];
    return <EntityPage
            category={t('Operations')}
            title={t('Catalogs')}
        ><ListSection<Catalog>
            callbacks={listRef}
            headers={headers}
            renderRow={(e) => ({
                cols: [{
                    display: [{
                        label: e.name || '',             
                    },
                    {
                        label: e.description || '',
                        size: DisplayElemSize.xs,
                        secondary: true,
                    }],
                }, {
                    display: [{
                        label: e.state || '',
                        status:  e.state === 'ACTIVE' ? 'green' : 'blue',
                    }]
                } ],
            })}
            load={async (page: number) => {  
                const resp =  await new CatalogsApi().catalogs(page);
                if (resp.status === 200) {
                    return resp.data;
                }
                return { error: 'TODO' };
            }}
            emptyLabel={t('No catalogs yet')}
            onRowClick={b => navigate(`/ops/catalogs/${b.id}`)}
            rowActions={c => c.fromFederated ? [{ 
                label: 'Refresh',
                icon: <BiRefresh></BiRefresh>, 
                execute: () => {
                    new CatalogsApi().resyncCatalog(c.id!)
                    notify.info('Refreshing catalog')
                }
            }] : [
                {
                    label: 'Update', 
                    icon: <FaEdit></FaEdit>, 
                    execute: () => {
                        openModal('update_catalog', <UpdateCatalog id={c.id!} onRefresh={() => listRef.current?.refresh()}></UpdateCatalog>, {
                            title: t('Update Catalog') || ''
                        })
                    }
                },
                { 
                    label: 'Refresh',
                    icon: <BiRefresh></BiRefresh>, 
                    execute: () => {
                        new CatalogsApi().resyncCatalog(c.id!)
                        notify.info('Refreshing catalog')
                    }
                },
                { 
                    label: 'Delete',
                    icon: <BiTrash></BiTrash>, 
                    execute: async () => {
                        openConfirm('delete_catalog', {
                            title: 'Delte Catalog',
                            confirmationText: 'Are you sure you want to delete this catalog?',
                            cancelText: "No don't delete",
                            submitText: "Delete catalog",
                            color: 'red',
                            onSubmit: async () => {
                                notify.info('Deleting catalog')
                                new CatalogsApi().deleteCatalog(c.id!)
                                listRef.current?.refresh()
                                return { type: ActionResultType.SUCCESS }
                            }
                        })
                    }
                }
            ]}
            topActions={[
                { 
                    label: 'Add Catalog',
                    icon: <FaPlus></FaPlus>,
                    execute: () => {
                        openModal('add_catalog', <AddCatalog onRefresh={() => listRef.current?.refresh()}></AddCatalog>, {
                            title: t('Add Catalog') || ''
                        })
                    }
                }
            ]}
            logo={{
                getUrl: (e) => `/img/catalogs/${e.source?.toLowerCase()}.svg`,
                width: 35,
                maxHeight: 35,
                headerIcon: <PiPackageDuotone></PiPackageDuotone>
            }}
        ></ListSection>
    </EntityPage>
}