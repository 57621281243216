import AiAnimation from "@/components/animations/AiAnimation";
import { GraphQueryResult } from "@/graph/visualize/providers/GraphLoaderProvider";
import { ChatMessage, DataGraphMeta, GraphsApi } from "@/libs/client";
import { Button, Group, Stack, Textarea } from "@mantine/core";
import { MutableRefObject, useCallback, useEffect, useRef, useState } from "react";
import classes from './AiDrawer.module.css';
import cx from 'clsx';

const GREETINGS: string[] = [
    "Hello! I'm here to assist with all your graph-related questions and tasks.",
    "Welcome! How can I help you with your graphs today?",
    "Hi there! Ready to assist you with anything related to graphs.",
    "Greetings! What graph-related challenges can I help you overcome?",
    "Hey! Need assistance with graphs? I'm here for you.",
    "Good day! How may I assist you with your graph-related endeavors?",
    "Welcome aboard! Let's tackle your graph tasks together. What do you need?",
    "Hi! Ready to support you with anything related to graphs. What's on your mind?",
    "Hello there! I'm your go-to for anything graph-related. What can I do for you?",
    "Greetings! Excited to help you with your graph needs. What brings you here today?",
    "Hey there! Graphs on your agenda? I've got you covered.",
    "Good to see you! Ready to dive into the world of graphs together?",
    "Greetings and salutations! How can I assist you with your graph endeavors?",
    "Hi friend! Graph-related challenges ahead? Let's conquer them together.",
    "Welcome! Graph tasks are my specialty. What can I do for you today?",
    "Hello! Looking to make sense of graphs? I'm here to help.",
    "Greetings, explorer of graphs! What adventure can we embark on today?",
    "Hi! Let's make your graph-related journey smooth and successful.",
    "Hello there! Graph puzzles to solve? I'm up for the challenge.",
    "Welcome! Ready to unravel the mysteries hidden within your graphs?",
    "Greetings, graph enthusiast! How can I assist you in your exploration?",
  ];
  
function chooseRandomGreeting(): string {
    return GREETINGS[Math.floor(Math.random() * GREETINGS.length)];
}
  
function ChatBubble({ message, mine, intro, error, board }: { message: string, mine?: boolean, intro?: boolean, error?: boolean, board: MutableRefObject<HTMLDivElement | null> }) {
    const [actualMessage, setActualMessage] = useState(mine ? message : "")
    useEffect(() => {
        if (mine) {
            return;
        }
        const checker = setInterval(() => {
            setActualMessage(act => {
                let i = 0
                for (; i < message.length; i++) {
                    if (act[i] !== message[i] || i >= act.length) {
                        break;
                    }
                }
                if (board.current) {
                    board.current.scrollTop = board.current.scrollHeight;
                }
                if (message.length === act.length) {
                    clearTimeout(checker);
                }
                return message.substring(0, Math.min(message.length, i + 2));
            });
        }, 50);
        return () => {
            clearInterval(checker);
        }
    }, [message])
    if (error) {
        return <div className={cx(classes.chatBubble, classes.errorBubble)}>
            {message}
        </div>
    }
    return <div className={cx(classes.chatBubble, mine ? classes.userBubble : classes.aiBubble, intro ? classes.introBubble : undefined)}>
        {actualMessage}
    </div>
}

type ChatMessageWithRaw = ChatMessage & {
    displatedMessage: string;
    error?: boolean;
    uiOnly?: boolean;
}

function removeLastColon(input: string): string {
    if (input.endsWith(':')) {
        return input.slice(0, -1);
    }
    return input;
}


export default function AiDrawer({ loadDataGraph, projectId }: { projectId: string, loadDataGraph: (query: string) => Promise<GraphQueryResult<DataGraphMeta>> }) {
    const [messages, setMessages] = useState<ChatMessageWithRaw[]>([])
    const [waitingForResp, setWaitingForResp] = useState(false);
    const [introMessage] = useState(chooseRandomGreeting());
    const board = useRef<HTMLDivElement | null>(null)
    const [currentQueries, setCurrentQueries] = useState<string[]>([]);
    const sessionCounter = useRef(0)
    const submitMessage = useCallback(async (newMessage: string) => {
        setMessages(old => [...old, { content: newMessage, role: 'user', displatedMessage: newMessage }]);
        setWaitingForResp(true);
        const curSession = sessionCounter.current
        try {
            const resp = await new GraphsApi().dataGraphAiAssitant(projectId, { 
                messages: [...messages.filter(e => !e.error && !e.uiOnly), { content: newMessage, role: 'user' }]
            });
            if (curSession !== sessionCounter.current) {
                return;
            }
            setMessages(old => [...old, { content: resp.data.raw || "", role: 'assistant', displatedMessage: removeLastColon(resp.data.message) }])
            if (resp.data.resetGraph) {
                await loadDataGraph("")
                setCurrentQueries([]);
            } else if (resp.data.queries.length) {
                setCurrentQueries(resp.data.queries)
                await loadDataGraph(resp.data.queries.join(";"))
            }
        } catch(err) {
            console.log(err)
            setMessages(old => [...old, { displatedMessage: 'An error has occurred while evaluating your request', error: true }]);
        }

        setWaitingForResp(false);
    }, [messages, loadDataGraph])
    return <div>
        <Button variant="white" color="red" size="sm" style={{position: 'absolute', top: 5, right: 5}} onClick={() => {
            setMessages([]);
            sessionCounter.current++;
            setWaitingForResp(false);
        }}>Clear</Button>
        <Button variant="white" size="sm" color="sfy-green" style={{position: 'absolute', top: 5, right: 65}} onClick={() => {
            let displatedMessage = 'There are no current queries applied to the graph.';
            if (currentQueries.length === 1) {
                displatedMessage = 'The current query applied is: \n' + currentQueries[0]
            } else if (currentQueries.length > 1) {
                displatedMessage = 'The current queries applied are: \n' + currentQueries.map(q => `   - ${q}`).join("\n") 
            }
            setMessages(m => [...m, { uiOnly: true, displatedMessage }]);
        }}>Current Query</Button>
        <h2 style={{fontWeight: 'lighter'}}>Query AI Assistant</h2>
        <div style={{overflowY: 'auto', maxHeight: 'calc(100vh - 290px)'}} ref={board}>
            <Group>
                <div style={{marginRight: -10}}>
                    <AiAnimation height={100}></AiAnimation>
                </div>
                <ChatBubble intro message={introMessage} board={board}></ChatBubble>
            </Group>
            <Stack gap={16} style={{marginTop: 10}}>
                {
                    messages.map((m, idx) => <ChatBubble key={idx} mine={m.role === 'user'} message={m.displatedMessage || ''} error={m.error} board={board}></ChatBubble>)
                }
            </Stack>
        </div>
        <div style={{position: 'absolute', bottom: 20, width: 'calc(100% - 40px)'}}>
            <Textarea 
                styles={{ input: {height: 100}}}
                onKeyDown={e => {
                    if (e.key == 'Enter' && !e.shiftKey) {
                        if (waitingForResp) {
                            e.preventDefault();
                            return;
                        }
                        e.preventDefault();
                        submitMessage(e.currentTarget.value)
                        e.currentTarget.value = '';
                        setTimeout(() => {
                            if (board.current) {
                                board.current.scrollTop = board.current.scrollHeight;
                            }
                        }, 100)
                    }
                }}
            ></Textarea>
        </div>
    </div>
}