import { useContainer } from "@/components/containers/ContainerProvider";
import ListSection, { ListSectionCallbacks } from "@/components/page/sections/ListSection";
import { DisplayElemSize } from "@/components/table/EntityTable";
import { Script, ScriptTypeEnum, ScriptsApi } from "@/libs/client";
import { useTitle } from "@/libs/hooks/useTitle";
import { useProject } from "@/libs/project/ProjectProvider";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { AddScript } from "./actions/AddScript";
import { FaEdit, FaPlay, FaTrash } from "react-icons/fa";
import notify from "@/libs/notify/notify";
import { useNavigate } from "react-router-dom";
import { UpdateScript } from "./actions/UpdateScript";

const SCRIPT_LANGUAGES = {
    'PYTHON2': 'Python 2 (Legacy)',
    'PYTHON': 'Python 3 (Beta)',
}

export default function ScriptsList({ type }: { type: ScriptTypeEnum }) {
    const { t } = useTranslation()
    const { curProject, projectUrl } = useProject()
    const { openModal } = useContainer()
    const navigate = useNavigate()
    const listRef = useRef<ListSectionCallbacks>()
    useTitle(t("Scripts"))
    const headers = [
        t('common.name'),
        'Language',
        type === 'REPORT' ? 'Run Every' : 'Target'
    ];
    return <ListSection<Script>
        callbacks={listRef}
        headers={headers}
        renderRow={(e) => ({
            cols: [{
                display: [{
                    label: e.name || '',
                    size: DisplayElemSize.lg,
                }],
            }, {
                display: [{
                    label: SCRIPT_LANGUAGES[e.language!] || '',
                }],
            }, {
                display: [{
                    label: type === 'REPORT' ? e.trigger?.period : e.target,
                }]
            }],
        })}
        load={async (page) => {
            const resp = await new ScriptsApi().scripts(curProject.id!, type, page);
            if (resp.status === 200) {
                return resp.data;
            }
            return { error: 'TODO' };
        }}
        addLabel="Add Script"
        onAdd={() => {
            openModal('add_script', <AddScript type={type} onRefresh={() => listRef.current?.refresh()}></AddScript>, {
                title: t('Add Script') || ''
            })
        }}
        onRowClick={b => navigate(projectUrl(`/analysis/scripts/${b.id}`))}
        rowActions={(s) => { 
            const run = {
                label: 'Run Script',
                icon: <FaPlay></FaPlay>,
                execute: async () => {
                    await new ScriptsApi().runScript(s.id!)
                    notify.success('Script executed successfully ' + s.name)
                }
            };
            const update = {
                label: 'Update',
                icon: <FaEdit></FaEdit>,
                execute: async () => {
                    openModal('update_script', <UpdateScript id={s.id!} onRefresh={() => listRef.current?.refresh()}></UpdateScript>, {
                        title: t('Update Script') || ''
                    })           
                }
            }
            const del = {
                label: 'Delete',
                icon: <FaTrash></FaTrash>,
                execute: async () => {
                    await new ScriptsApi().deleteScript(s.id!)
                    listRef.current?.refresh()
                    notify.success('Successfully deleted report ' + s.name)
                }
            };
            if (type === 'REPORT') {
                return [run, update, del]
            }
            return [update, del]
        }}
        emptyLabel={t(type === 'REPORT' ? 'No report scripts yet' : 'No scan scripts yet')}
    ></ListSection>
}