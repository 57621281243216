import { WorkflowNodeDescriptor, WorkflowNodeContext, WorkflowNodeValues } from "../../descriptor";
import EmbeddedFilterNodesForm from "./EmbeddedFilterNodesForm";
import FilterNodesForm from "./FilterNodesForm";

const get_nodes_desc: WorkflowNodeDescriptor = {
    descriptorId: 'actions/filter_nodes',
    configureForm: (_: WorkflowNodeContext, values: WorkflowNodeValues) => <FilterNodesForm values={values}></FilterNodesForm>,
    embeddedForm: (_: WorkflowNodeContext, values: WorkflowNodeValues) => <EmbeddedFilterNodesForm values={values}></EmbeddedFilterNodesForm>,
    getOutput: (ctx: WorkflowNodeContext, values: WorkflowNodeValues) => {
        if (Object.entries(ctx.inputs).length !== 1) {
            return { invalid: true }
        }
        if (!values.getValue("entity")) {
            return { invalid: true }
        }
        return {
            types: [{ type: `nodes:${values.getValue("entity")}` }],
            many: Object.values(ctx.inputs)[0].many,
        };
    },
};

export default get_nodes_desc;