import EntityPage from "@/components/page/EntityPage";
import { TabData } from "@/components/page/EntityTabs";
import { Deployment, DeploymentsApi } from "@/libs/client";
import { useTitle } from "@/libs/hooks/useTitle";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";


export default function DeploymentLanding() {
    const { id } = useParams()
    const [deployment, setDeployment] = useState<Deployment>()
    const fetch = async () => {
        const res = await new DeploymentsApi().deployment(id!)
        if (res.status == 200) {
            setDeployment(res.data);
        } else {
            //TODO
        }
    }
    useEffect(() => {
        fetch();
    }, [])
    useEffect(() => {
        if (deployment) {
            useTitle(`${deployment.name} - Deployment`)
        }
    }, [deployment])
    return deployment && <EntityPage
        category={`Deployment`}
        title={deployment.name || ''}
        tabs={[{
            key: 'details',
            label: "Details",
            route: `/ops/deployments/${id}/details`,
        }, {
            key: 'secrets',
            label: "Secret Keys",
            route: `/ops/deployments/${id}/secrets`,

        }, {
            key: 'events',
            label: "Events",
            route: `/ops/deployments/${id}/events`,

        }] as TabData[]}
        breadcrumbs={[
            {
                key: 'deployments',
                label: 'Deployments',
                link: '/ops/deployments',
            },
            {
                key: 'name',
                label: deployment.name!,
            }
        ]}
    ></EntityPage>
}