import { ReactNode } from "react"

export type EntityAction<T> = {
    label: string
    icon: ReactNode
    execute: (elem: T) => void
}

export type TopAction = {
    label: string
    icon: ReactNode
    execute: () => void
}

export type ActionResult = {
    type: ActionResultType
    errors?: string[]
}

export enum ActionResultType {
    SUCCESS,
    FAILURE,
    NONE,
    FORM_ERROR,
}