import { ChangeItemProps } from "@/graph/visualize/common/commit/ChangeFab";
import { Change } from "@/libs/client";
import { RiDragMoveFill, RiEditFill, RiCloseFill, RiAddFill } from "react-icons/ri";
import { SCHEMA_NEW_COLOR, SCHEMA_REMOVED_COLOR, SCHEMA_UPDATED_COLOR } from "../config/schema.constants";
import { PiGraphFill } from "react-icons/pi";
import { FaBroom } from "react-icons/fa";

export function buildSchemaChangeProps(change: Change): ChangeItemProps {
    const data = change.data!
    switch(change.type) {
        case 'ADD': return {
            title: change.target === 'ENTITY' ? `Added Entity` : `Added Relationship`,
            elementLabel: data.addEntity?.entity?.type || data.addRelationship?.relationship?.name,
            detail: change.elementId!,
            renderIcon: (props: any) => <RiAddFill {...props}></RiAddFill>,
            color: SCHEMA_NEW_COLOR,
            change,
        }
        case 'UPDATE': return {
            title: change.target === 'ENTITY' ? `Updated Entity` : `Updated Relationship`,
            elementLabel: data.updateEntity?.entity?.type || data.updateRelationship?.relationship?.name,
            detail: change.elementId!,
            renderIcon: (props: any) => <RiEditFill {...props}></RiEditFill>,
            color: SCHEMA_UPDATED_COLOR,
            change,
        }
        case 'REMOVE': return {
            title: "Removed Entity",
            elementLabel: data.removeEntity?.id || data.removeRelationship?.id,
            detail: change.elementId!,
            renderIcon: (props: any) => <RiCloseFill {...props}></RiCloseFill>,
            color: SCHEMA_REMOVED_COLOR,
            change,
        }
        case 'MOVE': return {
            title: "Move Entity",
            elementLabel: data.moveEntity?.id,
            detail: change.elementId!,
            renderIcon: (props: any) => <RiDragMoveFill {...props}></RiDragMoveFill>,
            color: "#726cc4",
            change,
        }
        case 'LAYOUT': return {
            title: "Set Layout",
            detail: "All vertices",
            renderIcon: (props: any) => <PiGraphFill {...props}></PiGraphFill>,
            color: "#8f6cc4",
            change,
        }
    }
    throw Error("Cannot find change")
}

export function getSchemaDetails(change: Change) {
        const data = change.data!
    switch(change.type) {
        case 'ADD': return {
            title: change.target === 'ENTITY' ? `Added Entity` : `Added Relationship`,
            elementLabel: data.addEntity?.entity?.type || data.addRelationship?.relationship?.name,
            detail: change.elementId!,
            renderIcon: (props: any) => <RiAddFill {...props}></RiAddFill>,
            color: SCHEMA_NEW_COLOR,
            change,
        }
        case 'UPDATE': return {
            title: change.target === 'ENTITY' ? `Updated Entity` : `Updated Relationship`,
            elementLabel: data.updateEntity?.entity?.type || data.updateRelationship?.relationship?.name,
            detail: change.elementId!,
            renderIcon: (props: any) => <RiEditFill {...props}></RiEditFill>,
            color: SCHEMA_UPDATED_COLOR,
            change,
        }
        case 'REMOVE': return {
            title: "Removed Entity",
            elementLabel: data.removeEntity?.id || data.removeRelationship?.id,
            detail: change.elementId!,
            renderIcon: (props: any) => <RiCloseFill {...props}></RiCloseFill>,
            color: SCHEMA_REMOVED_COLOR,
            change,
        }
        case 'MOVE': return {
            title: "Move Entity",
            elementLabel: data.moveEntity?.id,
            detail: change.elementId!,
            renderIcon: (props: any) => <RiDragMoveFill {...props}></RiDragMoveFill>,
            color: "#726cc4",
            change,
        }
        case 'LAYOUT': return {
            title: "Set Layout",
            detail: "All vertices",
            renderIcon: (props: any) => <PiGraphFill {...props}></PiGraphFill>,
            color: "#8f6cc4",
            change,
        }
        case 'CLEAR_ALL': return {
            title: 'Clear all',
            detail: '',
            renderIcon: (props: any) => <FaBroom {...props}></FaBroom>,
            color: "#8b588",
            change,
        }
    }
}