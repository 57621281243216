import { Group, Stack } from "@mantine/core";
import { FaCaretDown, FaCaretRight, FaList } from "react-icons/fa";
import { Change, Commit } from "@/libs/client";
import { useState } from "react";
import FabButton from "@/components/buttons/FabButton";
import { useContainer } from "@/components/containers/ContainerProvider";
import classes from './ChangeFab.module.css';
import { DrawerMode } from "@/components/containers/SfyDrawer";

type Attr = {
    key: string,
    value: string
}

export type ChangeItemProps = { 
    title: string,
    elementLabel?: string,
    change: Change,
    detail: string,
    color: string,
    renderIcon: (props: {size: string, color: string}) => JSX.Element
    attrs?: Attr[]
}

function ChangeItem({ title, color, detail, renderIcon, attrs, elementLabel }: ChangeItemProps) {
    const [open, setOpen] = useState(false)
    const hasAttrs = attrs?.length || 0 > 0
    return <Group gap={0} style={{ cursor: hasAttrs ? 'pointer' : undefined, boxSizing: 'content-box', userSelect: 'none', height: open ? '7rem' : '5rem', overflow: 'hidden', transition: 'height 0.5s'}}
        onClick={() => hasAttrs && setOpen(!open)}
    >
            <div style={{ height: '100%', backgroundColor: color, display: 'flex', paddingLeft: '0.6rem', paddingRight: '0.6rem', justifyContent: 'center', alignItems: 'center'}}>
                    {renderIcon({ size: "20px", color: 'white' })}
            </div>
        <Group style={{ flex: 1, backgroundColor: 'white' , height: '100%'}}>
            <Stack gap={0} style={{paddingLeft: '10px', flex: 1, whiteSpace: 'nowrap', maxWidth: '330px' }}>
            <div >
                <div style={{fontSize: '0.85rem', fontWeight: 400, color: '#2d2d2d',overflow: 'hidden', textOverflow: 'ellipsis'}}>
                    <span style={{color,fontWeight: 500}}>{title}</span> { elementLabel ? <>: <span>{elementLabel}</span></> : undefined }
                </div>
                <div style={{fontSize: '0.7rem', color: '#787b82', overflow: 'hidden', textOverflow: 'ellipsis'}}>{detail}</div>
            </div>
            {open ? <div>
            </div> : <></>}
            </Stack>
            {hasAttrs && 
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '8px' }}>
                { open ? <FaCaretDown size="12px"></FaCaretDown> : <FaCaretRight size="12px"></FaCaretRight> }
            </div>
            }
        </Group>
    </Group>
}

type ChangeFabProps = { 
    commit: Commit,
    version: number | string,
    buildChangeProps: (change: Change) => ChangeItemProps
}

export default function ChangeFab({ version, commit, buildChangeProps }: ChangeFabProps) {
    const { openDrawer } = useContainer()
    return <FabButton variant="secondary" className={classes.changesButton}
        onClick={() => openDrawer('changes', <Stack gap={5} style={{marginTop: 0 }}>
            { commit.changes?.map(c => <ChangeItem key={c.elementId} {...buildChangeProps(c)} />) }
        </Stack>, { title: `Changes (v${version})`, details: `Total Changes: ${commit.changes?.length}`, noSpacing: true, backgroundColor: '#f8f8f8', mode: DrawerMode.GRAPH })}>
        <FaList></FaList>
        { (commit?.changes?.length || 0) > 0 ? <div className={classes.count}>{commit?.changes?.length}</div> : <></>}
    </FabButton>
}