
import { PartialTenant } from "@/libs/client";
import { sortBy } from "@/libs/utils/sort";
import moment from "moment";
import { isLocalhost } from "../utils/env";
const STORAGE_TENANT_KEY = "_sfy_tenant";

const PREVIOUSLY_SIGNED_KEY = '_sfy_previously_signed';

type SignedInTenant = {
    tenant: PartialTenant;
    lastSignedIn: number;
}

const parentHost = `${window.location.host.split('.').slice(1).join('.').split(':')[0]}`;

const addSignedInTenant = (tenant: PartialTenant) => {
    const signedInMap = JSON.parse(localStorage.getItem(PREVIOUSLY_SIGNED_KEY) || '{}')
    signedInMap[tenant.name || ''] = {
        tenant,
        lastSignedIn: moment().unix(),
    }
    if (isLocalhost()) {
        localStorage.setItem(PREVIOUSLY_SIGNED_KEY, JSON.stringify(signedInMap))
    } else {
        document.cookie = `${PREVIOUSLY_SIGNED_KEY}=${JSON.stringify(signedInMap)}; domain=${parentHost}; path=/`
    }
}

export const removeSignedInTenant = (name: string) => {
    const signedInMap = JSON.parse(localStorage.getItem(PREVIOUSLY_SIGNED_KEY) || '{}')
    delete signedInMap[name]
    if (isLocalhost()) {
        localStorage.setItem(PREVIOUSLY_SIGNED_KEY, JSON.stringify(signedInMap))
    } else {
        document.cookie = `${PREVIOUSLY_SIGNED_KEY}=${JSON.stringify(signedInMap)}; domain=${parentHost}; path=/`
    }
}

export const getSignedInTenants = () =>  {
    let value;
    if (isLocalhost()) {
        value = localStorage.getItem(PREVIOUSLY_SIGNED_KEY);
    } else {
        value = getCookie(PREVIOUSLY_SIGNED_KEY)
    }
    const signedInMap = JSON.parse(value || '{}')
    return Object.values(signedInMap).map(v => v as SignedInTenant)
        .sort(sortBy(v => moment().from(v.lastSignedIn)))
        .reverse() 
}

export function saveTenantForLogin(tenant: PartialTenant) {
    addSignedInTenant(tenant)
    if (isLocalhost()) {
        return localStorage.setItem(STORAGE_TENANT_KEY, tenant.name!);
    }
}

export function getTenantForLogin(): string | undefined {
    if (!isLocalhost()) {
        return window.location.host.split('.')[0];
    }
    const currentUrl = window.location.href;
    const urlSearchParams = new URLSearchParams(currentUrl.split('?')[1]);
    return urlSearchParams.get('t') || localStorage.getItem("_sfy_tenant") || undefined;
}

//TODO extract 
function getCookie(cookieName: string) {
    // Split the cookie string into individual cookies
    const cookiesArray = document.cookie.split(';');

    // Iterate over the cookies to find the one with the specified name
    for (let i = 0; i < cookiesArray.length; i++) {
        const cookie = cookiesArray[i].trim();

        // Check if the cookie starts with the desired name
        if (cookie.startsWith(cookieName + '=')) {
            // Return the value of the cookie
            return cookie.substring(cookieName.length + 1);
        }
    }

    // Return null if the cookie is not found
    return null;
}
