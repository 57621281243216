import { useTitle } from "@/libs/hooks/useTitle";
import EntityPage from "@/components/page/EntityPage";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { TabData } from "@/components/page/EntityTabs";
import { useProject } from "@/libs/project/ProjectProvider";
import { GeneratedReport, ReportsApi } from "@/libs/client";
import moment from "moment";
import { useTranslation } from "react-i18next";

export default function GeneratedReportLanding() {
    const { t } = useTranslation()
    const { id, gid } = useParams()
    const { projectUrl } = useProject()
    const [report, setReport] = useState<GeneratedReport>()
    const fetch = async () => {
        const res = await new ReportsApi().generatedReport(gid!)
        if (res.status == 200) {
            setReport(res.data);
        } else {
            //TODO
        }
    }
    useEffect(() => {
        fetch();
    }, [])
    useEffect(() => {
        if (report) {
            useTitle(`${report.report?.name} - ${moment(report.createdDate).format('YYYY-MM-DD - HH:mm:ss')} - ${t('generated_reports.label_other')}`)
        }
    }, [report])
    return report && <EntityPage
        category={t('generated_reports.label_other')}
        title={`${report.report?.name} - ${moment(report.createdDate).format('YYYY-MM-DD - HH:mm:ss')}`}
        tabs={[{
            key: 'details',
            label: t('common.details'),
            route: projectUrl(`/insights/reports/${id}/generated_reports/${gid}/details`),
        }] as TabData[]}
        breadcrumbs={[
            {
                key: 'reports',
                label: t('common.label_other'),
                link: projectUrl('/insights/reports'),
            },
            {
                key: 'name',
                label: report.report!.name!,
                link: projectUrl(`/insights/reports/${id}`),
            },
            {
                key: 'created',
                label: moment(report.report!.createdDate!).format('YYYY-MM-DD - HH:mm:ss'),
            }
        ]}
    ></EntityPage>
}