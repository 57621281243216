import { Flex } from '@mantine/core';
import Header from "@/components/header/Header";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTitle } from '@/libs/hooks/useTitle';
import LoginBox from "./LoginBox";
import InfoBox from "./InfoBox";
import classes from './LoginLanding.module.css';
import { ContextApi, LoginContext } from '@/libs/client';
import { getTenantForLogin } from '@/libs/common/tenant';
import { useLocation, useNavigate } from 'react-router-dom';
import FindTenantBox from './FindTenantBox';
import { isLocalhost } from '@/libs/utils/env';

export default function LoginLanding({ findTenant } : { findTenant?: boolean }) {
    const { t } = useTranslation()
    const [loginCtx, setLoginCtx] = useState<LoginContext>()
    const navigate = useNavigate();
    const location = useLocation();
    useTitle(t("common.login"))
    const fetchLoginCtx = async () => {
        if (findTenant) {
            return
        }
        const searchParams = new URLSearchParams(location.search);
        let tenant = getTenantForLogin();
        if (!tenant) {
            navigate(`/find-tenant`, { replace: true })
            return
        }
        const resp = await new ContextApi().loginContext(tenant)
        tenant = resp.data.tenant?.name
        if ((!searchParams.has("t") || searchParams.get("t") != tenant) && isLocalhost()) {
            searchParams.set('t', resp.data.tenant?.name!)
            navigate(`${location.pathname}${searchParams.size ? '?' : ''}${searchParams.toString()}`, { replace: true })
        }
        setLoginCtx(resp.data)
    }
    useEffect(() => {
        fetchLoginCtx()
    }, [location])
    return (loginCtx || findTenant) &&<React.Fragment>
        <Header loggedIn={false}></Header>
        <Flex className={classes.grid}>
            <div style={{flex: 1}}>
                { findTenant ? <FindTenantBox></FindTenantBox> : <LoginBox loginCtx={loginCtx!}></LoginBox> }
            </div>
            <div className={classes.infoBox}>
                <InfoBox></InfoBox>
            </div>
        </Flex>
    </React.Fragment>
}