import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { ApiError } from '@/libs/common/errors';
import { DataGraphMeta, GraphsApi, Project, SchemaGraph } from '@/libs/client';
import isApiError from '@/libs/utils/errors';
import { useProject } from '@/libs/project/ProjectProvider';
import LazyDataGraph from '../render/data/lazy/LazyDataGraph';

export type GraphQueryResult<T> = {
    success: boolean
    graph?: T
    errors?: string[]
}

type GraphLoaderContextProps = {
    dataGraph?: LazyDataGraph
    currentQuery?: string
    schemaGraph?: SchemaGraph
    reloadSchemaGraph: (integration?: string) => Promise<GraphQueryResult<SchemaGraph>>
    reloadDataGraph: (integration?: string) => Promise<GraphQueryResult<DataGraphMeta>>
    loadDataGraph: (query: string) => Promise<GraphQueryResult<DataGraphMeta>>
    initErrors: string[]
    isLoading: () => boolean
    loadingSchema: { loading: boolean, timeInMs: number }
    loadingData: { loading: boolean, timeInMs: number }
}

export const GraphLoaderContext = createContext({
} as GraphLoaderContextProps);

export const useGraphLoader = () => useContext(GraphLoaderContext);

async function fetchGraph<T>(fetcher: (query?: string) => Promise<T>) : Promise<GraphQueryResult<T>>  {
    try {
        const res = await fetcher()
        return { success: true, graph: res }
    } catch(err) {
        let errors = ['Unexpected error while executing query']
        if (isApiError(err)) {
            errors = (err as AxiosError<ApiError[]>).response?.data.map(e => e.message) || errors
        }
        return { success: false, errors }
    } 
}

export async function fetchDataGraph(project: Project, integration?: string) : Promise<GraphQueryResult<DataGraphMeta>> {
    return fetchGraph(async () => (await new GraphsApi().dataGraphMeta(project.id!, integration)).data)
}

export async function fetchSchemaGraph(project: Project, integration?: string) : Promise<GraphQueryResult<SchemaGraph>> {
    return fetchGraph(async () => (await new GraphsApi().schemaGraph(project.id!, integration || undefined)).data)
}

export const GraphLoaderProvider = ({ children } : { children: JSX.Element | JSX.Element[]}) => {
    const [schemaGraph, setSchemaGraph] = useState<SchemaGraph | undefined>()
    const [dataGraph, setDataGraph] = useState<LazyDataGraph | undefined>()
    const [currentQuery, setCurrentQuery] = useState<string>("")
    const [initErrors, setInitErrors] = useState<string[]>([])
    const [loadingSchema, setLoadingSchema] = useState({ loading: true, timeInMs: 0 })
    const [loadingData, setLoadingData] = useState({ loading: true, timeInMs: 0 })
    const { curProject } = useProject()
    const loadDataGraph = async (query: string, integration?: string) => {
        const startTime = performance.now();
        setLoadingData({ loading: true, timeInMs: 0 })
        const res = await fetchDataGraph(curProject, integration)
        if (res.success) {
            const lazy = new LazyDataGraph(curProject.id!, res.graph!, { integration, query });
            await lazy.init();
            setDataGraph(lazy);
            setCurrentQuery(query); //TODO key add
        }
        setLoadingData({ loading: false, timeInMs: performance.now() - startTime })
        return res
    }
    const loadSchemaGraph = async (integration?: string) => {
        const startTime = performance.now();
        const res = await fetchSchemaGraph(curProject, integration)
        res.success ? setSchemaGraph(res.graph) : setInitErrors(res.errors || [])
        setLoadingSchema({ loading: false, timeInMs: performance.now() - startTime})
        return res
    }
    const reloadDataGraph = (integration?: string) => loadDataGraph(currentQuery, integration)

    useEffect(() => {
        loadDataGraph("")
        loadSchemaGraph("")
    }, [])

    const isLoading = useCallback(() => {
        return loadingSchema.loading || loadingData.loading;
    }, [loadingSchema, loadingData])

    return (
        <GraphLoaderContext.Provider value={{ 
            currentQuery,
            schemaGraph,
            dataGraph,
            loadDataGraph,
            reloadDataGraph,
            reloadSchemaGraph: loadSchemaGraph,
            initErrors,
            isLoading,
            loadingSchema,
            loadingData,
        }}>
            {children}
        </GraphLoaderContext.Provider>
    );
};