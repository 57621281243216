import { useTranslation } from "react-i18next"
import SfyForm from "@/components/form/SfyForm"
import { Avatar, Button, Checkbox, Group, Select, Stack, Text } from "@mantine/core"
import { useEffect, useMemo, useState } from "react"
import { BsFiletypeCsv, BsFiletypeJson } from 'react-icons/bs'
import { HiDownload } from "react-icons/hi"
import { GraphsApi, Project } from "@/libs/client"
import { GraphType } from "@/libs/utils/graph"
import { useProject } from "@/libs/project/ProjectProvider"
import { useGraphDisplay } from "../../providers/GraphDisplayProvider"
import { useGraphLoader } from "../../providers/GraphLoaderProvider"
import { useContainer } from "@/components/containers/ContainerProvider"
import { MdOutlineFileDownload } from "react-icons/md"
import { buildApiUrl } from "@/libs/utils/apiUrl"
import classes from './ExportGraph.module.css';

function FileDownload({ name, format } : { name: string, format?: string, currentQuery: string, fieldsToInclude: string[], allFields: boolean }) {
    const { curProject } = useProject()
    const getIcon = () => {
        switch(format) {
            case "csv": return <BsFiletypeCsv></BsFiletypeCsv>
            case "json": return <BsFiletypeJson></BsFiletypeJson>
        }
    }
    const href = useMemo(() => {
        //`/graphs/REF/download/${name}?query=${currentQuery}&fields=${fieldsToInclude.join(',')}&allFields=${allFields}`
        return buildApiUrl(`/projects/${curProject.id}/graphs/schema/export/graph.json`);
    }, [])
    return <a className={classes.downloadLink} href={href} download>
        <Group wrap="nowrap" className={classes.download}>
        <Avatar size={94} radius="md">
            {getIcon()}
        </Avatar>
        <div>
          <Text fz="lg" fw={500}>
            {name}
          </Text>
          <Group wrap="nowrap" gap={10} mt={3}>
            <Text fz="xs" c="dimmed">
              
            </Text>
          </Group>
        </div>
        <div style={{fontSize: '2rem', position: 'absolute', right: 20, color: '#12b886' }}>
            <HiDownload></HiDownload>
        </div>
      </Group>
      </a>
}

async function fetchAvailableFields(project: Project) {
    //TODO make it an API endpoint instead
    const res = await new GraphsApi().dataGraph(project.id!)
    const graph = res.data
    const fields = ['id', 'label', ...[...new Set([...(Object.values(graph?.nodes || {})), ...(Object.values(graph?.edges || {}))].flatMap(v => Object.keys(v.properties || {})))].sort()]
    return fields
}

type ExportGraphProps = {
    onClose: () => void
    currentQuery: string | undefined
    includeFields: string[]
    activeType: GraphType
}


export default function ExportGraph({ onClose, activeType, currentQuery, includeFields } : ExportGraphProps) {
    const [format, setFormat] = useState<string | null>("json")
    const [view, setView] = useState<string | null>(currentQuery ? "current_query" : "entire")
    const [fields, setFields] = useState<string | null>(includeFields.length ? "selected" : "all")
    const [selectedFields, setSelectedFields] = useState<string[]>([...includeFields])
    const [availableFields, setAvailableFields] = useState<string[]>([])
    const { curProject } = useProject()
    const isSchema = activeType === 'SCHEMA';
    useEffect(() => {
        fetchAvailableFields(curProject).then(setAvailableFields);
    }, [curProject])
    const { t } = useTranslation()
    return <SfyForm
          submitLabel={t("Export graph")} 
          onClose={onClose}
          hideSubmit={true}
        >
        <div>
            <Stack gap={3}>
            <Select
            label="Format"
            placeholder=""
            value={format}
            onChange={setFormat}
            data={[
                { value: 'json', label: 'JSON' },
                { value: 'csv', label: 'CSV' },
            ]}
            />
            { !isSchema && <Select
                label="View"
                placeholder=""
                value={view}
                onChange={setView}
                data={[
                    { value: 'entire', label: 'Entire Graph' },
                    { value: 'current_query', label: 'Current Query' },
                ]}
            /> }
            { !isSchema && <Select
                label="Fields"
                placeholder=""
                value={fields}
                onChange={setFields}
                data={[
                    { value: 'all', label: 'Include all fields' },
                    { value: 'selected', label: 'Include selected fields' },
                ]}
            /> }
            {
                fields === 'selected' &&
                <Checkbox.Group style={{border: '1px solid #f0f0f0', marginTop: 10, maxHeight: 200, overflowY: 'auto', padding: 3}} value={selectedFields} onChange={setSelectedFields}>
                    {availableFields.map(af => <Checkbox key={af} style={{marginTop: 5}} value={af} label={af}></Checkbox>)}
                </Checkbox.Group>
            }
            </Stack>
            <div style={{marginTop: 20}}>
                { format === 'json' ? <FileDownload
                        format={format || undefined} 
                        fieldsToInclude={fields === 'selected' ? selectedFields : []}
                        allFields={fields === 'all'}
                        currentQuery={(view === 'current_query' ? currentQuery : '' ) || ''} 
                        name={ 'graph.json' }
                ></FileDownload> : <><FileDownload 
                    format={format || undefined} 
                    fieldsToInclude={fields === 'selected' ? selectedFields : []}
                    allFields={fields === 'all'}
                    currentQuery={(view === 'current_query' ? currentQuery : '' ) || ''} 
                    name={ activeType === 'DATA' ? "nodes.csv" : "entities.csv" }></FileDownload>
                <FileDownload 
                    format={format || undefined} 
                    fieldsToInclude={fields === 'selected' ? selectedFields : []}
                    allFields={fields === 'all'}
                    currentQuery={(view === 'current_query' ? currentQuery : '') || ''} 
                    name={ activeType === 'DATA' ? "edges.csv" : "relationships.csv" }></FileDownload></> }
            </div>
        </div>
    </SfyForm>

}


const EXPORT_GRAPH_KEY = 'export_graph'
export function ExportGraphButton() {
    const { t } = useTranslation()
    const { includeFields, activeType } = useGraphDisplay()
    const { currentQuery } = useGraphLoader()
    const { openDrawer, closeDrawer } = useContainer()
    return <Button 
        onClick={() => openDrawer(EXPORT_GRAPH_KEY, <ExportGraph activeType={activeType} onClose={() => closeDrawer(EXPORT_GRAPH_KEY)} currentQuery={currentQuery} includeFields={includeFields}></ExportGraph>, { title: t('Export Graph') || ''})}
        variant="secondary"
        styles={{label: {fontWeight: 600}, root: {marginRight: 8}}}
        size="xs" 
        leftSection={<MdOutlineFileDownload></MdOutlineFileDownload>}>{t('Export')}</Button>

}