import { ActionIcon, Badge } from "@mantine/core";
import { HiMiniBell } from "react-icons/hi2";
import classes from './AlertDropdown.module.css';
import cx from 'clsx';
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, AlertsApi } from "@/libs/client";
import moment from "moment";
import { getLevelColor } from "@/admin/alerts/AlertsLanding";
import { boldify } from "@/libs/utils/strings";
import RenderHTML from "@/libs/utils/RenderHTML";
import { FaTimes } from "react-icons/fa";

export default function AlertDropdown() {
    const navigate = useNavigate()
    const [opened, setOpened] = useState(false);
    const [alerts, setAlerts] = useState<Alert[]>([]);
    const [alertTotal, setAlertTotal] = useState(0);
    const handleWindowClick = useCallback(() => {
        setOpened(false);
    }, []);
    const fetchAlerts = async () => {
        const resp = await new AlertsApi().alerts();
        setAlerts(resp.data.list?.slice(0, 5) || []);
        setAlertTotal(resp.data.totalElements || 0)
    }
    useEffect(() => {
        fetchAlerts();
    }, [])
    useEffect(() => {
        if (opened) {
            window.addEventListener('click', handleWindowClick);
        } else {
            window.removeEventListener('click', handleWindowClick);
        }
    }, [opened])
    return <div>
        <div style={{position: 'relative', marginTop: 2}}>
            <ActionIcon onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                setOpened(v => !v)
            }} color="white" variant="subtle"><HiMiniBell size="1.2rem"></HiMiniBell></ActionIcon>
            { alertTotal > 0 ? <div className={classes.stateCircle}></div> : undefined }
        </div>
        <div className={cx(classes.dropdown, opened ? classes.open : undefined)} onClick={e => {
            e.stopPropagation();
        }}>
            <div className={classes.dropdownHeader}>
                <div style={{position: 'absolute', left: 15, top: 8, fontSize: '1rem'}}>
                    <HiMiniBell></HiMiniBell>
                    { alertTotal > 0 ? <div className={classes.stateCircle}></div> : undefined }
                </div>
                <ActionIcon onClick={() => setOpened(false)} size="sm" style={{position: 'absolute', right: 10, top: 9}} color="transparent">
                    <FaTimes size="0.9rem"></FaTimes>
                </ActionIcon>
                Alerts (<b>{alertTotal}</b>)
            </div>
            { alertTotal === 0 ? <div className={classes.noNew}>
                No new alerts
            </div> : <></> }
            { alerts.map(a => <div className={classes.alert}>
                <div style={{display: 'flex'}}>
                    <div style={{flex: 1, fontSize: '0.9rem'}}>
                        <RenderHTML>
                            {boldify(a.message || '', a.context)}
                        </RenderHTML>
                    </div>
                    <div> 
                        <Badge size="sm" color={getLevelColor(a.level!)}>{a.level}</Badge>
                    </div>
                </div>
                <div style={{fontSize: '0.75rem', color: '#a0a0a0'}}>
                    {moment(a.createdDate).fromNow()} 
                </div>
            </div>) }
            <div className={classes.viewAll} onClick={() => {
                navigate('/admin/alerts')
                setOpened(false)
            }}>
                View all alerts
            </div>
        </div>
    </div>
}