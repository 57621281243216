import { SchemaEntity, SchemaGraph, SchemaRelationship } from "../client";

export type GraphType = 'DATA' | 'SCHEMA'

export function buildEntitiesMap(s: SchemaGraph) : { [key: string] : SchemaEntity } {
    return Object.values(s.entities).reduce((acc, cur) => {
        acc[cur.type!] = cur
        return acc
    }, {} as { [key: string] : SchemaEntity })
}

export function buildRelationshipsMap(s: SchemaGraph) : { [key: string] : SchemaRelationship } {
    return Object.values(s.relationships).reduce((acc, cur) => {
        acc[cur.name!] = cur
        return acc
    }, {} as { [key: string] : SchemaRelationship })
}

export function getEntity(s: SchemaGraph, type: string) : SchemaEntity {
    return buildEntitiesMap(s)[type]
}