import ListSection, { ListSectionCallbacks } from "@/components/page/sections/ListSection";
import { DisplayElemSize } from "@/components/table/EntityTable";
import { Catalog, CatalogsApi, Integration } from "@/libs/client";
import { useTitle } from "@/libs/hooks/useTitle";
import { buildApiUrl } from "@/libs/utils/apiUrl";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { GrInstallOption } from "react-icons/gr";
import { PiPackageDuotone } from "react-icons/pi";
import { useNavigate, useParams } from "react-router-dom";

export default function OpsCatalogIntegrations() {
    const { id } = useParams()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [catalog, setCatalog] = useState<Catalog>()
    
    const listRef = useRef<ListSectionCallbacks>()
    useTitle(t("integrations.label_other"))
    const headers = [
        t('common.name'),
        t('common.latest_version'),
    ];

    const fetchCatalog = async () => {
        const resp = await (new CatalogsApi().catalog(id!))
        setCatalog(resp.data)
    }
    useEffect(() => {
        fetchCatalog()
    }, [])

    return catalog && <ListSection<Integration>
            callbacks={listRef}
            headers={headers}
            renderRow={(e) => ({
                cols: [{
                    display: [{
                        label: e.label || '',
                    },
                    {
                        label: e.description || '',
                        size: DisplayElemSize.xs,
                        secondary: true,
                    }],
                },{
                    display: [{
                        label: e.latestVersion || '',
                        size: DisplayElemSize.lg,
                    }],
                } ],
            })}
            load={async (page: number) => {  
                const resp =  await new CatalogsApi().catalogIntegrations(id!, page)
                if (resp.status === 200) {
                    return resp.data;
                }
                return { error: 'TODO' };
            }}
            emptyLabel={t('No integrations yet')}
            rowActions={() => {
                if (catalog.source === "GITHUB") {
                    return [{
                        label: 'Edit Schema',
                        icon: <GrInstallOption></GrInstallOption>,
                        execute(elem) {
                            navigate(`/ops/catalogs/${id}/integrations/${elem.id}/schema/edit`)
                        },
                    }]
                }
                return []    
            }}
            logo={{
                getUrl: (e) => buildApiUrl(`/integrations/${e.id}/logo.svg`),
                width: 35,
                headerIcon: <PiPackageDuotone></PiPackageDuotone>
            }}
        ></ListSection>
}