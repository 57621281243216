
type SortOrder = "ASC" | "DESC";

export function sortBy<T>(
  extractField: (item: T) => Date | string | number | undefined,
  sortOrder: SortOrder = "ASC"
) {
  return (a: T, b: T) => {
    const aF = extractField(a)
    const bF = extractField(b)
    if (typeof aF === 'number' && typeof bF === 'number') {
      return (sortOrder === "ASC" ? 1 : -1) * (aF - bF);
    } else if (aF instanceof Date && bF instanceof Date) {
      return (sortOrder === "ASC" ? 1 : -1) * (aF.getTime() - bF.getTime());
    }
    const comparison = (extractField(a) || '').toString().localeCompare((extractField(b) || '').toString());
    return sortOrder === "ASC" ? comparison : -comparison;
  };
}