import { Divider, Group, Menu, UnstyledButton } from "@mantine/core";
import { FaArrowRight } from "react-icons/fa";
import { HiDotsVertical } from "react-icons/hi";
import React, { ReactNode } from "react";
import classes from './GraphToolbar.module.css';

type ActionItem = {
    label: string
    onClick: () => void
}

type ActionItemGroup = {
    label: string
    items: ActionItem[]
}

type MoreActionsProps = {
    actions: ActionItemGroup[]
}

function MoreActions({ actions } : MoreActionsProps) {
    return <>
        <Menu shadow="md" width={200} offset={{ crossAxis: -80, mainAxis: 10 }}>
            <Menu.Target>
                <UnstyledButton>
                    <div className={classes.moreActions}>
                        <HiDotsVertical size={14}></HiDotsVertical>
                    </div>
                </UnstyledButton>
            </Menu.Target>

            <Menu.Dropdown>
                { actions.map(ag =>
                    <React.Fragment key={ag.label}>
                        <Menu.Label key={ag.label}>{ag.label}</Menu.Label>
                        { ag.items.map(a => 
                            <Menu.Item
                                key={a.label}
                                onClick={a.onClick}
                            >
                                {a.label}
                            </Menu.Item>)
                        }
                    </React.Fragment> 
                    )
                }
            </Menu.Dropdown>
        </Menu>
    </>
}

type GraphToolbarBreadcrumbProps = {
    breadcrumb: string[]
}

function GraphToolbarBreadcrumb({ breadcrumb } : GraphToolbarBreadcrumbProps) {
    function splitArray<T>(arr: T[]): [T[], T] {
        const firstPart = arr.slice(0, arr.length - 1);
        const lastElement = arr[arr.length - 1];
        return [firstPart, lastElement];
    }
    const splitBreadcrumb = splitArray(breadcrumb)
    return <div style={{display: 'flex', alignItems: 'center', userSelect: 'none', fontSize: '0.9rem'}}>
        {splitBreadcrumb[0].map(b => 
            <React.Fragment key={b}>
                <div style={{color: '#555'}}>{ b } </div>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: 8, marginRight: 8, marginTop: 3}}>
                    <FaArrowRight size="0.6rem"  color="#12b886"></FaArrowRight>
                </div>
            </React.Fragment>
        )}
        <div style={{ fontWeight: 600, color: '#12b886'}}>{splitBreadcrumb[1]}</div>
    </div>
}

type GraphToolbarProps = {
    lhs?: ReactNode[][]
    rhs?: ReactNode[][]
    moreActions?: ActionItemGroup[]
    breadcrumb?: string[]
}

export default function GraphToolbar({ breadcrumb, lhs, rhs, moreActions } : GraphToolbarProps ) {
    const hasActions = Object.values(moreActions || []).length > 0
    return <div className={classes.toolbar}>
        <div style={{flex: 1}}>
            { breadcrumb ? <GraphToolbarBreadcrumb breadcrumb={breadcrumb}></GraphToolbarBreadcrumb> : undefined}
            <Group gap="xs">
                { lhs?.map((arr, idx) => [
                    ...arr.map(node => node),
                    idx < lhs.length - 1 ? <Divider key={idx} size="xs" orientation="vertical" /> : <React.Fragment key={idx}></React.Fragment>
                ])?.flatMap(e => e)}
            </Group>
        </div>
        <Group gap={2}>
            { rhs?.map(arr => [
                ...arr.map(node => node),
                <Divider size="xs" orientation="vertical" />    
            ])?.flatMap(e => e)}
        </Group>
        { hasActions && 
            <>
                <MoreActions actions={moreActions || []}></MoreActions>
            </>
        }
    </div>
} 