import { Ticket, TicketsApi } from "@/libs/client"
import { Badge, Group, Stack } from "@mantine/core"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom"
import showdown from "showdown";

const converter = new showdown.Converter();

export default function TicketDetails() {
    const { t } = useTranslation()
    const { id } = useParams()
    const [ticket, setTicket] = useState<Ticket>()
    const fetchTicket = async () => {
        setTicket((await new TicketsApi().ticket(id!)).data)
    }
    useEffect(() => {
        fetchTicket()
    }, [])
    return ticket && <Stack>
        <div>
            <label>{t('common.title')}</label>
            <div>{ticket.title}</div>
        </div>
        <div>
            <label>{t('common.labels')}</label>
            <Group gap={4}>{ticket.labels.map(l => <Badge>{l}</Badge>)}</Group>
        </div>
        <div>
            <label>{t('common.description')}</label>
            <div style={{fontSize: '0.9rem'}}>
            <div style={{border: '1px solid #f0f0f0', padding: '10px 20px', borderRadius: 10}} dangerouslySetInnerHTML={{ __html: converter.makeHtml(ticket.description || '') }} />
            </div>
        </div>
        { ticket.synchedService != null ? <>
            <div>
                <label>{t('common.service')}</label>
                <div>{ticket.synchedService}</div>
            </div>

            <div>
                <label>{t('tickets.service_ticket_number')}</label>
                <div><a href={ticket.synchedUrl}>{ticket.synchedTicketNumber}</a></div>
            </div>
        </> : <></> }
    </Stack>
}