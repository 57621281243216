import { useContainer } from "@/components/containers/ContainerProvider";
import ListSection, { ListSectionCallbacks } from "@/components/page/sections/ListSection";
import { DisplayElemSize } from "@/components/table/EntityTable";
import { InstalledIntegration, IntegrationsApi, Mapping } from "@/libs/client";
import { useTitle } from "@/libs/hooks/useTitle";
import { useTranslation } from "react-i18next";
import MappingForm from "./actions/add/MappingForm";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { FaEdit, FaTrash } from "react-icons/fa";
import { transformMappedType } from "./utils/mapping.utils";
import { BiDownload } from "react-icons/bi";
import { downloadFileFromObject } from "@/libs/utils/download";
import ImportMappingsForm from "./actions/ImportMappingsForm";

export default function MappingList() {
    const { openModal } = useContainer()
    const { t } = useTranslation()
    const listRef = useRef<ListSectionCallbacks>()
    const params = useParams()
    useTitle(t("Mappings"))
    const headers = [
        t('File Pattern'),
        t('Mapped')
    ];
    const [installed, setInstalled] = useState<InstalledIntegration>()
    const fetchInstalled = async () => {
        const resp = await new IntegrationsApi().installedIntegration(params.id!)
        setInstalled(resp.data)
    }
    useEffect(() => {
        fetchInstalled()
    }, []);
    return <ListSection<Mapping>
        callbacks={listRef}
        headers={headers}
        renderRow={(e) => ({
            cols: [{
                display: [{
                    label: e.filePattern || '',
                    size: DisplayElemSize.lg,
                }, {
                    label: e.fileType || '',
                    size: DisplayElemSize.sm,
                    secondary: true,
                }],
            }, {
                display: [{
                    elem: transformMappedType(e as any),
                    size: DisplayElemSize.lg,
                }, {
                    label: e.elementType || '',
                    size: DisplayElemSize.sm,
                    secondary: true,
                }],
            }],
        })}
        load={async () => {
            const resp = await new IntegrationsApi().listMappings(params.id!)
            if (resp.status === 200) {
                return { list: resp.data };
            }
            return { error: 'TODO' };
        }}
        emptyLabel={t('No mappings yet')}
        addLabel={t('Add Mapping')}
        onAdd={() => openModal('add_mapping_form', <MappingForm
            installedId={params.id!}
            onCompleted={() => listRef.current?.refresh()}
            ></MappingForm>, {
                title: 'Add Mapping',
            })}
        topActions={[
            {
                label: "Import Mappings",
                icon: <BiDownload></BiDownload>,
                execute: () => {
                    openModal('import_mappings', <ImportMappingsForm id={params.id!} onRefresh={listRef.current?.refresh!}></ImportMappingsForm>, {
                        title: 'Import Mappings'
                    })
                }
            },
            {
                label: "Export Mappings",
                icon: <BiDownload></BiDownload>,
                execute: async () => {
                    const resp = await new IntegrationsApi().listMappings(params.id!)
                    downloadFileFromObject(resp.data, `mappings-${params.id}.json`)
                }
            }
        ]}
        rowActions={(e) => [{
            label: 'Update',
            icon: <FaEdit></FaEdit>,
            execute: (e) => {
                openModal('update_mapping_form', <MappingForm
                    update
                    installedId={params.id!}
                    mapping={e}
                    custom={!!installed?.integration?.customType}
                    onCompleted={() => listRef.current?.refresh()}
                ></MappingForm>, {
                    title: 'Update Mapping',
                })
            }
        },{
            label: 'Delete',
            icon: <FaTrash></FaTrash>,
            execute: async () => {
                await new IntegrationsApi().deleteMapping(e.id!)
                listRef.current?.refresh()
            }            
        }]}
    ></ListSection>
}