import { useTranslation } from "react-i18next"
import SfyForm from "@/components/form/SfyForm"
import CodeMirror from "react-codemirror"
import { useForm } from "@mantine/form"
import FormTextInput from "@/components/form/inputs/FormTextInput"
import { ActionResultType } from "@/components/actions/actions"
import { ViewsApi } from "@/libs/client"
import classes from './SaveGraphView.module.css';

export default function SaveGraphView({ projectId, onClose, currentQuery } : { projectId: string, onClose: () => void, onRefresh: () => void, currentQuery: string | undefined}) {
    const { t } = useTranslation()
    const form = useForm({
        initialValues: {
            name: '',
        },
    });
    return <SfyForm 
          submitLabel={t("Save view")} 
          onClose={onClose}
          onSubmit={async () => {
            await new ViewsApi().addView(projectId, {
                ...form.values,
                query: currentQuery,
            })
            return { type: ActionResultType.SUCCESS }
          }}
          successLabel={t("Successfully saved view!")}
          errorLabel={t("Unable to save view.")}
        >
        <FormTextInput
            label={t('common.name')}
            required
            {...form.getInputProps('name')}
        ></FormTextInput>
        <label className={classes.label}>Gremlin Query</label>
        <CodeMirror className={classes.query} value={currentQuery} options={{lineNumbers: true, mode: 'groovy', theme: 'neo', readOnly: true}}></CodeMirror>
    </SfyForm>
}