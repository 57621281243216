import { useGraphDisplay } from "@/graph/visualize/providers/GraphDisplayProvider";
import GraphToggle from "@/graph/visualize/common/toolbar/GraphToggle";
import GraphToolbar from "@/graph/visualize/common/toolbar/GraphToolbar";
import ViewDetailsButton from "@/graph/visualize/common/toolbar/ViewDetailsButton";
import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { SchemaGraph } from "@/libs/client";
import { SchemaDetails } from "@/graph/visualize/common/actions/view/SchemaDetails";
import { useProject } from "@/libs/project/ProjectProvider";
import { ExportGraphButton } from "@/graph/visualize/common/actions/ExportGraph";
import { useTranslation } from "react-i18next";

type SchemaViewerToolbarProps = {
    schemaGraph?: SchemaGraph
}

export default function SchemaViewerToolbar({ schemaGraph } : SchemaViewerToolbarProps) {
    const { t } = useTranslation()
    const { selected } = useGraphDisplay()
    const { projectUrl } = useProject()
    let selectedActions: ReactNode[][] = []
    if (selected?.length > 0) {
        selectedActions = [[<ViewDetailsButton
            key="details"
            renderDetails={() => <SchemaDetails selected={selected} schemaGraph={schemaGraph!}></SchemaDetails>}
        ></ViewDetailsButton>]]
    }
    const navigate = useNavigate()
    return <>
        <GraphToolbar
            moreActions={[{
                label: t('common.actions'),
                items: [
                {
                    label: t('graph.update_schema'),
                    onClick: () => navigate(projectUrl('/data/graph/schema/edit'))
                }
            ]}]}
            lhs={[[<GraphToggle key="toggle"></GraphToggle>]]}
            rhs={[...selectedActions, [<ExportGraphButton></ExportGraphButton>]]}
        ></GraphToolbar>
    </>
}