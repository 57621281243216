import { ContainerProviderContextProps } from '@/components/containers/ContainerProvider';
import AddEntity from '../actions/AddEntity';
import { EdgeEditor } from './schema.edgeEdit';
import { CommitsApi, Position, SchemaEntity, SchemaGraph, SchemaRelationship, UndoChangeTargetEnum, UndoChangeTypeEnum } from '@/libs/client';
import UpdateEntity from '../actions/UpdateEntity';
import { ModifiedGraph } from '@/libs/graph/modified';
import { MutableRefObject } from 'react';
import cytoscape from 'cytoscape';

export function initContextMenu(
    cy: cytoscape.Core, 
    { openModal, closeAllModals } : ContainerProviderContextProps,
    commitId: string,
    reloadCommit: () => void,
    edgeEditor: EdgeEditor,
    modifiedGraphRef: MutableRefObject<ModifiedGraph<SchemaGraph, SchemaEntity, SchemaRelationship> | undefined>,
    fullSchema?: SchemaGraph){
    // @ts-ignore
    const instance = cy.contextMenus({
        evtType: 'cxttap',
        menuItems: [
        {
            id: 'select-all', 
            content: 'Select All',
            coreAsWell: true,
            onClickFunction: function () {
                cy.nodes().forEach(it => {
                    it.select()
                })
            },
            hasTrailingDivider: true,
        },
        {
            id: 'add', 
            content: 'Add Entity', 
            coreAsWell: true,
            onClickFunction: function (event: { position: Position }) {
                openModal('add_entity', <AddEntity position={event.position} commitId={commitId} reloadCommit={reloadCommit} onClose={closeAllModals}></AddEntity>, { title: 'Add Entity' })
            },
        },
        {
            id: 'set_layout',
            content: 'Set Layout', 
            coreAsWell: true,
            onClickFunction: async function () { 
                await new CommitsApi().addChanges(commitId!, [{
                    setLayout: {
                        layout: cy.nodes().reduce((acc, n) => {
                            acc[n.id()] = n.position()
                            return acc
                        }, {} as { [key: string]: Position })
                    }
                }])
                reloadCommit()
            },
        },
        {
            id: 'add_relationship', 
            content: 'Add Relationship', 
            selector: 'node[type!="removed"]', 
            onClickFunction: function (node: any) { 
                edgeEditor.start(node.target);
            },
        },
        {
            id: 'update', 
            content: 'Update', 
            selector: 'node[type!="removed"]', 
            onClickFunction: function () { 
                const n = cy.nodes(":selected")[0]
                const rawEntity = n.data('rawEntity') as SchemaEntity
                const materializedEntity = fullSchema?.entities[rawEntity.type!]
                const updatableEntity = modifiedGraphRef.current?.nodes.find(no => no.type === rawEntity.type) || { type: rawEntity.type!, fields: {} }
                openModal('update_entity', <UpdateEntity 
                    updatableEntity={updatableEntity}
                    materializedEntity={materializedEntity}
                    commitId={commitId} 
                    reloadCommit={reloadCommit} 
                    onClose={closeAllModals}
                ></UpdateEntity>, { title: 'Update Entity' })
            },
        },
        {
            id: 'undo', 
            content: 'Undo', 
            selector: 'node[type="new"],node[type="removed"],node[type="updated"],edge[type="new"],edge[type="removed"]', 
            onClickFunction: async function () { 
                const getUndoType = (data: any) => {
                    if (data.new) {
                        return UndoChangeTypeEnum.Add
                    } else if (data.updated) {
                        return UndoChangeTypeEnum.Update
                    } else if (data.removed) {
                        return UndoChangeTypeEnum.Remove
                    }
                }
                await Promise.all([
                    ...cy.nodes(":selected").map(n => ({
                        elementId: n.id(),
                        target: UndoChangeTargetEnum.Entity,
                        type: getUndoType(n.data())
                    })),
                    ...cy.edges(":selected").map(e => ({
                        elementId: e.id(),
                        target: UndoChangeTargetEnum.Relationship,
                        type: getUndoType(e.data())
                    }))]
                    .filter(n => n.type)
                    .map(n => new CommitsApi().undoChange(commitId!, n)))
                reloadCommit()
            },
        },
        {
            id: 'remove', // ID of menu item
            content: 'Remove', // Display content of menu item
            tooltipText: 'Remove', // Tooltip text for menu item
            // Filters the elements to have this menu item on cxttap
            // If the selector is not truthy no elements will have this menu item on cxttap
            selector: 'node[type!="removed"], edge[type!="removed"]', 
            onClickFunction: async function () { // The function to be executed on click
                await Promise.all(cy.nodes(":selected")
                    .map(async n => {
                        await new CommitsApi().addChanges(commitId!, [{
                            removeEntity: {
                                id: n.id()!,
                            }
                        }])
                    }))
                await Promise.all(cy.edges(':selected')
                    .map(async n => {
                        await new CommitsApi().addChanges(commitId!, [{
                            removeRelationship: {
                                id: n.id()!,
                            }
                        }])
                    }))
                cy.nodes().forEach(n => { n.unselect() })
                cy.edges().forEach(e => { e.unselect() })
                reloadCommit()
            },
        },
    ]
    });
    cy.on('cxttap', (evt) => {
        cy.nodes().forEach(n => { n.unselect() })
        if (evt.target.select) {
            evt.target.select()
        }
    })
}
