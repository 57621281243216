import { ActionResult } from "@/components/actions/actions";
import { useContainer } from "@/components/containers/ContainerProvider";
import SfyForm from "@/components/form/SfyForm";
import FormSelect from "@/components/form/inputs/FormSelect";
import FormTextInput from "@/components/form/inputs/FormTextInput";
import { GraphsApi, SchemaGraph, Script, ScriptTypeEnum, ScriptsApi, TriggerPeriodEnum } from "@/libs/client";
import { useProject } from "@/libs/project/ProjectProvider";
import { sortBy } from "@/libs/utils/sort";
import { Button, Flex, Group, Select, Stack, Switch } from "@mantine/core";
import { useForm } from "@mantine/form";
import CodeEditor from '@uiw/react-textarea-code-editor';
import { useEffect, useMemo, useState } from "react";

export function ScriptForm({ onSubmit, initialScript, type }: { initialScript?: Script, type: ScriptTypeEnum, onSubmit: (values: any) => Promise<ActionResult> }) {
    const { curProject } = useProject()
    const { closeAllModals } = useContainer();
    const [enableTrigger, setEnableTrigger] = useState<boolean>(initialScript?.trigger != null)
    const form = useForm({
        initialValues: {
            language: 'PYTHON',
            ...initialScript,
        }
    })
    const [schema, setSchema] = useState<SchemaGraph>()
    const fetchSchema = async () => {
        const resp = await new GraphsApi().schemaGraph(curProject.id!);
        if (resp.status === 200) {
            setSchema(resp.data);
        }
    }
    useEffect(() => {
        if (type === 'CONDITION') {
            fetchSchema()
        }
    }, [])
    const [code, setCode] = useState('')
    useEffect(() => {
        if (initialScript)
            setCode(initialScript.data || '')
    }, [])
    const entityOpts = useMemo(() => {
        if (!schema){
            return [];
        }
        return Object.keys(schema.entities).map(e => ({ value: e, label: e })).sort(sortBy(e => e.label))
    }, [schema])
    const [testResult, setTestResult] = useState('')
    return <SfyForm onClose={closeAllModals} onSubmit={() => onSubmit({
            ...form.values,
            type,
            data: code,
        })}>
        <Flex>
        <Stack style={{flex:1}}>
            <FormTextInput
                label="Name"
                withAsterisk
                {...form.getInputProps('name')}
            ></FormTextInput>
            <Select
                label="Language"
                allowDeselect={false}
                data={[
                    { value: 'PYTHON', label: 'Python (Beta)' },
                    { value: 'PYTHON2', label: 'Python 2 (Legacy)' },
                ]}
                withAsterisk
                {...form.getInputProps('language')}
            ></Select>
            <label style={{paddingLeft: 5, marginTop: 10}}>Script</label>
            <CodeEditor
                value={code}
                language="python"
                placeholder="Please enter your Python script."
                onChange={(evn: any) => setCode(evn.target.value)}
                padding={15}
                minHeight={200}
                style={{
                    borderRadius: 10,
                    fontSize: 12,
                    backgroundColor: "#fafafa",
                    fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                }}
            />
            { type === 'REPORT' ? <><label style={{paddingLeft: 5, marginTop: 10}}>Trigger</label>
            <Switch label="Enable trigger schedule" checked={enableTrigger} onChange={e => {
                setEnableTrigger(e.currentTarget.checked)
                if (!e.currentTarget.checked) {
                    form.setFieldValue('trigger', undefined)
                } else {
                    form.setFieldValue('trigger', {
                        period: 'HOUR',
                        duration: 1,
                    })
                }
            }}></Switch>
            { enableTrigger && <Group>
                <div>Run Every</div> <Select data={[
                    {value: 'HOUR', label: 'Hour'}, 
                    {value: 'DAY', label: 'Day'}, 
                ]}
                value={form.values.trigger?.period}
                onChange={v => form.setFieldValue('trigger', {
                    ...form.values.trigger,
                    period: v as TriggerPeriodEnum,
                })}
                ></Select>
            </Group> }</> : schema && <FormSelect
                label="Target"
                withAsterisk
                allowDeselect={false}
                description="Target element of the condition"
                searchable
                data={entityOpts}
                {...form.getInputProps('target')}
            ></FormSelect>
}
        </Stack>
        <div style={{flex: 1, overflow: 'hidden',
  wordWrap: 'break-word', paddingLeft: '2rem'}}>
            <Button disabled={form.values.language === 'PYTHON'} color="blue" style={{float: 'right'}} onClick={async () => {
                const resp = await new ScriptsApi().runTestScript(curProject.id!, { script: code || '' })
                setTestResult(resp.data)
            }}>Run Test</Button>
            <pre style={{maxHeight: '450px', minHeight: '450px', overflow: 'scroll', backgroundColor: '#fafafa', border: '1px solid #f0f0f0', borderRadius: 5, fontSize: '0.9rem', padding: '0.5rem 1rem', marginTop: '4rem'}}>
                {testResult}
            </pre>
        </div>
        </Flex>
    </SfyForm>
}