import { TextInput } from '@mantine/core';
import { CgSearch } from 'react-icons/cg';
import { TbVectorTriangle } from 'react-icons/tb';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { useGraphDisplay } from '../../providers/GraphDisplayProvider';
import { TreeElement, TreeView } from './TreeView';
import { FieldOptions } from './FieldOptions';
import classes from './GraphNavigator.module.css'

export type GraphNavigatorProps = {
    title: string
    loading: boolean
    elements: TreeElement[]
    onExpand?: (id: string) => void
}

export default function GraphNavigator({ title, loading, elements, onExpand }: GraphNavigatorProps) {
    const [search, setSearch] = useState("")
    const { activeType } = useGraphDisplay()
    
    const { t } = useTranslation()
    const [treeHeight, setTreeHeight] = useState<number>();
    const treeRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
        if (!treeRef.current) return; // wait for the elementRef to be available
        const resizeObserver = new ResizeObserver(() => {
            if (treeRef.current) {
                setTreeHeight(treeRef.current?.getBoundingClientRect().height);
            }
        });
        resizeObserver.observe(treeRef.current);
        return () => resizeObserver.disconnect(); // clean up 
    }, [treeRef])
    return <div className={classes.navigator}>
        <div className={classes.titleWrapper}>
            <div className={classes.title}>
                <TbVectorTriangle style={{marginRight: 5}}></TbVectorTriangle>
                { title }
            </div>
        </div>
        <div>
            <TextInput
                placeholder={t('common.search') + '...'}
                leftSection={<CgSearch color="white" />}
                onChange={(event) => setSearch(event.currentTarget.value)}
                styles={{
                    input: { backgroundColor: '#313131', borderRadius: 0, fontSize: '0.9rem', color: 'white', borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 0}
                }}
            >
            </TextInput>
        </div>
        <div style={{color: '#bdbdbd', display: 'flex', alignItems: 'center', padding: '0px 15px', paddingTop: 10, fontSize: '0.8rem' }}>
            <div style={{flex: 1}}>NAVIGATOR</div>
        </div>
        <div style={{overflowY: 'auto', flex: 1}} ref={treeRef}>
            { !loading && <TreeView elements={elements} height={treeHeight} search={search} onExpand={id => onExpand && onExpand(id.split("||")[0])}></TreeView>}
        </div>
        { activeType === 'DATA' ? <FieldOptions></FieldOptions> : undefined }
    </div>
}